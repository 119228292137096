/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  PROPUESTA__LIST_CONFOR_BY_ID_CLIENTE,
  PROPUESTA_CONFOR_BY_ID,
  PROPUESTA_LIST_CONFOR_DIF_ID,
  CREATE_SOLICITUD_GUIA,
} from "../../../Constants/ApiConstants"
import { Button } from "@mui/material"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import "../../../Styles/App/Apartado/Apartado.css"
import requests from "../../../Components/AxiosCalls/AxiosCall"
import NumberFormat from "react-number-format"
import PreviewImg from "../../../Components/PreviewImg"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import IconButton from "@mui/material/IconButton"
import { useNavigate, useParams } from "react-router-dom"
import Checkbox from "@mui/material/Checkbox"
import Divider from "@mui/material/Divider"

const SolicitarGuiaDhlAdd = (props) => {
  const { id } = useParams()
  const [_Propuestas, setPropuestas] = useState({})
  const navigate = useNavigate()

  const [_ListPropuestas, setListPropuestas] = useState([])
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const [_Ocultar, setOcultar] = useState(true)
  const [_Ocultar2, setOcultar2] = useState(true)
  const [_CheckOtros, setCheckOtros] = useState(false)
  const [_PiezasEnvio, setPiezasEnvio] = useState([])
  const [_idProp, setIdProp] = useState(null)

  const [_MbGuiaSolicitada, setMbGuiaSolicitada] = useState({
    //LngIdGuiaMb: 0,
    fecDia: "",
    lngIdRegistro: 0,
    fecEnvio: "",
    fecLlegada: "",
    txtGuiaMb: "",
    txtObservaciones: "",
    intIdStatusEnvio: 3,
    pdfGuia: "",
    piezas: [],
  })

  const handleChange = (event) => {
    if (event.target.checked === true) {
      getPropuestas()
      setOcultar2(false)
    } else {
      setListPropuestas([])
      setPiezasEnvio([])
      setOcultar2(true)
    }
    setCheckOtros(event.target.checked)
  }

  const handlePiezasEnvio = (e, idItem) => {
    let itemSeleccionados = [..._PiezasEnvio]
    if (e.target.checked === true) {
      itemSeleccionados = [idItem, ...itemSeleccionados]
      _PiezasEnvio.push(idItem)
    } else {
      itemSeleccionados = _PiezasEnvio.filter((item) => item !== idItem)
      setPiezasEnvio(itemSeleccionados)
    }
  }

  //abrir modal img
  const [IdImgPreview, setIdImgPreview] = useState()
  const [openModalImgPreview, setModalImgPreview] = useState(false)
  const handleOpenImgPreview = () => {
    setModalImgPreview(!openModalImgPreview)
  }

  const getPiezaSeleccionada = () => {
    props.setOpenLoadingScreen()
    requests
      .get(PROPUESTA_CONFOR_BY_ID, id)
      .then((response) => {
        setPropuestas(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log("Error: " + error)
        props.setCloseLoadingScreen()
      })
  }

  const getPropuestas = () => {
    props.setOpenLoadingScreen()
    requests
      .get(PROPUESTA_LIST_CONFOR_DIF_ID, id)
      .then((response) => {
        console.log(response)
        setListPropuestas(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log("Error: " + error)
        props.setCloseLoadingScreen()
      })
  }
  useEffect(() => {
    getPiezaSeleccionada()
  }, [])

  const guardar = () => {
    props.setOpenLoadingScreen()
    _PiezasEnvio.push(_Propuestas.lngIdRegistro)
    if (_PiezasEnvio.length > 0) {
      for (let i = 0; i < _PiezasEnvio.length; i++) {
        const element = _PiezasEnvio[i]
        let data = {
          PiezasId: parseInt(element),
        }
        _MbGuiaSolicitada.piezas.push(data)
      }
    }
    const formData = new FormData()
    formData.append("LngIdRegistro", _MbGuiaSolicitada.lngIdRegistro)
    formData.append("FecEnvio", _MbGuiaSolicitada.fecEnvio)
    formData.append("FecLlegada", _MbGuiaSolicitada.fecLlegada)
    formData.append("TxtGuiaMb", _MbGuiaSolicitada.txtGuiaMb)
    formData.append("TxtObservaciones", _MbGuiaSolicitada.txtObservaciones)
    formData.append("IntIdStatusEnvio", _MbGuiaSolicitada.intIdStatusEnvio)
    formData.append("PdfGuia", _MbGuiaSolicitada.pdfGuia)
    for (var i = 0; i < _MbGuiaSolicitada.piezas.length; i++) {
      let data = _MbGuiaSolicitada.piezas[i]
      formData.append("Piezas", data.PiezasId) //array
    }
    requests
      .post(CREATE_SOLICITUD_GUIA, formData)
      .then((response) => {
        setListPropuestas(response)
        props.setTypeSnackBar("success")
        props.setMessageAlert(
          "Gracias por solicitar la guía de envío. En breve recibirás la cotización en tu correo."
        )
        props.setOpenAlert(true)
        navigate("/")
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setTypeSnackBar("warning")
        props.setMessageAlertWarning(error.data.mensaje)
        props.setOpenAlertWarning(true)
        props.setCloseLoadingScreen()
      })
  }

  return (
    <div>
      <div className="containerViewUser py4 paddingTablets" hidden={_Ocultar}>
        {/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
        <div className="flex-start w90 pb2 pl1">
          <div className="chip-breadcrumbs">
            <span className="letter-breadcrumbs">V</span>
          </div>

          <div role="presentation" className="ml2">
            <Breadcrumbs aria-label="breadcrumb" separator="›">
              <Link
                underline="hover"
                className="breadcrumb-url"
                onClick={() => {
                  navigate(-1)
                }}
              >
                Ventas
              </Link>
              <Link underline="hover" className="breadcrumb-url bold">
                Solicitar guía de envío
              </Link>
            </Breadcrumbs>
          </div>
        </div>

        {/* //{_Propuestas.length === 0 ? null : ( */}
        <div className="box-consigna-pro">
          {/* //{_Propuestas.map((row, index) => ( */}
          <div className="box-list-consigna-pro">
            <div className="w20 relative">
              <img
                className="img-consigna-pro"
                src={_Propuestas.txtUrlImgFrente}
              />
              <div className="box-view-absolute">
                <IconButton
                  className="btn-blur"
                  onClick={function () {
                    setIdImgPreview(_Propuestas.txtUrlImgFrente)
                    handleOpenImgPreview()
                  }}
                >
                  <RemoveRedEyeIcon
                    className="RemoveEyeIconConsigna"
                    sx={{ m: 0, p: 0, color: "#000000" }}
                  />
                </IconButton>
              </div>
            </div>
            <div className="flex-column-start-between w80 pl1">
              <div className="w100">
                <div className="flex-between">
                  <span className="titleConsignaPropuestas upperCase ProductTitleMarca">
                    {_Propuestas.txtArticulo} {_Propuestas.txtMarca}{" "}
                    {_Propuestas.txtOtraMarca}
                    <span className="mx1"> </span>
                    <span className="p titleConsignaPropuestas ProductTitleMarca">
                      {/* {_Propuestas.txtTipoServicio} */}
                    </span>
                  </span>

                  <span className="p textFechaConsignaPropuestas co-grey">
                    {_Propuestas.fecPropuestas}
                  </span>
                </div>
                <span className="p textFechaConsignaPropuestas ProductTitleModelo bold">
                  <NumberFormat
                    value={_Propuestas.dblMontoPropuesta}
                    displayType={"text"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={true}
                    prefix={"$ "}
                    suffix={" DÓLARES"}
                  />
                </span>
              </div>

              <div className="flex-between w100">
                <p>
                  ¿Enviarás más piezas con la misma guía? da click en el cuadro
                  blanco y seleccíonalas{" "}
                  <Checkbox
                    checked={_CheckOtros}
                    onChange={handleChange}
                    size="small"
                  />
                </p>
                <Button
                  //disabled={_CheckOtros === true ? true : false}
                  style={{
                    display: _CheckOtros === true ? "none" : "",
                  }}
                  hidden
                  className="btn-calendar mr1 ProductTitleDescription"
                  //startIcon={<EventOutlinedIcon />}
                  onClick={guardar}
                >
                  Solicitar
                </Button>
              </div>
            </div>
          </div>
          {/* //))} */}
        </div>
        {/* //)} */}
      </div>
      <div hidden={_Ocultar2}>
        <div className="containerViewUser">
          <Divider sx={{ width: "60%" }}>
            Puedes seleccionar otras piezas
          </Divider>

          {_ListPropuestas.length === undefined ? null : (
            <>
              <div className="box-consigna-pro">
                {_ListPropuestas.map((row, index) => (
                  <div className="box-list-consigna-pro">
                    <div className="w20 relative">
                      <img
                        className="img-consigna-pro"
                        src={row.txtUrlImgFrente}
                      />
                      <div className="box-view-absolute">
                        <IconButton
                          className="btn-blur"
                          onClick={function () {
                            setIdImgPreview(row.txtUrlImgFrente)
                            handleOpenImgPreview()
                          }}
                        >
                          <RemoveRedEyeIcon
                            className="RemoveEyeIconConsigna"
                            sx={{ m: 0, p: 0, color: "#000000" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                    <div className="flex-column-start-between w80 pl1">
                      <div className="w100">
                        <div className="flex-between">
                          <span className="titleConsignaPropuestas upperCase ProductTitleMarca">
                            {row.txtArticulo} {row.txtMarca} {row.txtOtraMarca}
                            <span className="mx1"> - </span>
                            <span className="p titleConsignaPropuestas ProductTitleMarca">
                              {row.txtTipoServicio}
                            </span>
                          </span>

                          <span className="p textFechaConsignaPropuestas co-grey">
                            {row.fecPropuestas}
                          </span>
                        </div>
                        <span className="p textFechaConsignaPropuestas ProductTitleModelo bold">
                          <NumberFormat
                            value={row.dblMontoPropuesta}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={"$ "}
                            suffix={" DÓLARES"}
                          />
                        </span>
                      </div>
                      <div className="flex-between w100">
                        <p>
                          Seleccionar pieza{" "}
                          <Checkbox
                            key={index}
                            onChange={function (e) {
                              handlePiezasEnvio(e, row.lngIdRegistro)
                            }}
                            size="small"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          <Button
            className="btn-calendar mr1 ProductTitleDescription"
            size="small"
            onClick={guardar}
          >
            Solicitar
          </Button>
        </div>
      </div>
      <PreviewImg
        IdImgPreview={IdImgPreview}
        openModalImgPreview={openModalImgPreview}
        onCloseModalImgPreview={handleOpenImgPreview}
      />
    </div>
  )
}

export default SolicitarGuiaDhlAdd

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import {
	Card,
	CardActions,
	CardContent,
	Button,
	CardMedia,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
	GUIA_MB_EVALUACION_LIST,
	GUIA_MB_MARCAR_ENVIADO,
	GUIA_MB_ENVIO_COM_PAGO,
} from '../../../Constants/ApiConstants'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Chip, Alert, Fab, Typography } from '@mui/material'
import CitasAdd from '../../../Components/Preevaluaciones/Citas/CitasAdd'
import InfoIcon from '@mui/icons-material/Info'
import '../../../Styles/App/Apartado/Apartado.css'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import EventIcon from '@mui/icons-material/Event'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import SendIcon from '@mui/icons-material/Send'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useNavigate } from 'react-router-dom'
import {
	CALENDAR_VIEW,
	ENVIO_PIEZAS_DHL,
	ENVIO_PIEZAS_OTRA_PAQUETERIA,
	CALENDAR_VIEW_CON_FOR_MB,
} from '../../../Constants/routesConstants'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ThemeProvider, styled } from '@mui/styles'
import {
	gtmGoTo_AceptarPropuesta_Log,
	gtmGoTo_RechazaPropuesta_Log,
	gtmGoTo_NuevaCita_Log,
	gtm_OpenTerminos_Log,
} from '../../../Constants/GoogleTagManager/gtmConstants'
import { TablePagination } from '@mui/material'
import * as locales from '@mui/material/locale'
import { createTheme, useTheme } from '@mui/material/styles'

const Input = styled('input')({
	display: 'none',
})

const EnviosConForView = (props) => {
	const [_ListGuiaEnvios, setListGuiaEnvios] = useState([])
	const [_IdGuia, setIdGuia] = useState()
	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin
	const [nameProduct, setNameProduct] = useState([])

	const [_Ocultar, setOcultar] = useState(true)
	const [_Ocultar2, setOcultar2] = useState(true)
	const [_Comprobante, setComprobante] = useState(null)

	const navigate = useNavigate()

	//abrir modal img
	const [IdImgPreview, setIdImgPreview] = useState()
	const [openModalImgPreview, setModalImgPreview] = useState(false)
	const handleOpenImgPreview = () => {
		setModalImgPreview(!openModalImgPreview)
	}

	const getPropuestas = () => {
		props.setOpenLoadingScreen()
		requests
			.get(GUIA_MB_EVALUACION_LIST, userInfo.intIdUsuario)
			.then((response) => {
				console.log(response)
				setListGuiaEnvios(response)
				setNameProduct([])
				if (response.length > 0) {
					setOcultar(false)
					setOcultar2(true)
				} else {
					setOcultar(true)
					setOcultar2(false)
				}
				props.setCloseLoadingScreen()
				setIdGuia(null)
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getPropuestas()
	}, [])

	const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setOpen(!open)
	}

	const openCalendar = (lngIdPropuestas) => {
		gtmGoTo_NuevaCita_Log()
		navigate(`${CALENDAR_VIEW}${lngIdPropuestas}`)
	}
	const handleComprobante = (e) => {
		setComprobante(e.target.files[0])
	}

	const marcarEnviado = (id) => {
		props.setOpenLoadingScreen()
		requests
			.delete(GUIA_MB_MARCAR_ENVIADO, id)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageAlert(response.mensaje)
				props.setOpenAlert(true)
				props.setCloseLoadingScreen()
				setIdGuia(null)
				getPropuestas()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageAlertWarning(error.data.mensaje)
				props.setOpenAlertWarning(true)
				props.setCloseLoadingScreen()
			})
	}
	const adjuntarComprobante = (id) => {
		if (_Comprobante !== '' && _Comprobante !== null) {
			const formData = new FormData()
			formData.append('lngIdGuiaMb', id === null ? '' : id)
			formData.append(
				'txtUrlComprobanteGuia',
				_Comprobante === null ? '' : _Comprobante
			)
			props.setOpenLoadingScreen()
			requests
				.post(GUIA_MB_ENVIO_COM_PAGO, formData)
				.then((response) => {
					props.setTypeSnackBar('success')
					props.setMessageAlert(response.mensaje)
					props.setOpenAlert(true)
					props.setCloseLoadingScreen()
					setIdGuia(null)
					getPropuestas()
				})
				.catch((error) => {
					props.setTypeSnackBar('warning')
					props.setMessageAlertWarning(error.data.mensaje)
					props.setOpenAlertWarning(true)
					props.setCloseLoadingScreen()
				})
		} else {
			props.setTypeSnackBar('warning')
			props.setMessageAlertWarning('Debes adjuntar el comprobante de pago')
			props.setOpenAlertWarning(true)
			props.setCloseLoadingScreen()
		}
	}
	/*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION INICIO	::::::::::::::::::::::::::::::::::::::::::::::: */
	const [locale, setLocale] = React.useState('esES')
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}
	const theme = useTheme()
	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales[locale]),
		[locale, theme]
	)
	/*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION FIN	::::::::::::::::::::::::::::::::::::::::::::::: */
	return (
		<div>
			<div className='containerViewUser my4 paddingTablets' hidden={_Ocultar}>
				{/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
				<div className='flex-start w90 pb2 pl1'>
					<div className='chip-breadcrumbs'>
						<span className='letter-breadcrumbs'>E</span>
					</div>

					<div role='presentation' className='ml2'>
						<Breadcrumbs aria-label='breadcrumb' separator='›'>
							<Link
								underline='hover'
								className='breadcrumb-url '
								onClick={() => {
									navigate(-1)
								}}
							>
								Mis Envios
							</Link>
							<Link underline='hover' className='breadcrumb-url bold'>
								Estatus de envíos
							</Link>
						</Breadcrumbs>
					</div>
				</div>

				{_ListGuiaEnvios.length === 0 ? null : (
					<div className='box-consigna-pro'>
						{_ListGuiaEnvios
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => (
								<div className='box-list-consigna-pro'>
									<div className='flex-column-start-between w100 pl1'>
										<div className='flex-column-start w100' style={{ gap: 5 }}>
											<div className='flex-between '>
												<span className='titleConsignaPropuestas upperCase ProductTitleMarca'>
													No. Guía: {row.txtGuiaMb}
													<span className='mx1'> </span>
													<span className='p titleConsignaPropuestas ProductTitleMarca'></span>
												</span>

												<span className='p textFechaConsignaPropuestas co-grey'>
													<strong>Fecha solicitud: </strong> {row.fecDia}
												</span>
											</div>

											<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
												<strong>Estatus envío: &nbsp;</strong>
												{row.txtStatusEnvio}
											</span>

											<span className='p textFechaConsignaPropuestas ProductTitleDescription'></span>
											<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
												<strong>Paquetería: &nbsp;</strong>
												{row.txtNombrePaqueteria}
											</span>
											{row.txtStatusEnvio === 'Cotizacion' ? (
												<>
													<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
														<strong>Cotización: &nbsp;</strong>
														{row.dblCotizacion}
													</span>
													<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
														<strong>No.Cuenta: &nbsp;</strong>
														{row.txtNumCuenta}
													</span>

													<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
														<strong>Banco: &nbsp;</strong>
														{row.txtBanco}
													</span>
													<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
														<strong>Beneficiario: &nbsp;</strong>
														{row.txtBeneficiario}
													</span>
												</>
											) : null}
										</div>

										<div className='flex-start mt1'>
											{row.txtStatusEnvio ===
											'Devolución' ? null : row.txtStatusEnvio ===
											  'En tránsito' ? null : row.txtStatusEnvio ===
											  'Solicita guía' ? null : (
												<>
													{row.txtStatusEnvio === 'Guía solicitada envíada' ? (
														row.txtUrlGuia === null ||
														row.txtUrlGuia === '' ? null : (
															<>
																<Button
																	className='btn-calendar mr1'
																	startIcon={<FileDownloadIcon />}
																	onClick={function () {
																		window.open(row.txtUrlGuia, '_blank')
																	}}
																>
																	Descargar Guía
																</Button>
																<Button
																	className='btn-calendar mr1'
																	startIcon={<SendIcon />}
																	onClick={function () {
																		marcarEnviado(row.lngIdGuiaMb)
																	}}
																>
																	Marcar como enviado
																</Button>
															</>
														)
													) : null}

													{row.txtStatusEnvio === 'Recibido' ? (
														<Button
															className='btn-calendar mr1'
															startIcon={<EventIcon />}
															onClick={function () {
																navigate(
																	CALENDAR_VIEW_CON_FOR_MB + row.lngIdGuiaMb
																)
															}}
														>
															Agendar cita
														</Button>
													) : null}

													{row.txtStatusEnvio === 'Cotizacion' ? (
														<>
															<label htmlFor='txtUrlComprobanteGuia'>
																<Input
																	id='txtUrlComprobanteGuia'
																	type='file'
																	onChange={handleComprobante}
																/>
																<Button
																	startIcon={<CloudUploadIcon />}
																	variant='contained'
																	className='btn-fail mr1'
																	component='span'
																>
																	Adjuntar comprobante pago
																</Button>
															</label>
															<Typography
																variant='caption'
																gutterBottom
																component='div'
															>
																{_Comprobante !== null
																	? _Comprobante.name
																	: 'sin archivo'}
															</Typography>
															<Button
																className='btn-calendar ml1'
																onClick={function () {
																	adjuntarComprobante(row.lngIdGuiaMb)
																}}
															>
																Envíar
															</Button>
														</>
													) : null}
												</>
											)}
										</div>
									</div>
								</div>
							))}
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='center'
							spacing={2}
						>
							<Grid item xl={12} className='flex'>
								<ThemeProvider theme={themeWithLocale}>
									<TablePagination
										rowsPerPageOptions={[10, 25, 100]}
										component='div'
										count={_ListGuiaEnvios.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</ThemeProvider>
							</Grid>
						</Grid>
					</div>
				)}
			</div>

			<div hidden={_Ocultar2}>
				<div className='containerViewUser'>
					<Alert
						className='alert-no-data'
						icon={
							<InfoIcon fontSize='inherit' className='alert-icon-no-data' />
						}
					>
						Aún no cuentas piezas enviadas.
					</Alert>
				</div>
			</div>
			{/* <CitasEdit
				registroId={registroId}
				getListCitas={getListCitas}
				open={open}
				onClose={handleOpen}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
				setMessageAlert={props.setMessageAlert}
				setOpenAlert={props.setOpenAlert}
				setMessageAlertWarning={props.setMessageAlertWarning}
				setOpenAlertWarning={props.setOpenAlertWarning}
			/>
			<PreviewImg
				IdImgPreview={IdImgPreview}
				openModalImgPreview={openModalImgPreview}
				onCloseModalImgPreview={handleOpenImgPreview}
			/> */}
		</div>
	)
}

export default EnviosConForView

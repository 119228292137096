/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Chip } from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import '../../../Styles/GeneralStyles.css'
import Button from '@mui/material/Button'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'

import {
	CITAS_MB_EMPEÑO,
	CITAS_MB_DESEMPEÑO,
	CITAS_MB_CONSIGNA,
	CITAS_MB_CON_VENCIDAS,
	CITAS_MB_CONSIGNA_FORANEA,
	CITAS_MB_INTERCAMBIO,
	CITAS_MB_RENTA,
} from '../../../Constants/routesConstants'
import { useSelector } from 'react-redux'
import imgConsigna from '../../../Images/MB-Citas/consigna.jpg'
import imgDesempeño from '../../../Images/MB-Citas/desempeños.jpg'
import imgEmpeño from '../../../Images/MB-Citas/empeño.jpg'
import imgVencidas from '../../../Images/MB-Citas/vencidas.jpg'
// import imgbarter from '../../Images/barterlogin.jpeg'

import {
	gtmGoTo_CitasVentas_Log,
	gtmGoTo_CitasEmpenos_Log,
	gtmGoTo_CitasDesempeños_Log,
	gtmGoTo_CitasVentasVencidad_Log,
} from '../../../Constants/GoogleTagManager/gtmConstants'
import requests from '../../../Components/AxiosCalls/AxiosCall'
const CitasView = (props) => {
	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin
	// const [listaCForaneas, setListaCForaneas] = useState([])
	const navigate = useNavigate()
	// TbMbPropuesta/ObtenerListaConsignaForaneasIdCliente/a8a21030-4886-4149-aeeb-96c9be620912
	// userInfo.intIdUsuario
	useEffect(() => {
		// requests
		// 	.get(
		// 		'TbMbPropuesta/ObtenerListaConsignaForaneasIdCliente',
		// 		userInfo.intIdUsuario
		// 	)
		// 	.then((response) => {
		// 		setListaCForaneas(response)
		// 	})
		// 	.catch((error) => {
		// 		console.log(error)
		// 	})
	}, [])
	return (
		<div className='containerViewUser py4 paddingTablets '>
			{/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
			<div className='flex-start w90 pb2 pl1'>
				<div className='chip-breadcrumbs'>
					<span className='letter-breadcrumbs'>C</span>
				</div>

				<div role='presentation' className='ml2'>
					<Breadcrumbs aria-label='breadcrumb' separator='›'>
						<Link underline='hover' className='breadcrumb-url bold' href='/'>
							Citas
						</Link>
					</Breadcrumbs>
				</div>
			</div>

			<Grid container spacing={1}>
			<Grid item xs={12} md={6} lg={6} className='flex mt2'>
							<div
								className='card'
								style={{
									// backgroundImage: `url("https://images.unsplash.com/photo-1590247813693-5541d1c609fd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1509&q=80")`,
									backgroundImage: `url(${imgConsigna})`,
								}}
							>
								<div className='card-content'>
									<h2 className='card-title HelveticaNeue'>Mis Ventas</h2>
									<p className='card-body AvenirLight'>
										Aquí podrás ver las citas agendadas o reagendar una cita.
									</p>
									<Button
										className='buttonn'
										onClick={function () {
											gtmGoTo_CitasVentas_Log()
											navigate(CITAS_MB_CONSIGNA)
										}}
									>
										Ver citas
									</Button>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={6} lg={6} className='flex mt2'>
						<div
								className='card'
								style={{
									backgroundImage: `url(${imgVencidas})`,
									// backgroundImage: `url("https://www.campustraining.es/wp-content/uploads/2021/10/historia-almacen.jpg")`,
								}}
							>
								<div className='card-content'>
									<h2 className='card-title HelveticaNeue'>Ventas Vencidas</h2>
									<p className='card-body AvenirLight'>
										Aquí podrás agendar o reagendar una cita para recoger tus
										artículos.
									</p>

									<Button
										className='buttonn'
										onClick={function () {
											gtmGoTo_CitasVentasVencidad_Log()
											navigate(CITAS_MB_CON_VENCIDAS)
										}}
									>
										Ver citas
									</Button>
								</div>
							</div>
							{/* <div
								className='card'
								style={{
									// backgroundImage: `url("https://images.unsplash.com/photo-1549964336-67d7d7d74ac2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80")`,
									backgroundImage: `url(${imgEmpeño})`,
								}}
							>
								<div className='card-content'>
									<h2 className='card-title HelveticaNeue'>Mis Empeños</h2>
									<p className='card-body AvenirLight'>
										Aquí podrás ver las citas agendadas o reagendar una cita.
									</p>
									<Button
										className='buttonn'
										onClick={function () {
											gtmGoTo_CitasEmpenos_Log()
											navigate(CITAS_MB_EMPEÑO)
										}}
									>
										Ver citas
									</Button>
								</div>
							</div> */}
						</Grid>
						{/* <Grid item xs={12} md={6} lg={6} className='flex mt6 mb-20'>
							<div
								className='card'
								style={{
									// backgroundImage: `url("https://www.campustraining.es/wp-content/uploads/2021/10/historia-almacen.jpg")`,
									backgroundImage: `url(${imgDesempeño})`,
								}}
							>
								<div className='card-content'>
									<h2 className='card-title HelveticaNeue'>Mis Desempeños</h2>
									<p className='card-body AvenirLight'>
										Aquí podrás agendar o reagendar una cita para recoger tus
										artículos.
									</p>
									<Button
										className='buttonn'
										onClick={function () {
											gtmGoTo_CitasDesempeños_Log()
											navigate(CITAS_MB_DESEMPEÑO)
										}}
									>
										Ver citas
									</Button>
								</div>
							</div>
						</Grid> */}
						{/* <Grid item xs={12} md={6} lg={6} className='flex mt6 mb-20'>
							<div
								className='card'
								style={{
									backgroundImage: `url(${imgVencidas})`,
									// backgroundImage: `url("https://www.campustraining.es/wp-content/uploads/2021/10/historia-almacen.jpg")`,
								}}
							>
								<div className='card-content'>
									<h2 className='card-title HelveticaNeue'>Ventas Vencidas</h2>
									<p className='card-body AvenirLight'>
										Aquí podrás agendar o reagendar una cita para recoger tus
										artículos.
									</p>

									<Button
										className='buttonn'
										onClick={function () {
											gtmGoTo_CitasVentasVencidad_Log()
											navigate(CITAS_MB_CON_VENCIDAS)
										}}
									>
										Ver citas
									</Button>
								</div>
							</div>
						</Grid> */}
				{/* {listaCForaneas.length > 0 ? (
					<Grid item xs={12} md={6} lg={6} className='flex mt2'>
						<div
							className='card'
							style={{
								// backgroundImage: `url("https://images.unsplash.com/photo-1590247813693-5541d1c609fd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1509&q=80")`,
								backgroundImage: `url(${imgConsigna})`,
							}}
						>
							<div className='card-content'>
								<h2 className='card-title HelveticaNeue'>Mis Ventas</h2>
								<p className='card-body AvenirLight'>
									Aquí podrás ver las videollamadas agendadas o reagendar una
									nueva.
								</p>
								<Button
									className='buttonn'
									onClick={function () {
										gtmGoTo_CitasVentas_Log()
										navigate(CITAS_MB_CONSIGNA_FORANEA)
									}}
								>
									Ver citas
								</Button>
							</div>
						</div>
					</Grid>
				) : (
					<>
						<Grid item xs={12} md={6} lg={6} className='flex mt2'>
							<div
								className='card'
								style={{
									// backgroundImage: `url("https://images.unsplash.com/photo-1590247813693-5541d1c609fd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1509&q=80")`,
									backgroundImage: `url(${imgConsigna})`,
								}}
							>
								<div className='card-content'>
									<h2 className='card-title HelveticaNeue'>Mis Ventas</h2>
									<p className='card-body AvenirLight'>
										Aquí podrás ver las citas agendadas o reagendar una cita.
									</p>
									<Button
										className='buttonn'
										onClick={function () {
											gtmGoTo_CitasVentas_Log()
											navigate(CITAS_MB_CONSIGNA)
										}}
									>
										Ver citas
									</Button>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={6} lg={6} className='flex mt2'>
							<div
								className='card'
								style={{
									// backgroundImage: `url("https://images.unsplash.com/photo-1549964336-67d7d7d74ac2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80")`,
									backgroundImage: `url(${imgEmpeño})`,
								}}
							>
								<div className='card-content'>
									<h2 className='card-title HelveticaNeue'>Mis Empeños</h2>
									<p className='card-body AvenirLight'>
										Aquí podrás ver las citas agendadas o reagendar una cita.
									</p>
									<Button
										className='buttonn'
										onClick={function () {
											gtmGoTo_CitasEmpenos_Log()
											navigate(CITAS_MB_EMPEÑO)
										}}
									>
										Ver citas
									</Button>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={6} lg={6} className='flex mt6 mb-20'>
							<div
								className='card'
								style={{
									// backgroundImage: `url("https://www.campustraining.es/wp-content/uploads/2021/10/historia-almacen.jpg")`,
									backgroundImage: `url(${imgDesempeño})`,
								}}
							>
								<div className='card-content'>
									<h2 className='card-title HelveticaNeue'>Mis Desempeños</h2>
									<p className='card-body AvenirLight'>
										Aquí podrás agendar o reagendar una cita para recoger tus
										artículos.
									</p>
									<Button
										className='buttonn'
										onClick={function () {
											gtmGoTo_CitasDesempeños_Log()
											navigate(CITAS_MB_DESEMPEÑO)
										}}
									>
										Ver citas
									</Button>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={6} lg={6} className='flex mt6 mb-20'>
							<div
								className='card'
								style={{
									backgroundImage: `url(${imgVencidas})`,
									// backgroundImage: `url("https://www.campustraining.es/wp-content/uploads/2021/10/historia-almacen.jpg")`,
								}}
							>
								<div className='card-content'>
									<h2 className='card-title HelveticaNeue'>Ventas Vencidas</h2>
									<p className='card-body AvenirLight'>
										Aquí podrás agendar o reagendar una cita para recoger tus
										artículos.
									</p>

									<Button
										className='buttonn'
										onClick={function () {
											gtmGoTo_CitasVentasVencidad_Log()
											navigate(CITAS_MB_CON_VENCIDAS)
										}}
									>
										Ver citas
									</Button>
								</div>
							</div>
						</Grid>
					</>
				)} */}
			</Grid>
		</div>
	)
}

export default CitasView

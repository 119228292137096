import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import LoopIcon from "@mui/icons-material/Loop"
import CloseIcon from "@mui/icons-material/Close"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import "../../Styles/Styles.css"
import requests from "../../Components/AxiosCalls/AxiosAuth"
import TextField from "@mui/material/TextField"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { useForm } from "react-hook-form"
import Checkbox from "@mui/material/Checkbox"
import { styled } from "@mui/material/styles"
import {
  CLIENTES_REGISTRER_EDIT,
  CIUDADES_LIST,
  ESTADOS_LIST,
  CLIENTES_BY_ID_USER2,
  CLIENTES_DIRECCION,
  BANCO_LIST,
  CLIENTES_CUENTA_BANCO,
  GENERO_LIST,
} from "../../Constants/ApiConstants"
import { INICIO } from "../../Constants/routesConstants"
import { useSelector } from "react-redux"
import FormHelperText from "@mui/material/FormHelperText"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import "../../Styles/App/User/Profile.css"
import IconButton from "@mui/material/IconButton"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

import TableContratos from "./TableContratos"
import { useNavigate } from "react-router-dom"

import InfoBancario from "./InfoBancario"

import {
  gtmGoTo_HabilitarEdicionPerfil_Log,
  gtmGoTo_SendEdicionPerfil_Log,
} from "../../Constants/GoogleTagManager/gtmConstants"

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #CBB878",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#CBB878",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#CBB878",
  },
})

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  )
}

const AntTabs = styled(Tabs)({
  borderBottom: "0px solid #CBB878",
  "& .MuiTabs-indicator": {
    backgroundColor: "#CBB878",
  },
})
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    marginRight: window.screen.width <= 600 ? null : theme.spacing(1),
    color: "#000",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: window.screen.width <= 600 ? 13.5 : 17,
    fontFamily: [].join(","),
    "&:hover": {
      backgroundColor: "#ffffff08",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#CBB878",
      fontWeight: "600",
      backgroundColor: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
)
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
const ClientesProfile = (props) => {
  let navigate = useNavigate()

  const userLogin = useSelector((state) => state.userLogin)
  const [value, setValue] = React.useState(0)
  const { loading, error, userInfo } = userLogin
  const methods = useForm()
  const { handleSubmit } = methods
  const [_checkEnvio, setcheckEnvio] = useState(false)
  const [_ListEstados, setListEstados] = useState([])
  const [_ListCiudades, setListCiudades] = useState([])
  const [_ListEstados2, setListEstados2] = useState([])
  const [_ListCiudades2, setListCiudades2] = useState([])
  const [_ListaBancos, setListaBancos] = useState([])
  const [_ListGenero, setListGenero] = useState([])

  const [_Cliente, setCliente] = useState({
    lngIdCliente: "",
    txtNombre: "",
    txtSNombre: "",
    txtAParterno: "",
    txtAMaterno: "",
    txtCelular: "",
    txtRfc: "",
    txtEmail: "",
    txtObservaciones: "",
    fecAlta: "",
    fecBaja: "",
    txtPassword: "",
    txtPasswordConfirma: "",
    bolFormulario: true,
    bolConfirmaEmail: false,
    intIdGenero: "",
    direccion: [],
    imagen: [],
    cuentaBancaria: [],
  })

  const handleCliente = (event) => {
    setCliente({ ..._Cliente, [event.target.name]: event.target.value })
  }
  const handleChangeTab = (event, newValue) => {
    setValue(newValue)
  }
  const [_DirCliente, setDirCliente] = useState({
    txtDireccion: "",
    txtComplemento: "",
    intIdEstado: "",
    intIdCiudad: "",
    intIdPais: 0,
    intIdTipoDir: 0,
    txtCodigoPostal: "",
  })

  const handleDirCliente = (event) => {
    setDirCliente({ ..._DirCliente, [event.target.name]: event.target.value })
  }
  const [_DirEnvio, setDirEnvio] = useState({
    txtDireccion: "",
    txtComplemento: "",
    intIdEstado: "",
    intIdCiudad: "",
    intIdPais: 0,
    intIdTipoDir: 1,
    txtCodigoPostal: "",
  })

  const handleDirEnvio = (event) => {
    setDirEnvio({ ..._DirEnvio, [event.target.name]: event.target.value })
  }

  const [_Cuenta, setCuenta] = useState({
    lngIdCuentasCliente: "",
    lngIdCliente: "",
    intIdBanco: "",
    numCuenta: "",
    numClabe: "",
    numIndentificacion: "",
    txtTelefono: "",
    txtObservaciones: "",
    txtTitular: "",
  })

  const hanldeCuenta = (event) => {
    setCuenta({ ..._Cuenta, [event.target.name]: event.target.value })
  }
  const getListGenero = () => {
    props.setOpenLoadingScreen()
    requests
      .get(GENERO_LIST)
      .then((response) => {
        setListGenero(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }
  const getListEstados = () => {
    props.setOpenLoadingScreen()
    requests
      .get(ESTADOS_LIST)
      .then((response) => {
        setListEstados(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const handleEstado = (e) => {
    props.setOpenLoadingScreen()
    setDirCliente({ ..._DirCliente, intIdEstado: e.target.value })
    requests
      .get(CIUDADES_LIST, e.target.value)
      .then((response) => {
        setListCiudades(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const getListEstados2 = () => {
    props.setOpenLoadingScreen()
    requests
      .get(ESTADOS_LIST)
      .then((response) => {
        setListEstados2(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const handleEstado2 = (event) => {
    props.setOpenLoadingScreen()
    setDirEnvio({ ..._DirEnvio, intIdEstado: event.target.value })
    requests
      .get(CIUDADES_LIST, event.target.value)
      .then((response) => {
        setListCiudades2(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const handleCiudad2 = (event) => {
    props.setCloseLoadingScreen()
    setDirEnvio({ ..._DirEnvio, intIdCiudad: event.target.value })
  }

  const getBancos = () => {
    props.setOpenLoadingScreen()
    requests
      .get(BANCO_LIST)
      .then((response) => {
        setListaBancos(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const guardar = async () => {
    props.setOpenLoadingScreen()
    if (
      _DirEnvio.intIdEstado !== "" &&
      _DirEnvio.intIdCiudad !== "" &&
      _DirEnvio.txtCodigoPostal !== "" &&
      _DirEnvio.txtComplemento !== "" &&
      _DirEnvio.txtDireccion !== ""
    ) {
      _Cliente.direccion.push(_DirEnvio)
    }

    _Cliente.direccion.push(_DirCliente)
    _Cliente.cuentaBancaria.push(_Cuenta)
    console.log(_Cliente)

    if (
      _Cliente.direccion.length > 0 &&
      (_Cliente.intIdGenero !== 0 || _Cliente.intIdGenero !== "") &&
      _Cliente.txtRfc !== ""
    ) {
      requests
        .put(CLIENTES_REGISTRER_EDIT, _Cliente)
        .then((response) => {
          gtmGoTo_SendEdicionPerfil_Log()
          props.setTypeSnackBar("success")
          props.setMessageAlert(response.mensaje)
          props.setOpenAlert(true)
          navigate(INICIO)
          window.location.reload()
          props.setCloseLoadingScreen()
        })
        .catch((error) => {
          props.setTypeSnackBar("warning")
          props.setMessageAlertWarning(error.data.mensaje)
          props.setOpenAlertWarning(true)
          props.setCloseLoadingScreen()
          console.log(error)
        })
    } else {
      props.setTypeSnackBar("warning")
      props.setMessageAlertWarning("Datos incompletos, favor de verificar.")
      props.setOpenAlertWarning(true)
      props.setCloseLoadingScreen()
    }
  }

  const [_Ocultar, setOcultar] = useState(true)
  const [_MensajeError, setMensajeError] = useState("")
  const validatePasswords = () => {
    let valido = / /
    setMensajeError("")
    if (_Cliente.txtPassword === _Cliente.txtPasswordConfirma) {
      if (_Cliente.txtPassword.match(/[A-Z]/)) {
        if (_Cliente.txtPassword.match(/\d/)) {
          if (!valido.test(_Cliente.txtPassword)) {
            if (_Cliente.txtPassword.length <= 15) {
            } else {
              setOcultar(false)
              setMensajeError(
                "La contraseña no debe ser mayor de 15 caracteres."
              )
            }
          } else {
            setOcultar(false)
            setMensajeError("La contraseña no debe tener espacios en blanco.")
          }
        } else {
          setOcultar(false)
          setMensajeError("La contraseña debe tener al menos un número.")
        }
      } else {
        setOcultar(false)
        setMensajeError(
          "La contraseña debe tener al menos una letra mayuscula."
        )
      }
    } else {
      setOcultar(false)
      setMensajeError("Las contraseñas no coiciden.")
    }
  }

  useEffect(() => {
    getListGenero()
    getListEstados()
    getListEstados2()
    getBancos()
    getRegistro()
  }, [])

  const getRegistro = () => {
    props.setOpenLoadingScreen()
    requests
      .get(CLIENTES_BY_ID_USER2, userInfo.intIdUsuario)
      .then((response) => {
        asignarValores(response)
        requests
          .get(CLIENTES_DIRECCION, response.lngIdCliente)
          .then((response) => {
            asignarValoresDir(response)
          })
          .catch((error) => {
            console.log(error)
          })
        requests
          .get(CLIENTES_CUENTA_BANCO, response.lngIdCliente)
          .then((response) => {
            AsignarCuentas(response)
          })
          .catch((error) => {
            console.log(error)
          })
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }
  const asignarValores = (objeto) => {
    console.log(objeto)
    setCliente({
      ..._Cliente,
      lngIdCliente: objeto.lngIdCliente === null ? "" : objeto.lngIdCliente,
      txtNombre: objeto.txtNombre === null ? "" : objeto.txtNombre,
      txtSNombre: objeto.txtSNombre === null ? "" : objeto.txtSNombre,
      txtAParterno: objeto.txtAParterno === null ? "" : objeto.txtAParterno,
      txtAMaterno: objeto.txtAMaterno === null ? "" : objeto.txtAMaterno,
      txtCelular: objeto.txtCelular === null ? "" : objeto.txtCelular,
      txtRfc: objeto.txtRfc === null ? "" : objeto.txtRfc,
      txtEmail: objeto.txtEmail === null ? "" : objeto.txtEmail,
      txtObservaciones:
        objeto.txtObservaciones === null ? "" : objeto.txtObservaciones,
      fecAlta: objeto.fecAlta === null ? "" : objeto.fecAlta,
      fecBaja: objeto.fecBaja === null ? "" : objeto.fecBaja,
      txtPassword: objeto.txtPassword === null ? "" : objeto.txtPassword,
      txtPasswordConfirma:
        objeto.txtPasswordConfirma === null ? "" : objeto.txtPasswordConfirma,
      bolFormulario: objeto.bolFormulario === null ? "" : objeto.bolFormulario,
      bolConfirmaEmail:
        objeto.bolConfirmaEmail === null ? "" : objeto.bolConfirmaEmail,
      intIdGenero: objeto.intIdGenero === null ? "" : objeto.intIdGenero,
    })
  }

  const handlechecked = (event) => {
    setcheckEnvio(event.target.checked)
  }
  const asignarValoresDir = (objeto) => {
    for (var z = 0; z < objeto.length; z++) {
      let regi = objeto[z]
      if (regi.intIdTipoDir === 1) {
        setcheckEnvio(true)
        setDirEnvio({
          ..._DirEnvio,
          lngIdDireccion: regi === null ? "" : regi.lngIdDireccion,
          txtDireccion: regi === null ? "" : regi.txtDireccion,
          txtComplemento: regi === null ? "" : regi.txtComplemento,
          intIdEstado: regi === null ? "" : regi.intIdEstado,
          intIdCiudad: regi === null ? "" : regi.intIdCiudad,
          intIdPais: regi === null ? "" : regi.intIdPais,
          intIdTipoDir: regi === null ? "" : regi.intIdTipoDir,
          txtCodigoPostal: regi === null ? "" : regi.txtCodigoPostal,
        })
        requests
          .get(CIUDADES_LIST, regi.intIdEstado)
          .then((response) => {
            setListCiudades2(response)
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        setcheckEnvio(false)
        setDirCliente({
          ..._DirCliente,
          lngIdDireccion: regi === null ? "" : regi.lngIdDireccion,
          txtDireccion: regi === null ? "" : regi.txtDireccion,
          txtComplemento: regi === null ? "" : regi.txtComplemento,
          intIdEstado: regi === null ? "" : regi.intIdEstado,
          intIdCiudad: regi === null ? "" : regi.intIdCiudad,
          intIdPais: regi === null ? "" : regi.intIdPais,
          intIdTipoDir: regi === null ? "" : regi.intIdTipoDir,
          txtCodigoPostal: regi === null ? "" : regi.txtCodigoPostal,
        })
        requests
          .get(CIUDADES_LIST, regi.intIdEstado)
          .then((response) => {
            setListCiudades(response)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  const AsignarCuentas = (objeto) => {
    setCuenta({
      ..._Cuenta,
      lngIdCuentasCliente:
        objeto.lngIdCuentasCliente === null ? "" : objeto.lngIdCuentasCliente,
      lngIdCliente: objeto.lngIdCliente === null ? "" : objeto.lngIdCliente,
      intIdBanco: objeto.intIdBanco === null ? "" : objeto.intIdBanco,
      numCuenta: objeto.numCuenta === null ? "" : objeto.numCuenta,
      numClabe: objeto.numClabe === null ? "" : objeto.numClabe,
      numIndentificacion:
        objeto.numIndentificacion === null ? "" : objeto.numIndentificacion,
      txtTelefono: objeto.txtTelefono === null ? "" : objeto.txtTelefono,
      txtObservaciones:
        objeto.txtObservaciones === null ? "" : objeto.txtObservaciones,
      txtTitular: objeto.txtTitular === null ? "" : objeto.txtTitular,
    })
  }

  const [disabled, setDisabled] = useState(true)
  const handleEditProfile = () => {
    gtmGoTo_HabilitarEdicionPerfil_Log()
    setDisabled(!disabled)
  }

  const [openModal, setModal] = useState(false)
  const handleOpen = () => {
    setModal(!openModal)
  }

  return (
    <div className="containerViewUser">
      <div className="box-header-profile">
        <div className="header-profile1"></div>
        <div className="avatar-profile">
          <p className="letter-avatar">
            {userInfo.nombre
              ? userInfo.nombre.substring(0, 1).toUpperCase()
              : null}
          </p>
        </div>
        <div className="header-profile2">
          <p className="title-profile mt4">
            {userInfo.nombre + " " + userInfo.apaterno}
          </p>

          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                backgroundColor: "#FFF",
                display: "flex",
                borderRadius: "5px",
                paddingLeft: 2,
              }}
            >
              <AntTabs
                value={value}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
                variant="scrollable"
              >
                <AntTab label="Mi Información" {...a11yProps(0)} />
                <AntTab label="Mis Contratos" {...a11yProps(1)} />
              </AntTabs>
            </Box>
          </Box>
        </div>
      </div>
      <div
        className="Container-Profile-Body"
        component="form"
        onSubmit={handleSubmit(guardar)}
      >
        <TabPanel className="animated fadeIn" value={value} index={0}>
          <div className="box-profile-inf">
            <div className="HeaderBoxProfile">
              <p className="title-profile-inf">Datos personales</p>
            </div>
            <div className="box-profile-inf-body">
              <div className="flex-between">
                <Button
                  className="btn-edit-profile"
                  startIcon={
                    disabled === true ? (
                      <AutoFixHighIcon sx={{ ml: 1 }} />
                    ) : (
                      <CloseIcon sx={{ ml: 1 }} />
                    )
                  }
                  onClick={handleEditProfile}
                >
                  Editar Perfil
                </Button>
                {disabled === true ? null : (
                  <Button
                    onClick={guardar}
                    className="btn-save-edit"
                    startIcon={<LoopIcon />}
                  >
                    Actualizar Perfil
                  </Button>
                )}
              </div>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                direction="row"
                container
                spacing={1}
              >
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="txtNombre"
                    required
                    fullWidth
                    id="txtNombre"
                    label="Nombre"
                    color="warning"
                    size="small"
                    value={_Cliente.txtNombre}
                    onChange={handleCliente}
                    variant="standard"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    id="txtSNombre"
                    label="Segundo nombre"
                    name="txtSNombre"
                    color="warning"
                    size="small"
                    value={_Cliente.txtSNombre}
                    onChange={handleCliente}
                    variant="standard"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    id="txtAParterno"
                    label="Apellido paterno"
                    name="txtAParterno"
                    color="warning"
                    size="small"
                    value={_Cliente.txtAParterno}
                    onChange={handleCliente}
                    variant="standard"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    id="txtAMaterno"
                    label="Apellido materno"
                    name="txtAMaterno"
                    color="warning"
                    size="small"
                    value={_Cliente.txtAMaterno}
                    onChange={handleCliente}
                    variant="standard"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    id="txtRfc"
                    label="Cédula"
                    name="txtRfc"
                    color="warning"
                    size="small"
                    value={_Cliente.txtRfc}
                    onChange={handleCliente}
                    variant="standard"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    id="txtCelular"
                    label="Celular"
                    name="txtCelular"
                    color="warning"
                    size="small"
                    value={_Cliente.txtCelular}
                    onChange={handleCliente}
                    variant="standard"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    id="txtEmail"
                    label="Correo electrónico"
                    name="txtEmail"
                    color="warning"
                    size="small"
                    type="email"
                    value={_Cliente.txtEmail}
                    onChange={handleCliente}
                    variant="standard"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    name="txtPassword"
                    label="Contraseña"
                    type="password"
                    id="txtPassword"
                    color="warning"
                    size="small"
                    value={_Cliente.txtPassword}
                    onBlur={validatePasswords}
                    onChange={handleCliente}
                    variant="standard"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    name="txtPasswordConfirma"
                    label="Confirmar contraseña"
                    type="password"
                    id="txtPasswordConfirma"
                    color="warning"
                    size="small"
                    value={_Cliente.txtPasswordConfirma}
                    onBlur={validatePasswords}
                    onChange={handleCliente}
                    variant="standard"
                    disabled={disabled}
                  />
                  <FormHelperText
                    id="component-error-text"
                    sx={{ color: "red" }}
                    hidden={_Ocultar}
                  >
                    {_MensajeError}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    color="warning"
                    required
                    disabled={disabled}
                  >
                    <InputLabel id="intIdGenero">Sexo</InputLabel>
                    <Select
                      labelId="intIdGenero"
                      id="intIdGenero"
                      name="intIdGenero"
                      value={_Cliente.intIdGenero}
                      onChange={handleCliente}
                      label="Estado"
                      inputProps={{
                        name: "intIdGenero",
                        id: "intIdGenero",
                      }}
                    >
                      <MenuItem value="" />
                      {_ListGenero.map((object, index) => (
                        <MenuItem value={object.intIdGeneroRow}>
                          {object.textGenero}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <p className="title-profile-inf">Dirección</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                direction="row"
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  sx={{ p: 2 }}
                >
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      color="warning"
                      required
                      disabled={disabled}
                    >
                      <InputLabel id="intIdEstado">Provincia</InputLabel>
                      <Select
                        labelId="intIdEstado"
                        id="intIdEstado"
                        name="intIdEstado"
                        value={_DirCliente.intIdEstado}
                        onChange={handleEstado}
                        label="Estado"
                        inputProps={{
                          name: "intIdEstado",
                          id: "intIdEstado",
                        }}
                      >
                        <MenuItem value="" />
                        {_ListEstados.map((object, index) => (
                          <MenuItem value={object.intIdEstado}>
                            {object.txtEstado}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      color="warning"
                      required
                      disabled={disabled}
                    >
                      <InputLabel id="intIdCiudad">Distrito</InputLabel>
                      <Select
                        labelId="intIdCiudad"
                        id="intIdCiudad"
                        name="intIdCiudad"
                        value={_DirCliente.intIdCiudad}
                        onChange={handleDirCliente}
                        label="Ciudad"
                        inputProps={{
                          name: "intIdCiudad",
                          id: "intIdCiudad",
                        }}
                      >
                        <MenuItem value="" />
                        {_ListCiudades.map((object, index) => (
                          <MenuItem value={object.intIdCiudad}>
                            {object.txtCiudad}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                      required
                      value={_DirCliente.txtDireccion}
                      onChange={handleDirCliente}
                      id="txtDireccion"
                      name="txtDireccion"
                      label="Dirección"
                      variant="standard"
                      size="small"
                      color="warning"
                      fullWidth
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                      required
                      value={_DirCliente.txtComplemento}
                      onChange={handleDirCliente}
                      id="txtComplemento"
                      name="txtComplemento"
                      label="Dirección 2"
                      variant="standard"
                      size="small"
                      color="warning"
                      fullWidth
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                      // required
                      value={_DirCliente.txtCodigoPostal}
                      onChange={handleDirCliente}
                      id="txtCodigoPostal"
                      name="txtCodigoPostal"
                      label="Código postal"
                      variant="standard"
                      size="small"
                      color="warning"
                      fullWidth
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <div className="container-Checkbox-Profile">
                      <BpCheckbox
                        checked={_checkEnvio}
                        onChange={handlechecked}
                      />

                      <p>Agregar dirección de envío</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div
            className="box-profile-inf"
            style={{ display: _checkEnvio === true ? "block" : "none" }}
          >
            <div className="HeaderBoxProfile">
              <p className="title-profile-inf">Dirección de envío</p>
            </div>
            <div className="box-profile-inf-body">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                direction="row"
                container
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    color="warning"
                    required
                    disabled={disabled}
                  >
                    <InputLabel id="intIdEstado">Provincia</InputLabel>
                    <Select
                      labelId="intIdEstado"
                      id="intIdEstado"
                      name="intIdEstado"
                      value={_DirEnvio.intIdEstado}
                      onChange={handleEstado2}
                      label="Estado"
                      inputProps={{
                        name: "intIdEstado",
                        id: "intIdEstado",
                      }}
                    >
                      <MenuItem value="" />
                      {_ListEstados2.map((object, index) => (
                        <MenuItem value={object.intIdEstado}>
                          {object.txtEstado}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    color="warning"
                    required
                    disabled={disabled}
                  >
                    <InputLabel id="intIdCiudad">Distrito</InputLabel>
                    <Select
                      labelId="intIdCiudad"
                      id="intIdCiudad"
                      name="intIdCiudad"
                      value={_DirEnvio.intIdCiudad}
                      onChange={handleCiudad2}
                      label="Ciudad"
                      inputProps={{
                        name: "intIdCiudad",
                        id: "intIdCiudad",
                      }}
                    >
                      <MenuItem value="" />
                      {_ListCiudades2.map((object, index) => (
                        <MenuItem value={object.intIdCiudad}>
                          {object.txtCiudad}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextField
                    required
                    value={_DirEnvio.txtDireccion}
                    onChange={handleDirEnvio}
                    id="txtDireccion"
                    name="txtDireccion"
                    label="Dirección"
                    variant="standard"
                    size="small"
                    color="warning"
                    fullWidth
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextField
                    required
                    value={_DirEnvio.txtComplemento}
                    onChange={handleDirEnvio}
                    id="txtComplemento"
                    name="txtComplemento"
                    label="Dirección 2"
                    variant="standard"
                    size="small"
                    color="warning"
                    fullWidth
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextField
                    //required
                    value={_DirEnvio.txtCodigoPostal}
                    onChange={handleDirEnvio}
                    id="txtCodigoPostal"
                    name="txtCodigoPostal"
                    label="Código postal"
                    variant="standard"
                    size="small"
                    color="warning"
                    fullWidth
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="box-profile-inf">
            <div className="HeaderBoxProfile">
              <p className="title-profile-inf">
                Datos Bancarios
                <IconButton
                  sx={{ ml: 5 }}
                  onClick={() => {
                    handleOpen()
                  }}
                >
                  <InfoOutlinedIcon sx={{ color: "#000" }} />
                </IconButton>
              </p>
            </div>
            <div className="box-profile-inf-body">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                direction="row"
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  sx={{ p: 2 }}
                >
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                      required
                      value={_Cuenta.txtTitular}
                      onChange={hanldeCuenta}
                      id="txtTitular"
                      name="txtTitular"
                      label="Titular"
                      variant="standard"
                      size="small"
                      color="warning"
                      fullWidth
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      color="warning"
                      required
                      disabled={disabled}
                    >
                      <InputLabel id="intIdBanco">Banco</InputLabel>
                      <Select
                        labelId="intIdBanco"
                        id="intIdBanco"
                        name="intIdBanco"
                        value={_Cuenta.intIdBanco}
                        onChange={hanldeCuenta}
                        label="Estado"
                        inputProps={{
                          name: "intIdBanco",
                          id: "intIdBanco",
                        }}
                      >
                        <MenuItem value="" />
                        {_ListaBancos.map((object, index) => (
                          <MenuItem value={object.intIdBanco}>
                            {" "}
                            {object.txtBanco}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                      required
                      value={_Cuenta.numCuenta}
                      onChange={hanldeCuenta}
                      id="numCuenta"
                      name="numCuenta"
                      label="No. de cuenta"
                      variant="standard"
                      size="small"
                      color="warning"
                      fullWidth
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                      required
                      value={_Cuenta.numClabe}
                      onChange={hanldeCuenta}
                      id="numClabe"
                      name="numClabe"
                      label="Tipo cuenta (Ahorro/Corriente)"
                      variant="standard"
                      size="small"
                      color="warning"
                      fullWidth
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </TabPanel>
        {/* ****************** MIS CONTRATOS ****************** */}

        <TabPanel className="animated fadeIn" value={value} index={1}>
          <div className="box-profile-table-info">
            <TableContratos
              userId={userInfo.intIdUsuario}
              setOpenLoadingScreen={props.setOpenLoadingScreen}
              setCloseLoadingScreen={props.setCloseLoadingScreen}
              setOpenSnackBar={props.setOpenSnackBar}
              setMessageSnackBar={props.setMessageSnackBar}
              setTypeSnackBar={props.setTypeSnackBar}
            />
          </div>
        </TabPanel>
      </div>
      <InfoBancario open={openModal} onClose={handleOpen} />
    </div>
  )
}
export default ClientesProfile

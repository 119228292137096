/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import { Chip, Alert, Fab } from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import '../../../Styles/GeneralStyles.css'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import HelpIcon from '@mui/icons-material/Help'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import {
  CLIENTES_CONSIGNAS_VENTAS,
  CLIENTES_CONSIGNAS_EN_VENTA,
  CLIENTES_CONSIGNAS_RECHAZO,
  CLIENTES_CONSIGNAS_LLEGADOS,
  CLIENTES_CON_PROPUESTAS_VIEW,
  CLIENTES_CONSIGNAS_PIEZAS,
  CLIENTES_PROFILE,
} from '../../../Constants/routesConstants'
import { CLIENTES_PERFIL_COMPLETO } from '../../../Constants/ApiConstants'

import ConsignasHelp from './ConsignasHelp'
import Avatar from '@mui/material/Avatar'
import iconConsigna from '../../../Images/MB-Consigna/Consigna_Icon.png'
import FloatinfWhatsApp from '../../../Components/FloatingWhatsApp/FloatinfWhatsApp'

import imgPropuestas from '../../../Images/MB-Consigna/Propuestas.jpg'
import imgEnVenta from '../../../Images/MB-Consigna/ArticulosVenta.jpg'
import imgVendidas from '../../../Images/MB-Consigna/Vendidas2.jpg'
import imgRechazados from '../../../Images/MB-Consigna/Rechazados.jpg'
import requests from '../../../Components/AxiosCalls/AxiosAuth'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'

import {
  gtmGoTo_Propuestas_Log,
  gtmGoTo_ArticulosVenta_Log,
  gtmGoTo_ConsignasVendidas_Log,
  gtmGoTo_ConsignasRechazadas_Log,
  gtmGoTo_Perfil_Log,
} from '../../../Constants/GoogleTagManager/gtmConstants'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  appBarTransparent: {
    display: 'block',
    // position: 'fixed',
    // color: '#cbb878',
    // bottom: '30px',
    // fontSize: '120px !important',
    // backgroundColor: '#0000001a !important',
    transition: 'all .9s ease-in-out !important',
    // backdropFilter: 'blur(3px)',
    opacity: 1,
  },
  appBarSolid: {
    // display: 'none',
    // backgroundColor: '#183076 !important',
    transition: 'all .9s ease-in-out !important',
    opacity: 0,
  },
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#cfb76d',
    color: '#000',
    textTransform: 'uppercase',
    fontWeight: '600',
    // fontSize: window.screen.width <= 600 ? 9 : null,
    border: 0,
    paddingTop: 5,
    paddingBottom: 7,
    // borderRadius: '20px',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#545454',
    // padding: window.screen.width <= 600 ? 13 : null,
    // fontSize: window.screen.width <= 600 ? 8 : null,
    border: 0,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
    fontWeight: 'bold',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const ClientesConsignaPiezasView = (props) => {
  const navigate = useNavigate()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  const [_Complet, setComplete] = useState()
  const getProfile = () => {
    //if(bool !== true){
    if (
      userInfo.intIdUsuario !== null &&
      userInfo.intIdUsuario !== '' &&
      userInfo.intIdUsuario !== undefined
    ) {
      requests
        .get(CLIENTES_PERFIL_COMPLETO, userInfo.intIdUsuario)
        .then((response) => {
          setComplete(response)
          sessionStorage.setItem('profile', JSON.stringify(response))
        })
        .catch((error) => {
          console.log(error)
        })
    }
    //}
  }
  useEffect(() => {
    getProfile()
  }, [])
  const SMSWHatsapp = () => {
    window.open(
      'https://api.whatsapp.com/send/?phone=50763590318&text=👋¡Hola! Quiero referir.',
      '_blank'
    )
  }
  function createData(name, calories, fat) {
    return { name, calories, fat }
  }
  const rows = [
    createData('$ 0 - $ 10,000.00', '3.4%'),
    createData('$ 10,001.00 - $ 20,000.00', '3.0%'),
    createData('$ 20,001.00 - $ 35,000.00', '2.5%'),
    createData('$ 35,001.00 - $ 100,000.00', '2.3%'),
    createData('$ 100,001.00 - (+)', '1.9%'),
  ]
  function createDataa(name, calories) {
    return { name, calories }
  }
  const rowsMovil = [
    createDataa('$ 0 - $ 10,000.00', '3.4%'),
    createDataa('$ 10,001.00 - $ 20,000.00', '3.0%'),
    createDataa('$ 20,001.00 - $ 35,000.00', '2.5%'),
    createDataa('$ 35,001.00 - $ 100,000.00', '2.3%'),
    createDataa('$ 100,001.00 - (+)', '1.9%'),
  ]
  const columns = [
    {
      id: 'Valuación de pieza',
      label: 'Valuación de pieza Mínimo',
      radius: '30px 0px 0px 30px',
    },
    {
      id: 'Comisión por referir',
      label: 'Comisión por referir',
      radius: '0px 30px 30px 0px',
    },
  ]
  const columnsMovil = [
    {
      id: 'Valuación de pieza',
      label: 'Valuación de pieza',
      // radius: '30px 0px 0px 30px',
    },
    {
      id: 'Comisión por referir',
      label: 'Comisión por referir',
      // radius: '0px 30px 30px 0px',
    },
  ]

  return (
    <div className="containerViewUser py4  paddingTablets">
      {_Complet === false ? (
        <Alert
          severity="warning"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontSize: '16px',
            mb: 1,
          }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={function () {
                gtmGoTo_Perfil_Log()
                navigate(CLIENTES_PROFILE)
              }}
            >
              Completar Perfil
            </Button>
          }
        >
          Es necesario completar tu perfil, para administrar tus ventas.
        </Alert>
      ) : null}

      {/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
      <div className="flex-start w90 pb2 pl1">
        <div className="chip-breadcrumbs">
          <span className="letter-breadcrumbs">V</span>
        </div>

        <div role="presentation" className="ml2">
          <Breadcrumbs aria-label="breadcrumb" separator="›">
            <Link underline="hover" className="breadcrumb-url bold">
              Ventas
            </Link>
          </Breadcrumbs>
        </div>
      </div>

      <Grid container spacing={1}>
        <Grid item lg={12} className="flex-column text-center">
          <div
            style={{
              backgroundColor: '#757D65',
              width: '95%',
              borderRadius: '10px',
            }}
          >
            <p className="pl3 pr3 co-white ">
              <span className="bold">Ventas:</span> En esta sección podrás
              consultar el importe que te hemos propuesto para la consignación
              de tu artículo Preloved.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className="flex">
          <div
            className="card"
            style={{
              backgroundImage: `url(${imgPropuestas})`,
              // backgroundImage: `url("https://images.unsplash.com/photo-1591892150204-2f872745bc4b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80")`,
            }}
          >
            <div className="card-content">
              <h2 className="card-title HelveticaNeue">Propuestas Recibidas</h2>
              <p className="card-body AvenirLight">
                En este apartado podrás consultar el importe que te hemos
                propuesto por la consigna de tu artículo Preloved.
              </p>
              <a
                className="buttonn AvenirLight"
                onClick={function () {
                  if (_Complet !== false) {
                    gtmGoTo_Propuestas_Log()
                    navigate(CLIENTES_CON_PROPUESTAS_VIEW)
                  } else {
                    props.setTypeSnackBar('warning')
                    props.setMessageAlertWarning(
                      'Debes completar tu perfil, para administrar tus ventas.'
                    )
                    props.setOpenAlertWarning(true)
                  }
                }}
              >
                Ver piezas
              </a>
            </div>
          </div>
          {/* <div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://images.unsplash.com/photo-1591892150204-2f872745bc4b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80'
							/>
						</div>

						<div className='card-center'>
							<p className='bold'>PROPUESTAS RECIBIDAS</p>
							<p className='w80 center text-center'>
								En este apartado podrás consultar el importe que te hemos
								propuesto por la consigna de tu artículo Preloved.
							</p>
							<div className='mt-10'>
								<Button
									className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
									onClick={function () {
										navigate(CLIENTES_CON_PROPUESTAS_VIEW)
									}}
								>
									Ver piezas
								</Button>
							</div>
						</div>
					</div> */}
        </Grid>
        <Grid item xs={12} lg={6} md={6} className="flex">
          {/* <div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://images.unsplash.com/photo-1543163521-1bf539c55dd2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
							/>
						</div>

						<div className='card-center'>
							<p className='bold'>MIS ARTÍCULOS EN VENTA</p>
							<p className='w80 center text-center'>
								Aquí podrás dar seguimiento a los artículos que están ya en
								venta, podrás ver el estatus de cada articulo así como
								aplicarles un descuento. En el dado caso que tengas tu articulo
								contigo, si sales de viaje podrás notificarnos para que tu
								articulo quede en standby en nuestra plataforma.
							</p>
							<div className='mt1'>
								<Button
									className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
									onClick={function () {
										navigate(CLIENTES_CONSIGNAS_PIEZAS)
									}}
								>
									Ver articulos
								</Button>
							</div>
						</div>
					</div> */}

          <div
            className="card"
            style={{
              backgroundImage: `url(${imgEnVenta})`,
              // backgroundImage: `url("https://images.unsplash.com/photo-1543163521-1bf539c55dd2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80")`,
            }}
          >
            <div className="card-content">
              <h2 className="card-title">Mis artículos en venta</h2>
              <p className="card-body">
                Aquí podrás dar seguimiento a los artículos que están ya en
                venta, podrás ver el estatus de cada articulo así como
                aplicarles un descuento.
                {/* En el dado caso que tengas tu articulo
								contigo, si sales de viaje podrás notificarnos para que tu
								articulo quede en standby en nuestra plataforma. */}
              </p>
              <a
                className="buttonn"
                onClick={function () {
                  if (_Complet !== false) {
                    gtmGoTo_ArticulosVenta_Log()
                    navigate(CLIENTES_CONSIGNAS_PIEZAS)
                  } else {
                    props.setTypeSnackBar('warning')
                    props.setMessageAlertWarning(
                      'Debes completar tu perfil, para administrar tus ventas.'
                    )
                    props.setOpenAlertWarning(true)
                  }
                }}
              >
                Ver articulos
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} md={6} className="flex mt6 mb-20">
          {/* <div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://images.unsplash.com/photo-1559563458-527698bf5295?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
							/>
						</div>

						<div className='card-center'>
							<p className='bold'>VENDIDAS</p>
							<p className='w80 center text-center'>
								Aquí podrás revisar los artículos que ya se vendieron o están en
								tránsito.
							</p>
						</div>

						<div className=''>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								onClick={function () {
									navigate(CLIENTES_CONSIGNAS_VENTAS)
								}}
							>
								Ver piezas
							</Button>
						</div>
					</div> */}
          <div
            className="card"
            style={{
              backgroundImage: `url(${imgVendidas})`,
              // backgroundImage: `url("https://images.unsplash.com/photo-1559563458-527698bf5295?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80")`,
            }}
          >
            <div className="card-content">
              <h2 className="card-title">Vendidas</h2>
              <p className="card-body">
                Aquí podrás revisar los artículos que ya se vendieron o están en
                tránsito.
              </p>
              <a
                className="buttonn"
                onClick={function () {
                  if (_Complet !== false) {
                    gtmGoTo_ConsignasVendidas_Log()
                    navigate(CLIENTES_CONSIGNAS_VENTAS)
                  } else {
                    props.setTypeSnackBar('warning')
                    props.setMessageAlertWarning(
                      'Debes completar tu perfil, para administrar tus ventas .'
                    )
                    props.setOpenAlertWarning(true)
                  }
                }}
              >
                Ver piezas
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} md={6} className="flex mt6 mb-20">
          {/* <div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://plus.unsplash.com/premium_photo-1661657759493-f21eb0d67e27?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'
							/>
						</div>

						<div className='card-center'>
							<p className='bold'>RECHAZADOS</p>
							<p className='w80 center text-center'>
								Aquí podrás ver tus artículos que no pasaron los estándares de
								calidad para su comercialización.
							</p>
						</div>

						<div className=''>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								onClick={function () {
									navigate(CLIENTES_CONSIGNAS_EN_VENTA)
								}}
							>
								Ver piezas
							</Button>
						</div>
					</div> */}
          <div
            className="card"
            style={{
              backgroundImage: `url(${imgRechazados})`,
              // backgroundImage: `url("https://plus.unsplash.com/premium_photo-1661657759493-f21eb0d67e27?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80")`,
            }}
          >
            <div className="card-content">
              <h2 className="card-title">Rechazados</h2>
              <p className="card-body">
                Aquí podrás ver tus artículos que no pasaron los estándares de
                calidad para su comercialización.
              </p>
              <a
                className="buttonn"
                onClick={function () {
                  if (_Complet !== false) {
                    gtmGoTo_ConsignasRechazadas_Log()
                    navigate(CLIENTES_CONSIGNAS_RECHAZO)
                  } else {
                    props.setTypeSnackBar('warning')
                    props.setMessageAlertWarning(
                      'Debes completar tu perfil, para administrar tus ventas y tus empeños.'
                    )
                    props.setOpenAlertWarning(true)
                  }
                }}
              >
                Ver piezas
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* <div className="ContainerSection3MB flex-column text-center">
        <p className="mb0">
          <span>Programa de referidos</span>
        </p>
        <hr className="hr-referidos" />
        <p className="mt0">¡TU PASIÓN POR EL LUJO PUEDE HACERTE GANAR!</p>

        {window.screen.width <= 600 ? (
          <span>
            Recomienda a tus amigos, familiares o conocidos que deseen vender
            bolsos, zapatos y accesorios de marcas de lujo, y obtén comisiones
            especiales por cada transacción exitosa.
          </span>
        ) : (
          <span>
            Recomienda a tus amigos, familiares o conocidos que deseen vender
            bolsos, zapatos y accesorios de <br />
            marcas de lujo, y obtén comisiones especiales por cada transacción
            exitosa.
          </span>
        )}
        <div className="CardTableCalcula">
          {window.screen.width <= 600 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columnsMovil.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        style={{ borderRadius: column.radius }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsMovil.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.calories}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        style={{ borderRadius: column.radius }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.calories}</StyledTableCell>
                      <StyledTableCell>{row.fat}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <span className="mt5">
          ** Cuando tu referido firme su contrato de consigna, deberá darnos tu
          nombre completo
        </span>
        <span className="mb5">
          *** Una vez que se venda su pieza, realizaremos el pago de tu comisión
          en la cuenta que nos indiques.
        </span>
        <div className="ButtonReferir">
          <Button
            variant="contained"
            className="ButtonReferir"
            onClick={function () {
              SMSWHatsapp()
            }}
          >
            QUIERO REFERIR
          </Button>
        </div>
      </div> */}
      {/* <Fab
        sx={{ position: 'fixed', bottom: 100, right: 16 }}
        aria-label="Add"
        color="primary"
        onClick={function () {
          handleOpen()
        }}
      >
        <HelpIcon />
      </Fab> */}

      {/* <Fab
				sx={{ position: 'fixed', bottom: 80, right: 16 }}
				aria-label='Add'
				color='primary'
				onClick={function () {
					handleOpen()
				}}
			>
				kk
				<HelpIcon />
			</Fab> */}

      <ConsignasHelp
        open={open}
        onClose={handleOpen}
        setOpenLoadingScreen={props.setOpenLoadingScreen}
        setCloseLoadingScreen={props.setCloseLoadingScreen}
        setOpenSnackBar={props.setOpenSnackBar}
        setMessageSnackBar={props.setMessageSnackBar}
        setTypeSnackBar={props.setTypeSnackBar}
      />
    </div>
  )
}
export default ClientesConsignaPiezasView

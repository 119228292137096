/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import { Chip, Alert, Fab } from '@mui/material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import '../../../Styles/GeneralStyles.css'
import Button from '@mui/material/Button'

import HelpIcon from '@mui/icons-material/Help'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import {
	CLIENTES_EMP_PRO_VIEW,
	CLIENTES_EMP_PROPUESTAS_VIEW,
	CLIENTES_EMP_REFRENDO_VIEW,
	CLIENTES_EMP_DESEMPEÑO_VIEW,
	CLIENTES_PROFILE,
} from '../../../Constants/routesConstants'
import EmpeñoHelp from './EmpeñoHelp'
import Avatar from '@mui/material/Avatar'
import iconEmpeno from '../../../Images/MB-Empena/Empeno_Icon_Blanco.png'
import imgPropuestas from '../../../Images/MB-Empena/Propuestas_Recibidas.jpg'
import imgMisEmpeños from '../../../Images/MB-Empena/Mis_Empeños.jpg'
import imgMisRefrendos from '../../../Images/MB-Empena/Mis_Refrendos.jpg'
import imgMisDesempeños from '../../../Images/MB-Empena/Mis_Desempeños.jpg'
import requests from '../../../Components/AxiosCalls/AxiosAuth'
import { useDispatch, useSelector } from 'react-redux'
import { CLIENTES_PERFIL_COMPLETO } from '../../../Constants/ApiConstants'

import {
	gtmGoTo_Propuestas_Emp_Log,
	gtmGoTo_ArticulosEmpenados_Log,
	gtmGoTo_MisRefrendos_Emp_Log,
	gtmGoTo_Desempenos_Emp_Log,
	gtmGoTo_Perfil_Log,
} from '../../../Constants/GoogleTagManager/gtmConstants'

const ClienteEmpenoViewMB = (props) => {
	const navigate = useNavigate()
	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin
	const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setOpen(!open)
	}
	const [_Complet, setComplete] = useState()
	const getProfile = () => {
		//if(bool !== true){
		if (
			userInfo.intIdUsuario !== null &&
			userInfo.intIdUsuario !== '' &&
			userInfo.intIdUsuario !== undefined
		) {
			requests
				.get(CLIENTES_PERFIL_COMPLETO, userInfo.intIdUsuario)
				.then((response) => {
					setComplete(response)
					sessionStorage.setItem('profile', JSON.stringify(response))
				})
				.catch((error) => {
					console.log(error)
				})
		}
		//}
	}
	useEffect(() => {
		getProfile()
	}, [])

	return (
		<div className='containerViewUser py4 paddingTablets'>
			{_Complet === false ? (
				<Alert
					severity='warning'
					sx={{
						display: 'flex',
						justifyContent: 'center',
						fontWeight: 'bold',
						fontSize: '16px',
						mb: 1,
					}}
					action={
						<Button
							color='inherit'
							size='small'
							onClick={function () {
								gtmGoTo_Perfil_Log()
								navigate(CLIENTES_PROFILE)
							}}
						>
							Completar Perfil
						</Button>
					}
				>
					Es necesario completar tu perfil, para administrar tus ventas y tus
					empeños.
				</Alert>
			) : null}
			{/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
			<div className='flex-start w90 pb2 pl1'>
				<div className='chip-breadcrumbs'>
					<span className='letter-breadcrumbs'>E</span>
				</div>

				<div role='presentation' className='ml2'>
					<Breadcrumbs aria-label='breadcrumb' separator='›'>
						<Link underline='hover' className='breadcrumb-url bold' href='/'>
							Empeños
						</Link>
					</Breadcrumbs>
				</div>
			</div>

			<Grid container spacing={2}>
				<Grid item lg={12} className='flex-column text-center'>
					<div
						style={{
							backgroundColor: '#9EB5AE',
							width: '95%',
							borderRadius: '10px',
						}}
					>
						<p className='pl3 pr3 co-black '>
							<span className='bold'>Empeño:</span> consiste en obtener un
							préstamo inmediato de dinero a cambio de dejarnos tu artículo
							PreLoved, el cual podrás recuperar al realizar el pago del importe
							del préstamo más el pago de intereses dentro de determinado plazo.
						</p>
					</div>
				</Grid>
				<Grid item xs={12} md={6} lg={6} className='flex mt2'>
					<div
						className='card'
						style={{
							backgroundImage: `url(${imgPropuestas})`,
							// backgroundImage: `url("https://images.unsplash.com/photo-1525103504173-8dc1582c7430?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1474&q=80")`,
						}}
					>
						<div className='card-content'>
							<h2 className='card-title'>Propuestas Recibidas</h2>
							<p className='card-body'>
								En este apartado podrás consultar el importe que te hemos
								ofrecido por el Empeño de tu artículo Preloved.
							</p>
							<Button
								className='buttonn'
								onClick={function () {
									if (_Complet !== false) {
										gtmGoTo_Propuestas_Emp_Log()
										navigate(CLIENTES_EMP_PROPUESTAS_VIEW)
									} else {
										props.setTypeSnackBar('warning')
										props.setMessageAlertWarning(
											'Debes completar tu perfil, para administrar tus ventas y tus empeños.'
										)
										props.setOpenAlertWarning(true)
									}
								}}
							>
								Ver propuestas
							</Button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} md={6} lg={6} className='flex mt2'>
					<div
						className='card'
						style={{
							backgroundImage: `url(${imgMisEmpeños})`,
							// backgroundImage: `url("https://images.unsplash.com/photo-1595475349262-88ba1e1eecaf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80")`,
						}}
					>
						<div className='card-content'>
							<h2 className='card-title'>Mis Empeños</h2>
							<p className='card-body'>
								En esta sección podrás dar seguimiento los artículos que tienes
								empeñados con nosotros, así como visualizar tu contrato y fechas
								de vencimiento.
							</p>
							<Button
								className='buttonn'
								onClick={function () {
									if (_Complet !== false) {
										gtmGoTo_ArticulosEmpenados_Log()
										navigate(CLIENTES_EMP_PRO_VIEW)
									} else {
										props.setTypeSnackBar('warning')
										props.setMessageAlertWarning(
											'Debes completar tu perfil, para administrar tus ventas y tus empeños.'
										)
										props.setOpenAlertWarning(true)
									}
								}}
							>
								Ver articulos
							</Button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} md={6} lg={6} className='flex mt6 mb-30'>
					<div
						className='card'
						style={{
							backgroundImage: `url(${imgMisRefrendos})`,
							// backgroundImage: `url("https://images.unsplash.com/photo-1637262448017-0fbbec87a898?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80")`,
						}}
					>
						<div className='card-content'>
							<h2 className='card-title'>Mis Refrendos</h2>
							<p className='card-body'>
								Es el pago de interés a liquidar por el préstamo adquirido al
								dejar tu artículo PreLoved, dicho pago se te cobra al mes de
								vencimiento y solo podrás realizarlo por única ocasión para
								extender el plazo original de tu Empeño por un mes más.
							</p>
							<Button
								className='buttonn'
								onClick={function () {
									if (_Complet !== false) {
										gtmGoTo_MisRefrendos_Emp_Log()
										navigate(CLIENTES_EMP_REFRENDO_VIEW)
									} else {
										props.setTypeSnackBar('warning')
										props.setMessageAlertWarning(
											'Debes completar tu perfil, para administrar tus ventas y tus empeños.'
										)
										props.setOpenAlertWarning(true)
									}
								}}
							>
								Ver Detalles
							</Button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} md={6} lg={6} className='flex mt6 mb-30'>
					<div
						className='card'
						style={{
							backgroundImage: `url(${imgMisDesempeños})`,
							// backgroundImage: `url("https://images.unsplash.com/photo-1545776771-0a6367761ff3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1099&q=80")`,
						}}
					>
						<div className='card-content'>
							<h2 className='card-title'>mis desempeños</h2>
							<p className='card-body'>
								Es el pago por el importe del préstamo más el interés mensual
								acordado, una vez realizado el pago podrás agendar una cita para
								recuperar tu artículo PreLoved.
							</p>
							<Button
								className='buttonn'
								onClick={function () {
									if (_Complet !== false) {
										gtmGoTo_Desempenos_Emp_Log()
										navigate(CLIENTES_EMP_DESEMPEÑO_VIEW)
									} else {
										props.setTypeSnackBar('warning')
										props.setMessageAlertWarning(
											'Debes completar tu perfil, para administrar tus ventas y tus empeños.'
										)
										props.setOpenAlertWarning(true)
									}
								}}
							>
								Ver Detalles
							</Button>
						</div>
					</div>
				</Grid>
			</Grid>
			<Fab
				sx={{ position: 'fixed', bottom: 100, right: 16 }}
				aria-label='Add'
				color='primary'
				onClick={function () {
					handleOpen()
				}}
			>
				<HelpIcon />
			</Fab>
			<EmpeñoHelp
				open={open}
				onClose={handleOpen}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
		</div>
	)
}

export default ClienteEmpenoViewMB

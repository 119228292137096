/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import '../Styles/TerminosConditions/TermsConditions.css'
const TermsConditions = () => {
  return (
    <div className="ContainerTerms mt5 mb5">
      <div className="Container-Content-Terms shadow">
        <h2 className="text-center Title">Términos y Condiciones</h2>
        <h3 className="text-center Subtitle">FOUNDIT LUXE SECONDHAND</h3>

        {/* ************************SECTION 1************************* */}
        <div>
          <p className="section-Title">1. Generales.</p>
          <p className="section-Body">
            <span className="bold co-black">
              ARTÍCULOS DE LUJO, S.A. DE C.V.
            </span>
            (con nombre comercial, "
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            "), con domicilio en Finca 3232, ubicada en Calle 3era Oeste San
            Felipe, Casco Antiguo, República de Panamá. Provee servicios (los
            "Servicios") a través de los cuales los Usuarios pueden de manera
            enunciativa más no limitativa comprar, vender, consignar diversos
            artículos de lujo, así como celebrar contratos de mutuo.
          </p>
          <p className="section-Body">
            A continuación, se describen los términos y condiciones generales
            aplicables a todos los servicios ofrecidos por
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            (en adelante los "Términos y Condiciones"). Cualquier persona que
            visite{' '}
            <a href="https://http://pa.founditmembers.com//" target="_blank">
              http://pa.founditmembers.com/
            </a>{' '}
            (en adelante “El Sitio”), se registre como Usuario y/o utilice los
            Servicios (el "Usuario") acepta someterse integralmente a estos
            Términos y Condiciones de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND.
            </span>
          </p>
        </div>
        {/* ************************SECTION 2************************* */}
        <div>
          <p className="section-Title">2. Glosario.</p>
          <p className="section-Body">
            <span className="bold co-black">Usuario:</span> Persona que hace uso
            de la plataforma{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND.
            </span>
          </p>
          <p className="section-Body">
            <span className="bold co-black">Comprador:</span> El Usuario que
            desee adquirir un artículo ofertado a través del Sitio de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND.
            </span>
          </p>
          <p className="section-Body">
            <span className="bold co-black">Datos Personales:</span> Información
            proporcionada por el Usuario conforme a los formularios que para tal
            efecto emite
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND.
            </span>
          </p>
          <p className="section-Body">
            <span className="bold co-black">Deserción de artículos:</span> El
            retiro de la o los artículos otorgados en consignación por parte del
            Usuario en el Sitio de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>
            , previo pago de la pena convencional.
          </p>
          <p className="section-Body">
            <span className="bold co-black">Formato:</span> Es el documento por
            medio del cual el Usuario entrega a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            , el artículo.
          </p>
          <p className="section-Body">
            <span className="bold co-black">Oferta:</span> El ofrecimiento al
            público de los artículos en catálogo de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND.
            </span>
          </p>
          <p className="section-Body">
            <span className="bold co-black">Perfil:</span> Cuenta única y
            personal del Usuario que realiza su registro, en donde se encuentran
            los datos de información personal, historial de operaciones y en
            caso de haber dado artículos a Consignación, su inventario de ventas
            y regresos.
          </p>
          <p className="section-Body">
            <span className="bold co-black">Artículos:</span> Todo bien mueble
            de segunda mano que se encuentre publicado en el Sitio de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>
            , los cuales de manera enunciativa mas no limitativa pueden ser
            ropa, zapatos, bolsas y accesorios.
          </p>
          <p className="section-Body">
            <span className="bold co-black">Artículos a Consignación:</span> Son
            aquellos artículos que el consignante, requiriendo los Servicios de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>
            , entrega a ésta última en consignación.
          </p>
          <p className="section-Body">
            <span className="bold co-black">Consignante:</span> Usuario que pone
            en venta su artículo de lujo por medio de la plataforma de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND.
            </span>
          </p>
          <p className="section-Body">
            <span className="bold co-black">Precio de Venta:</span> Toda aquella
            publicación de un artículo en el Sitio de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>
            , que muestra un precio y una breve descripción del artículo
            ofertado. El precio señalado en la Oferta de Venta no es negociable,
            el cual será debidamente respetado por el "Usuario".
          </p>
          <p className="section-Body">
            <span className="bold co-black">Segunda Mano:</span> Ropa, calzado,
            accesorios de moda, bolsos y/o similares que hayan sido usados y no
            sean considerados como nuevos.
          </p>
          <p className="section-Body">
            <span className="bold co-black">Servicios:</span> Son las
            actividades que realiza
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            de manera enunciativa más no limitativa como lo son comprar, vender,
            consignar diversos artículos de lujo, así como celebrar contratos de
            mutuo.
          </p>
          <p className="section-Body">
            <span className="bold co-black">Showroom:</span> Tienda física.
          </p>
          <p className="section-Body">
            <span className="bold co-black">Sitio:</span> Página de Internet de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>
            , bajo la denominación{' '}
            <a href="http://http://pa.founditmembers.com//" target="_blank">
              http://pa.founditmembers.com/.
            </a>
          </p>
          <p className="section-Body">
            <span className="bold co-black">Términos y condiciones:</span> Son
            las disposiciones que regulan los Servicios que proporciona
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND.
            </span>
          </p>
        </div>
        {/* ************************SECTION 3************************* */}
        <div>
          <p className="section-Title">
            3. Registro, ingreso y notificaciones.
          </p>
          <p className="section-Body">
            Registrarse en{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no tiene costo. Para registrarse, el Usuario deberá completar todos
            los campos del formulario de registro con información actualizada y
            verdadera ("Datos Personales") y asume el compromiso de mantener
            debidamente actualizados los Datos Personales. Al momento de
            registrarse, el Usuario utilizará su correo electrónico como nombre
            de Usuario y una contraseña, misma que será confidencial y de su
            exclusiva responsabilidad. Toda información proporcionada por el
            Usuario deberá de ser cierta y comprobable.
          </p>
          <p className="section-Body">
            El Usuario podrá acceder a su perfil personal (cuenta única y
            personal del Usuario, en donde se encuentran los datos de
            información personal, historial de compras y en caso de haber dado
            artículos a consignación, su inventario de ventas y regresos. Todo
            esto será conocido como el "Perfil") mediante el ingreso de su
            correo electrónico y Contraseña o bien, por las opciones que se
            encuentren disponibles para su identificación. En el caso que el
            Usuario se identifique a través de una Red Social o algún medio
            similar, el Usuario autoriza a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            a utilizar los datos públicos que aparezcan en su respectivo Perfil
            o cuenta, según corresponda.
          </p>
          <p className="section-Body">
            El Perfil es personal, único e intransferible. Está prohibido que un
            mismo Usuario registre o tenga más de un Perfil.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            podrá solicitar comprobantes y/o datos adicionales a los Usuarios
            para verificar la veracidad y autenticidad de sus datos y el Usuario
            estará obligado a acceder a esto.
          </p>
          <p className="section-Body">
            Así mismo, en caso que el Usuario realice alguna operación, se podrá
            solicitar a esta mayor información por motivos de seguridad de cada
            operación.
          </p>
          <p className="section-Body">
            La realización de alguna operación con datos falsos o inciertos, así
            como, cualquier irregularidad respecto a la identidad del Usuario
            que no hayan sido verificada y/o comprobada, serán motivo para que{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            bloquee la operación y/o el Perfil del Usuario.
          </p>
          <p className="section-Body">
            Por cuestiones de higiene personal{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no ofrece servicios relacionados con trajes de baño sin etiqueta,
            ropa interior y/o cosméticos. Así mismo no se aplican cambios ni
            devoluciones.
          </p>
          <p className="section-Body">
            Los Servicios sólo están disponibles para personas que tengan
            capacidad legal para realizarlos. No podrán utilizar los servicios
            menores de edad ni personas que no cuenten con plena capacidad de
            ejercicio.
          </p>
          <p className="section-Body">
            El Usuario será responsable por todas las operaciones efectuadas en
            su Perfil. El Usuario se compromete a notificar a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            de forma inmediata, vía telefónica o correo electrónico, sobre
            cualquier uso no autorizado de su Perfil, así como el ingreso por
            terceros no autorizados al mismo.
          </p>
          <p className="section-Body">
            En caso de que el Usuario entregue artículos a
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            en consignación y se convierta en vendedor, usará su Perfil para
            poder ingresar datos bancarios de pago, poder visualizar el
            historial de sus ventas en
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>
            , y el estatus de sus artículos.
          </p>
          <p className="section-Body">
            Las Notificaciones a que se refieren en los presentes Términos y
            Condiciones se realizarán de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            al Usuario vía notificación en su perfil sin limitarse al envío al
            correo electrónico proporcionado por el Usuario en su perfil,
            acordando las partes, que dicho perfil y correo electrónico serán
            los medios principales para las notificaciones que requiera hacer
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            y viceversa. Se entiende que los plazos empiezan a contar a partir
            del momento en que se realiza la notificación en el perfil, por lo
            tanto, será responsabilidad del Usuario estar constantemente
            revisando su perfil.
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no será responsable de los datos que no hayan sido correctamente
            proporcionados por el Usuario, o bien, que estos Datos Personales y
            su medio de administración como correo electrónico, bloqueen las
            comunicaciones de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            como correo no deseado o spam.
          </p>
          <p className="section-Body">
            <p className="section-Title">ADVERTENCIA</p>
            <span className="bold">
              Salvo que se indique lo contrario de forma expresa en el presente
              documento, (i) el Usuario utiliza el sitio y servicios bajo su
              cuenta y riesgo; y (ii) en la medida más amplia permitida por la
              legislación aplicable, los artículos y servicios se proporcionan
              “tal y como están” y “según su disponibilidad” sin garantías
              expresas ni implícitas de ningún tipo fuera de lo establecido
              dentro de los presentes términos y condiciones. (iii) El Usuario
              acepta y entiende que FOUNDIT LUXE SECONDHAND oferta artículos
              previamente usados y/o participa como consignatario en la compra y
              venta de ciertos artículos de lujo por cual libera en este acto a
              FOUNDIT LUXE SECONDHAND de cualquier tipo de obligación y/o
              responsabilidad, demanda, fallo, carga, multa, obligación, pago,
              erogación, menoscabo, pérdida, daño, perjuicio, reclamación o
              gasto que resulte de la relación correspondiente a las partes y no
              a FOUNDIT LUXE SECONDHAND. (iv) Entre otras cosas, este acuerdo
              contiene una cláusula arbitral que contiene una renuncia a
              entablar demandas colectivas aplicables a los Usuarios residentes
              de los Estados Unidos (incluyendo sus posesiones y territorios),
              Canadá y México.
            </span>
          </p>
        </div>

        {/* ************************SECTION 4************************* */}
        <div>
          <p className="section-Title">
            4. Proceso de Compra y Método de Pago.
          </p>
          <p className="section-Body">
            Seleccionar objeto deseado sobre el portal{' '}
            <a href="https://http://pa.founditmembers.com//" target="_blank">
              http://pa.founditmembers.com/.
            </a>{' '}
            y agregarlo al carrito de compra. El carrito de compra acumulará
            objetos deseados.
          </p>

          <p className="section-Body">
            Terminado el proceso de selección el Usuario procederá al pago.
          </p>
          <p className="section-Body">
            El Usuario deberá llenar toda la información solicitada para temas
            de pagos, envíos y entrega.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no se hace responsable por cualquier falla resultante de un error en
            el registro de información por parte del Usuario.
          </p>

          <p className="section-Body">
            El Usuario seleccionará tipo de entrega ya sea en el showroom o a
            domicilio.
          </p>
          <p className="section-Body">
            Posterior al proceso de pago y registro de información para la
            entrega el Usuario recibirá un correo electrónico con la información
            de entrega.
          </p>
          <p className="section-Body">
            El Comprador completa una orden de compra a través del Sitio cuando
            ingresa un método de pago válido y que dicho pago sea aprobado por
            medio de una transacción bancaria en línea.
          </p>
          <p className="section-Body">
            Para efectos de inventario. El Usuario entiende que la mayoría de
            los artículos de
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            son de inventario limitado si no es que únicas. Para efectos de este
            texto no habrá solicitudes de pruebas ni apartado de artículos. El
            Usuario solo podrá hacerse del artículo si es que fue el primero en
            concretar la venta y acepta que, en ningún momento,
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            se hará responsable de la falta de inventario de un artículo en
            particular. El Usuario compra en el entendido que el artículo se le
            adjudicará al primer Usuario en completar una transacción exitosa.
          </p>
          <p className="section-Body">
            Si debido, a una falta de inventario o falla técnica de la
            plataforma en la publicación del artículo no es posible concretar la
            entrega el total de la compra de un producto, será reembolsado
            acorde a los tiempos de la Entidad Bancaria utilizada.
          </p>
          <p className="section-Body">
            Para efectuar el pago de objetos seleccionados, el Usuario deberá
            confirmar el monto a pagar para cubrir el costo; los pagos se
            realizan en línea o físicamente en el showroom a través de los
            medios mencionados en la página{' '}
            <a href="https://http://pa.founditmembers.com//" target="_blank">
              http://pa.founditmembers.com/.
            </a>{' '}
            El Usuario está obligado notificar a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            cualquier cargo indebido o fraudulento en la tarjeta o cuenta
            bancaria utilizada para las compras en el menorplazode tiempo
            posible,así cómo, proporcionar toda la evidencia necesaria para
            proceder en estos casos.
          </p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            ofrece las siguientes opciones de pago y el Usuario al comprar con{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            se adhiere estrictamente al cumplimiento de todas sus políticas,
            requisitos, términos y condiciones en el entendido de que cualquier
            incumplimiento de las mismas podrá invalidar su transacción y
            provocar su eliminación como Usuario entre otras consecuencias que{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            pueda reservarse.
          </p>
          <p className="section-Body">
            Para la tienda en línea se ofrecen las siguientes opciones de pago:
          </p>
          <p className="section-Body">
            <ol>
              <li>Tarjetas de crédito.</li>
              <li>
                Tarjetas de débito de principales bancos nacionales selectos.
              </li>
              <li>Efectivo.</li>
              <li>
                Transferencias mediante instituciones financieras tradicionales
                y/o instituciones financieras de pagos electrónicos.
              </li>
            </ol>
          </p>
        </div>
        {/* ************************SECTION 5************************* */}
        <div>
          <p className="section-Title">5. Políticas de envió y entrega.</p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            se reserva el derecho de contratar con la empresa que le parezca
            conveniente los servicios de envío y recolección de los artículos
            que promociona a través de su plataforma, el costo, el tiempo,
            método, lugar y fecha de entrega y recolección se sujetara a las
            políticas que dicha empresa determine.
          </p>
          <p className="section-Body">
            En caso que el artículo no sea entregado en el plazo fijado, no se
            le podrá reclamar interés alguno a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            , el Usuario podrá contactar al Centro de Atención a Clientes de la
            empresa encargada del envío para que responda por dicho servicio.
          </p>
        </div>

        {/* ************************SECTION 6************************* */}
        <div>
          <p className="section-Title">6. Devoluciones.</p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            se adhiere a los términos y condiciones establecidos por la Ley
            Federal de Protección al Consumidor en cuanto a la atención a las
            devoluciones.
          </p>
          <p className="section-Body">
            En su caso, el Usuario debe notificar a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            de su intención y razones para la posible devolución a{' '}
            <a>foundit.pa@founditpreloved.com</a>. En un plazo máximo de cinco
            días naturales a partir de que haya recibido el artículo; en caso de
            no enviar dicho correo se considerará tácitamente aceptada la
            entrega y aceptación de ésta.
          </p>
          <p className="section-Body">
            Para ejercer el derecho de devolución de artículo, el comprador debe
            de:
            <ul>
              <li>
                Notificar a{' '}
                <span className="bold italic co-black">
                  FOUNDIT LUXE SECONDHAND
                </span>{' '}
                dentro del plazo señalado a partir de la entrega del artículo
                por parte de la paquetería en el domicilio proporcionado.
              </li>
              <li>
                El equipo{' '}
                <span className="bold italic co-black">
                  FOUNDIT LUXE SECONDHAND
                </span>{' '}
                enviará un correo electrónico con las instrucciones para la
                devolución del artículo.
              </li>
              <li>
                Contará el Usuario con 48 horas para enviar el artículo desde
                que se le envíe el mail con las instrucciones.
              </li>
              <li>
                Una vez que{' '}
                <span className="bold italic co-black">
                  FOUNDIT LUXE SECONDHAND
                </span>{' '}
                reciba el artículo analizará: el estado del artículo, si cuenta
                con las etiquetas necesarias y si está en las mismas condiciones
                en las que se vendió.
              </li>
              <li>
                Si el artículo cumple con los lineamientos de devolución, el
                equipo{' '}
                <span className="bold italic co-black">
                  FOUNDIT LUXE SECONDHAND{' '}
                </span>
                generará el reembolso al método de pago utilizado para la
                compra.
              </li>
              <li>
                El artículo debe de estar en el mismo estado en que fue enviado,
                sin la posibilidad de que haya sido alterado en el que el
                Usuario la recibió.
              </li>
              <li>
                El artículo debe de llevar todas las etiquetas con las que se
                entregó adheridas, así como sus complementos (caja de la marca,
                bolsa o protector, certificado, etc.) y accesorios.
              </li>
              <li>
                En caso que el Usuario no cumpla con la totalidad del proceso
                señalado, la devolución será improcedente y el producto será
                enviado a donación.
              </li>
            </ul>
          </p>
          <p className="section-Body">
            En caso de recibir el paquete a domicilio/o en sucursal de
            paquetería incompleto, se pide enviar fotografías y video de cómo
            fue recibido el paquete en donde se vea el empaque y los artículos
            que incluía al correo <a>foundit.pa@founditpreloved.com</a> para
            evidenciar el caso con la paquetería y poder proceder con la
            investigación.
          </p>
          <p className="section-Body">
            Si el Usuario notifica a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            el deseo de su devolución después del periodo mencionado,{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no podrá aceptar la devolución.
          </p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            se reserva el derecho de no aceptar devoluciones de artículos:
            <ul>
              <li>
                Con etiquetas desprendidas: antes de usar tus artículos
                asegúrate de haberlas probado ya que después de quitar sus
                etiquetas y sellos no podremos aceptarlas.
              </li>
              <li>
                Artículos rotos, manchados y en diferentes condiciones de cómo
                se enviaron.
              </li>
            </ul>
          </p>
          <p className="section-Body">
            Sólo se aceptará la devolución si el artículo se encuentra en las
            mismas condiciones en las que en las que se compraron. De no cumplir
            dichas condiciones será enviado a donación.
          </p>
        </div>

        {/* ************************SECTION 7************************* */}
        <div>
          <p className="section-Title">7. Compra y venta de artículos.</p>
          <p className="section-Body">
            Para el caso de las compras y/o ventas de artículos: aceptada la
            oferta, identificado, pagado y recibido el artículo, una vez
            transcurrido el plazo que señala la legislación aplicable. La
            operación se da por concluida y por tanto la relación entre las
            partes respecto a ese artículo se da por terminada.
          </p>
          <p className="section-Body">
            Sin perjuicio de lo anterior{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            podrá Terminar anticipadamente la relación si:
            <ul>
              <li>
                El Precio no es pagado puntualmente acorde a lo estipulado por{' '}
                <span className="bold italic co-black">
                  FOUNDIT LUXE SECONDHAND
                </span>
                , a cuyo efecto las partes pactan expresamente que la falta de
                pago total producirá la rescisión de pleno derecho.
              </li>
              <li>
                El Usuario incumpla con cualquiera de las obligaciones asumidas
                en estos términos y condiciones.
              </li>
            </ul>
          </p>
        </div>

        {/* ************************SECTION 8************************* */}
        <div>
          <p className="section-Title">
            8. Consignación de Productos para venta.
          </p>
          <p className="section-Body">
            El consignante que desee vender un artículo a través de los
            servicios que ofrece{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>
            lo hará por medio de una consignación de artículos de lujo. Deberá
            llenar y entregar un formato de entrega de los artículos de lujo (el
            “Formato”) que señale: (i) información completa y veraz de su
            identidad y de su forma de pago, (ii) información detallada del
            artículo (marca, año de colección, estado del artículo, garantía,
            declarar si esta cumple con las etiquetas del artículo de
            composición, forma de cuidado y/o lavado, precio al que se compró,
            entre otros); (iii) Declaración bajo protesta de decir verdad que es
            legítimo y único propietario del artículo a consignar sin limitación
            alguna que en derecho proceda .(iv) Si desea recibir transferencia
            bancaria o crédito a favor para transacciones dentro del Sitio (el
            “Abono a su Cuenta{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            ”) como contraprestación, en el mismo formato deberá (v) revisar y
            aceptar el contrato de consignación con{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            .
          </p>
          <p className="section-Body">
            Los artículos en consignación deben ser Auténticos de la Marca y/o
            Diseño. En caso que algún artículo de lujo no sea auténtico, el
            consignante será responsable por cualquier reclamo debido a la
            autenticidad de las prendas que deja en consignación. Si el artículo
            de lujo está publicado y resulta no ser auténtico, se dará de baja
            del sitio para su investigación y se le notificará al consignante
            vía correo electrónico. Adicionalmente, en caso que se encuentre que
            el artículo no es auténtico se procederá en términos del título de
            Artículos Prohibidos de los presentes Términos y Condiciones y se
            cancelará la cuenta del consignante, quien no podrá volver a
            realizar acto alguno en el Sitio y será responsable frente a los
            Usuarios de
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            que presenten alguna reclamación al respecto, por lo que el
            consignante tendrá la responsabilidad de sacar en paz y a salvo los
            derechos de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>
            . En caso de que se haya vendido dicho artículo de lujo y resulte no
            ser auténtico, el consignante se obligará a restituir a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            por cualquier daño moral creado en contra de la marca y/o sus socios
            comerciales o demás partes interesadas.
          </p>
          <p className="section-Body">
            No se aceptarán artículos sin etiqueta o sin marca visible, así como
            artículos de marcas sin demanda que no se encuentren registradas en
            el Directorio de marcas. Aún, cuando la marca del artículo que el
            consignante entregará esté registrada en el Directorio de marcas,
            con base en la demanda de compra,{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            se reserva el derecho de aceptar el artículo dependiendo la
            categoría, no obstante, lo anterior, el Personal especializado de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            revisará cuidadosamente el artículo de lujo.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            se reserva el derecho de aceptar o no el artículo, expresando los
            motivos procedentes al consignante. En caso de no aceptar el
            artículo,{' '}
            <span className="bold co-black">FOUNDIT LUXE SECONDHAND</span>{' '}
            notificará de la inadmisibilidad del artículo vía correo electrónico
            y/o perfil del consignante.
          </p>
          <p className="section-Body">
            Una vez aceptado el artículo por{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            , este enviará un correo electrónico a el consignante, informando
            que su artículo será puesto a la venta en el Sitio, así como, el
            precio asignado por{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            para la venta al público (que ya incluye el IVA), para lo cual, el
            consignante contará con un plazo de 6 días hábiles para confirmar el
            acuerdo, de lo contrario, se entenderá que está de acuerdo con el
            mismo y el artículo será exhibido para su Venta; en caso de que el
            consignante no acepte los Precios de su artículo de lujo, dentro del
            plazo señalado de 6 días hábiles, el consignante debe informar vía
            correo electrónico a <a> foundit.pa@founditpreloved.com</a>.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            en ningún caso será obligado principal, subsidiario o solidario para
            responder por cualquier queja, devolución o disminución que el
            comprador haga del artículo de lujo, sea a su recepción, antes o
            posterior a ello. El consignante asume los costos y gastos que
            implique cualquier queja o devolución liberando en este acto a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            de cualquier responsabilidad al respecto y se obliga a liberar a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            de cualquier acción legal que pudiera iniciarse en su contra con
            motivo de esta operación.
          </p>

          {/* CONSIGNA FORANEA */}

          {/* <p className='section-Body'>
						<ul>
							<li className='bold co-black'>
								Consigna foránea.
							</li>
						</ul>						
					</p>

					<p className='section-Body'>

						El Usuario que se encuentre en otros Estados de la República Mexicana que desee vender un artículo
						de lujo a través de los servicios que ofrece <span className='bold italic co-black'>FOUNDIT</span>
						lo hará por medio de una “consignación
						foránea” enviando por servicio de mensajería especializada sus piezas a el lugar que se designe para
						tales efectos, el costo del envío será por cuenta del Usuario, teniendo la opción de enviarlo por medio
						de <span className='bold italic co-black'>FOUNDIT</span> o generar la guía directamente con el
						servicio de mensajería especializada de su
						conveniencia, compartiendo el número de rastreo con <span className='bold italic co-black'>FOUNDIT.</span>
					</p>
					<p className='section-Body'>

						El Usuario deberá enviar el artículo de lujo a consignar de acuerdo a las instrucciones de
						<span className='bold italic co-black'>FOUNDIT</span>,
						de igual forma que en las fotos enviadas previamente a <span className='bold italic co-black'>FOUNDIT</span>,
						si al recibirlo <span className='bold italic co-black'>FOUNDIT</span> detecta
						que el artículo de lujo se encuentra en condiciones diferentes a las previamentes exhibidas, se
						devolverá al Usuario y este deberá pagar el envío correspondiente.
					</p>
					<p className='section-Body'>

						Una vez que <span className='bold italic co-black'>FOUNDIT</span> reciba el artículo de lujo a consignar se realizará una videollamada con el
						Usuario para realizar el avalúo y confirmar el estado del artículo de lujo para su consigna.
					</p>
					<p className='section-Body'>

						Pasando la revisión efectuada por <span className='bold italic co-black'>FOUNDIT</span> a el artículo de
						lujo, se procederá a firmar el contrato
						respectivo de consigna, una vez recabadas las firmas se pondrá el artículo de lujo en consigna por un
						periodo de tres meses, en caso de que no se logre vender en este plazo, se regresará el artículo de
						lujo al consignante debiendo este último pagar los costos del envío.
					</p> */}
        </div>
        {/* <div>
					<p className='section-Title'>
						8. Apartado
					</p>
					<p className='section-Body'>

						El Usuario podrá apartar el artículo de lujo que elija pagando el treinta, cuarenta o cincuenta por
						ciento del valor total del artículo seleccionado, teniendo treinta días para liquidar el cien por ciento
						del costo del artículo de lujo, el Usuario en este periodo de tiempo podrá abonar en su Perfil para
						liquidar el costo total del artículo de lujo, entendiendo que en caso de no liquidar la totalidad en este
						plazo se cobrará una penalidad, esta pena será del treinta por ciento, devolviendo al Usuario en su
						caso el restante excedente.
					</p>
				</div> */}

        {/* ************************SECTION 9************************* */}
        <div>
          <p className="section-Title">9. Deserción de artículos.</p>
          <p className="section-Body">
            En caso de que el Usuario requiera una o todos los artículos
            consignados de regreso antes de que el artículo cumpla tres meses
            desde la fecha en que fue puesta en venta en el Sitio,
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            aplicará una penalización de{' '}
            <span className="bold co-black">$100.00 (CIEN DÓLARES 00/100)</span>{' '}
            por cada artículo, en razón del costo de gestión y almacenaje del
            artículo. La Deserción del artículo no será posible si el artículo
            fue vendido previa a la solicitud del Usuario, en virtud de que{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            lo puso a disposición del consumidor.
          </p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            contará con hasta quince días naturales para hacer aviso de dicha
            venta y en caso de ser firme, se compromete a pagar la Ganancia
            correspondiente a dicha venta. Para que pueda aplicar la Deserción
            del artículo, el Usuario debe de notificar su deseo de tener de
            vuelta el artículo a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            , quien, a su vez, tendrá un plazo de tres días naturales, contados
            a partir de dicha notificación, para sacarla de disposición del
            Consumidor Final.
          </p>
        </div>
        {/* ************************SECTION 10************************* */}
        <div>
          <p className="section-Title">10. Precio.</p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            establecerá el precio óptimo del artículo para la atracción de
            compradores y vendedores.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            enviará una notificación a el Usuario vía correo electrónico, con la
            información proporcionada por el Usuario, con un rango de precios
            para el artículo. Los precios establecidos por{' '}
            <span className="bold co-black">FOUNDIT LUXE SECONDHAND</span> son
            los precios finales de venta. El precio al que el Usuario ofrecerá
            el artículo considera precios históricos de venta en el Sitio y
            precios de mercado, así como descuentos del Precio Original,
            conforme a las siguientes variables:
            <ul>
              <li>
                Por la devaluación por haber salido de la tienda de origen.
              </li>
              <li>Por la marca y todo lo que ello implica.</li>
              <li>Por el año y modelo del artículo.</li>
              <li>Por su nivel de uso y condición.</li>
              <li>Demanda actual del artículo.</li>
              <li>
                Sí cuentan con las etiquetas referentes a los componentes,
                fabricantes y/o importadores, cuidados y forma de lavado de
                ésta.
              </li>
              <li>
                El Precio Original (precio asignado por la tienda cuando se
                vende el producto a primera mano).
              </li>
            </ul>
          </p>
          <p className="section-Body">
            El precio a la venta que sea notificado al Usuario, incluye el
            Impuesto al Valor Agregado. El Usuario contará con un plazo de tres
            días naturales para inconformarse con el precio asignado, de lo
            contrario, se entenderá que está de acuerdo con el mismo y el
            artículo será exhibido para su Venta; en caso de que el Usuario no
            acepte los Precios de sus artículos, dentro del plazo señalado de
            tres días el Usuario debe informar vía correo a{' '}
            <a>foundit.pa@founditpreloved.com</a>,{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            devolverá los artículos del Usuario (“Deserción de Artículos”), las
            cuales, serán enviados a la dirección registrada. El Usuario asumirá
            el costo que implique la devolución del artículo. En caso de que la
            paquetería no pueda entregar el paquete, o{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no tenga registrado el domicilio correcto, el artículo será donado
            después del periodo establecido.
          </p>
        </div>
        {/* ************************SECTION 11************************* */}
        <div>
          <p className="section-Title">11. Ganancia para Usuarios.</p>
          <p className="section-Body">
            La Ganancia por venta de los artículos en Consignación del Usuario
            será el que{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>
            determine para cada caso en concreto.
          </p>
        </div>
        {/* ************************SECTION 12************************* */}
        <div>
          <p className="section-Title">12. Derechos del Usuario.</p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>{' '}
            está autorizado a aceptar y recibir el pago del Comprador y avisarle
            a el Usuario que su artículo se vendió, en términos de lo señalado
            en el presente.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>{' '}
            se compromete a transferir a el Usuario la Ganancia, a través del
            medio que haya elegido (ya sea en Abono en Crédito a su Perfil o en
            transferencia bancaria).
          </p>

          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>{' '}
            se reserva el derecho de pago de Ganancia en caso de que el artículo
            se encuentre en investigación por reclamo o si es que no cumple con
            las cláusulas mencionadas en estos Términos y Condiciones
          </p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>{' '}
            no aceptará reclamaciones relativas a envíos, devoluciones,
            deserciones y regreso de artículos por expiración o rechazos después
            de cinco días naturales a partir de la llegada de dichos envíos.
          </p>
          <p className="section-Body">
            La condición de los artículos al final de su periodo de consignación
            o cualquier otro modelo en que se requiera el resguardo de la cosa,
            puede variar respecto a la condición inicial debido al tiempo de
            almacenaje. El consignante o quien sea que haya entregado el
            artículo a resguardo acepta que{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>{' '}
            no se hará responsable por dichas variaciones ocasionadas por el
            deterioro natural por el almacenamiento de los artículos.
          </p>
        </div>
        {/* ************************SECTION 13************************* */}
        <div>
          <p className="section-Title">
            13. Seguridad y propiedad intelectual.
          </p>
          <p className="section-Body">
            <span className="bold co-black">Seguridad</span>.
          </p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            está obligado a cumplir con toda la normativa aplicable en materia
            de medidas de seguridad aplicables a los Datos Personales.
            Adicionalmente,{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            usa los estándares de la industria en materia de protección de la
            confidencialidad de sus Datos Personales.
          </p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            considera a los datos de sus Usuarios como un activo que debe ser
            protegido de cualquier pérdida o acceso no autorizado. Empleamos
            diversas técnicas de seguridad para proteger tales datos de accesos
            no autorizados por Usuarios dentro o fuera de nuestra compañía. Sin
            embargo, es necesario tener muy en cuenta que la seguridad perfecta
            no existe en Internet.
          </p>
          <p className="section-Body">
            Todos los Datos Personales de los Usuarios serán almacenados en un
            fichero automatizado de datos personales. Asimismo, durante el
            proceso de registro el Usuario y la Compradora autorizan a{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            realizar envío de promociones y ofertas, así como llamadas
            telefónicas, envío de correos electrónicos, mensajes de texto con
            fines informativos de atención a clientes y fines mercadológicos.
          </p>
          <p className="section-Body">
            <span className="bold italic co-black">
              Propiedad intelectual (Enlaces)
            </span>
          </p>
          <p className="section-Body">
            Los contenidos de las pantallas relativas a los servicios de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            como así también los programas, bases de datos, redes, archivos que
            permiten al Usuario acceder y usar su Perfil, son de propiedad de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            y están protegidas por las leyes y los tratados internacionales de
            derecho de autor, marcas, patentes, modelos y diseños industriales.
            El uso indebido y la reproducción total o parcial de dichos
            contenidos quedan prohibidos, salvo autorización expresa y por
            escrito de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            .
          </p>
          <p className="section-Body">
            El Sitio puede contener enlaces a otros sitios web, lo cual no
            indica que sean propiedad u operados por{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            . En virtud que{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no tiene control sobre tales sitios, NO será responsable por los
            contenidos, materiales, acciones y/o servicios prestados por los
            mismos, ni por daños o pérdidas ocasionadas por la utilización de
            estos, sean causadas directa o indirectamente. La presencia de
            enlaces a otros sitios web no implica una sociedad, relación,
            aprobación, respaldo de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            a dichos sitios y sus contenidos.
          </p>
        </div>
        {/* ************************SECTION 14************************* */}
        <div>
          <p className="section-Title">14. Modificaciones de T & C.</p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            podrá modificar los Términos y Condiciones Generales del presente
            contrato en cualquier momento, por lo que las publicaciones de las
            modificaciones al presente documento serán generalizadas para todo
            titular. Cualquier modificación a los presentes será realizada
            cuando{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            lo considere apropiado, siendo exclusiva responsabilidad del Usuario
            asegurarse de tomar conocimiento de tales modificaciones, por lo que
            el Usuario está obligado a revisar estos términos y condiciones de
            Uso a su ingreso a la Página Web, para que siempre se encuentre
            informado sobre eventuales modificaciones, por igual, se les hará
            llegar una notificación vía correo electrónico para informar de la
            realización de dichos cambios.
          </p>
          <p className="section-Body">
            Las alteraciones o modificaciones a los presentes Términos y
            Condiciones se volverán efectivas al momento de su publicación en el
            Sitio de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            , sin perjuicio de algún otro medio por el que{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            dé a conocer las modificaciones o alteraciones al presente
            documento.
          </p>
          <p className="section-Body">
            Una vez realizadas las modificaciones, se presumirá que el Usuario
            que continúe usando el Sitio, solicitado sus servicios, o realizando
            los actos que dieron origen a la relación con
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>
            , tendrá pleno conocimiento, habrá leído y consentido los Términos y
            Condiciones reformados.
          </p>
          <p className="section-Body">
            El Usuario deberá aceptar en el Sitio toda vez que existan
            modificaciones a los Términos y Condiciones.
          </p>
        </div>
        {/* ************************SECTION 15************************* */}
        <div>
          <p className="section-Title">15. Tratamiento de Datos Personales.</p>
          <p className="section-Body">
            Una vez registrado en el Sitio,{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no venderá, alquilará o compartirá los Datos Personales excepto en
            las formas establecidas en nuestro Aviso de Privacidad. Se hará todo
            lo que esté al alcance de{' '}
            <span className="bold co-black">FOUNDIT LUXE SECONDHAND</span> para
            proteger la privacidad de la información. Puede suceder que, en
            virtud de órdenes judiciales, o de regulaciones legales,{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            se vea compelida a revelar información a las autoridades o terceras
            partes bajo ciertas circunstancias, o bien en casos que terceras
            partes puedan interceptar o acceder a cierta información o
            transmisiones de datos en cuyo caso{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no responderá por la información que sea revelada.
          </p>
          <p className="section-Body">
            Las funciones, información y materiales proporcionados y descritos
            mediante los Servicios están protegidos por las leyes de derechos de
            autor, marcas registradas, patentes y otras relacionadas con la
            propiedad intelectual. Todos los textos, contenidos gráficos,
            videos, datos y demás contenidos que estén disponibles mediante los
            Servicios (denominados en conjunto, los “Contenidos de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            ”) son proporcionados al Usuario por{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            , sus socios o licenciantes, únicamente como soporte del uso
            permitido de los Servicios por parte del Usuario.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            podrá modificar los contenidos de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            en cualquier momento a su exclusiva discreción. Salvo por lo
            dispuesto en contrario expresamente en el presente, no se otorga
            licencia alguna al Usuario para cualquier otro fin y cualquier otro
            uso de los servicios o de los contenidos de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            por el Usuario constituirá una violación sustancial de este acuerdo.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            y sus socios o licenciantes conservan todos los derechos respecto de
            los servicios y los contenidos de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            , así como todas las patentes, marcas registradas, derechos de
            autor, derechos sobre los esquemas de trazado de circuitos
            integrados, secretos comerciales y otros derechos de propiedad
            intelectual relacionados. No se otorga licencia, derecho o
            participación alguna en cualquier marca registrada de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            o de cualquier tercero en virtud de este acuerdo.
          </p>
          <p className="section-Body">
            El Usuario tiene derecho a obtener confirmación sobre si{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            está tratando o no datos personales que le conciernen y, en tal
            caso, acceder a estos datos (Acceso). Asimismo, es su derecho
            solicitar la corrección de tu información personal en caso de que
            esté desactualizada, sea inexacta o incompleta (Rectificación); que
            la eliminemos de nuestros registros o bases de datos cuando
            consideres que la misma no está siendo utilizada conforme a los
            principios, deberes y obligaciones previstas en la normativa
            (Cancelación); así como oponerse al uso de tus datos personales para
            fines específicos (Oposición). Estos derechos se conocen como
            derechos ARCO.
          </p>
          <p className="section-Body">
            De la misma manera, puede revocar en todo momento el consentimiento
            que, en su caso, nos haya otorgado para el tratamiento de sus datos
            personales.
          </p>
          <p className="section-Body">
            Para el ejercicio de cualquiera de estos derechos debe dirigirse por
            escrito a Protección de Datos a través de la siguiente dirección de
            correo electrónico siguiente: <a>foundit.pa@founditpreloved.com</a>
          </p>
        </div>
        {/* ************************SECTION 16************************* */}
        <div>
          <p className="section-Title">16. Restricciones basadas en la edad.</p>
          <p className="section-Body">
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            se preocupa por la seguridad y la privacidad de los menores. Por
            esta razón, y para ser coherentes con las condiciones de uso por
            parte de terceros proveedores de servicios utilizados por{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            , usted debe tener al menos dieciocho (18) años, o haber alcanzado
            la mayoría de edad de conformidad con la legislación del país en el
            que reside para utilizar los Servicios. De lo contrario, solo podrá
            utilizar los Servicios con la participación de un progenitor o
            tutor.
          </p>
        </div>
        {/* ************************SECTION 17************************* */}
        <div>
          <p className="section-Title">17. Limitaciones de responsabilidad.</p>
          <p className="section-Body">
            Salvo por lo dispuesto expresamente en el presente, en ningún caso
            las partes de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            serán responsables de las lesiones, pérdidas, reclamaciones o daños
            y perjuicios directos ni por los daños especiales, ejemplares,
            punitivos, incidentales o consecuenciales del tipo que sean, ya sea
            de fuente contractual o extracontractual o de cualquier otra, aun si
            se les hubiera informado sobre la posibilidad de esos daños y
            perjuicios, que surjan o de alguna manera se relacionen con (1) este
            acuerdo (incluidos sus respectivas modificaciones), (2) el uso de
            los sitios y servicios de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            , los contenidos de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            o el contenido del Usuario, (3) la falla o demora (incluido, sin
            limitarse, a el uso o imposibilidad de uso de algún componente de
            los servicios). Asimismo, el Usuario específicamente comprende y
            acepta que los terceros que lo dirijan al sitio de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>
            por referencia, enlace o cualquier otro medio, no son responsables
            ante el Usuario por ninguna razón en absoluto, incluyendo, sin
            limitarse, a los daños y perjuicios o pérdida relacionada con el uso
            de los servicios o con los contenidos de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            .
          </p>
          <p className="section-Body">
            Si la exención de responsabilidad de los daños y perjuicios directos
            no es legalmente aplicable, el Usuario acuerda expresamente que
            nuestra responsabilidad en todo momento se limitará a $50.00 USD
            (cincuenta dólares estadounidenses) o su equivalente en moneda
            nacional al tipo de cambio que en el día del evento reporte el Banco
            de México.
          </p>
          <p className="section-Body">
            El Usuario y{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            comprenden y aceptan que las exenciones de responsabilidad,
            exclusiones y limitaciones son elementos esenciales de este acuerdo
            y que representan una asignación de riesgo razonable. En particular,
            el Usuario comprende que{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            sólo podrá poner los servicios a su disposición bajo estos términos,
            y acepta que este acuerdo permanecerá vigente y se aplicará aun
            cuando se determine que un recurso limitado especificado en este
            acuerdo no ha cumplido con su propósito esencial.
          </p>
        </div>
        {/* ************************SECTION 18************************* */}
        <div>
          <p className="section-Title">18. Exención de garantías.</p>
          <p className="section-Body">
            El Usuario entiende que el uso de los servicios corre por su cuenta
            y riesgo, y que{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>
            no puede garantizar que los servicios no se interrumpan ni estén
            libres de errores. Los servicios, todos los contenidos de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            y cualquier otra información, productos, artículos y materiales
            incluidos en los servicios o a los que puede accederse mediante los
            servicios, se brindan al Usuario “tal como están” y sin garantías de
            ningún tipo.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>
            expresamente rechaza todas las declaraciones, garantías, condiciones
            o indemnizaciones, expresas o implícitas, incluyendo, sin limitarse,
            a cualquier garantía de comerciabilidad, aptitud para un fin
            determinado, título, ausencia de violación de derechos de terceros,
            o toda garantía que surja de un trato comercial, cumplimiento o uso
            comercial.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>
            no garantiza que el uso que usted hace de los servicios no se
            interrumpa ni esté libre de errores, que{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            revisará la información o los materiales disponibles mediante los
            servicios para su exactitud ni que conservará o mantendrá esa
            información o materiales sin pérdida alguna.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no será responsable de las demoras, interrupciones, fallas en el
            servicio u otros problemas inherentes al uso de internet y de las
            comunicaciones electrónicas u otros sistemas fuera del control
            razonable de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            .
          </p>
          <p className="section-Body">
            Las exenciones de responsabilidad anteriormente descritas se aplican
            con el máximo alcance permitido por la ley. El Usuario podrá tener
            otros derechos legales. Sin embargo, la duración de las garantías
            exigidas legalmente, si las hubiera, se limitarán al máximo alcance
            permitido por ley.
          </p>
          <p className="section-Title">
            Sitios web, aplicaciones y servicios de terceros.
          </p>
          <p className="section-Body">
            Los Servicios podrán contener enlaces de hipertextos con sitios web
            y aplicaciones operados por terceros aparte de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            . Esos enlaces de hipertexto son proporcionados únicamente como
            referencia para el Usuario y{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no controla esos sitios web ni es responsable de su contenido. La
            inclusión de esos enlaces de hipertexto por parte de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            en esos sitios web o aplicaciones, no implica la ratificación de ese
            material en esos sitios web o aplicaciones ni ninguna asociación con
            sus operadores.
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no asume responsabilidad alguna en absoluto por los sitios web,
            aplicaciones, contenidos, funciones, productos o servicios de esos
            terceros, disponibles en los sitios web o aplicaciones de dichos
            terceros. Asimismo, Apple Inc., Google, Inc., Microsoft Corporation,
            BlackBerry Limited u otras similares presentes o futuras serán
            beneficiarios externos de este contrato si usted accede a los
            Servicios usando las aplicaciones desarrolladas para Apple iOS,
            Android, Microsoft Windows o dispositivos móviles alimentados por
            BlackBerry, respectivamente. Estos beneficiarios externos no
            constituyen una parte de este contrato y no son responsables de
            ninguna manera de la provisión o soporte de los Servicios. Su acceso
            de los Servicios usando estos dispositivos está sujeto a los
            términos establecidos en los términos de servicio aplicables de los
            beneficiarios externos, incluida la transferibilidad de licencia y
            otras normas de uso incluidas en ellos.
          </p>
        </div>
        {/* ************************SECTION 19************************* */}
        <div>
          <p className="section-Title">19. Privacidad.</p>
          <p className="section-Body">
            Estos términos y condiciones están sujetos a la política de
            privacidad de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            , la cual se entiende incorporada a la presente como si a la letra
            se insertase.{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no es responsable o de cualquier otra manera responsable legalmente
            de cualquier uso o divulgación de su información de contacto, o
            información financiera, por parte de un tercero con quien{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            tenga permitido divulgar su información de contacto bajo la política
            de privacidad de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            .{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            no es responsable por ninguna pérdida de datos que surja por la
            operación del sitio o por aplicar los términos y condiciones.
            Instamos a todos los Usuarios a que mantengan su propia versión de
            respaldo de cualquier contenido de Usuario o cualquier otro tipo de
            información que emitan al Sitio.
          </p>
          <p className="section-Body">
            Si alguna de las disposiciones, o las partes pertinentes de este
            acuerdo fuera considerada sin validez conforme a una ley o estado de
            derecho aplicable, en ese caso, no obstante, esa disposición, o la
            parte pertinente, este acuerdo permanecerá en plena vigencia y
            vigor, y esa disposición o su parte pertinente será considerada
            omitida.
          </p>
          <p className="section-Body">
            Cesión. El Usuario no podrá transferir, ceder ni delegar este
            acuerdo ni los derechos otorgados y obligaciones asumidas conforme
            al presente de ninguna manera, sin embargo,
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            podrá transferirlos, cederlos o delegarlos libremente.
          </p>
        </div>
        {/* ************************SECTION 20************************* */}
        <div>
          <p className="section-Title">
            20. Compromiso Arbitral, Jurisdicción y Competencia.
          </p>
          <p className="section-Body">
            Si es residente de los Estados Unidos de América (incluidas sus
            posesiones y territorios) o de Canadá, usted acepta que todas las
            controversias, conflictos, demandas, alegatos, reclamaciones o
            antecedentes de acciones judiciales (incluidos la interpretación y
            alcance de esta cláusula y la arbitrabilidad de la controversia,
            conflicto, demanda, alegato, reclamación o antecedente de la acción
            judicial) entre usted y cualquier parte y de
            <span className="bold italic co-black">
              {' '}
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            o sus sucesores o cesionarios, serán resueltos exclusivamente por
            medio de procedimiento de mediación y arbitraje vinculante y
            confidencial. Si es residente de México, por el presente
            expresamente renuncia a todo derecho a ejercer una demanda colectiva
            ante un tribunal mexicano contra{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            sus representantes, agentes, socios, colaboradores o gestores, así
            como cualesquiera de las partes de{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>
            , en virtud de los términos del Código Federal de Procedimientos
            Civiles y cualquier otra legislación mexicana aplicable.
          </p>
          <p className="section-Body">
            Toda controversia derivada de este contrato o convenio o que guarde
            relación con el mismo, incluida cualquier cuestión relativa a su
            existencia, validez, terminación, interpretación o ejecución, se
            someterá para su resolución en primer lugar a mediación.
          </p>
          <p className="section-Body">
            Si la mediación resultare infructuosa, la controversia será resuelta
            definitivamente mediante arbitraje.
          </p>
          <p className="section-Body">
            La designación de mediadores y la administración del arbitraje y la
            mediación se regirán por las normas vigentes a la fecha de
            presentación de la solicitud de arbitraje o mediación.
          </p>
          <p className="section-Body">
            En caso de arbitraje y si la ley lo permite, usted acepta renunciar
            a su derecho de entablar una demanda ante un tribunal para hacer
            valer o defender sus derechos. Sus derechos serán determinados por
            un árbitro neutral y no por un juez o jurado. Usted tiene derecho a
            una audiencia imparcial, pero los procedimientos de arbitraje son
            más simples y limitados que las normas que se aplican en un
            tribunal. Las decisiones del árbitro son tan aplicables como
            cualquier resolución de un tribunal y están sujetas a una
            modificación muy limitada por parte de un tribunal.
          </p>
          <p className="section-Body">
            En caso de arbitraje y si la ley lo permite, usted y{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            deberán regirse por las siguientes normas: (1) las reclamaciones
            entabladas por usted o{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            deberán interponerse en carácter individual por cada una de las
            partes, y no como demandante o miembro de una demanda colectiva en
            un supuesto procedimiento de demanda colectiva o con representante;
            (2) el árbitro no podrá consolidar las reclamaciones de más de una
            persona, tampoco podrá dictaminar sobre ninguna forma de
            procedimiento colectivo o por representante, y no podrá otorgar una
            reparación a un grupo de demanda colectiva; (3) el árbitro deberá
            respetar los derechos de privilegio y privacidad reconocidos por
            ley; (4) el arbitraje será confidencial y ni usted ni nosotros
            podremos divulgar la existencia, contenido o resultado de un
            arbitraje, salvo que la ley lo exija o a fin de hacer valer el laudo
            arbitral; (5) el árbitro podrá otorgar una reparación individual o
            recursos judiciales individuales permitidos por una ley aplicable; y
            (6) cada parte paga los honorarios y gastos de sus propios abogados.
          </p>
          <p className="section-Body">
            Sin perjuicio de lo precedente, tanto usted como{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND
            </span>{' '}
            podrán entablar una acción individual en un tribunal para causas
            menores. Asimismo, las demandas por difamación, violación de la Ley
            de Fraude y Abuso Informáticos, y la violación o apropiación
            indebida de patentes, derechos de autor, marcas registradas o
            secretos comerciales de la otra parte, no estarán sujetas a este
            acuerdo arbitral.
          </p>
          <p className="section-Body">
            A excepción de los incisos (1) y (2) en esta cláusula (que prohíben
            el arbitraje de clase o colectivo), si alguna parte de esta
            disposición de arbitraje se considera sin validez, inaplicable o
            ilegal o, en su defecto, está en conflicto con los Reglamentos y
            Procedimientos, en ese caso, el resto de esta cláusula arbitral
            permanecerá vigente y será considerada de conformidad con sus
            términos, como si la parte sin validez, inaplicable, ilegal o
            conflictiva no hubiera estado incluida en el presente. Sin embargo,
            si tanto el inciso (1) como el (2) fueran considerados sin validez,
            inaplicables o ilegales, en ese caso, la totalidad de la cláusula
            arbitral será nula y quedará sin efecto, y ni usted ni{' '}
            <span className="bold italic co-black">
              FOUNDIT LUXE SECONDHAND{' '}
            </span>
            tendrán derecho a un arbitraje. Si por alguna razón, una demanda
            tuviera lugar en un tribunal y no por arbitraje (incluidas las
            reclamaciones entabladas por las partes fuera de los Estados Unidos
            o Canadá), el conflicto se interpondrá exclusivamente en un tribunal
            estatal o federal ubicado en [la Ciudad de México].
          </p>
        </div>
        {/* ************************SECTION 21************************* */}
        <div>
          <p className="section-Title">21. Ley aplicable.</p>
          <p className="section-Body">
            Para todos los efectos de interpretación, cumplimiento o ejecución
            de los presentes términos y condiciones, las partes convienen
            expresamente que el mismo se regirá por las leyes aplicables en la
            Ciudad de México, en lo que no se opongan a los términos
            expresamente pactados en este documento y se someten expresamente a
            la jurisdicción y competencia de los tribunales de esta ciudad,
            renunciando expresamente al beneficio de cualquier otro fuero o
            legislación que tengan o que llegaren a adquirir en virtud de su
            domicilio o nacionalidad, actual o futura o por cualquier otra causa
            generadora de competencia.
          </p>
        </div>
      </div>
    </div>
  )
}

export default TermsConditions

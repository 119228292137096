/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, DialogTitle, TextField } from '@mui/material'
import '../../Styles/Styles.css'
import { useDispatch } from 'react-redux'
import { login } from '../../Actions/UserActions'
import { useFormik } from 'formik'
import { CLIENTES_RENT_FIRST_PROD_VIEW } from '../../Constants/routesConstants'
import { useNavigate } from 'react-router-dom'
import Slide from '@mui/material/Slide'
import * as Yup from 'yup'

import LBL from '../../Images/logo-barter-sin-fondo.webp'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const LoginMbRenta = (props) => {
  const navigate = useNavigate()

  const handleClose = () => {
    props.onClose()
    props.getRegistros()
    LimpiarValores()
  }
  const [_Banco, setBanco] = useState({
    //intIdBanco: "",
    txtBanco: '',
  })
  const LimpiarValores = () => {
    setBanco({ ..._Banco, txtBanco: '' })
  }

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      correo: '',
      password: '',
    },
    validationSchema: Yup.object({
      correo: Yup.string()
        .email('El formato del correo no es válido.')
        .required('El correo es requerido.'),
      password: Yup.string().required('La correo es requerido.'),
    }),
    onSubmit: (values) => {
      dispatch(login(values, props))
      navigate(CLIENTES_RENT_FIRST_PROD_VIEW)
    },
  })

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose ? props.onClose : null}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(10px)',
          },
        }}
      >
        <div className="modal-LoginMB">
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <Box className="containerHeaderModal">
              <DialogTitle
                id="alert-dialog-title"
                className="containerHeaderModal font-letter font-barter font-20"
              >
                Iniciar sesión
              </DialogTitle>

              <Button onClick={handleClose}>
                <CloseIcon
                  fontSize="small"
                  className="btn-close-icon-modal font-dark"
                />
              </Button>
            </Box>

            <div className="flex-column w100">
              <img className="mt3 w40" src={LBL} />
              <div className="flex-column-start my3 w90">
                <span className="h2">¡Hola!</span>
                <span className="p co-grey">
                  inicia sesión para usar FoundIt Members
                </span>
              </div>
            </div>

            <div className="center mt2 w90">
              <TextField
                margin="normal"
                required
                fullWidth
                id="correo"
                label="Correo electronico"
                name="correo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.correo}
                error={formik.touched.correo && Boolean(formik.errors.correo)}
                helperText={formik.touched.correo && formik.errors.correo}
                color="warning"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                color="warning"
              />
            </div>
            <div className="flex my3">
              <span className="p co-grey pointer">Recuperar Contraseña</span>
              <span className="mx2"> | </span>
              <span className="p bold pointer">Crear Cuenta</span>
            </div>
            <div className="center w90">
              <Button className="btn-login" type="submit">
                Iniciar sesión
              </Button>
            </div>
          </Box>
        </div>
      </Dialog>
    </div>
  )
}
export default LoginMbRenta

import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/faqsMB.css'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemText from '@mui/material/ListItemText'
import MuiAccordion from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'
import MuiAccordionSummary from '@mui/material/AccordionSummary'

const Accordionn = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    border: '2px solid #E5E7E0',
    borderRadius: 4,
  },
  '&::before': {
    display: 'none',
  },
  '& .Mui-expanded': {
    backgroundColor: '#757D65',
  },
}))

const AccordionSummaryy = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    color: '#fff',
  },
  '& .MuiTypography-root': {
    // color: "#000",
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    '& .MuiTypography-root': {
      color: '#fff',
    },
  },
}))

const FqSectionApartado = (props) => {
  return (
    <section className="FaqsSectionVentas">
      <div className="contentFaqs">
        <span className="titlefqs">Apartado</span>
        <Accordionn className="accordion">
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Cómo puedo apartar un artículo?</Typography>
          </AccordionSummaryy>

          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                1. Selecciona la pieza que más te guste en{' '}
                <a href="www.pa.founditpreloved.com" target="_blank">
                  www.pa.founditpreloved.com
                </a>
                <br />
                2. Da click en el botón "Apartar".
                <br />
                3. Te llevará a nuestra página de founditmembers.com si no
                tienes usuario tendrás que crearlo, ya que en ese perfil podrás
                ir administrando tus pagos de apartado.
                <br />
                4. Selecciona el % que abonarás para apartar y el número de
                quincenas en las que terminarás de pagar la pieza, puedes
                seleccionar 2 o 4 quincenas.
                <br />
                5. La pieza te será entregada una vez que liquides todos tus
                pagos.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn className="accordion">
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Qué métodos de pago aceptan para apartar?</Typography>
          </AccordionSummaryy>

          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Podrás pagar con tarjeta o transferencia bancaria en nuestro
                sitio web{' '}
                <a href="www.pa.founditmembers.com" target="_blank">
                  www.pa.founditmembers.com
                </a>
              </p>
              <p>
                Si pagas con tarjeta se cargará un monto pequeño por uso de
                cobro con tarjeta.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn className="accordion">
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Si aparto un artículo cuándo me lo entregan?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Una vez que hayas finiquitado los pagos de tu apartado se te
                hará la entrega de la pieza.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn className="accordion">
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Cómo me entregan mi artículo una vez que he liquidado los pagos?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Si eres de la Ciudad de Panamá podrás acudir a nuestra tienda a
                recoger tu compra o bien realizar el pago de envío a domicilio,
                este monto dependerá de en qué parte de Panamá vivas.
              </p>
              <p>
                Si eres de otra Provincia, deberás pagar el envío con paquetería
                justo cuando hagas tu último pago quincenal, antes de pagar
                consulta con el equipo el precio de tu envío.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn className="accordion">
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Qué pasa si realizo un apartado pero ya no realizo ningún pago?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                En caso de que no se realicen los pagos y se termine el tiempo
                para hacerlo, se cobrará una penalidad del 30% del valor total
                de la pieza.
              </p>
              <p>Consulta nuestros términos y condiciones.</p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <span></span>
      </div>
    </section>
  )
}

export default FqSectionApartado

import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { postAbonoCreate } from "../../../../Actions/MundoBarterActions"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import IconButton from "@mui/material/IconButton"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import NumberFormat from "react-number-format"
import ModalCargoServicio from "../../ModalCargoServicio"

const AmountPayRefrendar = (props) => {
  const dispatch = useDispatch()

  const handleNext = (data) => {
    dispatch(postAbonoCreate(data))
    props.handleNext()
  }

  const [amountPay, setAmountCodi] = useState({
    monto: 0,
    saldo: props.detalles.dblSaldoActual,
    refrendo: 0,
    fee: 0,
    totalPagar: props.detalles.dblTotalEmpeño,
  })
  const getRefrendo = () => {
    var data = JSON.parse(sessionStorage.getItem("refrendo"))
    if (data !== null) {
      setAmountCodi({
        ...amountPay,
        saldo: props.detalles.dblSaldoActual,
        monto: data.refrendo,
        fee: data.fee,
        totalPagar: data.totalPagar,
      })
    }
  }
  useEffect(() => {
    getRefrendo()
  }, [])

  const [openModalTerminos, setModalTerminos] = useState(false)
  const handleOpenTerminos = () => {
    setModalTerminos(!openModalTerminos)
  }
  return (
    <>
      <p className="text-center fs30 bold">DETALLE DE PAGO</p>
      <div className="w90 center">
        <hr />
        <div className="line-height-10">
          <div className="flex w100 line-height-0">
            <p className="w50 title-pay-liq">Monto a refrendar</p>
            <p className="w50 subtitle-pay-liq">
              <NumberFormat
                value={amountPay.monto + amountPay.fee}
                displayType={"text"}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={"$ "}
                suffix={" DÓLARES"}
              />
            </p>
          </div>

          <div className="flex w100">
            <p className="w50 title-pay-liq">Total a pagar</p>
            <p className="w50 subtitle-pay-liq">
              <NumberFormat
                value={amountPay.totalPagar}
                displayType={"text"}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={"$ "}
                suffix={" DÓLARES"}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="flex ">
        <Button
          className="btn-pay"
          //disabled={amountPay.length === 0 ? true : false}
          disabled={props.detalles.dblSaldoActual === 0 ? true : false}
          //onClick={handleNext}
          onClick={function () {
            handleNext(amountPay)
          }}
        >
          Realizar pago
        </Button>
      </div>
      <ModalCargoServicio
        openModalImgPreview={openModalTerminos}
        onCloseModalImgPreview={handleOpenTerminos}
      />
    </>
  )
}
export default AmountPayRefrendar

import React, { useEffect, useState } from 'react'
import {
	Button,
	Radio,
	RadioGroup,
	Slider,
	Grid,
	FormControl,
	InputLabel,
	Paper,
	Select,
	TextField,
	MenuItem,
	Box,
	Checkbox,
	FormGroup,
	FormControlLabel,
	Typography,
	Divider,
	Tooltip,
	Fade,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useStylesGeneral } from '../../Styles/GeneralStyles'
import { styled } from '@mui/material/styles'
import SendIcon from '@mui/icons-material/Send'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import requests from '../AxiosCalls/AxiosCall'
import AddIcon from '@mui/icons-material/Add'
import Compressor from 'compressorjs'
import '../../Styles/Styles.css'
import {
	CUESTIONARIO_ADD,
	CUESTIONARIO_DINAMICO_ADD,
	CUESTIONARIO_DINAMICO_ADD_IMG,
	MARCAS_LIST,
	C_ARTICULO_LIST,
	C_PRECIO_LIST,
	C_MATERIAL_LIST,
	C_TIEMPO_LIST,
	C_ESTADO_LIST,
	C_DESGASTE_LIST,
	C_ACCESORIOS_LIST,
	C_JOYERIA01_LIST,
	C_JOYERIA02_LIST,
	MB_TIPO_SERVICIO_LIST,
	GET_LIST_TIPOS_SERV_DES,
	CREATE_FORMULARIO_TEST,
	GET_LIST_ESTADOS_MX,
	MB_TIPO_SERVICIO_LIST_TEST,
} from '../../Constants/ApiConstants'
import { useSelector } from 'react-redux'

import {
	gtm_SendPreevaluacion_Log,
	gtm_OtraPreevaluacion_Log,
	gtm_No_OtraPreevaluacion_Log,
} from '../../Constants/GoogleTagManager/gtmConstants'

import { INICIO } from '../../Constants/routesConstants'
const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: 3,
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto #CBB878',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark'
				? 'rgba(57,75,89,.5)'
				: 'rgba(206,217,224,.5)',
	},
}))

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#CBB878',
	backgroundImage:
		'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage:
			"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
			" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
			"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#CBB878',
	},
})

// Inspired by blueprintjs
function BpCheckbox(props) {
	return (
		<Checkbox
			sx={{
				'&:hover': { bgcolor: 'transparent' },
			}}
			disableRipple
			color='default'
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			inputProps={{ 'aria-label': 'Checkbox demo' }}
			{...props}
		/>
	)
}

const Input = styled('input')({
	display: 'none',
})

function Row({
	onChange,
	onRemove,
	bolServicio,
	intIdArticulo,
	intIdMarca,
	intIdPrecio,
	txtOtraMarca,
	ImagenDetalle,
	ImagenDet,
	txtComentario,
	intIdTipoServicio,
}) {
	const classes = useStylesGeneral()

	const [_ImageDD, setImageDD] = useState([])
	const [_ImageDD2, setImageDD2] = useState([])
	const [_Precios, setPrecios] = React.useState([])
	const getPrecios = () => {
		requests
			.get(C_PRECIO_LIST)
			.then((response) => {
				setPrecios(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}
	const [_Marca, setMarca] = React.useState([])
	const getMarca = () => {
		requests
			.get(MARCAS_LIST)
			.then((response) => {
				setMarca(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}
	const [_Articulos, setArticulos] = React.useState([])
	const getArticulos = () => {
		requests
			.get(C_ARTICULO_LIST)
			.then((response) => {
				setArticulos(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}
	const [_TipoServicio, setTipoServicio] = React.useState([])
	const getTipoServicio = () => {
		requests
			.get(MB_TIPO_SERVICIO_LIST_TEST)
			.then((response) => {
				setTipoServicio(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const [Contador, setContador] = useState(0)
	const [Name, setName] = useState('ImagenDet')

	const handleImageDD = (e) => {
		let count = Contador + 1
		setContador(count)
		let newName = Name + count
		setName(newName)
		onChange(Name, e.target.files[0])
		setImageDD(e.target.files[0])
	}

	const [Contador2, setContador2] = useState(0)
	const [Name2, setName2] = useState('ImagenDetalle')
	const handleImageDD2 = (e) => {
		let count = Contador2 + 1
		setContador2(count)
		let newName = Name2 + count
		setName2(newName)
		setImageDD2(e.target.files[0])
		onChange(Name2, e.target.files[0])
	}
	return (
		<>
			<Grid item xs={6} sm={6} md={3} lg={3}>
				<FormControl variant='standard' fullWidth color='warning' required>
					<InputLabel id='intIdTipoServicio'>Servicio</InputLabel>
					<Select
						labelId='intIdTipoServicio'
						id='intIdTipoServicio'
						name='intIdTipoServicio'
						value={intIdTipoServicio}
						onChange={(e) => onChange('intIdTipoServicio', e.target.value)}
						label='Servicio'
						inputProps={{
							name: 'intIdTipoServicio',
							id: 'intIdTipoServicio',
						}}
					>
						<MenuItem value='' />
						{_TipoServicio.map((object, index) => (
							<MenuItem value={object.intIdTipoServicio}>
								{object.txtTipoServicio}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={6} sm={6} md={3} lg={3}>
				<FormControl variant='standard' fullWidth color='warning' required>
					<InputLabel id='intIdArticulo'>Artículo</InputLabel>
					<Select
						labelId='intIdArticulo'
						id='intIdArticulo'
						name='intIdArticulo'
						value={intIdArticulo}
						onChange={(e) => onChange('intIdArticulo', e.target.value)}
						label='Servicio'
						inputProps={{
							name: 'intIdArticulo',
							id: 'intIdArticulo',
						}}
					>
						<MenuItem value='' />
						{_Articulos.map((object, index) => (
							<MenuItem value={object.intIdArticulo}>
								{object.txtArticulo}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={6} sm={6} md={3} lg={3}>
				<FormControl variant='standard' fullWidth color='warning' size='small'>
					<InputLabel htmlFor='intIdMarca'>Marca</InputLabel>
					<Select
						value={intIdMarca}
						onChange={(e) => onChange('intIdMarca', e.target.value)}
						label='intIdMarca'
						inputProps={{
							name: 'intIdMarca',
							id: 'intIdMarca',
						}}
					>
						<MenuItem value='' />
						{_Marca.map((object, index) => (
							<MenuItem value={object.intIdMarca}>{object.txtMarca}</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={6} sm={6} md={3} lg={3}>
				<TextField
					fullWidth
					label='Otra marca:'
					name='txtOtraMarca'
					onChange={(e) => onChange('txtOtraMarca', e.target.value)}
					value={txtOtraMarca}
					color='warning'
					variant='standard'
					size='small'
					inputProps={{ style: { textTransform: 'uppercase' } }}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3} lg={3}>
				<FormControl fullWidth size='small' color='warning' variant='standard'>
					<InputLabel htmlFor='intIdPrecio' required>
						Precio de compra
					</InputLabel>
					<Select
						value={intIdPrecio}
						onChange={(e) => onChange('intIdPrecio', e.target.value)}
						label='intIdPrecio'
						inputProps={{
							name: 'intIdPrecio',
							id: 'intIdPrecio',
						}}
					>
						<MenuItem aria-label='None' value='' />
						{_Precios.map((object, index) => (
							<MenuItem value={object.intIdPrecio}>{object.txtPrecio}</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={6} sm={6} md={3} lg={6}>
				<TextField
					fullWidth
					label='Comentarios'
					name='txtComentario'
					onChange={(e) => onChange('txtComentario', e.target.value)}
					value={txtComentario}
					color='warning'
					variant='standard'
					size='small'
				/>
			</Grid>
			<Grid
				container
				alignItems='center'
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				spacing={2}
				sx={{ ml: 3 }}
				className={classes.bodyQuestionWhite}
			>
				<h3 className={classes.question}>
					*Nota: Las imagenes de los articulos deben tener fondo blanco.
				</h3>
			</Grid>
			<Grid
				container
				alignItems='center'
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				spacing={2}
				sx={{ ml: 10 }}
				className={classes.bodyQuestionWhite}
			>
				<fieldset className=' flex-column field-set-white  mt-20'>
					<legend align='center'>
						<strong>Fotos actuales del producto</strong>
					</legend>
					<Grid container spacing={2} sx={{ mt: 2 }} xs={12}>
						<Grid item xs={12} xl={4}>
							<fieldset className=' flex-column field-set '>
								<legend align='center'>
									<strong>Imagen 1</strong>
								</legend>

								<label htmlFor='ImagenDet'>
									<Input
										id={Name}
										type='file'
										onChange={handleImageDD}
										name={Name}
										//disabled
									/>
									<Tooltip
										title='Cargar imagen frente'
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 600 }}
										arrow
									>
										<Button
											color='secondary'
											variant='contained'
											component='span'
											className='back-gradient-5 bord-radius-10  font-letter font-light mt-10'
											startIcon={<CloudUploadIcon />}
										>
											Adjuntar imagen
										</Button>
									</Tooltip>
								</label>
								<Typography variant='caption' gutterBottom component='div'>
									{_ImageDD !== null ? _ImageDD.name : 'Sin archivo'}
								</Typography>
							</fieldset>
						</Grid>
						<Grid item xs={12} xl={4}>
							<fieldset className=' flex-column field-set '>
								<legend align='center'>
									<strong>Imagen 2</strong>
								</legend>
								<label htmlFor='ImagenDetalle'>
									<Input
										id={Name2}
										type='file'
										onChange={handleImageDD2}
										name={Name2}
									/>
									<Tooltip
										title='Cargar imagen atrás'
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 600 }}
										arrow
									>
										<Button
											color='secondary'
											variant='contained'
											component='span'
											className='back-gradient-5 bord-radius-10  font-letter font-light mt-10'
											startIcon={<CloudUploadIcon />}
										>
											Adjuntar imagen
										</Button>
									</Tooltip>
								</label>
								<Typography variant='caption' gutterBottom component='div'>
									{_ImageDD2 !== null ? _ImageDD2.name : 'Sin archivo'}
								</Typography>
							</fieldset>
						</Grid>
						<Grid item xs={12} xl={4}></Grid>
					</Grid>
				</fieldset>
			</Grid>

			<Grid item xs={3}>
				<Tooltip title='Eliminar artículo' arrow aria-label='add'>
					<Button onClick={onRemove} className='font-btn-eliminar'></Button>
				</Tooltip>
			</Grid>
		</>
	)
}

const CuestionarioEmpConAdd = (props) => {
	const classes = useStylesGeneral()
	const methods = useForm()
	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin
	const { handleSubmit } = methods
	const navigate = useNavigate()
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//ESTADOS INICIALES
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	const [_ImageFrente, setImageFrente] = useState(null)
	const [_ImageAtras, setImageAtras] = useState(null)
	const [_ImagenIzquierda, setImagenIzquierda] = useState(null)
	const [_ImagenDerecha, setImagenDerecha] = useState(null)
	const [_ImagenDentro, setImagenDentro] = useState(null)
	const [_ImagenDetalle, setImagenDetalle] = useState(null)
	const [_ImagenDetalle2, setImagenDetalle2] = useState(null)
	const [_ImagenDetalle3, setImagenDetalle3] = useState(null)
	const [_EstadosMX, setEstadosMx] = useState([])

	const [openDialog, setOpenDialog] = useState(false)
	const [openDialogDescription, setOpenDialogDescription] = useState(true)
	//=_=
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//ESTADO INICIAL DEL ARTICULO
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	const initialState = {
		//lngIdRegistro: 0,
		IntIdUsuario: '',
		bolServicio: '',
		intIdArticulo: '',
		intIdMarca: '',
		txtOtraMarca: '',
		intIdPrecio: '',
		intIdMaterial: '',
		intIdTiempoUso: '',
		intIdEstado: '',
		bolReparado: '',
		bolRoto: '',
		porManchadoInt: 0,
		porManchadoExt: 0,
		porRayadoInt: 0,
		porRayadoExt: 0,
		porLimpieza: 0,
		intIdDesgaste: 0,
		intIdMultiple: 0,
		bolPedreria: '',
		intIdAccesorio: 0,
		bolCertificado: '',
		intIdAccesorioOtro: 0,
		bolCierresCompletos: '',
		bolCierresFuncionales: '',
		bolHerrajesBrillantes: '',
		bolPedreriaCompleta: '',
		bolEstuche: '',
		bolHebillascompletas: '',
		bolArrugado: '',
		bolBrochefuncional: '',
		bolRelojDigita: '',
		bolFuncionando: '',
		intIdJoyeria01: '',
		intIdJoyeria02: '',
		intIdStatus: '',
		bolBandolera: '',
		bolCandado: '',
		bolDustBag: '',
		bolLlaveMon: '',
		bolParche: '',
		bolDustBagZ: '',
		bolRepuesto: '',
		intIdTipoServicio: 2,
		intIdEstadoRep: '',
		txtUrlImgFrente: '',
		txtUrlImgAtras: '',
		txtUrlImgIzqIzquierda: '',
		txtUrlImgDerecha: '',
		txtUrlImgDentro: '',
		txtUrlImgDetalle: '',
		TxtUrlImgDetalle2: '',
		TxtUrlImgDetalle3: '',
	}
	const [_AtbRegistro, setAtbRegistro] = React.useState(initialState)
	const handleAtbRegistro = (event) => {
		setAtbRegistro({ ..._AtbRegistro, [event.target.name]: event.target.value })
	}

	const [_OcultarEstado, setOcultarEstado] = useState(true)
	const [_Requerido, setRequerido] = useState(false)
	const handleTipoServicio = (event) => {
		if (event.target.value === 4) {
			setOcultarEstado(false)
			setRequerido('true')
			setAtbRegistro({ ..._AtbRegistro, intIdTipoServicio: event.target.value })
		} else {
			setRequerido('false')
			setOcultarEstado(true)
			setAtbRegistro({ ..._AtbRegistro, intIdTipoServicio: event.target.value })
		}
	}

	const [_AtbRegistroDin, setAtbRegistroDin] = useState({
		lngIdCliente: '',
		fecFecha: '',
	})

	const handleCloseDialogDescription = () => {
		setOpenDialogDescription(false)
	}
	const handleCloseDialog = () => {
		gtm_OtraPreevaluacion_Log()
		setAtbRegistro(initialState)
		setImageFrente(null)
		setImageAtras(null)
		setImagenIzquierda(null)
		setImagenDerecha(null)
		setImagenDentro(null)
		setImagenDetalle(null)
		setImagenDetalle2(null)
		setImagenDetalle3(null)
		setMaterial([])
		setLimpieza(0)
		setArtManchadoInt(0)
		setArtManchadoExt(0)
		setArtRayadoExt(0)
		setArtRayadoInt(0)
		setOcultar(true)
		setOcultar2(true)
		setOcultar3(true)
		setOcultar4(true)
		setOpenDialog(false)
	}
	const returnHome = () => {
		gtm_No_OtraPreevaluacion_Log()
		setAtbRegistro(initialState)
		setImageFrente(null)
		setImageAtras(null)
		setImagenIzquierda(null)
		setImagenDerecha(null)
		setImagenDentro(null)
		setImagenDetalle(null)
		setImagenDetalle2(null)
		setImagenDetalle3(null)
		setMaterial([])
		setLimpieza(0)
		setArtManchadoInt(0)
		setArtManchadoExt(0)
		setArtRayadoExt(0)
		setArtRayadoInt(0)
		setOcultar(true)
		setOcultar2(true)
		setOcultar3(true)
		setOcultar4(true)
		setOpenDialog(false)
		navigate(INICIO)
	}

	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//PROCESO DE GUARDADO
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	const guardar = async () => {
		props.setOpenLoadingScreenPB()
		props.setMsjLoadingScreen(
			'Este proceso puede tardar unos segundos, por favor no cierre la página'
		)
		const formData = new FormData()
		formData.append('IntIdUsuario', userInfo.intIdUsuario)
		formData.append('bolServicio', _AtbRegistro.bolServicio)
		formData.append('intIdArticulo', _AtbRegistro.intIdArticulo)
		formData.append('intIdMarca', _AtbRegistro.intIdMarca)
		formData.append('txtOtraMarca', _AtbRegistro.txtOtraMarca)
		formData.append('intIdPrecio', _AtbRegistro.intIdPrecio)
		formData.append('intIdMaterial', _AtbRegistro.intIdMaterial)
		formData.append('intIdTiempoUso', _AtbRegistro.intIdTiempoUso)
		formData.append('intIdEstado', _AtbRegistro.intIdEstado)
		formData.append('bolReparado', _AtbRegistro.bolReparado)
		formData.append('bolRoto', _AtbRegistro.bolRoto)
		formData.append('porManchadoInt', _AtbRegistro.porManchadoInt)
		formData.append('porManchadoExt', _AtbRegistro.porManchadoExt)
		formData.append('porRayadoInt', _AtbRegistro.porRayadoInt)
		formData.append('porRayadoExt', _AtbRegistro.porRayadoExt)
		formData.append('porLimpieza', _AtbRegistro.porLimpieza)
		formData.append('intIdDesgaste', _AtbRegistro.intIdDesgaste)
		formData.append('intIdMultiple', _AtbRegistro.intIdMultiple)
		formData.append('bolPedreria', _AtbRegistro.bolPedreria)
		formData.append('intIdAccesorio', _AtbRegistro.intIdAccesorio)
		formData.append('bolCertificado', _AtbRegistro.bolCertificado)
		formData.append('intIdAccesorioOtro', _AtbRegistro.intIdAccesorioOtro)
		formData.append('bolCierresCompletos', _AtbRegistro.bolCierresCompletos)
		formData.append('bolCierresFuncionales', _AtbRegistro.bolCierresFuncionales)
		formData.append('bolHerrajesBrillantes', _AtbRegistro.bolHerrajesBrillantes)
		formData.append('bolPedreriaCompleta', _AtbRegistro.bolPedreriaCompleta)
		formData.append('bolEstuche', _AtbRegistro.bolEstuche)
		formData.append('bolHebillascompletas', _AtbRegistro.bolHebillascompletas)
		formData.append('bolArrugado', _AtbRegistro.bolArrugado)
		formData.append('bolBrochefuncional', _AtbRegistro.bolBrochefuncional)
		formData.append('bolRelojDigita', _AtbRegistro.bolRelojDigita)
		formData.append('bolFuncionando', _AtbRegistro.bolFuncionando)
		formData.append('intIdJoyeria01', _AtbRegistro.intIdJoyeria01)
		formData.append('intIdJoyeria02', _AtbRegistro.intIdJoyeria02)
		formData.append('intIdStatus', _AtbRegistro.intIdStatus)
		formData.append('bolBandolera', _AtbRegistro.bolBandolera)
		formData.append('bolCandado', _AtbRegistro.bolCandado)
		formData.append('bolDustBag', _AtbRegistro.bolDustBag)
		formData.append('bolLlaveMon', _AtbRegistro.bolLlaveMon)
		formData.append('bolParche', _AtbRegistro.bolParche)
		formData.append('bolDustBagZ', _AtbRegistro.bolDustBagZ)
		formData.append('bolRepuesto', _AtbRegistro.bolRepuesto)
		formData.append('intIdTipoServicio', _AtbRegistro.intIdTipoServicio)
		formData.append('intIdEstadoRep', _AtbRegistro.intIdEstadoRep)
		formData.append('txtUrlImgFrente', _ImageFrente)
		formData.append('txtUrlImgAtras', _ImageAtras)
		formData.append('txtUrlImgIzqIzquierda', _ImagenIzquierda)
		formData.append('txtUrlImgDerecha', _ImagenDerecha)
		formData.append('txtUrlImgDentro', _ImagenDentro)
		formData.append('txtUrlImgDetalle', _ImagenDetalle)
		formData.append('txtUrlImgDetalle2', _ImagenDetalle2)
		formData.append('txtUrlImgDetalle3', _ImagenDetalle3)
		if (
			_ImageFrente !== null &&
			_ImageAtras !== null &&
			_ImagenIzquierda !== null &&
			_ImagenDerecha !== null &&
			_ImagenDentro !== null &&
			_ImagenDetalle !== null
		) {
			requests
				.post(CREATE_FORMULARIO_TEST, formData) //.post(CUESTIONARIO_ADD, formData)
				.then((response) => {
					gtm_SendPreevaluacion_Log()
					setOpenDialog(true)
					props.setCloseLoadingScreenPB()
					props.setMsjLoadingScreen('')
				})
				.catch((error) => {
					props.setTypeSnackBar('warning')
					props.setMessageAlertWarning(error.mensaje)
					props.setOpenAlertWarning(true)
					props.setCloseLoadingScreenPB()
					props.setMsjLoadingScreen('')
				})
		} else {
			props.setTypeSnackBar('warning')
			props.setMessageAlertWarning(
				'Debes adjuntar imagenes de tu artículo. Favor de verificar.'
			)
			props.setOpenAlertWarning(true)
			props.setCloseLoadingScreenPB()
		}
	}

	const [_ArticulosPost, setArticulosPost] = useState([])
	const guardarDinamico = async () => {
		if (rows.length >= 3 && rows.length <= 10) {
			props.setOpenLoadingScreen()
			_AtbRegistroDin.lngIdCliente = userInfo.intIdUsuario
			requests
				.post(CUESTIONARIO_DINAMICO_ADD, _AtbRegistroDin)
				.then((response) => {
					if (rows.length > 0) {
						for (var a = 0; a < rows.length; a++) {
							let reg = rows[a]
							let Info = {
								lngIdRegistroDin: response.data.lngIdRegistroDin,
								bolServicio: reg.bolServicio,
								intIdArticulo: reg.intIdArticulo,
								intIdMarca: reg.intIdMarca,
								intIdPrecio: reg.intIdPrecio,
								ImagenDetalle: reg.ImagenDetalle,
								ImagenDetalle2: reg.ImagenDet,
								txtOtraMarca: reg.txtOtraMarca,
								txtComentario: reg.txtComentario,
								intIdTipoServicio: reg.intIdTipoServicio,
							}
							_ArticulosPost.push(Info)
						}
					}
					for (var i = 0; i < _ArticulosPost.length; i++) {
						let datos = _ArticulosPost[i]
						const formData = new FormData()
						formData.append('LngIdRegistroDin', datos.lngIdRegistroDin)
						formData.append('IntIdArticulo', datos.intIdArticulo)
						formData.append('IntIdMarca', datos.intIdMarca)
						formData.append('BolServicio', datos.bolServicio)
						formData.append('BolServicio', datos.bolServicio)
						formData.append('TxtOtraMarca', datos.txtOtraMarca)
						formData.append('IntIdPrecio', datos.intIdPrecio)
						formData.append('TxtComentario', datos.txtComentario)
						formData.append('IntIdTipoServicio', datos.intIdTipoServicio)
						formData.append('TxtUrlImg', datos.ImagenDetalle)
						formData.append('TxtUrlImg2', datos.ImagenDetalle2)
						requests
							.post(CUESTIONARIO_DINAMICO_ADD_IMG, formData)
							.then((response) => {
								gtm_SendPreevaluacion_Log()
								props.setTypeSnackBar('success')
								props.setMessageAlert(response.mensaje)
								props.setOpenAlert(true)
								props.setCloseLoadingScreen()
							})
							.catch((error) => {
								props.setTypeSnackBar('warning')
								props.setMessageAlertWarning(error.mensaje)
								props.setOpenAlertWarning(true)

								props.setCloseLoadingScreen()
							})
					}

					props.setTypeSnackBar('success')
					props.setMessageAlert(response.mensaje)
					props.setOpenAlert(true)
					navigate('/')
					props.setCloseLoadingScreen()
				})
				.catch((error) => {
					props.setTypeSnackBar('warning')
					props.setMessageAlertWarning(error.data.mensaje)
					props.setOpenAlertWarning(true)
					props.setCloseLoadingScreen()
				})
		} else {
			props.setTypeSnackBar('warning')
			props.setMessageAlertWarning(
				'Debes enviar entre 3 o 10 artículos. Favor de verificar.'
			)
			props.setOpenAlertWarning(true)
			props.setCloseLoadingScreen()
		}
	}

	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//LISTADO DE CATALOGOS
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	const [_TipoServicio, setTipoServicio] = React.useState([])
	const getTipoServicio = () => {
		props.setOpenLoadingScreen()
		requests
			// .get(GET_LIST_TIPOS_SERV_DES) //.get(MB_TIPO_SERVICIO_LIST)
			.get(MB_TIPO_SERVICIO_LIST_TEST) //.get(MB_TIPO_SERVICIO_LIST)
			.then((response) => {
				setTipoServicio(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}

	const [_Precios, setPrecios] = React.useState([])
	const getPrecios = () => {
		props.setOpenLoadingScreen()
		requests
			.get(C_PRECIO_LIST)
			.then((response) => {
				setPrecios(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}

	const [_Material, setMaterial] = React.useState([])

	const [_TiempoUso, setTiempoUso] = React.useState([])
	const getTiempoUso = () => {
		props.setOpenLoadingScreen()
		requests
			.get(C_TIEMPO_LIST)
			.then((response) => {
				setTiempoUso(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	const [_Estado, setEstado] = React.useState([])
	const getEstado = () => {
		props.setOpenLoadingScreen()
		requests
			.get(C_ESTADO_LIST)
			.then((response) => {
				setEstado(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	const [_Desgaste, setDesgaste] = React.useState([])
	const getDesgaste = () => {
		props.setOpenLoadingScreen()
		requests
			.get(C_DESGASTE_LIST)
			.then((response) => {
				setDesgaste(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	const [_Accesorios, setAccesorios] = React.useState([])
	const getAccesorios = () => {
		props.setOpenLoadingScreen()
		requests
			.get(C_ACCESORIOS_LIST)
			.then((response) => {
				setAccesorios(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	const [_Joreria01, setJoreria01] = React.useState([])
	const getJoreria01 = () => {
		props.setOpenLoadingScreen()
		requests
			.get(C_JOYERIA01_LIST)
			.then((response) => {
				setJoreria01(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	const [_Joreria02, setJoreria02] = React.useState([])
	const getJoreria02 = () => {
		props.setOpenLoadingScreen()
		requests
			.get(C_JOYERIA02_LIST)
			.then((response) => {
				setJoreria02(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	const [_Marca, setMarca] = React.useState([])
	const getMarca = () => {
		props.setOpenLoadingScreen()
		requests
			.get(MARCAS_LIST)
			.then((response) => {
				setMarca(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}
	const [_completePerfil, setCompletePerfil] = useState(false)
	const [_Ocultar, setOcultar] = React.useState(true)
	const [_Ocultar2, setOcultar2] = React.useState(true)
	const [_Ocultar3, setOcultar3] = React.useState(true)
	const [_Ocultar4, setOcultar4] = React.useState(true)
	const [_Articulos, setArticulos] = React.useState([])
	const getArticulos = () => {
		props.setOpenLoadingScreen()
		requests
			.get(C_ARTICULO_LIST)
			.then((response) => {
				setArticulos(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	const handleArticulo = (event) => {
		setAtbRegistro({ ..._AtbRegistro, intIdArticulo: event.target.value })
		if (event.target.value === 1) {
			setOcultar(false)
			setOcultar2(true)
			setOcultar3(true)
			setOcultar4(true)
		} else if (event.target.value === 2) {
			setOcultar(true)
			setOcultar2(false)
			setOcultar3(true)
			setOcultar4(true)
		} else if (event.target.value === 3) {
			setOcultar(true)
			setOcultar2(true)
			setOcultar3(false)
			setOcultar4(true)
		} else if (event.target.value === 4) {
			setOcultar(true)
			setOcultar2(true)
			setOcultar3(true)
			setOcultar4(false)
		}
		props.setOpenLoadingScreen()
		requests
			.get(C_MATERIAL_LIST, event.target.value)
			.then((response) => {
				setMaterial(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}

	const getBool = () => {
		var bool = sessionStorage.getItem('profile')

		setCompletePerfil(bool)
	}

	const getEstadosMx = () => {
		props.setOpenLoadingScreen()
		requests
			.get(GET_LIST_ESTADOS_MX)
			.then((response) => {
				setEstadosMx(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getBool()
		getArticulos()
		getMarca()
		getPrecios()
		getTiempoUso()
		getEstado()
		getDesgaste()
		getAccesorios()
		getJoreria01()
		getJoreria02()
		getTipoServicio()
		getEstadosMx()
	}, [])
	const [_Limpieza, setLimpieza] = React.useState(0)
	const handleLimpieza = (event, newValue) => {
		setLimpieza(newValue)
		setAtbRegistro({ ..._AtbRegistro, porLimpieza: newValue })
	}
	function LimpiezaText(_Limpieza) {
		return `${_Limpieza} %`
	}
	const [_ArtManchadoInt, setArtManchadoInt] = React.useState(0)
	const handleManchadoInt = (event, newValue) => {
		setArtManchadoInt(newValue)
		setAtbRegistro({ ..._AtbRegistro, porManchadoInt: newValue })
	}
	function ManchadoIntText(_ArtManchadoInt) {
		return `${_ArtManchadoInt} %`
	}
	const [_ArtManchadoExt, setArtManchadoExt] = React.useState(0)
	const handleManchadoExt = (event, newValue) => {
		setArtManchadoExt(newValue)
		setAtbRegistro({ ..._AtbRegistro, porManchadoExt: newValue })
	}
	function ManchadoExtText(_ArtManchadoExt) {
		return `${_ArtManchadoExt} %`
	}
	const [_ArtRayadoExt, setArtRayadoExt] = React.useState(0)
	const handleRayadoExt = (event, newValue) => {
		setArtRayadoExt(newValue)
		setAtbRegistro({ ..._AtbRegistro, porRayadoExt: newValue })
	}
	function RayadoExtText(_ArtRayadoExt) {
		return `${_ArtRayadoExt} %`
	}

	const [_ArtRayadoInt, setArtRayadoInt] = React.useState(0)
	const handleRayadoInt = (event, newValue) => {
		setArtRayadoInt(newValue)
		setAtbRegistro({ ..._AtbRegistro, porRayadoInt: newValue })
	}
	function RayadoIntText(_ArtRayadoInt) {
		return `${_ArtRayadoInt} %`
	}
	const [_ZapatoAcc, setZapatoAcc] = React.useState({
		bolParche: false,
		bolDustBagZ: false,
		bolRepuesto: false,
	})
	const handleZapatoAcc = (event) => {
		setZapatoAcc({ ..._ZapatoAcc, [event.target.name]: event.target.checked })
		setAtbRegistro({
			..._AtbRegistro,
			[event.target.name]: event.target.checked,
		})
	}
	const [_BolsoAcc, setBolsoAcc] = React.useState({
		bolBandolera: false,
		bolCandado: false,
		bolDustBag: false,
		bolLlaveMon: false,
	})
	const handleBolsoAcc = (event) => {
		setBolsoAcc({ ..._BolsoAcc, [event.target.name]: event.target.checked })
		setAtbRegistro({
			..._AtbRegistro,
			[event.target.name]: event.target.checked,
		})
	}
	const [_BolPedreria, setBolPedreria] = React.useState([])
	const handleBolPedreria = (event) => {
		setBolPedreria(event.target.value)
		setAtbRegistro({ ..._AtbRegistro, bolPedreria: event.target.value })
	}

	const handleImageFrente = (e) => {
		props.setOpenLoadingScreen()
		const file = e.target.files[0]
		new Compressor(file, {
			quality: 0.6,
			success(result) {
				const myFile = new File([result], e.target.files[0].name, {
					type: result.type,
				})
				setImageFrente(myFile)
				props.setCloseLoadingScreen()
			},
		})
	}
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//CARGAR, ELIMINAR Y VER IMAGEN ATRÁS
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	const handleImageAtras = (e) => {
		props.setOpenLoadingScreen()

		const file = e.target.files[0]
		new Compressor(file, {
			quality: 0.6,
			success(result) {
				const myFile = new File([result], e.target.files[0].name, {
					type: result.type,
				})
				setImageAtras(myFile)
				props.setCloseLoadingScreen()
			},
		})
	}
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//CARGAR, ELIMINAR Y VER IMAGEN IZQUIERDA
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	const handleImageIzquierda = (e) => {
		props.setOpenLoadingScreen()
		const file = e.target.files[0]
		new Compressor(file, {
			quality: 0.6,
			success(result) {
				const myFile = new File([result], e.target.files[0].name, {
					type: result.type,
				})
				setImagenIzquierda(myFile)
				props.setCloseLoadingScreen()
			},
		})
	}
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//CARGAR, ELIMINAR Y VER IMAGEN DERECHA
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	const handleImageDerecha = (e) => {
		props.setOpenLoadingScreen()
		const file = e.target.files[0]
		new Compressor(file, {
			quality: 0.6,
			success(result) {
				const myFile = new File([result], e.target.files[0].name, {
					type: result.type,
				})
				setImagenDerecha(myFile)
				props.setCloseLoadingScreen()
			},
		})
	}
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//CARGAR, ELIMINAR Y VER IMAGEN DENTRO
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	const handleImageDentro = (e) => {
		props.setOpenLoadingScreen()
		const file = e.target.files[0]
		new Compressor(file, {
			quality: 0.6,
			success(result) {
				const myFile = new File([result], e.target.files[0].name, {
					type: result.type,
				})
				setImagenDentro(myFile)
				props.setCloseLoadingScreen()
			},
		})
	}
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//CARGAR, ELIMINAR Y VER IMAGEN DETALLE
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	const handleImageDetalle = (e) => {
		props.setOpenLoadingScreen()
		const file = e.target.files[0]
		new Compressor(file, {
			quality: 0.6,
			success(result) {
				const myFile = new File([result], e.target.files[0].name, {
					type: result.type,
				})
				setImagenDetalle(myFile)
				props.setCloseLoadingScreen()
			},
		})
	}
	const handleImageDetalle2 = (e) => {
		props.setOpenLoadingScreen()
		const file = e.target.files[0]
		new Compressor(file, {
			quality: 0.6,
			success(result) {
				const myFile = new File([result], e.target.files[0].name, {
					type: result.type,
				})
				setImagenDetalle2(myFile)
				props.setCloseLoadingScreen()
			},
		})
	}
	const handleImageDetalle3 = (e) => {
		props.setOpenLoadingScreen()
		const file = e.target.files[0]
		new Compressor(file, {
			quality: 0.6,
			success(result) {
				const myFile = new File([result], e.target.files[0].name, {
					type: result.type,
				})
				setImagenDetalle3(myFile)
				props.setCloseLoadingScreen()
			},
		})
	}
	const [_NoAritculos, setNoArticulos] = useState({ cantidad: '' })
	const [_OcultarForm, setOcultarForm] = useState(false)
	const [_OcultarFormDinamico, setOcultarFormDinamico] = useState(true)
	const handleNoArticulos = (event) => {
		setNoArticulos({ ..._NoAritculos, cantidad: event.target.value })
		if (event.target.value === '1') {
			setOcultarForm(false)
			setOcultarFormDinamico(true)
		}
		if (event.target.value === '2') {
			setOcultarForm(true)
			setOcultarFormDinamico(false)
		}
		if (event.target.value === '') {
			setOcultarForm(true)
			setOcultarFormDinamico(true)
		}
	}
	const defaultState = {
		bolServicio: '',
		intIdArticulo: '',
		intIdMarca: '',
		intIdPrecio: '',
		ImagenDetalle: '',
		ImagenDet: '',
		txtComentario: '',
		txtOtraMarca: '',
		intIdTipoServicio: '',
	}

	const [rows, setRows] = useState([defaultState])

	const handleOnChange = (index, name, value) => {
		const copyRows = [...rows]
		copyRows[index] = {
			...copyRows[index],
			[name]: value,
		}
		setRows(copyRows)
	}

	const handleOnAdd = () => {
		setRows(rows.concat(defaultState))
	}
	const handleOnRemove = (index) => {
		const copyRows = [...rows]
		copyRows.splice(index, 1)
		setRows(copyRows)
	}

	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//RANGO MANCHADO INTERIOR
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=

	const [activeIndexManchadoInt, setActiveIndexManchadoInt] = useState(null)
	function CalificacionManchadoInt({ active, onClick, number }) {
		return (
			<div className={`BtnsRangos ${active ? 'active' : ''}`} onClick={onClick}>
				{number}
			</div>
		)
	}
	const onClickHandlerManchadoIn = (index, value) => {
		//setEncuesta({ ..._Encuesta, numRecomendacion: index })
		setAtbRegistro({ ..._AtbRegistro, porManchadoInt: value })

		setActiveIndexManchadoInt(index)
	}
	const OptionsManchadoInt = [
		{
			value: 0,
			valuetxt: '0',
		},
		{
			value: 10,
			valuetxt: '10',
		},
		{
			value: 20,
			valuetxt: '20',
		},
		{
			value: 30,
			valuetxt: '30',
		},
		{
			value: 40,
			valuetxt: '40',
		},
		{
			value: 50,
			valuetxt: '50',
		},
		{
			value: 60,
			valuetxt: '60',
		},
		{
			value: 70,
			valuetxt: '70',
		},
		{
			value: 80,
			valuetxt: '80',
		},
		{
			value: 90,
			valuetxt: '90',
		},
		{
			value: 100,
			valuetxt: '100',
		},
	]
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//RANGO MANCHADO EXTERIOR
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=

	const [activeIndexManchadoExt, setActiveIndexManchadoExt] = useState(null)
	function CalificacionManchadoExt({ active, onClick, number }) {
		return (
			<div className={`BtnsRangos ${active ? 'active' : ''}`} onClick={onClick}>
				{number}
			</div>
		)
	}
	const onClickHandlerManchadoExt = (index, value) => {
		//setEncuesta({ ..._Encuesta, numRecomendacion: index })
		setAtbRegistro({ ..._AtbRegistro, porManchadoExt: value })

		setActiveIndexManchadoExt(index)
	}
	const OptionsManchadoExt = [
		{
			value: 0,
			valuetxt: '0',
		},
		{
			value: 10,
			valuetxt: '10',
		},
		{
			value: 20,
			valuetxt: '20',
		},
		{
			value: 30,
			valuetxt: '30',
		},
		{
			value: 40,
			valuetxt: '40',
		},
		{
			value: 50,
			valuetxt: '50',
		},
		{
			value: 60,
			valuetxt: '60',
		},
		{
			value: 70,
			valuetxt: '70',
		},
		{
			value: 80,
			valuetxt: '80',
		},
		{
			value: 90,
			valuetxt: '90',
		},
		{
			value: 100,
			valuetxt: '100',
		},
	]

	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//RANGO RAYADO INTERIOR
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=

	const [activeIndexRayadoInt, setActiveIndexRayadoInt] = useState(null)
	function CalificacionRayadoInt({ active, onClick, number }) {
		return (
			<div className={`BtnsRangos ${active ? 'active' : ''}`} onClick={onClick}>
				{number}
			</div>
		)
	}
	const onClickHandlerRayadoInt = (index, value) => {
		//setEncuesta({ ..._Encuesta, numRecomendacion: index })
		setAtbRegistro({ ..._AtbRegistro, porRayadoInt: value })

		setActiveIndexRayadoInt(index)
	}
	const OptionsRayadoInt = [
		{
			value: 0,
			valuetxt: '0',
		},
		{
			value: 10,
			valuetxt: '10',
		},
		{
			value: 20,
			valuetxt: '20',
		},
		{
			value: 30,
			valuetxt: '30',
		},
		{
			value: 40,
			valuetxt: '40',
		},
		{
			value: 50,
			valuetxt: '50',
		},
		{
			value: 60,
			valuetxt: '60',
		},
		{
			value: 70,
			valuetxt: '70',
		},
		{
			value: 80,
			valuetxt: '80',
		},
		{
			value: 90,
			valuetxt: '90',
		},
		{
			value: 100,
			valuetxt: '100',
		},
	]

	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//RANGO RAYADO EXTERIOR
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=

	const [activeIndexRayadoExt, setActiveIndexRayadoExt] = useState(null)
	function CalificacionRayadoExt({ active, onClick, number }) {
		return (
			<div className={`BtnsRangos ${active ? 'active' : ''}`} onClick={onClick}>
				{number}
			</div>
		)
	}
	const onClickHandlerRayadoExt = (index, value) => {
		//setEncuesta({ ..._Encuesta, numRecomendacion: index })
		setAtbRegistro({ ..._AtbRegistro, porRayadoExt: value })

		setActiveIndexRayadoExt(index)
	}
	const OptionsRayadoExt = [
		{
			value: 0,
			valuetxt: '0',
		},
		{
			value: 10,
			valuetxt: '10',
		},
		{
			value: 20,
			valuetxt: '20',
		},
		{
			value: 30,
			valuetxt: '30',
		},
		{
			value: 40,
			valuetxt: '40',
		},
		{
			value: 50,
			valuetxt: '50',
		},
		{
			value: 60,
			valuetxt: '60',
		},
		{
			value: 70,
			valuetxt: '70',
		},
		{
			value: 80,
			valuetxt: '80',
		},
		{
			value: 90,
			valuetxt: '90',
		},
		{
			value: 100,
			valuetxt: '100',
		},
	]

	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
	//RANGO LIMPIEZA
	//=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=

	const [activeIndexLimpieza, setActiveIndexLimpieza] = useState(null)
	function CalificacionLimpieza({ active, onClick, number }) {
		return (
			<div className={`BtnsRangos ${active ? 'active' : ''}`} onClick={onClick}>
				{number}
			</div>
		)
	}
	const onClickHandlerLimpieza = (index, value) => {
		//setEncuesta({ ..._Encuesta, numRecomendacion: index })
		setAtbRegistro({ ..._AtbRegistro, porLimpieza: value })

		setActiveIndexLimpieza(index)
	}
	const OptionsLimpieza = [
		{
			value: 0,
			valuetxt: '0',
		},
		{
			value: 10,
			valuetxt: '10',
		},
		{
			value: 20,
			valuetxt: '20',
		},
		{
			value: 30,
			valuetxt: '30',
		},
		{
			value: 40,
			valuetxt: '40',
		},
		{
			value: 50,
			valuetxt: '50',
		},
		{
			value: 60,
			valuetxt: '60',
		},
		{
			value: 70,
			valuetxt: '70',
		},
		{
			value: 80,
			valuetxt: '80',
		},
		{
			value: 90,
			valuetxt: '90',
		},
		{
			value: 100,
			valuetxt: '100',
		},
	]
	return (
		<div
			className='containerViewUser'
			style={{
				marginTop: _completePerfil === 'true' ? '50px' : '100px',
			}}
		>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
				className={classes.back}
			>
				<Grid item xs={12} xl={8}>
					<Grid item xs={12} className={classes.titleCuestionary}>
						<Paper className={classes.paperTitle} elevation={0}>
							<h2 className='flex titleCuestionario'>
								EVALUACIÓN DEL ARTÍCULO
							</h2>

							<Grid
								container
								alignItems='center'
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								spacing={2}
								className={classes.selectOptions}
							>
								{/* <Grid item xs={6} sm={6} md={3} lg={4}>
									<h3 className={classes.question}>
										¿Cuántos artículos desea evaluar?
									</h3>
								</Grid>
								<Grid item xs={6} sm={6} md={3} lg={3}>
									<FormControl
										variant='standard'
										fullWidth
										color='warning'
										required
									>
										<InputLabel id='cantidad'></InputLabel>
										<Select
											labelId='cantidad'
											id='cantidad'
											name='cantidad'
											// value={_NoAritculos.cantidad}
											value={'1'}
											onChange={handleNoArticulos}
											label=''
											inputProps={{
												name: 'cantidad',
												id: 'cantidad',
											}}
										>
											<MenuItem value='' />
											<MenuItem value='1'> 1 o 2 artículos</MenuItem>
											{/* <MenuItem value='2'> 3 o más artículos</MenuItem> */}
								{/* </Select>
									</FormControl>
								</Grid> */}
							</Grid>
						</Paper>
					</Grid>
					<Box component='form' onSubmit={handleSubmit(guardar)}>
						<Grid
							item
							xs={12}
							className={classes.bodyCuestionary}
							hidden={_OcultarForm}
						>
							<Paper className={classes.paperBody} elevation={0}>
								<div className={classes.paperCuestionaryTitle}>
									<Typography
										variant='h6'
										gutterBottom
										sx={{ fontWeight: '600', textTransform: 'uppercase' }}
									>
										Detalle del artículo
									</Typography>
								</div>

								{/* <Divider className={classes.dividerhr2} /> */}
								<div className={classes.paperCuestionaryBody}>
									<h3 className={classes.question}>
										{/* Si evaluas 2 artículos, tendrás que enviar un formulario por
										cada uno. */}
										Tendrás que enviar un formulario por cada articulo a
										preevaluar.
									</h3>
									<Grid
										container
										alignItems='center'
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										spacing={2}
										className={classes.selectOptions}
									>
										{/* <Grid item xs={6} sm={6} md={3} lg={3}>
											<FormControl
												variant='standard'
												fullWidth
												color='warning'
												required
											>
												<InputLabel id='intIdTipoServicio'>Servicio</InputLabel>
												<Select
													labelId='intIdTipoServicio'
													id='intIdTipoServicio'
													name='intIdTipoServicio'
													value={_AtbRegistro.intIdTipoServicio}
													onChange={handleTipoServicio}
													label='Servicio'
													inputProps={{
														name: 'intIdTipoServicio',
														id: 'intIdTipoServicio',
													}}
												>
													<MenuItem value='' />
													{_TipoServicio.map((object, index) => (
														<MenuItem value={object.intIdTipoServicio}>
															{object.txtTipoServicio}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid> */}
										<Grid item xs={6} sm={6} md={3} lg={3}>
											<FormControl
												variant='standard'
												fullWidth
												color='warning'
												required
											>
												<InputLabel id='intIdArticulo'>Articulo</InputLabel>
												<Select
													labelId='intIdArticulo'
													id='intIdArticulo'
													name='intIdArticulo'
													value={_AtbRegistro.intIdArticulo}
													onChange={handleArticulo}
													label='Servicio'
													inputProps={{
														name: 'intIdArticulo',
														id: 'intIdArticulo',
													}}
												>
													<MenuItem value='' />
													{_Articulos.map((object, index) => (
														<MenuItem value={object.intIdArticulo}>
															{object.txtArticulo}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={6} sm={6} md={3} lg={3}>
											<FormControl
												variant='standard'
												fullWidth
												color='warning'
												size='small'
											>
												<InputLabel htmlFor='intIdMarca'>Marca</InputLabel>
												<Select
													value={_AtbRegistro.intIdMarca}
													onChange={handleAtbRegistro}
													label='intIdMarca'
													inputProps={{
														name: 'intIdMarca',
														id: 'intIdMarca',
													}}
												>
													<MenuItem value='' />
													{_Marca.map((object, index) => (
														<MenuItem value={object.intIdMarca}>
															{object.txtMarca}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={6} sm={6} md={3} lg={3}>
											<TextField
												fullWidth
												label='Otra marca:'
												name='txtOtraMarca'
												onChange={handleAtbRegistro}
												value={_AtbRegistro.txtOtraMarca}
												color='warning'
												variant='standard'
												size='small'
												inputProps={{ style: { textTransform: 'uppercase' } }}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={3} lg={3}>
											<FormControl
												fullWidth
												size='small'
												color='warning'
												variant='standard'
											>
												<InputLabel htmlFor='intIdPrecio' required>
													Precio de compra
												</InputLabel>
												<Select
													value={_AtbRegistro.intIdPrecio}
													onChange={handleAtbRegistro}
													label='intIdPrecio'
													inputProps={{
														name: 'intIdPrecio',
														id: 'intIdPrecio',
													}}
												>
													<MenuItem aria-label='None' value='' />
													{_Precios.map((object, index) => (
														<MenuItem value={object.intIdPrecio}>
															{object.txtPrecio}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6} md={3} lg={3}>
											<FormControl
												fullWidth
												size='small'
												color='warning'
												variant='standard'
											>
												<InputLabel htmlFor='intIdMaterial' required>
													Material
												</InputLabel>
												<Select
													value={_Material.intIdMaterial}
													onChange={handleAtbRegistro}
													label='intIdMaterial'
													inputProps={{
														name: 'intIdMaterial',
														id: 'intIdMaterial',
													}}
												>
													<MenuItem aria-label='None' value='' />
													{_Material.map((object, index) => (
														<MenuItem value={object.intIdMaterial}>
															{object.txtMaterial}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6} md={3} lg={3}>
											<FormControl
												fullWidth
												size='small'
												color='warning'
												variant='standard'
											>
												<InputLabel htmlFor='intIdTiempoUso' required>
													Tiempo de uso
												</InputLabel>
												<Select
													value={_AtbRegistro.intIdTiempoUso}
													onChange={handleAtbRegistro}
													label='intIdTiempoUso'
													inputProps={{
														name: 'intIdTiempoUso',
														id: 'intIdTiempoUso',
													}}
												>
													<MenuItem aria-label='None' value='' />
													{_TiempoUso.map((object, index) => (
														<MenuItem value={object.intIdTiempoUso}>
															{object.txtTiempoUso}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6} md={3} lg={3}>
											<FormControl
												fullWidth
												size='small'
												color='warning'
												variant='standard'
											>
												<InputLabel htmlFor='intIdEstado' required>
													Estado de conservación
												</InputLabel>
												<Select
													value={_AtbRegistro.intIdEstado}
													onChange={handleAtbRegistro}
													label='intIdEstado'
													inputProps={{
														name: 'intIdEstado',
														id: 'intIdEstado',
													}}
												>
													<MenuItem aria-label='None' value='' />
													{_Estado.map((object, index) => (
														<MenuItem value={object.intIdEstado}>
															{object.txtEstado}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>

										<Grid
											item
											xs={12}
											sm={6}
											md={3}
											lg={3}
											hidden={_OcultarEstado}
										>
											<FormControl
												fullWidth
												size='small'
												color='warning'
												variant='standard'
											>
												<InputLabel
													htmlFor='intIdEstadoRep'
													required={_Requerido}
												>
													Lugar donde radicas
												</InputLabel>
												<Select
													value={_AtbRegistro.intIdEstadoRep}
													onChange={handleAtbRegistro}
													label='intIdEstadoRep'
													inputProps={{
														name: 'intIdEstadoRep',
														id: 'intIdEstadoRep',
													}}
												>
													<MenuItem aria-label='None' value='' />
													{_EstadosMX.map((object, index) => (
														<MenuItem value={object.intIdEstado}>
															{object.txtEstado}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									</Grid>

									<Grid
										container
										alignItems='center'
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										spacing={1}
										className={classes.bodyQuestionWhite}
									>
										<Grid item xs={12} sm={5} alignItems='center'>
											<h3 className={classes.question}>
												¿El artículo ha sido reparado?
											</h3>
										</Grid>
										<Grid item xs={12} sm={7} md={3} lg={3} alignItems='center'>
											<FormControl component='fieldset'>
												<RadioGroup
													aria-label='gender'
													name='bolReparado'
													value={_AtbRegistro.bolReparado}
													onChange={handleAtbRegistro}
													row
												>
													<FormControlLabel
														value='true'
														control={
															<Radio
																sx={{
																	color: '#00000099',
																	'&.Mui-checked': {
																		color: '#CBB878',
																	},
																}}
															/>
														}
														label='Si'
													/>
													<FormControlLabel
														value='false'
														control={
															<Radio
																sx={{
																	color: '#00000099',
																	'&.Mui-checked': {
																		color: '#CBB878',
																	},
																}}
															/>
														}
														label='No'
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>
									<Grid
										container
										alignItems='center'
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										spacing={1}
										className={classes.bodyQuestionGrey}
									>
										<Grid item xs={12} sm={5} alignItems='center'>
											<h3 className={classes.question}>
												¿El artículo esta roto?
											</h3>
										</Grid>
										<Grid item xs={12} sm={7} alignItems='center'>
											<FormControl component='fieldset'>
												<RadioGroup
													aria-label='gender'
													name='bolRoto'
													value={_AtbRegistro.bolRoto}
													onChange={handleAtbRegistro}
													row
												>
													<FormControlLabel
														value='true'
														control={
															<Radio
																sx={{
																	color: '#00000099',
																	'&.Mui-checked': {
																		color: '#CBB878',
																	},
																}}
															/>
														}
														label='Si'
													/>
													<FormControlLabel
														value='false'
														control={
															<Radio
																sx={{
																	color: '#00000099',
																	'&.Mui-checked': {
																		color: '#CBB878',
																	},
																}}
															/>
														}
														label='No'
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>
									<Grid
										container
										alignItems='center'
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										spacing={1}
										className={classes.bodyQuestionWhite}
									>
										<Grid item xs={12} sm={5} alignItems='center'>
											<h3 className={classes.question}>
												¿El artículo esta manchado?
											</h3>
										</Grid>
										<Grid item xs={12} sm={7} alignItems='center'>
											<Typography
												id='section-Title-EncuestaRangos'
												gutterBottom
												className='TitleInterior-Exterior'
											>
												Porcentaje de manchas en el interior
												<Divider light />
											</Typography>
											<div className='EncuestaGridRangos'>
												{OptionsManchadoInt.map((product, index) => (
													<CalificacionManchadoInt
														key={index}
														active={activeIndexManchadoInt === index}
														onClick={() =>
															onClickHandlerManchadoIn(index, product.valuetxt)
														}
														number={product.valuetxt}
													/>
												))}
											</div>
											<div className='EncuestaBetweenRangos'>
												<p className='section-Title-EncuestaRangos'>Poco</p>
												<p className='section-Title-EncuestaRangos'>Mucho</p>
											</div>
											{/* <Slider
												value={
													typeof _ArtManchadoInt === 'number'
														? _ArtManchadoInt
														: 0
												}
												onChange={handleManchadoInt}
												aria-labelledby='discrete-slider-custom'
												valueLabelDisplay='auto'
												getAriaValueText={ManchadoIntText}
												step={10}
												marks
												min={0}
												max={100}
												sx={{ color: '#CBB878' }}
											/> */}

											<Typography
												id='section-Title-EncuestaRangos'
												gutterBottom
												className='TitleInterior-Exterior'
											>
												Porcentaje de manchas en el exterior
												<Divider light />
											</Typography>
											<div className='EncuestaGridRangos'>
												{OptionsManchadoExt.map((product, index) => (
													<CalificacionManchadoExt
														key={index}
														active={activeIndexManchadoExt === index}
														onClick={() =>
															onClickHandlerManchadoExt(index, product.valuetxt)
														}
														number={product.valuetxt}
													/>
												))}
											</div>
											<div className='EncuestaBetweenRangos'>
												<p className='section-Title-EncuestaRangos'>Poco</p>
												<p className='section-Title-EncuestaRangos'>Mucho</p>
											</div>
											{/* <Slider
												value={
													typeof _ArtManchadoExt === 'number'
														? _ArtManchadoExt
														: 0
												}
												onChange={handleManchadoExt}
												aria-labelledby='discrete-slider-custom'
												valueLabelDisplay='auto'
												getAriaValueText={ManchadoExtText}
												step={10}
												marks
												min={0}
												max={100}
												sx={{ color: '#CBB878' }}
											/> */}
										</Grid>
									</Grid>
									<Grid
										container
										alignItems='center'
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										spacing={1}
										className={classes.bodyQuestionGrey}
									>
										<Grid item xs={12} sm={5} alignItems='center'>
											<h3 className={classes.question}>
												¿El artículo esta rayado?
											</h3>
										</Grid>
										<Grid item xs={12} sm={7} alignItems='center'>
											<Typography
												id='section-Title-EncuestaRangos'
												gutterBottom
												className='TitleInterior-Exterior'
											>
												Porcentaje de rayones en el interior
												<Divider light />
											</Typography>
											{/* <Slider
												value={
													typeof _ArtRayadoInt === 'number' ? _ArtRayadoInt : 0
												}
												onChange={handleRayadoInt}
												aria-labelledby='discrete-slider-custom'
												valueLabelDisplay='auto'
												getAriaValueText={RayadoIntText}
												step={10}
												marks
												min={0}
												max={100}
												sx={{ color: '#CBB878' }}
											/> */}
											<div className='EncuestaGridRangos'>
												{OptionsRayadoInt.map((product, index) => (
													<CalificacionRayadoInt
														key={index}
														active={activeIndexRayadoInt === index}
														onClick={() =>
															onClickHandlerRayadoInt(index, product.valuetxt)
														}
														number={product.valuetxt}
													/>
												))}
											</div>
											<div className='EncuestaBetweenRangos'>
												<p className='section-Title-EncuestaRangos'>Poco</p>
												<p className='section-Title-EncuestaRangos'>Mucho</p>
											</div>

											<Typography
												id='section-Title-EncuestaRangos'
												className='TitleInterior-Exterior'
												gutterBottom
											>
												Porcentaje de rayones en el exterior
												<Divider light />
											</Typography>
											{/* <Typography id='section-Title-EncuestaRangos' gutterBottom>
												Exterior
											</Typography> */}
											<div className='EncuestaGridRangos'>
												{OptionsRayadoExt.map((product, index) => (
													<CalificacionRayadoExt
														key={index}
														active={activeIndexRayadoExt === index}
														onClick={() =>
															onClickHandlerRayadoExt(index, product.valuetxt)
														}
														number={product.valuetxt}
													/>
												))}
											</div>
											<div className='EncuestaBetweenRangos'>
												<p className='section-Title-EncuestaRangos'>Poco</p>
												<p className='section-Title-EncuestaRangos'>Mucho</p>
											</div>
											{/* <Slider
												value={
													typeof _ArtRayadoExt === 'number' ? _ArtRayadoExt : 0
												}
												onChange={handleRayadoExt}
												aria-labelledby='discrete-slider-custom'
												valueLabelDisplay='auto'
												getAriaValueText={RayadoExtText}
												step={10}
												marks
												min={0}
												max={100}
												sx={{ color: '#CBB878' }}
											/> */}
										</Grid>
									</Grid>
									<Grid
										container
										alignItems='center'
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										spacing={1}
										className={classes.bodyQuestionWhite}
									>
										<Grid item xs={12} sm={5} alignItems='center'>
											<h3 className={classes.question}>
												¿Qué porcentaje de limpieza tiene el artículo?
											</h3>
										</Grid>
										<Grid item xs={12} sm={7} alignItems='center'>
											<div className='EncuestaGridRangos'>
												{OptionsLimpieza.map((product, index) => (
													<CalificacionLimpieza
														key={index}
														active={activeIndexLimpieza === index}
														onClick={() =>
															onClickHandlerLimpieza(index, product.valuetxt)
														}
														number={product.valuetxt}
													/>
												))}
											</div>
											<div className='EncuestaBetweenRangos'>
												<p className='section-Title-EncuestaRangos'>Sucio</p>
												<p className='section-Title-EncuestaRangos'>Limpio</p>
											</div>
											{/* <Slider
												value={typeof _Limpieza === 'number' ? _Limpieza : 0}
												onChange={handleLimpieza}
												aria-labelledby='discrete-slider-custom'
												valueLabelDisplay='auto'
												getAriaValueText={LimpiezaText}
												step={10}
												marks
												min={0}
												max={100}
												sx={{ color: '#CBB878' }}
											/> */}
										</Grid>
									</Grid>

									{/* ZAPATOS */}
									<section hidden={_Ocultar}>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionGrey}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿Cuál es el nivel de desgaste de la suela del zapato?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl
													fullWidth
													size='small'
													color='warning'
													variant='standard'
												>
													<InputLabel htmlFor='intIdDesgaste'>
														Seleccione
													</InputLabel>
													<Select
														value={_AtbRegistro.intIdDesgaste}
														onChange={handleAtbRegistro}
														label='intIdDesgaste'
														inputProps={{
															name: 'intIdDesgaste',
															id: 'intIdDesgaste',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_Desgaste.map((object, index) => (
															<MenuItem value={object.intIdDesgaste}>
																{object.txtDesgaste}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionWhite}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿El zapato cuenta con alguno de estos accesorios?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<FormGroup>
														<FormControlLabel
															control={
																<BpCheckbox
																	checked={_ZapatoAcc.bolDustBagZ}
																	name='bolDustBagZ'
																	color='warning'
																	onChange={handleZapatoAcc}
																/>
															}
															label='Dust bag'
														/>
														<FormControlLabel
															control={
																<BpCheckbox
																	checked={_ZapatoAcc.bolParche}
																	name='bolParche'
																	color='warning'
																	onChange={handleZapatoAcc}
																/>
															}
															label='Parches'
														/>
														<FormControlLabel
															control={
																<BpCheckbox
																	checked={_ZapatoAcc.bolRepuesto}
																	name='bolRepuesto'
																	color='warning'
																	onChange={handleZapatoAcc}
																/>
															}
															label='Repuesto de tapas'
														/>
													</FormGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionGrey}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿El zapato cuenta con la pedreria completa?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolPedreria'
														value={_AtbRegistro.bolPedreria}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
														<FormControlLabel
															value=''
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='NA'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
									</section>
									{/* BOLSAS */}
									<section hidden={_Ocultar2}>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionGrey}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿La bolsa cuenta con certificado?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolCertificado'
														value={_AtbRegistro.bolCertificado}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionWhite}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿La bolsa cuenta con alguno de los siguientes
													accesorios?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl
													component='fieldset'
													className={classes.formControl}
												>
													<FormGroup>
														<FormControlLabel
															control={
																<BpCheckbox
																	checked={_BolsoAcc.bolBandolera}
																	name='bolBandolera'
																	color='warning'
																	onChange={handleBolsoAcc}
																/>
															}
															label='Bandolera (asa)'
														/>
														<FormControlLabel
															control={
																<BpCheckbox
																	checked={_BolsoAcc.bolCandado}
																	name='bolCandado'
																	color='warning'
																	onChange={handleBolsoAcc}
																/>
															}
															label='Candados'
														/>
														<FormControlLabel
															control={
																<BpCheckbox
																	checked={_BolsoAcc.bolDustBag}
																	name='bolDustBag'
																	color='warning'
																	onChange={handleBolsoAcc}
																/>
															}
															label='Dust Bag'
														/>
														<FormControlLabel
															control={
																<BpCheckbox
																	checked={_BolsoAcc.bolLlaveMon}
																	name='bolLlaveMon'
																	color='warning'
																	onChange={handleBolsoAcc}
																/>
															}
															label='Llaves monedero'
														/>
													</FormGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionGrey}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿La bolsa cuenta con los cierres completos?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolCierresCompletos'
														value={_AtbRegistro.bolCierresCompletos}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionWhite}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿Los cierres de la bolsa funcionan correctamente?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolCierresFuncionales'
														value={_AtbRegistro.bolCierresFuncionales}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionGrey}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿Los herrajes de la bolsa estan brillantes?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolHerrajesBrillantes'
														value={_AtbRegistro.bolHerrajesBrillantes}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionWhite}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿La bolsa cuenta con pedreria completa?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolPedreriaCompleta'
														value={_AtbRegistro.bolPedreriaCompleta}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
														<FormControlLabel
															value=''
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='NA'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
									</section>
									{/* JOYERIA */}
									<section hidden={_Ocultar3}>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionGrey}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													Seleccione el tipo de joyeria
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl
													fullWidth
													size='small'
													color='warning'
													variant='standard'
												>
													<InputLabel htmlFor='intIdJoyeria01'>
														Tipo de joyeria
													</InputLabel>
													<Select
														value={_AtbRegistro.intIdJoyeria01}
														onChange={handleAtbRegistro}
														label='intIdJoyeria01'
														inputProps={{
															name: 'intIdJoyeria01',
															id: 'intIdJoyeria01',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_Joreria01.map((object, index) => (
															<MenuItem value={object.intIdJoyeria01}>
																{object.txtJoyeria01}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionWhite}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													Seleccione el material de la joyeria
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl
													fullWidth
													size='small'
													color='warning'
													variant='standard'
												>
													<InputLabel htmlFor='intIdJoyeria02'>
														Material joyeria
													</InputLabel>
													<Select
														value={_AtbRegistro.intIdJoyeria02}
														onChange={handleAtbRegistro}
														label='intIdJoyeria02'
														inputProps={{
															name: 'intIdJoyeria02',
															id: 'intIdJoyeria02',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_Joreria02.map((object, index) => (
															<MenuItem value={object.intIdJoyeria02}>
																{object.txtJoyeria02}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</section>
									{/* ACCESORIOS */}
									<section hidden={_Ocultar4}>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionGrey}
											hidden={_Ocultar4}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													Seleccione el accesorio
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl
													fullWidth
													size='small'
													color='warning'
													variant='standard'
												>
													<InputLabel htmlFor='intIdAccesorio'>
														Accesorio
													</InputLabel>
													<Select
														value={_AtbRegistro.intIdAccesorio}
														onChange={handleAtbRegistro}
														label='intIdAccesorio'
														inputProps={{
															name: 'intIdAccesorio',
															id: 'intIdAccesorio',
														}}
													>
														<MenuItem aria-label='None' value='' />
														{_Accesorios.map((object, index) => (
															<MenuItem value={object.intIdAccesorio}>
																{object.txtAccesorio}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionWhite}
											hidden={_Ocultar4}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿El accesorio cuenta con estuche?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolEstuche'
														value={_AtbRegistro.bolEstuche}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
														<FormControlLabel
															value=''
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='NA'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionGrey}
											hidden={_Ocultar4}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿El accesorio cuenta con hebillas completas?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolHebillascompletas'
														value={_AtbRegistro.bolHebillascompletas}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
														<FormControlLabel
															value=''
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='NA'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionWhite}
											hidden={_Ocultar4}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿El accesorio esta arrugado?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolArrugado'
														value={_AtbRegistro.bolArrugado}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
														<FormControlLabel
															value=''
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='NA'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionGrey}
											hidden={_Ocultar4}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿El accesorio cuenta con pedreria?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolPedreria'
														value={_AtbRegistro.bolPedreria}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
														<FormControlLabel
															value=''
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='NA'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionWhite}
											hidden={_Ocultar4}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿El broche del accesorio funciona correctamente?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolBrochefuncional'
														value={_AtbRegistro.bolBrochefuncional}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
														<FormControlLabel
															value=''
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='NA'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionGrey}
											hidden={_Ocultar4}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿El accesorio es un reloj digital?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolRelojDigita'
														value={_AtbRegistro.bolRelojDigita}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
														<FormControlLabel
															value=''
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='NA'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
										<Grid
											container
											alignItems='center'
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											spacing={1}
											className={classes.bodyQuestionWhite}
											hidden={_Ocultar4}
										>
											<Grid item xs={12} sm={5} alignItems='center'>
												<h3 className={classes.question}>
													¿El accesorio funciona correctamente?
												</h3>
											</Grid>
											<Grid item xs={12} sm={7} alignItems='center'>
												<FormControl component='fieldset'>
													<RadioGroup
														aria-label='gender'
														name='bolFuncionando'
														value={_AtbRegistro.bolFuncionando}
														onChange={handleAtbRegistro}
														row
													>
														<FormControlLabel
															value='true'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='Si'
														/>
														<FormControlLabel
															value='false'
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='No'
														/>
														<FormControlLabel
															value=''
															control={
																<Radio
																	sx={{
																		color: '#00000099',
																		'&.Mui-checked': {
																			color: '#CBB878',
																		},
																	}}
																/>
															}
															label='NA'
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										</Grid>
									</section>
									<Grid
										container
										alignItems='center'
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										spacing={2}
										className={classes.bodyQuestionWhite}
									>
										<fieldset className=' flex-column field-set-white my-10 mt-20'>
											<legend align='center'>
												<strong>Fotos actuales del producto</strong>
											</legend>
											<p><strong>Si tu pieza no tiene detalles, sube otras fotos que consideres pertinentes para verla a detalle. </strong> </p>

											<Grid container spacing={2} sx={{ mt: 2 }}>
												<Grid item xs={12} xl={4}>
													<fieldset className=' flex-column field-set '>
														<legend align='center'>
															<strong>Posición Frente</strong>
														</legend>

														<label htmlFor='imagenFrente'>
															<Input
																id='imagenFrente'
																type='file'
																onChange={handleImageFrente}
																name='imagenFrente'
																//disabled
															/>
															<Tooltip
																title='Cargar imagen frente'
																TransitionComponent={Fade}
																TransitionProps={{ timeout: 600 }}
																arrow
															>
																<Button
																	color='secondary'
																	variant='contained'
																	component='span'
																	className='back-gradient-8 bord-radius-10 font-letter mt-10'
																	startIcon={<CloudUploadIcon />}
																	//disabled
																>
																	Adjuntar imagen
																</Button>
															</Tooltip>
														</label>
														<Typography
															variant='caption'
															gutterBottom
															component='div'
														>
															{_ImageFrente !== null
																? _ImageFrente.name
																: 'Sin archivo'}
														</Typography>
													</fieldset>
												</Grid>
												<Grid item xs={12} xl={4}>
													<fieldset className=' flex-column field-set '>
														<legend align='center'>
															<strong>Posición Detras</strong>
														</legend>
														<label htmlFor='imagenAtras'>
															<Input
																id='imagenAtras'
																type='file'
																onChange={handleImageAtras}
																name='imagenAtras'
																//disabled
															/>
															<Tooltip
																title='Cargar imagen atrás'
																TransitionComponent={Fade}
																TransitionProps={{ timeout: 600 }}
																arrow
															>
																<Button
																	color='secondary'
																	variant='contained'
																	component='span'
																	className='back-gradient-8 bord-radius-10  font-letter font-light mt-10'
																	startIcon={<CloudUploadIcon />}
																	//disabled
																>
																	Adjuntar imagen
																</Button>
															</Tooltip>
														</label>
														<Typography
															variant='caption'
															gutterBottom
															component='div'
														>
															{_ImageAtras !== null
																? _ImageAtras.name
																: 'Sin archivo'}
														</Typography>
													</fieldset>
												</Grid>
												<Grid item xs={12} xl={4}>
													<fieldset className=' flex-column field-set '>
														<legend align='center'>
															<strong>Lado Izquierdo</strong>
														</legend>
														<label htmlFor='imagenIzquierda'>
															<Input
																id='imagenIzquierda'
																type='file'
																onChange={handleImageIzquierda}
																name='imagenIzquierda'
																//disabled
															/>
															<Tooltip
																title='Cargar imagen lado izquierdo'
																TransitionComponent={Fade}
																TransitionProps={{ timeout: 600 }}
																arrow
															>
																<Button
																	color='secondary'
																	variant='contained'
																	component='span'
																	className='back-gradient-8 bord-radius-10  font-letter font-light mt-10'
																	startIcon={<CloudUploadIcon />}
																	//disabled
																>
																	Adjuntar imagen
																</Button>
															</Tooltip>
														</label>
														<Typography
															variant='caption'
															gutterBottom
															component='div'
														>
															{_ImagenIzquierda !== null
																? _ImagenIzquierda.name
																: 'Sin archivo'}
														</Typography>
													</fieldset>
												</Grid>
												<Grid item xs={12} xl={4}>
													<fieldset className=' flex-column field-set '>
														<legend align='center'>
															<strong>Lado derecho</strong>
														</legend>
														<label htmlFor='imagenDerecha'>
															<Input
																id='imagenDerecha'
																type='file'
																onChange={handleImageDerecha}
																name='imagenDerecha'
																//disabled
															/>
															<Tooltip
																title='Cargar imagen lado derecha'
																TransitionComponent={Fade}
																TransitionProps={{ timeout: 600 }}
																arrow
															>
																<Button
																	color='secondary'
																	variant='contained'
																	component='span'
																	className='back-gradient-8 bord-radius-10  font-letter font-light mt-10'
																	startIcon={<CloudUploadIcon />}
																	//disabled
																>
																	Adjuntar imagen
																</Button>
															</Tooltip>
														</label>
														<Typography
															variant='caption'
															gutterBottom
															component='div'
														>
															{_ImagenDerecha !== null
																? _ImagenDerecha.name
																: 'Sin archivo'}
														</Typography>
													</fieldset>
												</Grid>
												<Grid item xs={12} xl={4}>
													<fieldset className=' flex-column field-set '>
														<legend align='center'>
															<strong>Interior del producto</strong>
														</legend>
														<label htmlFor='imagenDentro'>
															<Input
																id='imagenDentro'
																type='file'
																onChange={handleImageDentro}
																name='imagenDentro'
																//disabled
															/>
															<Tooltip
																title='Cargar imagen dentro'
																TransitionComponent={Fade}
																TransitionProps={{ timeout: 600 }}
																arrow
															>
																<Button
																	color='secondary'
																	variant='contained'
																	component='span'
																	className='back-gradient-8 bord-radius-10  font-letter font-light mt-10'
																	startIcon={<CloudUploadIcon />}
																	//disabled
																>
																	Adjuntar imagen
																</Button>
															</Tooltip>
														</label>
														<Typography
															variant='caption'
															gutterBottom
															component='div'
														>
															{_ImagenDentro !== null
																? _ImagenDentro.name
																: 'Sin archivo'}
														</Typography>
													</fieldset>
												</Grid>
												<Grid item xs={12} xl={4}>
													<fieldset className=' flex-column field-set '>
														<legend align='center'>
															<strong>
																Detalle del producto (Mancha, rayones etc...)
															</strong>
														</legend>
														<label htmlFor='imagenDetalle'>
															<Input
																id='imagenDetalle'
																type='file'
																onChange={handleImageDetalle}
																name='imagenDetalle'
																//disabled
															/>
															<Tooltip
																title='Cargar imagen detalles'
																TransitionComponent={Fade}
																TransitionProps={{ timeout: 600 }}
																arrow
															>
																<Button
																	color='secondary'
																	variant='contained'
																	component='span'
																	className='back-gradient-8 bord-radius-10  font-letter font-light mt-10'
																	startIcon={<CloudUploadIcon />}
																	//disabled
																>
																	Adjuntar imagen
																</Button>
															</Tooltip>
														</label>
														<Typography
															variant='caption'
															gutterBottom
															component='div'
														>
															{_ImagenDetalle !== null
																? _ImagenDetalle.name
																: 'Sin archivo'}
														</Typography>
													</fieldset>
												</Grid>
												<Grid item xs={12} xl={4}>
													<fieldset className=' flex-column field-set '>
														<legend align='center'>
															<strong>
																Detalle del producto (Mancha, rayones etc...)
															</strong>
														</legend>
														<label htmlFor='imagenDetalle2'>
															<Input
																id='imagenDetalle2'
																type='file'
																onChange={handleImageDetalle2}
																name='imagenDetalle2'
																//disabled
															/>
															<Tooltip
																title='Cargar imagen detalles'
																TransitionComponent={Fade}
																TransitionProps={{ timeout: 600 }}
																arrow
															>
																<Button
																	color='secondary'
																	variant='contained'
																	component='span'
																	className='back-gradient-8 bord-radius-10  font-letter font-light mt-10'
																	startIcon={<CloudUploadIcon />}
																	//disabled
																>
																	Adjuntar imagen
																</Button>
															</Tooltip>
														</label>
														<Typography
															variant='caption'
															gutterBottom
															component='div'
														>
															{_ImagenDetalle2 !== null
																? _ImagenDetalle2.name
																: 'Sin archivo'}
														</Typography>
													</fieldset>
												</Grid>
												<Grid item xs={12} xl={4}>
													<fieldset className=' flex-column field-set '>
														<legend align='center'>
															<strong>
																Detalle del producto (Mancha, rayones etc...) 
															</strong>
														</legend>
														<label htmlFor='imagenDetalle3'>
															<Input
																id='imagenDetalle3'
																type='file'
																onChange={handleImageDetalle3}
																name='imagenDetalle3'
																//disabled
															/>
															<Tooltip
																title='Cargar imagen detalles'
																TransitionComponent={Fade}
																TransitionProps={{ timeout: 600 }}
																arrow
															>
																<Button
																	color='secondary'
																	variant='contained'
																	component='span'
																	className='back-gradient-8 bord-radius-10  font-letter font-light mt-10'
																	startIcon={<CloudUploadIcon />}
																	//disabled
																>
																	Adjuntar imagen
																</Button>
															</Tooltip>
														</label>
														<Typography
															variant='caption'
															gutterBottom
															component='div'
														>
															{_ImagenDetalle3 !== null
																? _ImagenDetalle3.name
																: 'Sin archivo'}
														</Typography>
													</fieldset>
												</Grid>
											</Grid>
										</fieldset>
									</Grid>
									{/* <Grid container spacing={2}>
									<Grid item xs={10} xl={4} sx={{ margin: 'auto' }}>
										<div className='flex'>
											<Button
												//type='submit'
												variant='contained'
												color='primary'
												className='back-gradient-2 bord-radius-10  font-letter font-light mt-30 w100'
												startIcon={<CalculateIcon />}
												onClick={calcular}
												//disabled
											>
												Calcular
											</Button>
										</div>
									</Grid>
								</Grid> */}
									<Grid container spacing={2}>
										<Grid item xs={10} xl={4} sx={{ margin: 'auto' }}>
											<div className='flex'>
												<Button
													type='submit'
													variant='contained'
													color='primary'
													className='back-gradient-7 bord-radius-10  font-letter font-light mt-30 w100'
													startIcon={<SendIcon />}
													//disabled
												>
													Enviar
												</Button>
											</div>
										</Grid>
									</Grid>
								</div>
							</Paper>
						</Grid>
					</Box>

					<Box component='form' onSubmit={handleSubmit(guardarDinamico)}>
						<Grid
							item
							xs={12}
							className={classes.bodyCuestionary}
							hidden={_OcultarFormDinamico}
						>
							<Paper className={classes.paperBody} elevation={0}>
								<Typography
									variant='h6'
									gutterBottom
									className={classes.subtitle}
								>
									Detalle de los artículos
								</Typography>

								<Divider className={classes.dividerhr2} />
								{rows.map((row, index) => (
									<Grid
										container
										alignItems='center'
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										spacing={2}
										className={classes.selectOptions}
									>
										<Row
											{...row}
											onChange={(name, value) =>
												handleOnChange(index, name, value)
											}
											onRemove={() => handleOnRemove(index)}
											key={index}
										/>
									</Grid>
								))}
								<Grid
									container
									spacing={2}
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
								>
									<Grid item xs={3}>
										<Button
											variant='contained'
											component='label'
											className='back-gradient-5 bord-radius-10  font-letter font-light mt-10'
											onClick={handleOnAdd}
											startIcon={<AddIcon />}
										>
											Nuevo artículo
										</Button>
									</Grid>
								</Grid>

								<Grid container spacing={2}>
									<Grid item xs={10} xl={4} sx={{ margin: 'auto' }}>
										<div className='flex'>
											<Button
												type='submit'
												variant='contained'
												color='primary'
												className='back-gradient-2 bord-radius-10  font-letter font-light mt-30 w100'
												startIcon={<SendIcon />}
												//disabled
											>
												Enviar
											</Button>
										</div>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle
					id='alert-dialog-title'
					className='bold HeaderDialogEvaluation'
				>
					{'¿Desea realizar la evaluación de otro artítulo?'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description' className='mt1'>
						El formulario se ha enviado con éxito. De 24 a 48 horas, recibirás
						un correo con nuestra respuesta.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={returnHome} className='DialogEvaluationBtnCancel'>
						No
					</Button>
					<Button
						onClick={handleCloseDialog}
						className='DialogEvaluationBtnAcep'
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>

			{/* MODAL DESCRIPCIÓN DE PROCESO DE EVALUACIÓN */}
			<Dialog
				open={openDialogDescription}
				onClose={handleCloseDialogDescription}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle
					id='alert-dialog-title'
					className='bold HeaderDialogEvaluation'
				>
					{/* <span></span> */}
					{'Descripción del proceso de evaluación'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description' className='mt1'>
						<span>
							{/* ¡Vende o empeña tus{' '} */}
							¡Vende tus{' '}
							<strong> bolsos, zapatos y accesorios de lujo </strong>.
						</span>
					</DialogContentText>
					<DialogContentText id='alert-dialog-description' className='mt1'>
						<span>
							Llena el formulario, valuaremos tu pieza y obtendrás la oferta
							ideal, sigue las instrucciones a continuación.
						</span>
					</DialogContentText>
					<DialogContentText id='alert-dialog-description' className='mt1'>
						<span>
							<strong>Aceptamos marcas Luxury y Premium:</strong>
							<ul>
								<li>Gucci</li>
								<li>Chanel</li>
								<li>Louis Vuitton</li>
								<li>Fendi</li>
							</ul>
						</span>
					</DialogContentText>
					<DialogContentText id='alert-dialog-description' className='mt1'>
						<span>Y muchas más...</span>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						className='DialogEvaluationBtnAcep'
						onClick={handleCloseDialogDescription}
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default CuestionarioEmpConAdd

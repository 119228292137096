import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/facturacion.css'

import Button from '@mui/material/Button'

const FactSection1Web = (props) => {
  return (
    <section className="FacSection1MB">
      <div className="body">
        <div className="contentSection1MB"></div>
        <div className="contentSection2MB">
          <p className="titlesection2MB">Facturación</p>
          <p className="subtitlesection2MB">¡Gracias por tu compra!</p>
          <p className="subtitlesection2MB">
            En caso de requerir factura podrás contactarnos al:
          </p>
          <p className="subtitlesection2MB">63-59-03-18</p>
          <p className="subtitlesection2MB">foundit.pa@founditpreloved.com</p>
        </div>
      </div>
    </section>
  )
}

export default FactSection1Web

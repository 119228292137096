/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import { ThemeProvider } from "@mui/material/styles"
import Theme from "../../../Styles/Theme"
import {
  Chip,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  Alert,
} from "@mui/material"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"

import "../../../Styles/GeneralStyles.css"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { CLIENTES_CONSIGNAS_PIEZAS } from "../../../Constants/routesConstants"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat"
import CategoryIcon from "@mui/icons-material/Category"
import PreviewImg from "../../../Components/PreviewImg"
import Avatar from "@mui/material/Avatar"
import iconConsigna from "../../../Images/MB-Consigna/Consigna_Icon.png"
import { CONSIGNAS_VENDIDAS_ID_CLIENTE } from "../../../Constants/ApiConstants"
import { useDispatch, useSelector } from "react-redux"
import requests from "../../../Components/AxiosCalls/AxiosCall"
import NumberFormat from "react-number-format"
import AlertInfo from "../../../Util/Alerts/AlertInfo"
import InfoIcon from "@mui/icons-material/Info"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import { TablePagination } from "@mui/material"
import * as locales from "@mui/material/locale"
import { createTheme, useTheme } from "@mui/material/styles"

const ConsignaVendidas = (props) => {
  const navigate = useNavigate()
  const [_Consignas, setConsignas] = useState([])
  const [IdImgPreview, setIdImgPreview] = useState()
  const [openModalImgPreview, setModalImgPreview] = useState(false)
  const handleOpenImgPreview = () => {
    setModalImgPreview(!openModalImgPreview)
  }

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const [_Ocultar, setOcultar] = useState(true)
  const [_Ocultar2, setOcultar2] = useState(true)
  const getConsignas = () => {
    props.setOpenLoadingScreen()
    requests
      .get(CONSIGNAS_VENDIDAS_ID_CLIENTE, userInfo.intIdUsuario)
      .then((response) => {
        console.log(response)
        setConsignas(response)

        if (response.length > 0) {
          setOcultar(false)
          setOcultar2(true)
        } else {
          setOcultar(true)
          setOcultar2(false)
        }
        props.setCloseLoadingScreen()
        // setIdPropuesta(null)
      })
      .catch((error) => {
        console.log("Error: " + error)
        props.setCloseLoadingScreen()
      })
  }
  useEffect(() => {
    getConsignas()
  }, [])

  const [alertInfo, setAlertInfo] = useState(false)
  const [titleAlertInfo, setTitleAlertInfo] = useState("")
  const [bodyAlertInfo, setBodyAlertInfo] = useState("")
  const handleCloseAlertInfo = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setAlertInfo(false)
  }
  /*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION INICIO	::::::::::::::::::::::::::::::::::::::::::::::: */
  const [locale, setLocale] = React.useState("esES")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const theme = useTheme()
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  )
  /*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION FIN	::::::::::::::::::::::::::::::::::::::::::::::: */
  return (
    <div>
      <div className="containerViewUser py4 paddingTablets" hidden={_Ocultar}>
        {/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
        <div className="flex-start w90 pb2 pl1">
          <div className="chip-breadcrumbs">
            <span className="letter-breadcrumbs">V</span>
          </div>

          <div role="presentation" className="ml2">
            <Breadcrumbs aria-label="breadcrumb" separator="›">
              <Link
                underline="hover"
                className="breadcrumb-url"
                onClick={() => {
                  navigate(-1)
                }}
              >
                Ventas
              </Link>
              <Link underline="hover" className="breadcrumb-url bold">
                Vendidas
              </Link>
            </Breadcrumbs>
          </div>
        </div>

        {_Consignas.length === 0 ? null : (
          <div className="box-consigna-pro">
            {_Consignas
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <div className="box-list-consigna-pro" key={row.lngIdProducto}>
                  <div className="w20 relative">
                    <img className="img-consigna-pro" src={row.txtUrl} />
                    <div className="box-view-absolute">
                      <IconButton
                        className="btn-blur"
                        onClick={function () {
                          setIdImgPreview(row.txtUrl)
                          handleOpenImgPreview()
                        }}
                      >
                        <RemoveRedEyeIcon
                          className="RemoveEyeIconConsigna"
                          sx={{ m: 0, p: 0, color: "#000000" }}
                        />
                      </IconButton>
                    </div>
                  </div>
                  <div className="flex-column-start-between w80 pl1">
                    <div className="w100">
                      <div className="flex-between-start ">
                        <span className="titleConsignaPropuestas upperCase ProductTitleMarca">
                          {row.intIdMarca} - {row.intIdMaterial}
                          <span className="mx1"> - </span>
                          <span className="p titleConsignaPropuestas ProductTitleMarca">
                            {row.intIdStatusPro}
                          </span>
                        </span>

                        <div>
                          <span className="p textFechaConsignaPropuestas co-grey">
                            {row.fecVenta}
                          </span>
                        </div>
                      </div>

                      <div className="flex-column-start">
                        <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                          SKU: {row.txtSkuReg}
                        </span>
                        <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                          Color: {row.intIdColor}
                        </span>
                        <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                          Estatus: {row.estatusConsigna}
                        </span>
                        <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                          Descuento aplicado: {row.dblDescuentoConsignaAplicado}{" "}
                          %
                        </span>
                        <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                          Total a pagar: &nbsp;
                          <NumberFormat
                            value={row.dblTotalaPagar}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={"$ "}
                            suffix={" DÓLARES"}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xl={12} className="flex">
                <ThemeProvider theme={themeWithLocale}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={_Consignas.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <div hidden={_Ocultar2}>
        <div className="containerViewUser">
          <Alert
            className="alert-no-data"
            icon={
              <InfoIcon fontSize="inherit" className="alert-icon-no-data" />
            }
          >
            Aún no cuentas con artículos en ventas.
          </Alert>
        </div>
      </div>

      <PreviewImg
        IdImgPreview={IdImgPreview}
        openModalImgPreview={openModalImgPreview}
        onCloseModalImgPreview={handleOpenImgPreview}
      />

      <AlertInfo
        openAlert={alertInfo}
        MensajeTitle={titleAlertInfo}
        MensajeBody={bodyAlertInfo}
        handleCloseAlert={handleCloseAlertInfo}
      />
    </div>
  )
}
export default ConsignaVendidas

import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/empeñaMB.css'
import Button from '@mui/material/Button'
import { Fade } from 'react-awesome-reveal'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CUESTIONARIO, CITAS_MB } from '../../../Constants/routesConstants'
import RegisterMb from '../../Login/RegisterMb'
const ESection3Web = (props) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const navigate = useNavigate()

  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    // gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    setOpenSingUp(false)
  }
  return (
    <section className="EmpeñaSection3MB">
      <div className="cardESection3">
        <img
          className="Logo"
          src={
            'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png'
          }
        />
        <span className="text">
          Confía en nuestro proceso y asegura que tus artículos Preloved reciban
          la atención y el valor que merecen
        </span>
        <div className="Contentrelative">
          <Fade cascade damping={0.5} triggerOnce>
            <div className="ContentDesign">
              <div className="section1Design">
                <div className="containerS1"></div>
                <div className="containerS2"></div>
              </div>
              <div className="section2Design">
                <div className="containerS2"></div>
              </div>
            </div>
            <div className="CardCenter">
              <div className="contentCardCenter">
                <span className="textcardcenter">
                  Empeña tus artículos Preloved en Foundit y obtén dinero rápido
                  con nuestro proceso sencillo y eficiente.
                </span>
                <div className="bottomLink">
                  <Button
                    onClick={
                      userInfo
                        ? function () {
                            navigate(CUESTIONARIO)
                            // gtmGoTo_EmpeñaAqui_Log()
                          }
                        : function () {
                            handleOpenSingUp()
                          }
                    }
                    className="btnLink"
                  >
                    Empeñar
                    <img
                      src={
                        'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png '
                      }
                    />
                  </Button>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <RegisterMb open={openSingUp} onClose={handleCloseSingUp} props={props} />
    </section>
  )
}

export default ESection3Web

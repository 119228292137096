/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import '../Styles/TerminosConditions/TermsConditions.css'
const PrivacyNotice = () => {
  return (
    <div className="ContainerTerms mt5">
      <div className="Container-Content-Terms shadow">
        <h2 className="text-center Subtitle">POLÍTICAS DE PRIVACIDAD</h2>
        <h3 className="text-center Subtitle">
          Y TRATAMIENTO DE DATOS PERSONALES
        </h3>

        {/* ************************SECTION 1************************* */}
        <div>
          <p className="section-Body">
            <span className="bold co-black">
              ARTÍCULOS DE LUJO, S.A. DE C.V.
            </span>{' '}
            (en lo sucesivo “<span className="bold co-black">FOUNDIT</span>
            ”), con domicilio para oír y recibir notificaciones el ubicado en
            Finca 3232, ubicada en Calle 3era Oeste San Felipe, Casco Antiguo,
            República de Panamá, correo electrónico de atención a clientes:
            foundit.pa@founditpreloved.com. Nuestra empresa está comprometida en
            proteger su privacidad de conformidad con lo establecido en la Ley
            81 de 2019 y el Decreto Ejecutivo No. 285 de 28 de mayo de 2021 que
            la reglamenta (en adelante y en conjunto, la “Legislación”). Además
            de lo dispuesto en la Legislación, FOUNDIT ha adoptado las mejores
            prácticas internacionales en el manejo y administración de “Datos
            Personales”, por lo que con el fin de informar de manera clara y
            específica que Datos Personales recabamos, los medios por los que se
            recaban, así como el tratamiento que damos a los mismos, siempre
            mediando nuestro compromiso de proteger su privacidad bajo los más
            altos estándares de ética, responsabilidad y profesionalismo,
            ponemos a su disposición el presente documento de Políticas de
            Privacidad y Tratamiento de Datos Personales.
          </p>
          <p className="section-Body">
            A fin de brindar mayor contexto, el{' '}
            <span className="bold">Tratamiento</span> de Datos Personales se
            debe entender como
            <span className="italic">
              “Cualquier operación o complejo de operaciones o procedimientos
              técnicos, de carácter automatizado o no, que permita recolectar,
              almacenar, grabar, organizar, elaborar, seleccionar, extraer,
              confrontar, interconectar, asociar, disociar, comunicar, ceder,
              intercambiar, transferir, transmitir o cancelar datos, o
              utilizarlos en cualquier forma”.
            </span>
          </p>
          <p className="section-Body">
            Esta política aplica a las operaciones internas de FOUNDIT LUXE
            SECONDHAND, sus colaboradores y su relacionamiento con clientes y
            socios comerciales.
          </p>
          <p className="section-Body">
            Las presentes políticas aplicarán para todos los datos personales
            que recopile y trate FOUNDIT en relación con los servicios
            profesionales y comerciales que presta como empresa.
          </p>
        </div>
        {/* ************************SECTION A ************************* */}
        <div>
          <p className="section-Title">A. DATOS PERSONALES QUE RECABAMOS.</p>
          <p className="section-Body">
            Para el cumplimiento de la relación jurídica y la prestación de
            servicios que usted solicite, si usted es cliente nuestro, FOUNDIT
            podrá recabar de usted las siguientes categorías de Datos
            Personales, ya sea mediante formatos físicos o electrónicos:
            <ul>
              <li>Datos de identificación y contacto.</li>
              <li>
                Datos patrimoniales (datos bancarios para cobro, fiscales para
                facturación).
              </li>
              <li>
                Datos biométricos (huellas dactilares, huellas de voz, datos de
                imagen en fotografías y/o vídeos).
              </li>
              <li>
                Datos de geolocalización, al hacer uso de la plataforma de
                FOUNDIT LUXE SECONDHAND.
              </li>
            </ul>
          </p>
          <p className="section-Body">
            Dentro de las categorías de Datos Patrimoniales y/o Datos
            Biométricos, al ser considerados como Datos Personales Sensibles que
            requieren especial protección, FOUNDIT LUXE SECONDHAND dará especial
            tratamiento a los mismos y solicitará su consentimiento expreso y
            por escrito de conformidad a lo establecido por la Legislación y las
            finalidades establecidas en el presente Aviso de Privacidad.
          </p>
          <p className="section-Body">
            Usted siempre podrá elegir no informarnos sus datos personales, pero
            en general se requiere dicha información para concretar las
            relaciones jurídicas y servicios por parte de FOUNDIT LUXE
            SECONDHAND.
          </p>
          <p className="section-Body">
            Si Usted elige no darnos determinada información, es posible que no
            pueda contratar nuestros servicios.
          </p>
        </div>
        {/* ************************SECTION B ************************* */}
        <div>
          <p className="section-Title">
            B. PRINCIPIOS GENERALES RELATIVOS AL TRATAMIENTO DE DATOS
            PERSONALES.
          </p>
          <p className="section-Body">
            Todos aquellos que estén bajo el ámbito de aplicación de estas
            Políticas, tendrán como principio garantizar:
          </p>

          <p className="section-Body">
            <ol>
              <li>Los derechos constitucionales de los interesados.</li>
              <li>
                Los derechos otorgados por las Leyes según sea el caso, de los
                interesados.
              </li>
              <li>El cumplimiento de toda regulación vigente.</li>
              <li>
                La respuesta inmediata en casos de riesgo de fugas de
                información.
              </li>
              <li>
                La evaluación de los procesos para el aseguramiento de las
                medidas de prevención de riesgos.
              </li>
              <li>La constante revisión de los procesos.</li>
              <li>
                La ejecución de los requerimientos de los interesados y pleno
                ejercicio de los derechos ARCO (Acceso, Rectificación,
                Cancelación, Oposición y Portabilidad) sobre los datos
                personales.
              </li>
              <li>
                Tener en cuenta estas Políticas en todos los servicios brindados
                y ejecutados en nombre de FOUNDIT LUXE SECONDHAND.
              </li>
            </ol>
          </p>
        </div>
        {/* <div>
					<p className='section-Title'>
						B. EL TRATAMIENTO DE DATOS PERSONALES POR PARTE DE FOUNDIT, TIENE
						LAS SIGUIENTES FINALIDADES.
					</p>
					<p className='section-Body'>
						Para identificación, verificación y/o contacto, en cualquier tipo de
						relación jurídica o de negocios con FOUNDIT.
					</p>
					<p className='section-Body'>
						Para el cumplimiento de obligaciones contractuales respecto a los
						servicios que debemos prestarle.
					</p>
					<p className='section-Body'>
						Para informarle sobre cambios en los productos y/o servicios que
						comercializamos.
					</p>
					<p className='section-Body'>
						Para realizar la cobranza y facturación de los servicios y/o
						productos que adquiere con nosotros, así como ejecutar procesos de
						reembolso en caso de aplicar.
					</p>
					<p className='section-Body'>
						Para envío de publicidad y promociones de FOUNDIT.
					</p>
					<p className='section-Body'>
						De manera adicional, utilizamos sus Datos Personales para las
						siguientes “Finalidades Secundarias” que no son necesarias para el
						cumplimiento de la relación jurídica que da origen al tratamiento de
						Datos Personales, pero que nos permiten y facilitan brindarle una
						mejor atención:
						<ul style={{ listStyle: 'none' }}>
							<li>
								- Para elaborar estudios y programas con fines de mercadotecnia
								y/o publicitarios.
							</li>
							<li>
								- Para mantenimiento de registros orientados a la prestación de
								servicios en el futuro.
							</li>
							<li>
								- Para informarle de productos, servicios, publicidad,
								promociones y boletines informativos presentes o futuros que
								pudieran resultar de su interés.
							</li>
							<li>
								- Para realizar encuestas de calidad de nuestros productos y/o
								servicios.
							</li>
						</ul>
					</p>
					<p className='section-Body'>
						En caso de que no desee que sus Datos Personales se utilicen para
						alguna(s) de las Finalidades Secundarias, lo invitamos a seguir el
						procedimiento establecido en el punto “E” del presente Aviso de
						Privacidad. La negativa para el uso de sus Datos Personales para
						estas Finalidades Secundarias no podrá ser un motivo para que le
						neguemos la prestación de los servicios y/o productos que adquiere o
						contrata con FOUNDIT.
					</p>
				</div> */}
        {/* ************************SECTION C ************************* */}
        <div>
          <p className="section-Title">
            C. PRINCIPIOS BÁSICOS RELATIVOS AL TRATAMIENTO DE DATOS PERSONALES.
          </p>
          <p className="section-Body">
            Los principios relativos al tratamiento de los datos personales
            sobre los que se fundamenta esta Política serán:
          </p>
          <p className="section-Body">
            <ul style={{ listStyle: 'none' }}>
              <li>
                - Lealtad con el interesado: Evitar discriminación y engaño para
                el recaudo de los datos, siempre utilizando medios legítimos
                para ello.
              </li>
              <li>
                - Transparencia y acceso: Asegurando siempre dar información a
                los interesados concerniente a la protección de los datos, así
                como la de disponer del acceso con lenguaje sencillo.
              </li>
              <li>
                - Licitud o legitimación del tratamiento: Contar siempre con el
                procedimiento de recaudo a través del consentimiento, o en su
                defecto con sustento a las excepciones que establezcan las
                Leyes.
              </li>
              <li>
                - Limitación de la finalidad: No utilizar los datos para
                propósitos distintos a los recaudados, y siempre dentro de lo
                establecido en las Leyes.
              </li>
              <li>
                - Minimización: No recaudar información o datos personales que
                no se requieran para los propósitos en que se solicita su
                conjunto.
              </li>
              <li>
                - Exactitud: Debe existir exactitud respecto a los datos que se
                recauda, y mantener siempre la información actualizada. De igual
                forma se deberá realizar las correcciones pertinentes para
                mantener la veracidad de la información.
              </li>
              <li>
                - Plazo de conservación: No mantener los datos por más tiempo
                del necesario, o más tiempo de lo que estipula la legislación en
                los casos que amerite.
              </li>
              <li>
                - Integridad y confidencialidad: Se debe procurar garantizar que
                los datos estén seguros para evitar alteraciones o se realicen
                usos no autorizados. Todo el personal deberá mantener estricta
                confidencialidad de los datos, haciendo uso de los mismos sólo
                según el fin por el que se ha recaudado, y las excepciones que
                establezca la Ley explícitamente.
              </li>
              <li>
                - Disponibilidad: En cuanto el interesado la solicite y dar la
                facilidad al interesado de ejercer sus derechos.
              </li>
            </ul>
          </p>
        </div>
        {/* ************************SECTION D ************************* */}
        <div>
          <p className="section-Title">D. PARTES</p>
          <p className="section-Body ">
            <ol className="bold" type="a">
              <li>Responsable y Tratamiento de Datos</li>
            </ol>
            FOUNDIT LUXE SECONDHAND será considerada como CO-RESPONSABLE y
            CUSTODIO del tratamiento de los datos personales que se recopilen en
            función de su actividad comercial, siempre que dentro de los
            contratos realizados con sus clientes, quede en manifiesto, por la
            naturaleza de la misma, que el responsable principal será el cliente
            de ARTICULOS DE LUJO, S.A. con la que está contratando. ARTICULOS DE
            LUJO, S.A. tomará las previsiones para el aseguramiento de que el
            cliente mantenga políticas de protección de datos conforme a la Ley,
            sin embargo, ARTICULOS DE LUJO, S.A. se limitará en responsabilidad
            respecto al recaudo que ésta realice y trate. Por otro lado,
            ARTICULOS DE LUJO, S.A. podrá ser el responsable principal, en
            aquellos casos en los que recaude información de sus clientes de
            manera directa, por lo que cualquier parámetro de servicios
            específicos brindados serán actualizados o comunicados a los
            usuarios, respecto al tratamiento de sus datos.
          </p>
          <p className="section-Body ">
            Los datos personales que trata FOUNDIT LUXE SECONDHAND serán
            suministrados por los clientes y socios comerciales que contratan
            nuestros servicios. Esto implica que el fundamento legal para el
            tratamiento de los datos nace de una relación contractual, y se
            asegura su tratamiento confidencial y protegiendo la privacidad de
            los datos.
          </p>
          <p className="section-Body ">
            Los datos personales que se tratan por parte de FOUNDIT LUXE
            SECONDHAND son necesarios para el desarrollo de la actividad
            comercial y serán tratados con este fin específico.
          </p>
          <p className="section-Body ">
            En base a la Ley N° 81 de 2019 y su Reglamento, se entiende como
            dato personal
            <span className="italic bold">
              “Cualquier información concerniente a personas naturales, que las
              identifica o las hace identificables”
            </span>
            .
          </p>
          <p className="section-Body ">
            Estos datos personales incluyen, pero no se limitan, a los
            siguientes:
          </p>
          <p className="section-Body ">
            <ul>
              <li>Nombre completo</li>
              <li>Sexo</li>
              <li>Fecha de Nacimiento</li>
              <li>Correo electrónico</li>
              <li>Número de teléfono</li>
            </ul>
          </p>
          <p className="section-Body ">
            Dentro del concepto de datos personales, también existen los datos
            que son considerados sensibles, los cuales son
            <span className="italic">
              “Aquel que se refiera a la esfera íntima de su titular, o cuya
              utilización indebida pueda dar origen a discriminación o conlleve
              un riesgo grave para este. De manera enunciativa, se consideran
              sensibles los datos personales que puedan revelar aspectos como
              origen racial o étnico; creencias o convicciones religiosas,
              filosóficas y morales; afiliación sindical; opiniones políticas;
              datos relativos a la salud, a la vida, a la preferencia u
              orientación sexual, datos genéticos o datos biométricos, entre
              otros, sujetos a regulación y dirigidos a identificar de manera
              unívoca a una persona natural”
            </span>
            .
          </p>
          <p className="section-Body ">
            Los datos considerados sensibles, tendrán un especial manejo y
            protección a fin de salvaguardar al titular de cualquier mal uso que
            pueda ocasionar un riesgo para sí o pueda derivar en discriminación.
          </p>
          <p className="section-Body ">
            La finalidad del tratamiento de los datos que realiza FOUNDIT LUXE
            SECONDHAND se detallará en los servicios establecidos y detallados
            en los contratos celebrados con clientes y socios comerciales.
          </p>
          <p className="section-Body ">
            Existe determinada información que FOUNDIT LUXE SECONDHAND maneja,
            pero no es considerada información personal, dado que esta
            información no identifica a los usuarios. Se utilizan perfiles de
            usuario e información anonimizada.
          </p>
          <p className="section-Body ">
            <ol className="bold" start="2" type="a">
              <li>Destinatarios de los Datos Personales:</li>
            </ol>
            Serán todas aquellas personas jurídicas o naturales que
            estrictamente deban participar en la cadena de tratamiento para
            poder ejecutar los servicios prestados, así como la ejecución de los
            fines de la sociedad, dejando claridad a los interesados de ello. Se
            manejará el tratamiento según la legislación vigente, tomando en
            cuenta que pueden existir autoridades competentes como
            destinatarios, y que explícitamente por Ley se establece que no
            requiere ni la comunicación ni el consentimiento del interesado, por
            ejemplo, en casos como investigaciones judiciales. Por ello, salvo
            las excepciones por Ley, aquellos que estén dentro del ámbito de
            aplicación de estas políticas, no podrán remitir o tratar datos con
            terceros fuera de los objetivos para los cuales fueron recaudados,
            sin consentimiento del interesado.
          </p>
        </div>
        {/* ************************SECTION E ************************* */}
        <div>
          <p className="section-Title">
            E. EL TRATAMIENTO DE DATOS PERSONALES POR PARTE DE FOUNDIT LUXE
            SECONDHAND, TIENE LAS SIGUIENTES FINALIDADES.
          </p>
          <p className="section-Body">
            Para identificación, verificación y/o contacto, en cualquier tipo de
            relación jurídica o de negocios con FOUNDIT LUXE SECONDHAND.
          </p>
          <p className="section-Body">
            Para el cumplimiento de obligaciones contractuales respecto a los
            servicios que debemos prestarle.
          </p>
          <p className="section-Body">
            Para informarle sobre cambios en los productos y/o servicios que
            comercializamos.
          </p>
          <p className="section-Body">
            Para realizar la cobranza y facturación de los servicios y/o
            productos que adquiere con nosotros, así como ejecutar procesos de
            reembolso en caso de aplicar.
          </p>
          <p className="section-Body">
            Para envío de publicidad y promociones de FOUNDIT LUXE SECONDHAND.
          </p>
          <p className="section-Body">
            De manera adicional, utilizamos sus Datos Personales para las
            siguientes “Finalidades Secundarias” que no son necesarias para el
            cumplimiento de la relación jurídica que da origen al tratamiento de
            Datos Personales, pero que nos permiten y facilitan brindarle una
            mejor atención:
          </p>
          <p className="section-Body">
            <ol style={{ listStyle: 'none' }}>
              <li>
                - Para elaborar estudios y programas con fines de mercadotecnia
                y/o publicitarios.
              </li>
              <li>
                - Para mantenimiento de registros orientados a la prestación de
                servicios en el futuro.
              </li>
              <li>
                - Para informarle de productos, servicios, publicidad,
                promociones y boletines informativos presentes o futuros que
                pudieran resultar de su interés.
              </li>
              <li>
                - Para realizar encuestas de calidad de nuestros productos y/o
                servicios.
              </li>
            </ol>
          </p>
          <p className="section-Body">
            En caso de que no desee que sus Datos Personales se utilicen para
            alguna(s) de las Finalidades Secundarias, lo invitamos a seguir el
            procedimiento establecido en el punto “E” del presente documento de
            Políticas de Privacidad. La negativa para el uso de sus Datos
            Personales para estas Finalidades Secundarias no podrá ser un motivo
            para que le neguemos la prestación de los servicios y/o productos
            que adquiere o contrata con FOUNDIT LUXE SECONDHAND.
          </p>
        </div>
        {/* ************************SECTION F ************************* */}
        <div>
          <p className="section-Title">
            F. PLAZO DE CONSERVACIÓN DE SUS DATOS PERSONALES.
          </p>
          <p className="section-Body">
            FOUNDIT LUXE SECONDHAND conservará sus Datos Personales durante el
            tiempo que dure la relación jurídica con usted, o conforme a los
            plazos establecidos en la Legislación, o hasta que usted nos
            solicite que los eliminemos, siempre y cuando la Legislación lo
            permita.
          </p>
        </div>
        {/* ************************SECTION G ************************* */}
        <div>
          <p className="section-Title">G. MEDIDAS DE SEGURIDAD.</p>
          <p className="section-Body">
            ARTÍCULOS DE LUJO, S.A. deberá ejecutar procedimientos de evaluación
            con terceros expertos en tecnologías, a fin de que realicen
            recomendaciones necesarias en los sistemas, para evitar posibles o
            futuras vulnerabilidades de la empresa.
          </p>
          <p className="section-Body">
            La empresa generará los manuales y capacitaciones correspondientes
            para los procesos funcionales y humanos, a fin de evitar la
            violabilidad o fuga de la información, incluyendo, pero no limitado
            a manejos de passwords, phishing, manejo de documentación, entre
            otros.
          </p>
          <p className="section-Body">
            Los datos personales tratados se mantendrán bajo la responsabilidad
            de FOUNDIT LUXE SECONDHAND mientras se mantenga la relación
            comercial.
          </p>
          <p className="section-Body">
            La información podrá mantenerse posterior a la culminación de la
            relación comercial o contractual por un periodo de 10 años. Este
            plazo puede variar si así lo establece una Ley especial o lo mandata
            una Autoridad competente.
          </p>
          <p className="section-Body">
            FOUNDIT LUXE SECONDHAND se compromete a resguardar los datos
            personales bajo estrictas normas de seguridad a fin de garantizar la
            confidencialidad, integridad, disponibilidad y resiliencia de la
            información, y ofrece los mayores estándares técnicos, físicos y de
            procedimiento para la preservación y resguardo de los datos
            personales.
          </p>
        </div>
        {/* ************************SECTION H ************************* */}
        <div>
          <p className="section-Title">
            H. MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES.
          </p>
          <p className="section-Body">
            Para prevenir el acceso no autorizado a sus datos personales y con
            el fin de asegurar que la información sea utilizada para los fines
            establecidos en este documento de Políticas de Privacidad, hemos
            establecido diversos procedimientos y medidas de seguridad con la
            finalidad de evitar el uso, daño, pérdida o divulgación no
            autorizados de sus datos, permitiéndonos tratarlos debidamente. Si
            desea manifestar su negativa al uso o divulgación de Datos
            Personales, usted podrá solicitar su inscripción en nuestro “Listado
            de Exclusión”. Para solicitar su inclusión a nuestro Listado de
            Exclusión deberá seguir el proceso establecido en el punto “E” del
            presente documento de Políticas de Privacidad.
          </p>
        </div>
        {/* ************************SECTION I ************************* */}
        <div>
          <p className="section-Title">
            EJERCICIO DE DERECHOS DE: (i) ACCESO, RECTIFICACIÓN, CANCELACIÓN,
            OPOSICIÓN (“Derechos de ARCO”); (ii) SOLICITUD DE REVOCACIÓN DEL
            CONSENTIMIENTO AL TRATAMIENTO DE DATOS PERSONALES; (iii)
            PORTABILIDAD DE DATOS; Y (iv) DERECHO AL OLVIDO.
          </p>
          <p className="section-Body">
            Derecho de acceso: Usted tiene derecho de acceder a sus Datos
            Personales en nuestra posesión y a los detalles del tratamiento de
            los mismos, así como a rectificarlos en caso de ser inexactos o
            incompletos;
          </p>
          <p className="section-Body">
            Derecho de Rectificación: El titular tiene derecho a pedir la
            corrección de cualquier dato personal que considere incorrecto,
            irrelevantes, incompletos, desfasados, inexactos, falsos o
            impertinente.
          </p>
          <p className="section-Body">
            Derecho de cancelación: El titular podrá solicitar cancelar o
            eliminar la información cuando considere que no se requieren para
            alguna de las finalidades señaladas en el presente documento de
            Políticas de Privacidad, cuando estén siendo utilizados para
            finalidades no consentidas o haya finalizado la relación contractual
            o de servicio, o bien, oponerse al tratamiento de los mismos para
            fines específicos. También podrá solicitar la eliminación de sus
            datos personales incorrectos, irrelevantes, incompletos, desfasados,
            inexactos, falsos o impertinentes.
          </p>
          <p className="section-Body">
            Derecho de Oposición: El titular podrá oponerse a un tratamiento
            especifico sobre sus datos personales.
          </p>
          <p className="section-Body">
            Derecho de Portabilidad: El titular podrá obtener una copia de los
            datos personales que reposen bajo la responsabilidad de FOUNDIT LUXE
            SECONDHAND.
          </p>
          <p className="section-Body">
            El mecanismo implementado para el ejercicio de dichos derechos, es a
            través de la presentación de la solicitud respectiva al correo
            electrónico foundit.pa@founditpreloved.com y/o domicilio de nuestras
            oficinas mismo que fue debidamente señalado al principio del
            presente documento de Políticas de Privacidad.
          </p>
          <p className="section-Body">Dicha solicitud deberá contener:</p>
          <p className="section-Body">
            1. El nombre del titular y domicilio u otro medio para comunicarle
            la respuesta a su solicitud;
          </p>
          <p className="section-Body">
            2. Los documentos que acrediten la identidad o, en su caso, la
            representación legal del titular;
          </p>
          <p className="section-Body">
            3. La descripción clara y precisa de los Datos Personales respecto
            de los que se busca ejercer alguno de los derechos antes
            mencionados; y cualquier otro elemento o documento que facilite la
            localización de los Datos Personales.
          </p>
          <p className="section-Body">
            4. En el caso de solicitudes de rectificación, el titular deberá
            indicar, además de lo anterior, las modificaciones a realizarse y
            aportar la documentación que sustente su petición.
          </p>
          <p className="section-Body">
            El titular de Datos Personales o quien lo represente, podrá
            solicitar su información a los responsables del tratamiento de
            datos, la cual deberá ser proporcionada en un plazo no mayor de diez
            (10) días hábiles, a partir de la fecha de presentación de dicha
            solicitud.
          </p>
          <p className="section-Body">
            FOUNDIT LUXE SECONDHAND modificará los datos cuando sean erróneos,
            inexactos, equívocos o incompletos dentro de un plazo máximo de
            cinco (5) días hábiles, contados desde la fecha en que se recibió
            completa la solicitud de acceso, rectificación, cancelación u
            oposición. Los plazos antes referidos podrán ser ampliados una sola
            vez por un periodo igual, siempre y cuando así lo justifiquen las
            circunstancias del caso.
          </p>
          <p className="section-Body">
            Es importante mencionar que no en todos los casos podremos
            satisfacer su solicitud de Derechos de ARCO, revocación del
            consentimiento, portabilidad o derecho al olvido, ya que es posible
            que por alguna obligación legal requiramos seguir conservando y/o
            dando tratamiento a sus Datos Personales de conformidad al presente
            documento de Políticas de Privacidad. Si éste fuera el caso, lo
            haremos de su conocimiento indicando las obligaciones que
            correspondan y procederemos en su caso, al bloqueo de los Datos
            Personales que así corresponda, lo anterior durante el término que
            la Legislación obligue para su conservación y hasta que proceda su
            eliminación definitiva.
          </p>
          <p className="section-Body">
            FOUNDIT LUXE SECONDHAND, podrá negar el ejercicio de los Derechos
            ARCO, en los siguientes supuestos:
          </p>
          <p className="section-Body">
            a) Cuando usted no sea el titular de los Datos Personales, o no haya
            acreditado fehacientemente la representación del titular;
          </p>
          <p className="section-Body">
            b) Cuando sus Datos Personales no obren en la base de datos de
            FOUNDIT LUXE SECONDHAND;
          </p>
          <p className="section-Body">
            c) Cuando se lesionen los derechos de un tercero;
          </p>
          <p className="section-Body">
            d) Cuando exista un impedimento legal o la resolución de una
            autoridad competente que restrinja sus Derechos ARCO;
          </p>
          <p className="section-Body">
            e) En caso de cancelación, cuando los Datos Personales sean objeto
            de tratamiento para la prevención o para el diagnóstico médico o la
            gestión de servicios de salud, o;
          </p>
          <p className="section-Body">
            f) Cuando la rectificación, cancelación u oposición haya sido
            previamente realizada.
          </p>
        </div>
        {/* ************************SECTION F ************************* */}
        <div>
          <p className="section-Title">F. TRANSFERENCIA DE DATOS.</p>
          <p className="section-Body">
            FOUNDIT LUXE SECONDHAND manifiesta que los datos personales que
            serán transferidos, compartidos y/o cedidos solo se dará por mandato
            contractual, cuando cuenten con el consentimiento previo, informado
            e inequívoco del titular, y con el fin de cumplir con el servicio o
            por interés legítimo a favor del titular de los datos personales.
          </p>
          <p className="section-Body">
            FOUNDIT LUXE SECONDHAND podrá conservar el tiempo que la Ley
            aplicable lo permita, la información en su base de datos con el
            objeto de simplificar la prestación del servicio.
          </p>
          <p className="section-Body">
            FOUNDIT LUXE SECONDHAND llevará un control y registro de las
            terceras partes a las cuales se le transfieren los datos a fin de
            que puedan ser consultados por parte del titular.
          </p>
        </div>

        {/* ************************SECTION G ************************* */}

        <div>
          <p className="section-Title">
            G. MEDIOS ELECTRÓNICOS, COOKIES O WEB BEACONS.
          </p>
          <p className="section-Body">
            FOUNDIT LUXE SECONDHAND le recomienda que al navegar por el sitio
            web permita la utilización de cookies para que cumplan ciertas
            funciones. Las cookies son tipos específicos de información que un
            sitio web transmite al disco duro de la computadora u otro
            dispositivo del usuario con el fin de mantener registros. Las
            cookies pueden servir para facilitar el uso de un sitio web
            guardando su configuración y preferencias mientras el usuario navega
            en Internet. FOUNDIT LUXE SECONDHAND no puede acceder a otra
            información almacenada en su dispositivo a través de las cookies,
            incluso si las mismas se descargan de nuestro sitio web. Las cookies
            no pueden cargar código malicioso de ningún tipo ni virus o malware,
            y no dañan el dispositivo del usuario.
          </p>
          <p className="section-Title">
            Los cookies también podrán ser utilizados para desplegar anuncios
            relevantes al consumidor en otros sitios que el usuario visite, los
            cuales también podrán ser removidos por el consumidor directamente
            en cada anuncio.
          </p>
        </div>

        {/* ************************SECTION I ************************* */}
        <div>
          <p className="section-Title">
            H. MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES.
          </p>
          <p className="section-Body">
            Para prevenir el acceso no autorizado a sus datos personales y con
            el fin de asegurar que la información sea utilizada para los fines
            establecidos en este documento de Políticas de Privacidad, hemos
            establecido diversos procedimientos y medidas de seguridad con la
            finalidad de evitar el uso, daño, pérdida o divulgación no
            autorizados de sus datos, permitiéndonos tratarlos debidamente. Si
            desea manifestar su negativa al uso o divulgación de Datos
            Personales, usted podrá solicitar su inscripción en nuestro “Listado
            de Exclusión”. Para solicitar su inclusión a nuestro Listado de
            Exclusión deberá seguir el proceso establecido en el punto “E” del
            presente documento de Políticas de Privacidad.
          </p>
        </div>
        {/* ************************SECTION I ************************* */}
        <div>
          <p className="section-Title">I. JURISDICCIÓN.</p>
          <p className="section-Body">
            Este documento de Políticas de Privacidad se rige por las leyes de
            Panamá, y cualquier controversia será resuelta frente a las
            autoridades panameñas competentes. Al aceptar este documento de
            Políticas de Privacidad, usted renuncia a cualquier otro fuero y
            legislación que le pudiere corresponder por razón de su domicilio.
          </p>
        </div>
        {/* ************************SECTION J ************************* */}
        <div>
          <p className="section-Title">J. AUTORIDAD.</p>
          <p className="section-Body">
            Si usted considera que su derecho a la protección de DATOS
            PERSONALES ha sido lesionado por alguna conducta u omisión por parte
            de FOUNDIT LUXE SECONDHAND, o presume alguna violación a las
            disposiciones previstas en la Legislación y demás ordenamientos
            aplicables, podrá interponer su inconformidad ante el Instituto
            Nacional de Transparencia, Acceso a la Información y Protección de
            Datos Personales (INAI).
          </p>
        </div>
        {/* ************************SECTION K ************************* */}
        <div>
          <p className="section-Title">K. ACEPTACIÓN DE LOS TÉRMINOS.</p>
          <p className="section-Body">
            Usted manifiesta que los Datos Personales que proporcione a FOUNDIT
            LUXE SECONDHAND han sido obtenidos de manera libre, informada,
            voluntaria e inequívoca en términos de la “Ley” y de este Aviso, por
            lo que esta declaración de Privacidad está sujeta a los Términos de
            Servicio del sitio web de{' '}
            <a href="http://pa.founditmembers.com/" target="_blank">
              http://pa.founditmembers.com/
            </a>{' '}
            antes descrito, lo cual constituye un acuerdo legal entre el usuario
            y FOUNDIT LUXE SECONDHAND.
          </p>
          <p className="section-Body">
            Por tanto, si el usuario utiliza los servicios del presente sitio
            web, significa que ha leído, entendido y acordado los términos antes
            expuestos y manifiesta expresamente su aceptación para que FOUNDIT
            LUXE SECONDHAND lleve a cabo el tratamiento de sus Datos Personales
            en los términos que en el mismo se señalan.
          </p>
        </div>
        {/* ************************SECTION L ************************* */}
        <div>
          <p className="section-Title">L. VIGENCIA.</p>
          <p className="section-Body">
            Esta es la versión más reciente de nuestro documento de Políticas de
            Privacidad y Protección de Datos Personales.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyNotice

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Button from "@mui/material/Button"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Alert from "@mui/material/Alert"
import {
  FLUJO_PAGO,
  FLUJO_REFRENDO,
  FLUJO_LIQUIDAR,
} from "../../../Constants/routesConstants"
import { useDispatch, useSelector } from "react-redux"
import {
  postRefrendpCreate,
  postLiquidarEmpCreate,
} from "../../../Actions/MundoBarterActions"

import {
  EMPEÑO_BY_ID,
  EMPEÑO_PAGO_LIST_BY_ID_EMPEÑO,
} from "../../../Constants/ApiConstants"

import requests from "../../../Components/AxiosCalls/AxiosCall"
import "../../../Styles/App/Empeño/EmpeñoView.css"
import NumberFormat from "react-number-format"

const ClientesConsignaView = (props) => {
  const { id } = useParams()
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const [_Empeño, setEmpeño] = useState([])
  const [_Abonos, setAbonos] = useState([])

  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel1) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel1 : false)
  }
  const getEmpeño = () => {
    props.setOpenLoadingScreen()
    requests
      .get(EMPEÑO_BY_ID, id)
      .then((response) => {
        setEmpeño(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log("Error: " + error)
        props.setCloseLoadingScreen()
      })
  }

  const [_Ocultar, setOcultar] = useState(true)
  const [_Ocultar2, setOcultar2] = useState(true)
  const getAbonos = () => {
    props.setOpenLoadingScreen()
    requests
      .get(EMPEÑO_PAGO_LIST_BY_ID_EMPEÑO, id)
      .then((response) => {
        setAbonos(response)
        if (response.length > 0) {
          setOcultar(false)
          setOcultar2(true)
        } else {
          setOcultar(true)
          setOcultar2(false)
        }
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log("Error: " + error)
        props.setCloseLoadingScreen()
      })
  }

  useEffect(() => {
    getEmpeño()
    getAbonos()
  }, [])

  return (
    <div className="containerViewUser p2">
      <div className="body-empeño">
        <div className="head-empeño flex-between p3">
          <div className="w20">
            <img className="img-EmpeñoView" src={_Empeño.txtUrl} />
          </div>

          <div className="w50 flex-column line-height-1 border-r-empeño">
            <p className="fs20">Total a pagar</p>
            <h2>
              <NumberFormat
                value={_Empeño.dblTotalaPagar}
                displayType={"text"}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={"$ "}
                suffix={" DÓLARES"}
              />
            </h2>
            <Button
              className="btn-empeño-pay"
              onClick={function () {
                dispatch(postLiquidarEmpCreate(_Empeño.dblTotalaPagar))
                navigate(FLUJO_LIQUIDAR + _Empeño.lngIdProducto)
              }}
            >
              Liquidar Empeño
            </Button>
            <Button
              className="btn-refrendo"
              onClick={function () {
                dispatch(postRefrendpCreate(_Empeño.dblTotalaPagar))
                navigate(FLUJO_REFRENDO + _Empeño.lngIdProducto)
              }}
            >
              Refrendar
            </Button>
            <Button
              className="btn-empeño"
              onClick={function () {
                navigate(FLUJO_PAGO + _Empeño.lngIdProducto)
              }}
            >
              Abonar
            </Button>
          </div>

          <div className="w30 line-height-1 pl3">
            <div className="py2 border-b-empeño">
              <p>Próximo Corte</p>
              <h3>5/Dic/2022</h3>
            </div>
            <div className="py2">
              <p>Vencimiento</p>
              <h3>{_Empeño.fecPierdeEmpe}</h3>
            </div>
          </div>
        </div>
        {/* ::::::::::::::::::::    lista de Detalle   :::::::::::::::::::::: */}
        <div className="box-proceso-producto-list" hidden={_Ocultar}>
          <div className="flex w95 ml2">
            <div className="w25 text-center bold fs16 ">Abono #</div>
            <div className="w25 text-center bold fs16 ">Fecha</div>
            <div className="w25 text-center bold fs16 ">Concepto</div>
            <div className="w25 text-center bold fs16 ">Valor total</div>
            <div className="w25 text-center bold fs16 ">Abonos</div>
            <div className="w25 text-center bold fs16 ">Debes</div>
            <div className="w25 text-center bold fs16 ">Estado</div>
          </div>
          {_Abonos.map((row, index) => (
            <div>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="w25 text-center fs16 ">1</div>
                  <div className="w25 text-center fs16 ">19 / Dic / 2022</div>
                  <div className="w25 text-center fs16 ">Abono</div>
                  <div className="w25 text-center fs16 ">$ 10,000.00</div>
                  <div className="w25 text-center fs16 ">$ 4,000.00</div>
                  <div className="w25 text-center fs16 ">$ 6,000.00</div>
                  <div className="w25 text-center fs16 ">
                    <Button className="success">Exitoso</Button>
                  </div>
                </AccordionSummary>

                <AccordionDetails>
                  <div className="flex-column-end pb2 fs12">
                    <div className="flex w42 ml2 border-bottom-2 mb1">
                      <div className="w60  bold fs16 ">Concepto</div>
                      <div className="w40  bold fs16 ">Total</div>
                    </div>

                    <div className="flex w42 ml2 border-bottom-2 mb1 line-height-0">
                      <p className="w60">Valor del producto</p>
                      <p className="w40">$ 54,000.00</p>
                    </div>

                    <div className="flex w42 ml2 border-bottom-2 mb1 line-height-0">
                      <p className="w60">Abono</p>
                      <p className="w40">$ 8,100.00</p>
                    </div>

                    <div className="flex w42 ml2 border-bottom-2 mb1 line-height-0">
                      <p className="w60">Cargo por servicios</p>
                      <p className="w40">$ 248.00</p>
                    </div>

                    {/* <div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>Interes</p>
										<p className='w40'>$ 1,728.00</p>
									</div> */}

                    {/* <div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>IVA</p>
										<p className='w40'>$ 13,608.00</p>
									</div> */}

                    <div className="flex w42 ml2 border-bottom-2 mb1 line-height-0">
                      <p className="w60">Total</p>
                      <p className="w40">$ 8,353.00</p>
                    </div>

                    <div className="flex w42 ml2 border-bottom-2 mb1 line-height-0">
                      <p className="w60">Saldo pendiente</p>
                      <p className="w40">$ 45,900.00</p>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
        <div className="box-proceso-producto-list" hidden={_Ocultar2}>
          <Alert severity="info">Aún no cuentas con abonos.</Alert>
        </div>
        {/* <div className='box2-detalle-empeño'>
					<div className='flex w95 ml2'>
						<div className='w25 text-center bold fs16 '>Mensualidad</div>
						<div className='w25 text-center bold fs16 '>Vencimiento</div>
						<div className='w25 text-center bold fs16 '>Estado</div>
						<div className='w25 text-center bold fs16 '>Monto</div>
					</div>
					<div>
						<Accordion
							expanded={expanded === 'panel1'}
							onChange={handleChange('panel1')}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel1bh-content'
								id='panel1bh-header'
							>
								<div className='w25 text-center fs16 '>1</div>
								<div className='w25 text-center fs16 '>10 / oct / 2022</div>
								<div className='w25 text-center fs16 '>
									<Button className='warning'>POR VENCER</Button>
									
								</div>
								<div className='w25 text-center fs16 '>$ 10,800.00</div>
							</AccordionSummary>

							<AccordionDetails>
								<div className='flex-column-end pb2 fs12'>
									<div className='flex w42 ml2 border-bottom-2 mb1'>
										<div className='w60  bold fs16 '>Concepto</div>
										<div className='w40  bold fs16 '>Total</div>
									</div>

									<div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>Capital</p>
										<p className='w40'>$ 10,800.00</p>
									</div>

									<div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>Interés</p>
										<p className='w40'>$ 1,080.00</p>
									</div>

									<div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>Refrendo</p>
										<p className='w40'>$ 0.00</p>
									</div>

									<div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>IVA</p>
										<p className='w40'>$ 1,728.00</p>
									</div>

									<div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>Pagaste</p>
										<p className='w40'>$ 13,608.00</p>
									</div>
								</div>
							</AccordionDetails>
						</Accordion>
					</div>
				</div> */}
      </div>
    </div>
  )
}

export default ClientesConsignaView

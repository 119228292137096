/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import requests from '../AxiosCalls/AxiosCall'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'

////-::::::::::::::::::::::::::::::::::::::
import { useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, TextField } from '@mui/material'
import '../../Styles/Styles.css'
import {
  CLIENTES_REGISTRER,
  TIPO_DE_SERVICIO,
  TIPO_DE_PIEZA,
} from '../../Constants/ApiConstants'
import Slide from '@mui/material/Slide'
import imgSingupbarter from '../../Images/Home/Registrer.jpeg'
import LBL from '../../Images/logo-barter-sin-fondo.png'

import LoginMb2 from '../../Components/Login/LoginMb2'

import {
  gtmOpenLoginUNL,
  gtmCloseLoginUNL,
  gtmCloseRegisterUNL,
  gtmSendRegisterUNL,
} from '../../Constants/GoogleTagManager/gtmConstants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const RegisterMb = (props) => {
  const methods = useForm()
  const { handleSubmit } = methods
  const [_ListTipoServicio, setListTipoServicio] = useState([])
  const [_ListTipoPieza, setListTipoPieza] = useState([])
  const [_Cliente, setCliente] = useState({
    txtNombre: '',
    txtAParterno: '',
    txtCelular: '',
    txtEmail: '',
    fecAlta: '',
    fecBaja: '',
    txtPassword: '',
    txtPasswordConfirma: '',
    txtCodigoConfirma: '',
    intIdTipoServicioPreReg: 2,
    intIdTipoPiezaPreReg: '',
  })

  const LimpiarValores = () => {
    setCliente({
      ..._Cliente,
      txtNombre: '',
      txtAParterno: '',
      txtCelular: '',
      txtEmail: '',
      fecAlta: '',
      fecBaja: '',
      txtPassword: '',
      txtPasswordConfirma: '',
      txtCodigoConfirma: '',
      intIdTipoServicioPreReg: '',
      intIdTipoPiezaPreReg: '',
    })
  }

  const handleCliente = (event) => {
    setCliente({ ..._Cliente, [event.target.name]: event.target.value })
  }

  const handleClose = () => {
    gtmCloseRegisterUNL()
    props.onClose()
    LimpiarValores()
  }

  const guardar = () => {
    console.log(props)
    debugger
    props.props.setOpenLoadingScreen()
    requests
      .post(CLIENTES_REGISTRER, _Cliente)
      .then((response) => {
        gtmSendRegisterUNL()
        props.props.setTypeSnackBar('success')
        props.props.setMessageAlert(
          'Gracias por registrarte a FoundIt Members. Entra a tu correo y confirma tu mail para continuar.'
        )
        props.props.setOpenAlert(true)
        props.props.setCloseLoadingScreen()
        handleClose()
      })
      .catch((error) => {
        props.props.setTypeSnackBar('warning')
        props.props.setMessageAlertWarning(error.data.mensaje)
        props.props.setOpenAlertWarning(true)
        props.props.setCloseLoadingScreen()
      })
  }

  const [_Ocultar, setOcultar] = useState(true)
  const [_MensajeError, setMensajeError] = useState('')
  const validatePasswords = () => {
    let valido = / /
    setMensajeError('')
    if (_Cliente.txtPassword === _Cliente.txtPasswordConfirma) {
      if (_Cliente.txtPassword.match(/[A-Z]/)) {
        if (_Cliente.txtPassword.match(/\d/)) {
          if (!valido.test(_Cliente.txtPassword)) {
            if (_Cliente.txtPassword.length <= 15) {
            } else {
              setOcultar(false)
              setMensajeError(
                'La contraseña no debe ser mayor de 15 caracteres.'
              )
            }
          } else {
            setOcultar(false)
            setMensajeError('La contraseña no debe tener espacios en blanco.')
          }
        } else {
          setOcultar(false)
          setMensajeError('La contraseña debe tener al menos un número.')
        }
      } else {
        setOcultar(false)
        setMensajeError(
          'La contraseña debe tener al menos una letra mayuscula.'
        )
      }
    } else {
      setOcultar(false)
      setMensajeError('Las contraseñas no coiciden.')
    }
  }

  //============== OPEN LOGIN =========================
  const [openLogin, setOpenLogin] = useState(false)
  const handleOpenlogin = () => {
    gtmOpenLoginUNL()
    setOpenLogin(!openLogin)
  }
  const handleCloseLogin = () => {
    gtmCloseLoginUNL()
    setOpenLogin(false)
  }

  //============== Navigate To LOGIN =========================
  const handleNavigateLogin = () => {
    gtmOpenLoginUNL()
    handleClose()
    handleOpenlogin()
  }

  const getTipoServicio = () => {
    requests
      .get(TIPO_DE_SERVICIO)
      .then((response) => {
        setListTipoServicio(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getTipoPieza = () => {
    requests
      .get(TIPO_DE_PIEZA)
      .then((response) => {
        setListTipoPieza(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const Piezas = (event) => {
    setCliente({ ..._Cliente, [event.target.name]: event.target.value })
  }
  const Servicio = (event) => {
    setCliente({ ..._Cliente, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    getTipoServicio()
    getTipoPieza()
  }, [])

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose ? props.onClose : null}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(10px)',
          },
        }}
      >
        <div className="ppp">
          <div className="modal-SingUpMb">
            {window.screen.width <= 600 ? (
              <div className="flex h100 bgImageRegisterMobile">
                <div className="bodyLoginMobile">
                  <div className="flex-end">
                    <Button onClick={handleClose}>
                      <CloseIcon
                        fontSize="small"
                        className="btn-close-icon-LoginMobile font-dark"
                      />
                    </Button>
                  </div>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: ' 0px 20px',
                    }}
                  >
                    <div className="flex-column w100">
                      <img className="w40" src={LBL} />
                      <div className="flex-column-start mb1 w100">
                        <span className="h2">¡Hola!</span>
                        <span className="p co-grey">
                          Regístrate y accede FoundIt Members
                        </span>
                      </div>
                    </div>

                    <Box
                      component="form"
                      onSubmit={handleSubmit(guardar)}
                      sx={{ mt: 3 }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="txtNombre"
                            required
                            fullWidth
                            id="txtNombre"
                            label="Nombre"
                            color="warning"
                            size="small"
                            value={_Cliente.txtNombre}
                            onChange={handleCliente}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            id="txtAParterno"
                            label="Apellido"
                            name="txtAParterno"
                            color="warning"
                            size="small"
                            value={_Cliente.txtAParterno}
                            onChange={handleCliente}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="txtCelular"
                            label="Celular"
                            name="txtCelular"
                            color="warning"
                            //type='email'
                            size="small"
                            value={_Cliente.txtCelular}
                            onChange={handleCliente}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="txtEmail"
                            label="Correo electrónico"
                            name="txtEmail"
                            color="warning"
                            type="email"
                            size="small"
                            value={_Cliente.txtEmail}
                            onChange={handleCliente}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            name="txtPassword"
                            label="Contraseña"
                            type="password"
                            id="txtPassword"
                            color="warning"
                            size="small"
                            value={_Cliente.txtPassword}
                            onBlur={validatePasswords}
                            onChange={handleCliente}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            name="txtPasswordConfirma"
                            label="Confirmar contraseña"
                            type="password"
                            id="txtPasswordConfirma"
                            color="warning"
                            size="small"
                            value={_Cliente.txtPasswordConfirma}
                            onBlur={validatePasswords}
                            onChange={handleCliente}
                          />
                          <FormHelperText
                            id="component-error-text"
                            sx={{ color: 'red' }}
                            hidden={_Ocultar}
                          >
                            {_MensajeError}
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            size="small"
                            fullWidth
                            color="warning"
                            required
                          >
                            <InputLabel id="intIdTipoServicioPreReg">
                              ¿Qué te gustaría hacer en FoundIt Members?
                            </InputLabel>
                            <Select
                              labelId="intIdTipoServicioPreReg"
                              id="intIdTipoServicioPreReg"
                              name="intIdTipoServicioPreReg"
                              value={_Cliente.intIdTipoServicioPreReg}
                              onChange={Servicio}
                              label="¿Qué te gustaría hacer en FoundIt Members?"
                              inputProps={{
                                name: 'intIdTipoServicioPreReg',
                                id: 'intIdTipoServicioPreReg',
                              }}
                            >
                              <MenuItem value="" />
                              {_ListTipoServicio.map((object, index) => (
                                <MenuItem
                                  value={object.intIdTipoServicioPreReg}
                                  key={index}
                                >
                                  {' '}
                                  {object.txtTipoServicio}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            size="small"
                            fullWidth
                            color="warning"
                            required
                          >
                            <InputLabel id="intIdTipoPiezaPreReg">
                              ¿Qué piezas premium o luxury te gustaría Vender?
                            </InputLabel>
                            <Select
                              labelId="intIdTipoPiezaPreReg"
                              id="intIdTipoPiezaPreReg"
                              name="intIdTipoPiezaPreReg"
                              value={_Cliente.intIdTipoPiezaPreReg}
                              onChange={Piezas}
                              label="¿Qué piezas premium o luxury te gustaría Vender?"
                              inputProps={{
                                name: 'intIdTipoPiezaPreReg',
                                id: 'intIdTipoPiezaPreReg',
                              }}
                            >
                              <MenuItem value="" />
                              {_ListTipoPieza.map((object, index) => (
                                <MenuItem
                                  value={object.intIdTipoPiezaPreReg}
                                  key={index}
                                >
                                  {' '}
                                  {object.txtTipoPieza}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        className="btnLogin"
                      >
                        Regístrate
                      </Button>
                    </Box>

                    <span
                      className="p pointer mb-20"
                      onClick={handleNavigateLogin}
                    >
                      ¿Ya tienes Cuenta? Inicia sesión
                    </span>
                  </Box>
                </div>
              </div>
            ) : (
              <div className="flex h100">
                <div className="w50">
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                    }}
                    src={imgSingupbarter}
                  />
                </div>
                <div className="w50">
                  <div className="flex-end mt1">
                    <Button onClick={handleClose}>
                      <CloseIcon
                        fontSize="small"
                        className="btn-close-icon-modal font-dark"
                      />
                    </Button>
                  </div>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: ' 0px 20px',
                    }}
                  >
                    <div className="flex-column w100">
                      <img className="w30" src={LBL} />
                      <div className="flex-column-start mb1 w100">
                        <span className="h2">¡Hola!</span>
                        <span className="p co-grey">
                          Regístrate y accede FoundIt Members
                        </span>
                      </div>
                    </div>

                    <Box
                      component="form"
                      onSubmit={handleSubmit(guardar)}
                      sx={{ mt: 3 }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="txtNombre"
                            required
                            fullWidth
                            id="txtNombre"
                            label="Nombre"
                            color="warning"
                            size="small"
                            value={_Cliente.txtNombre}
                            onChange={handleCliente}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            id="txtAParterno"
                            label="Apellido"
                            name="txtAParterno"
                            color="warning"
                            size="small"
                            value={_Cliente.txtAParterno}
                            onChange={handleCliente}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            id="txtCelular"
                            label="WhatsApp"
                            name="txtCelular"
                            color="warning"
                            //type='email'
                            size="small"
                            value={_Cliente.txtCelular}
                            onChange={handleCliente}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            id="txtEmail"
                            label="Correo electrónico"
                            name="txtEmail"
                            color="warning"
                            type="email"
                            size="small"
                            value={_Cliente.txtEmail}
                            onChange={handleCliente}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            name="txtPassword"
                            label="Contraseña"
                            type="password"
                            id="txtPassword"
                            color="warning"
                            size="small"
                            value={_Cliente.txtPassword}
                            onBlur={validatePasswords}
                            onChange={handleCliente}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            name="txtPasswordConfirma"
                            label="Confirmar contraseña"
                            type="password"
                            id="txtPasswordConfirma"
                            color="warning"
                            size="small"
                            value={_Cliente.txtPasswordConfirma}
                            onBlur={validatePasswords}
                            onChange={handleCliente}
                          />
                          <FormHelperText
                            id="component-error-text"
                            sx={{ color: 'red' }}
                            hidden={_Ocultar}
                          >
                            {_MensajeError}
                          </FormHelperText>
                        </Grid>
                        {/* <Grid item xs={12} sm={12}>
													<FormControl
														size='small'
														fullWidth
														color='warning'
														required
													>
														<InputLabel id='intIdTipoServicioPreReg'>
															¿Qué te gustaría hacer en Mundo Barter?
														</InputLabel>
														<Select
															labelId='intIdTipoServicioPreReg'
															id='intIdTipoServicioPreReg'
															name='intIdTipoServicioPreReg'
															value={_Cliente.intIdTipoServicioPreReg}
															onChange={Servicio}
															label='¿Qué te gustaría hacer en Mundo Barter?'
															inputProps={{
																name: 'intIdTipoServicioPreReg',
																id: 'intIdTipoServicioPreReg',
															}}
														>
															<MenuItem value='' />
															{_ListTipoServicio.map((object, index) => (
																<MenuItem
																	value={object.intIdTipoServicioPreReg}
																	key={index}
																>
																	{' '}
																	{object.txtTipoServicio}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid> */}
                        <Grid item xs={12} sm={12}>
                          <FormControl
                            size="small"
                            fullWidth
                            color="warning"
                            required
                          >
                            <InputLabel id="intIdTipoPiezaPreReg">
                              ¿Qué piezas premium o luxury te gustaría Vender?
                            </InputLabel>
                            <Select
                              labelId="intIdTipoPiezaPreReg"
                              id="intIdTipoPiezaPreReg"
                              name="intIdTipoPiezaPreReg"
                              value={_Cliente.intIdTipoPiezaPreReg}
                              onChange={Piezas}
                              label="¿Qué piezas premium o luxury te gustaría Vender?"
                              inputProps={{
                                name: 'intIdTipoPiezaPreReg',
                                id: 'intIdTipoPiezaPreReg',
                              }}
                            >
                              <MenuItem value="" />
                              {_ListTipoPieza.map((object, index) => (
                                <MenuItem
                                  value={object.intIdTipoPiezaPreReg}
                                  key={index}
                                >
                                  {' '}
                                  {object.txtTipoPieza}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        className="btnLogin"
                      >
                        Regístrate
                      </Button>
                    </Box>

                    <span
                      className="p pointer mb1"
                      onClick={handleNavigateLogin}
                    >
                      ¿Ya tienes Cuenta? inicia sesión
                    </span>
                  </Box>
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
      <LoginMb2 open={openLogin} onClose={handleCloseLogin} props={props} />
    </div>
  )
}

export default RegisterMb

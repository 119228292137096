import {
    PAY_CREATE,
    PAY_SUCCESS,
    PAY_FAIL,
    REFRENDO_CREATE,
    REFRENDO_SUCCESS,
    REFRENDO_FAIL
} from '../../Constants/MundoBarterConstants'

const dataInicial = {
        monto: 0,
		saldo: 0,
		refrendo: 0,
		fee: 0,
		totalPagar: 0,
		saldoPendiente: 0
}

const refrendoInicial = {
    saldoActual: 0,
    refrendo: 0,
    fee: 0,
    totalPagar: 0
}

export const abonoCreate = (state = {pay: {dataInicial}}, action) => {
    switch(action.type){
        case PAY_CREATE:
            return {...state, loading: false}
        case PAY_SUCCESS:
            return {loading: false, pay: action.payload}
        case PAY_FAIL:
            return {loading: false, pay: action.payload}
        default:
            return state
    }
}

export const refrendoCreate = (state = {refrendoInicial: {refrendoInicial}}, action) =>{
    switch(action.type){
        case REFRENDO_CREATE:
            return {...state, loading: false}
        case REFRENDO_SUCCESS:
            return {loading: false, pay: action.payload}
        case REFRENDO_FAIL:
            return {loading: false, pay: action.payload}
        default:
            return state
    }
}
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"

import Grid from "@mui/material/Grid"
import {
  Card,
  CardActions,
  CardContent,
  Button,
  CardMedia,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { CLIENTES_SALES } from "../../../Constants/routesConstants"
import {
  CONSIGNA_LIST_BY_ID_CLIENTE,
  MB_CONSIGNA_ACT_DES_MODO_VACACIONES,
} from "../../../Constants/ApiConstants"
import InfoIcon from "@mui/icons-material/Info"
import HelpIcon from "@mui/icons-material/Help"
import "../../../Styles/App/Apartado/Apartado.css"
import { Chip, Alert, Fab } from "@mui/material"
import requests from "../../../Components/AxiosCalls/AxiosCall"
import CategoryIcon from "@mui/icons-material/Category"
import ConsignasHelp from "./ConsignasHelp"
import IconButton from "@mui/material/IconButton"
import PreviewImg from "../../../Components/PreviewImg"
import NumberFormat from "react-number-format"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import AlertInfo from "../../../Util/Alerts/AlertInfo"
import AlertConfirmAction from "../../../Util/Alerts/AlertConfirmAction"
import TextField from "@mui/material/TextField"
import ModalConsignaDiscount from "../../../Components/Modals/ModalConsignaDiscount"
import Avatar from "@mui/material/Avatar"
import iconConsigna from "../../../Images/MB-Consigna/Consigna_Icon.png"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import { gtm_OpenDescuento_Log } from "../../../Constants/GoogleTagManager/gtmConstants"
import { TablePagination } from "@mui/material"
import * as locales from "@mui/material/locale"
import { createTheme, useTheme } from "@mui/material/styles"
import { ThemeProvider } from "@mui/material/styles"

const ConsignaProView = (props) => {
  const [_Consignas, setConsignas] = useState([])
  const [_IdProducto, setProducto] = useState(null)
  const navigate = useNavigate()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const [_Ocultar, setOcultar] = useState(true)
  const [_Ocultar2, setOcultar2] = useState(true)
  const [_IdMbConsigna, setIdMbConsigna] = useState(null)
  const [_EstatusMbConsigna, setEstatusMbConsigna] = useState(null)

  const getConsignas = () => {
    props.setOpenLoadingScreen()
    requests
      .get(CONSIGNA_LIST_BY_ID_CLIENTE, userInfo.intIdUsuario)
      .then((response) => {
        console.log(response)
        setConsignas(response)
        if (response.length > 0) {
          setOcultar(false)
          setOcultar2(true)
        } else {
          setOcultar(true)
          setOcultar2(false)
        }
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log("Error: " + error)
        props.setCloseLoadingScreen()
      })
  }
  useEffect(() => {
    getConsignas()
  }, [])

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }
  //abrir modal img
  const [IdImgPreview, setIdImgPreview] = useState()
  const [openModalImgPreview, setModalImgPreview] = useState(false)
  const handleOpenImgPreview = () => {
    setModalImgPreview(!openModalImgPreview)
  }

  //abrir modal discount
  const [openModalConsignaDiscount, setModalConsignaDiscount] = useState(false)
  const handleOpenModalConsignaDiscount = () => {
    gtm_OpenDescuento_Log()
    setModalConsignaDiscount(!openModalConsignaDiscount)
  }

  /*State Alertas */
  const [alertInfo, setAlertInfo] = useState(false)
  const [titleAlertInfo, setTitleAlertInfo] = useState("")
  const [bodyAlertInfo, setBodyAlertInfo] = useState("")
  const handleCloseAlertInfo = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setAlertInfo(false)
  }

  const [alertInfo2, setAlertInfo2] = useState(false)
  const [titleAlertInfo2, setTitleAlertInfo2] = useState("")
  const [bodyAlertInfo2, setBodyAlertInfo2] = useState("")
  const handleCloseAlertInfo2 = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setAlertInfo2(false)
  }

  /*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION INICIO	::::::::::::::::::::::::::::::::::::::::::::::: */
  const [locale, setLocale] = React.useState("esES")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const theme = useTheme()
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  )
  /*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION FIN	::::::::::::::::::::::::::::::::::::::::::::::: */
  return (
    <div>
      <div className="containerViewUser py4 paddingTablets" hidden={_Ocultar}>
        {/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
        <div className="flex-start w90 pb2 pl1">
          <div className="chip-breadcrumbs">
            <span className="letter-breadcrumbs">V</span>
          </div>

          <div role="presentation" className="ml2">
            <Breadcrumbs aria-label="breadcrumb" separator="›">
              <Link
                underline="hover"
                className="breadcrumb-url"
                onClick={() => {
                  navigate(-1)
                }}
              >
                Ventas
              </Link>
              <Link underline="hover" className="breadcrumb-url bold">
                Mis Artículos En Venta
              </Link>
            </Breadcrumbs>
          </div>
        </div>

        {_Consignas.length === 0 ? null : (
          <div className="box-consigna-pro mt3">
            {_Consignas
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <div className="box-list-consigna-pro" key={row.lngIdProducto}>
                  <div className="w20 relative">
                    <img className="img-consigna-pro" src={row.txtUrl} />
                    <div className="box-view-absolute">
                      <IconButton
                        className="btn-blur"
                        onClick={function () {
                          setIdImgPreview(row.txtUrl)
                          handleOpenImgPreview()
                        }}
                      >
                        <RemoveRedEyeIcon
                          className="RemoveEyeIconConsigna"
                          sx={{ m: 0, p: 0, color: "#000000" }}
                        />
                      </IconButton>
                    </div>
                  </div>
                  <div className="flex-column-start-between w80 pl1">
                    <div className="w100">
                      <div className="flex-between">
                        <span className="titleConsignaPropuestas upperCase ProductTitleMarca">
                          {row.intIdMarca} - {row.intIdMaterial}
                          <span className="mx1"> </span>
                          <span className="p titleConsignaPropuestas ProductTitleMarca"></span>
                        </span>

                        {window.screen.width <= 600 ? null : (
                          <div>
                            <span className="p textFechaConsignaPropuestas co-grey ">
                              {row.fecIniPublicacion}
                            </span>
                            <TrendingFlatIcon sx={{ fontSize: 20, mx: 1 }} />
                            <span className="p textFechaConsignaPropuestas co-grey">
                              {row.fecVenPublicacion}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="flex-column-start mt1">
                        {window.screen.width <= 600 ? (
                          <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                            Fecha: {row.fecIniPublicacion}{" "}
                            <span className="bold">-</span>{" "}
                            {row.fecVenPublicacion}
                          </span>
                        ) : null}
                        <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                          SKU: {row.txtSkuReg}
                        </span>

                        <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                          Color: {row.intIdColor}
                        </span>
                        <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                          Estatus: {row.estatusConsigna}
                        </span>
                        <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                          Descuento aplicado: {row.dblDescuentoConsignaAplicado}{" "}
                          %
                        </span>
                        <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                          Total a pagar: &nbsp;
                          <NumberFormat
                            value={row.dblTotalaPagar}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={"$ "}
                            suffix={" DÓLARES"}
                          />
                        </span>
                      </div>

                      <div className="flex-between">
                        <div className="flex-start">
                          <span
                            className="textFechaConsignaPropuestas co-red-MB bold"
                            onClick={function () {
                              setProducto(row.lngIdProducto)
                              handleOpenModalConsignaDiscount()
                            }}
                          >
                            Otorgar Descuento
                          </span>
                          <IconButton
                            aria-label="Info"
                            onClick={() => {
                              setAlertInfo(!alertInfo)
                              setTitleAlertInfo("Otorgar Descuento: ")
                              setBodyAlertInfo(
                                "Te permite agregar un descuento a tu producto, de esta manera insentivas la compra. "
                              )
                            }}
                          >
                            <InfoOutlinedIcon
                              className="RemoveEyeIconConsigna"
                              sx={{ color: "#C58989" }}
                            />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xl={12} className="flex">
                <ThemeProvider theme={themeWithLocale}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={_Consignas.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <div hidden={_Ocultar2}>
        <div className="containerViewUser">
          <Alert
            className="alert-no-data"
            icon={
              <InfoIcon fontSize="inherit" className="alert-icon-no-data" />
            }
          >
            Aún no cuentas con artículos en venta.
          </Alert>
        </div>
      </div>

      <PreviewImg
        IdImgPreview={IdImgPreview}
        openModalImgPreview={openModalImgPreview}
        onCloseModalImgPreview={handleOpenImgPreview}
      />

      <AlertInfo
        openAlert={alertInfo}
        MensajeTitle={titleAlertInfo}
        MensajeBody={bodyAlertInfo}
        handleCloseAlert={handleCloseAlertInfo}
      />

      <AlertConfirmAction
        openAlert={alertInfo2}
        MensajeTitle={titleAlertInfo2}
        MensajeBody={bodyAlertInfo2}
        handleCloseAlert={handleCloseAlertInfo2}
        IdMbConsigna={_IdMbConsigna}
        EstatusMbConsigna={_EstatusMbConsigna}
        getConsignas={getConsignas}
        props={props}
      />

      {_IdProducto !== null ? (
        <ModalConsignaDiscount
          openModalConsignaDiscount={openModalConsignaDiscount}
          onCloseModalConsignaDiscount={handleOpenModalConsignaDiscount}
          idProducto={_IdProducto}
          getConsignas={getConsignas}
          props={props}
        />
      ) : null}
    </div>
  )
}

export default ConsignaProView

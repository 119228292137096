/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import logo from '../../../Images/logo.png'
import NumberFormat from 'react-number-format'
import { gtm_CloseTerminosEmp_Log } from '../../../Constants/GoogleTagManager/gtmConstants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const EmpeñosTerminosCondiciones = (props) => {
  const handleClose = () => {
    gtm_CloseTerminosEmp_Log()
    props.onCloseModalImgPreview()
  }
  return (
    <div>
      <Dialog
        open={props.openModalImgPreview}
        onClose={
          props.onCloseModalImgPreview ? props.onCloseModalImgPreview : null
        }
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ color: '#583101' }} id="alert-dialog-title">
          {/* {'Terminos y Condiciones'} */}
          <img
            src="https://dl.dropbox.com/s/bkv6oejilxt6pyc/logo_blak%402x%20%282%29%20%281%29.png?dl=0"
            className="imgTermConditionsConsigna"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-justify"
          >
            A continuación se encuentra la cotización por tu articulo Pre loved
            con los detalles mas importantes para el servicio de EMPEÑO:
          </DialogContentText>

          <DialogContentText>
            <p className="title-Terms-Propuestas text-center toUppercase">
              {props.nameProduct.articulo} {props.nameProduct.marca}
            </p>
          </DialogContentText>

          <DialogContentText
            id="alert-dialog-description"
            className="text-justify mt-10 f-weight-bold font-20"
          >
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li className="subTitle-Terms-Propuestas">
                - Interés mensual: 20%{' '}
                <NumberFormat
                  value={(props.nameProduct.precioVenta * 20) / 100}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={'$ '}
                  suffix={' DÓLARES'}
                />
              </li>
              <li className="subTitle-Terms-Propuestas">
                - Plazo de un mes con derecho a un solo refrendo.
              </li>
              <li className="subTitle-Terms-Propuestas">
                - Pago por desempeño
                <NumberFormat
                  value={props.nameProduct.precioVenta}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={'$ '}
                  suffix={' DÓLARES'}
                />
              </li>
            </ul>
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            className="text-justify mt-10 co-red-open-table"
          >
            &#8226; Es importante saber que solo cuentas con 1 refrendo y al
            segundo mes tendrás que liquidar el empeño + el interés del segundo
            mes.
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            className="text-justify mt-10 "
          >
            &#8226; Recuerda que el presente es un Pre-avalúo y por lo tanto
            Barterluxe podrá declinar la oferta en caso de que el artículo Pre
            loved no sea original, no coincida con la información enviada o
            presente condiciones distintas a las declaradas en tu cuestionario,
            si este fuera el caso dicho artículo será devuelto, teniendo un
            costo de $100 dólares derivado de las pruebas realizadas al mismo.
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            className="text-justify mt-10 "
          >
            &#8226; La certificación de tu artículo tiene un costo de $26 + IVA.
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            className="text-justify mt-10 "
          >
            &#8226; Si estas de acuerdo con la cotización y condiciones te
            pedimos hagas una cita desde tu perfil en FoundIt Members para
            verificar tu artículo en nuestras oficinas, recuerda que debes
            presentarlo limpio y en las mismas condiciones que se encuentra en
            las fotografías que mandaste y debes presentarte con identificación
            oficial y datos de cuenta bancaria donde será depositado tu pago por
            empeño.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Aceptar</Button> */}
          <Button
            variant="outlined"
            sx={{
              color: '#583101',
              borderColor: '#583101',
              ':hover': { borderColor: '#583101' },
            }}
            onClick={handleClose}
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
				fullScreen
				open={props.openModalImgPreview}
				onClose={
					props.onCloseModalImgPreview ? props.onCloseModalImgPreview : null
				}
				TransitionComponent={Transition}
				PaperProps={{
					style: {
						background: 'rgba(255, 255, 255, 0.2)',
						backdropFilter: 'blur(10px)',
					},
				}}
				classN
			>
				<div className='relative modal-view-event'>
					<div className='box-modal-absolute'>
						<IconButton onClick={handleClose} className='btn-blur-close'>
							<CloseIcon sx={{ fontSize: 35 }} />
						</IconButton>
					</div>

					<div>asdasd</div>
				</div>
			</Dialog> */}
    </div>
  )
}

export default EmpeñosTerminosCondiciones

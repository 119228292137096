/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"

import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { useNavigate, useParams } from "react-router-dom"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import {
  EMPEÑO_BY_ID,
  EMPEÑO_PAGO_DETALLES,
  PORCENTAJE_LIST,
  PORCENTAJE_PORID,
  PRODUCTOS_DETAILS_BY_SKU,
  PRODUCTOS_DETAILS_BY_SKUP,
  GET_LIST_PLAZO_APA,
} from "../../../../Constants/ApiConstants"

// import AmountPayLiq from '../Liquidar/PaymentComponentLiq/AmountPayLiq'
// import PayLiq from '../Liquidar/PaymentComponentLiq/PayLiq'
// import SuccessfullLiq from '../Liquidar/PaymentComponentLiq/SuccessfullLiq'
import requests from "../../../AxiosCalls/AxiosCall"
import "../../../../Styles/PayCodi.css"
import NumberFormat from "react-number-format"
import AmountPayPrimerPago from "./PaymentComponentPrimerPago/AmountPayPrimerPago"
import CardPayPrimerPago from "./PaymentComponentPrimerPago/CardPayPrimerPago"
import PayPrimerPago from "./PaymentComponentPrimerPago/PayPrimerPago"
import { styled } from "@mui/styles"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { useSelector } from "react-redux"
import { TRASFER_PRIMER_PAGO } from "../../../../Constants/ApiConstants"
import { CLIENTES_SECTION_PRO } from "../../../../Constants/routesConstants"
import Checkbox from "@mui/material/Checkbox"
import "../../../../Styles/App/Apartado/ApartadoDetail.css"

const Input = styled("input")({
  display: "none",
})
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #CBB878",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}))
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#CBB878",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#CBB878",
  },
})
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  )
}
const PaymentFlowPrimerPago = (props) => {
  const { id } = useParams()

  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set())
  let navigate = useNavigate()
  const sku = JSON.parse(sessionStorage.getItem("sku"))
  const [_Producto, setProducto] = useState([])
  const [_OcultarFlujo, setOcultarFlujo] = useState(false)
  const [_checkEnvio, setcheckEnvio] = useState(false)

  const [_Comprobante, setComprobante] = useState(null)
  const [_Btn, setBtn] = useState(true)

  const isStepOptional = (step) => {
    return step === 1
  }

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }
  const [_DataTransfer, setDataTransfer] = useState({
    LngIdProducto: "",
    LngIdCliente: "",
    IntIdPorcentaje: "",
    DblMontoPago: "",
    TxtUrlComprobante: "",
    IntIdPlazoApa: "",
  })
  const getRegistro = () => {
    props.setOpenLoadingScreen()
    requests
      .get(PRODUCTOS_DETAILS_BY_SKUP, sku)
      .then((response) => {
        console.log(response)
        setProducto(response)
        let oper = (response.dblPrecioFinal * 30) / 100
        setPrecioFin(oper)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const [_Porcent, setPorcent] = useState([])
  const getPercent = () => {
    props.setOpenLoadingScreen()
    requests
      .get(PORCENTAJE_LIST)
      .then((response) => {
        setPorcent(response)
        sessionStorage.setItem("idPorc", JSON.stringify(1))

        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const handlePercent = (event) => {
    sessionStorage.setItem("idPorc", JSON.stringify(event.target.value))
    requests
      .get(PORCENTAJE_PORID + event.target.value)
      .then((response) => {
        props.setCloseLoadingScreen()
        sessionStorage.setItem("idPorc", JSON.stringify(event.target.value))
        let oper = (_Producto.dblPrecioFinal * response.dblPorApartado) / 100

        setPrecioFin(oper)
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const [precioFin, setPrecioFin] = useState()

  useEffect(() => {
    getRegistro()
    getPercent()
    getPlazos()
  }, [])

  //::::::::::::::::::::: Titulos de cada paso ::::::::::::::::::::::::::
  const steps = ["Monto a pagar", "Pagar", "Resumen de pago"]
  //::::::::::::::::::::: Vistas de cada paso ::::::::::::::::::::::::::
  function getSteps(step, handleNext, handleBack) {
    switch (step) {
      case 0:
        return (
          <AmountPayPrimerPago
            handleNext={handleNext}
            handleBack={handleBack}
            data={_Producto}
            precioFin={precioFin}
            props={props}
            btn={_Btn}
          />

          // <AmountPayLiq
          // 	handleNext={handleNext}
          // 	handleBack={handleBack}
          // 	data={_Empeño}
          // 	detalles={_Detalles}
          // />
        )
      case 1:
        return (
          <PayPrimerPago
            handleNext={handleNext}
            handleBack={handleBack}
            data={_Producto}
            precioFin={precioFin}
            props={props}
            plazo={_DataTransfer.IntIdPlazoApa}
            btn={_Btn}
          />

          // <PayLiq
          // 	handleNext={handleNext}
          // 	handleBack={handleBack}
          // 	data={_Empeño}
          // 	detalles={_Detalles}
          // />
        )
      case 2:
        return (
          <div>hola</div>
          // <SuccessfullLiq
          // 	handleNext={handleNext}
          // 	handleBack={handleBack}
          // 	data={_Empeño}
          // 	detalles={_Detalles}
          // />
        )
      default:
        throw new Error("Unknown step")
    }
  }

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const handleComprobante = (e) => {
    setComprobante(e.target.files[0])
  }
  const adjuntarComprobante = () => {
    var dataIdPor = JSON.parse(sessionStorage.getItem("idPorc"))

    if (_Comprobante !== "" && _Comprobante !== null) {
      const formData = new FormData()
      formData.append("LngIdProducto", id === null ? "" : id)
      formData.append(
        "LngIdCliente",
        userInfo.intIdUsuario === null ? "" : userInfo.intIdUsuario
      )
      formData.append("IntIdPorcentaje", dataIdPor === null ? "" : dataIdPor)
      formData.append("DblMontoPago", precioFin === null ? "" : precioFin)
      formData.append(
        "IntIdPlazoApa",
        _DataTransfer.IntIdPlazoApa === null ? "" : _DataTransfer.IntIdPlazoApa
      )

      formData.append(
        "TxtUrlComprobante",
        _Comprobante === null ? "" : _Comprobante
      )

      props.setOpenLoadingScreen()
      requests
        .post(TRASFER_PRIMER_PAGO, formData)
        .then((response) => {
          props.setTypeSnackBar("success")
          props.setMessageSnackBar(response.mensaje)
          props.setOpenSnackBar(true)
          navigate(CLIENTES_SECTION_PRO)

          props.setCloseLoadingScreen()
          // props.setCloseLoadingScreen()
          // setIdGuia(null)
          // getPropuestas()
        })
        .catch((error) => {
          props.setTypeSnackBar("warning")
          props.setMessageSnackBar(error.data.mensaje)
          props.setOpenSnackBar(true)
          props.setCloseLoadingScreen()
        })
    } else {
      props.setTypeSnackBar("warning")
      props.setMessageSnackBar("Debes adjuntar el comprobante de pago")
      props.setOpenSnackBar(true)
      props.setCloseLoadingScreen()
    }
  }
  const [_Plazos, setPlazos] = useState([])
  const getPlazos = () => {
    props.setOpenLoadingScreen()
    requests
      .get(GET_LIST_PLAZO_APA)
      .then((response) => {
        console.log(response)
        setPlazos(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }
  const handlePlazos = (event) => {
    setDataTransfer({ ..._DataTransfer, IntIdPlazoApa: event.target.value })
    if (event.target.value !== null && event.target.value !== "") {
      setBtn(false)
    }
  }
  const handlechecked = (event) => {
    if (event.target.checked === true) {
      setOcultarFlujo(true)
      setcheckEnvio(event.target.checked)
    } else {
      setOcultarFlujo(false)
      setcheckEnvio(event.target.checked)
    }
  }

  //::::::::::::::::::::: Vistas de cada paso ::::::::::::::::::::::::::
  return (
    <div>
      <div className="CardApartadoDetailPagos">
        <div className="BodyCardApartadoDetail">
          <div className="ApartadoDetailSection1">
            <div
              className="BoxImgADetail"
              style={{
                backgroundImage: `url(${_Producto.txtUrl})`,
              }}
            ></div>
          </div>
          <div className="ApartadoDetailSection2">
            {/* ***************** */}

            <div className="containerFlexBetween">
              <span className="bold upperCase">
                <span className="subtitlesdetail">
                  Precio Total del Artículo:{" "}
                </span>{" "}
                <NumberFormat
                  value={_Producto.dblPrecioFinal}
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$ "}
                  suffix={" DÓLARES"}
                />
              </span>
              <span className="bold upperCase">
                <div className="containerSelectDetailPago">
                  <span className="subtitlesdetail">Abónalo con: </span>{" "}
                  <FormControl variant="standard" size="small">
                    <Select
                      required
                      value={_Porcent.intIdMbPorcentajeApa}
                      onChange={handlePercent}
                      label="intIdMbPorcentajeApa"
                      inputProps={{
                        name: "intIdMbPorcentajeApa",
                        id: "intIdMbPorcentajeApa",
                      }}
                      size="small"
                      defaultValue={1}
                    >
                      {_Porcent.map((object, index) => (
                        <MenuItem value={object.intIdMbPorcentajeApa}>
                          {object.dblPorApartado}%
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </span>
            </div>
            <div>
              <span className="bold upperCase">
                <div className="containerSelectDetailPago">
                  <span className="subtitlesdetail">
                    Elige el plazo para pagar:{" "}
                  </span>{" "}
                  <FormControl variant="standard" size="small" className="w20">
                    <Select
                      required
                      value={_DataTransfer.IntIdPlazoApa}
                      onChange={handlePlazos}
                      label="IntIdPlazoApa"
                      inputProps={{
                        name: "IntIdPlazoApa",
                        id: "IntIdPlazoApa",
                      }}
                      size="small"
                      fullWidth
                      //defaultValue={1}
                    >
                      {_Plazos.map((object, index) => (
                        <MenuItem value={object.intIdPlazoPagoApa}>
                          {object.txtPlazo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </span>
            </div>
            {/* ***************** */}
            <div className="containerFlexBetween">
              <span className="bold upperCase">
                <span className="subtitlesdetail">Producto:</span>{" "}
                {_Producto.txtProductoH === null
                  ? _Producto.txtProductoM
                  : _Producto.txtProductoH}{" "}
                {_Producto.intIdMarca}
              </span>
              <span className="bold upperCase">
                <span className="subtitlesdetail">SKU:</span>{" "}
                {_Producto.txtSkuReg}{" "}
              </span>
            </div>
            {/* ***************** */}
            <div className="containerFlexBetween">
              <span className="bold upperCase">
                <span className="subtitlesdetail">Marca:</span>{" "}
                {_Producto.intIdMarca}
              </span>
              <span className="bold upperCase">
                <span className="subtitlesdetail">Color:</span>{" "}
                {_Producto.intIdColor}
              </span>
              <span className="bold upperCase">
                <span className="subtitlesdetail">Material:</span>{" "}
                {_Producto.intIdMaterial}
              </span>
            </div>
            {/* ***************** */}
            <div className="containerFlexBetween">
              {_Producto.txtAlturaPlataformaZa === null ? (
                " "
              ) : (
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Altura / Plataforma:</span>{" "}
                  {_Producto.txtAlturaPlataformaZa}
                </span>
              )}
              {_Producto.txtTallaZa === null ? (
                " "
              ) : (
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Talla:</span>{" "}
                  {_Producto.txtTallaZa}
                </span>
              )}
              {_Producto.txtAnchoBol === null ? (
                " "
              ) : (
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Ancho:</span>{" "}
                  {_Producto.txtAnchoBol}
                </span>
              )}
              {_Producto.txtLargoBol === null ? (
                " "
              ) : (
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Largo:</span>{" "}
                  {_Producto.txtLargoBol}
                </span>
              )}
              {_Producto.txtProfundidadBol === null ? (
                " "
              ) : (
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Profundidad:</span>{" "}
                  {_Producto.txtProfundidadBol}
                </span>
              )}
              {_Producto.txtAnchoCin === null ? (
                " "
              ) : (
                <strong>
                  Ancho: <p className="d-contents">{_Producto.txtAnchoCin}</p>{" "}
                </strong>
              )}
              {_Producto.txtLargoCin === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Largo: <p className="d-contents">{_Producto.txtLargoCin}</p>{" "}
                </strong>
              )}
              {_Producto.txtAnchoCo === null ? (
                " "
              ) : (
                <strong>
                  Ancho: <p className="d-contents">{_Producto.txtAnchoCo}</p>{" "}
                </strong>
              )}
              {_Producto.txtLargoCo === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Largo: <p className="d-contents">{_Producto.txtLargoCo}</p>{" "}
                </strong>
              )}
              {_Producto.txtFormaGa === null ? (
                " "
              ) : (
                <strong>
                  Forma: <p className="d-contents">{_Producto.txtFormaGa}</p>{" "}
                </strong>
              )}
              {_Producto.txtAnchoGa === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Ancho: <p className="d-contents">{_Producto.txtAnchoGa}</p>{" "}
                </strong>
              )}
              {_Producto.txtAltoGa === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Alto: <p className="d-contents">{_Producto.txtAltoGa}</p>{" "}
                </strong>
              )}
              {_Producto.txtVarillaGa === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Varilla:{" "}
                  <p className="d-contents">{_Producto.txtVarillaGa}</p>{" "}
                </strong>
              )}
              {_Producto.txtPuenteGa === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Puente: <p className="d-contents">{_Producto.txtPuenteGa}</p>{" "}
                </strong>
              )}
              {_Producto.txtAnchoMa === null ? (
                " "
              ) : (
                <strong>
                  Ancho: <p className="d-contents">{_Producto.txtAnchoMa}</p>{" "}
                </strong>
              )}
              {_Producto.txtLargoMa === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Largo: <p className="d-contents">{_Producto.txtLargoMa}</p>{" "}
                </strong>
              )}
              {_Producto.bolKilatajeJo === false ? (
                " "
              ) : (
                <strong>
                  Kilataje: <p className="d-contents">Si</p>{" "}
                </strong>
              )}
              {_Producto.txtMedidaJo === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Medida Joyeria:{" "}
                  <p className="d-contents">{_Producto.txtMedidaJo}</p>{" "}
                </strong>
              )}
              {_Producto.bolPiedrasJo === false ? (
                " "
              ) : (
                <strong className="pl1">
                  Piedras: <p className="d-contents">Si</p>{" "}
                </strong>
              )}
              {_Producto.txtSerieJo === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Serie: <p className="d-contents">{_Producto.txtSerieJo}</p>{" "}
                </strong>
              )}
              {_Producto.txtLeyJo === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Ley: <p className="d-contents">{_Producto.txtLeyJo}</p>{" "}
                </strong>
              )}
              {_Producto.bolKilatajeRe === false ? (
                " "
              ) : (
                <strong>
                  Kilataje: <p className="d-contents">Si</p>{" "}
                </strong>
              )}
              {_Producto.txtMaterialRe === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Material:{" "}
                  <p className="d-contents">{_Producto.txtMaterialRe}</p>{" "}
                </strong>
              )}
              {_Producto.txtModeloPulseraRe === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Ley:{" "}
                  <p className="d-contents">{_Producto.txtModeloPulseraRe}</p>{" "}
                </strong>
              )}
              {_Producto.txtTipoMaquinariaRe === null ? (
                " "
              ) : (
                <strong className="pl1">
                  Tipo Maquinaria:{" "}
                  <p className="d-contents">{_Producto.txtTipoMaquinariaRe}</p>{" "}
                </strong>
              )}
            </div>

            {/* ***************** */}
            <div className="containerFlexBetween">
              {/* <strong>Precio: </strong> */}
              <span className="bold upperCase textPrecio">
                <NumberFormat
                  value={precioFin}
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$ "}
                  suffix={" DÓLARES"}
                />
              </span>
            </div>
            <hr className="Dvividerhr" />
            <div className="container-Checkbox-ApatadoPagos">
              <BpCheckbox checked={_checkEnvio} onChange={handlechecked} />
              <span className="bold ">
                ¿Deseas realizar el pago mediante una transeferencia bancaria?
              </span>
            </div>
          </div>
        </div>
      </div>
      {_OcultarFlujo === false ? (
        <>
          {precioFin === undefined ? (
            " "
          ) : (
            <div className="CardApartadoDetailPagosContainerStepper">
              <Stepper
                activeStep={activeStep}
                sx={{
                  "& .Mui-active": {
                    "&.MuiStepIcon-root": {
                      fill: "#cbb878",
                    },
                    "& .MuiStepConnector-line": {
                      borderColor: "#cbb878",
                    },
                  },

                  "& .Mui-completed": {
                    "&.MuiStepIcon-root": {
                      color: "#cbb878",
                    },
                    "& .MuiStepConnector-line": {
                      borderColor: "#cbb878",
                    },
                  },
                }}
              >
                {steps.map((label, index) => {
                  const stepProps = {}
                  const labelProps = {}

                  if (isStepSkipped(index)) {
                    stepProps.completed = false
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <div className="box-paycodi-stepper">
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Ver historia de pago</Button>
                  </Box>
                </div>
              ) : (
                <>{getSteps(activeStep, handleNext, handleBack, navigate)}</>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="CardApartadoDetailPagosContainerStepper">
          <div className="CardApartadoDetailPagosCardStepper">
            <div className="BodyCardApartadoDetail">
              <div className="ApartadoDetailSection2">
                <span className="bold text-center">
                  Adjunta el comprobante de la transeferencia realizada, te
                  enviaremos un correo con la confirmación del pago.
                </span>
                <hr className="Dvividerhr" />
                {/* ***************** */}
                <div className="containerFlexBetween">
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Banco:</span> BANESCO
                  </span>
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">No. de Cuenta:</span>{" "}
                    221021877887
                  </span>
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Tipo de cuenta:</span>{" "}
                    Corriente
                  </span>
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Beneficiario:</span>{" "}
                    Articulos de lujo
                  </span>
                </div>

                <Box>
                  <label htmlFor="txtUrlComprobanteGuia">
                    <Input
                      id="txtUrlComprobanteGuia"
                      type="file"
                      onChange={handleComprobante}
                    />
                    <Button
                      startIcon={<CloudUploadIcon />}
                      variant="contained"
                      className="btn-fail mr1"
                      component="span"
                    >
                      Adjuntar comprobante pago
                    </Button>
                  </label>
                  <Typography variant="caption" gutterBottom component="div">
                    {_Comprobante !== null ? _Comprobante.name : "sin archivo"}
                  </Typography>
                </Box>

                <Button
                  className="btn-pay"
                  onClick={function () {
                    adjuntarComprobante()
                  }}
                  disabled={_Btn}
                >
                  Envíar
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>

    // <div className='containerViewUser'>
    // 	<div className='w70 pt5 pb2'>
    // 		<div>
    // 			<div className='box-proceso-producto-pay'>
    // 				<div className='w20 p2'>
    // 					<img className='img-proceso-producto' src={_Producto.txtUrl} />
    // 				</div>
    // 				<div className='flex-column-start w80 line-height-0'>
    // 					<div>
    // 						<div>
    // 							<p className='title-proceso-producto'>
    // 								{_Producto.txtProductoH === null
    // 									? _Producto.txtProductoM
    // 									: _Producto.txtProductoH}{' '}
    // 								- {_Producto.intIdMarca}
    // 							</p>
    // 							<div className='flex-start'>
    // 								<p>
    // 									<strong>Precio Total del Artículo: </strong>
    // 									<NumberFormat
    // 										value={_Producto.dblPrecioFinal}
    // 										displayType={'text'}
    // 										fixedDecimalScale={true}
    // 										decimalScale={2}
    // 										thousandSeparator={true}
    // 										prefix={'$ '}
    // 										suffix={' MXN'}
    // 									/>
    // 								</p>
    // 								&nbsp; &bull; &nbsp;
    // 								<p className='d-contents'>
    // 									<strong>Apártalo con: </strong>
    // 									<FormControl variant='standard' size='small'>
    // 										<Select
    // 											required
    // 											value={_Porcent.intIdMbPorcentajeApa}
    // 											onChange={handlePercent}
    // 											label='intIdMbPorcentajeApa'
    // 											inputProps={{
    // 												name: 'intIdMbPorcentajeApa',
    // 												id: 'intIdMbPorcentajeApa',
    // 											}}
    // 											size='small'
    // 											defaultValue={1}
    // 										>
    // 											{_Porcent.map((object, index) => (
    // 												<MenuItem value={object.intIdMbPorcentajeApa}>
    // 													{object.dblPorApartado}%
    // 												</MenuItem>
    // 											))}
    // 										</Select>
    // 									</FormControl>
    // 								</p>
    // 							</div>
    // 							<div className='flex-start'>
    // 								<p>
    // 									<strong>Pagarías: </strong>

    // 									<NumberFormat
    // 										value={precioFin}
    // 										displayType={'text'}
    // 										fixedDecimalScale={true}
    // 										decimalScale={2}
    // 										thousandSeparator={true}
    // 										prefix={'$ '}
    // 										suffix={' MXN'}
    // 									/>
    // 									{precioFin === undefined ? (
    // 										<p style={{ color: 'red' }}>
    // 											SELECCIONA PARA CONTINUAR
    // 										</p>
    // 									) : (
    // 										''
    // 									)}
    // 								</p>
    // 							</div>
    // 							<div className='flex-start'>
    // 								<p>
    // 									<strong>SKU: </strong> {_Producto.txtSkuReg}
    // 								</p>
    // 							</div>
    // 							<div className='flex-start'>
    // 								<p>
    // 									<strong>Material: </strong> {_Producto.intIdMaterial}
    // 								</p>
    // 							</div>
    // 							<div className='flex-start'>
    // 								<p>
    // 									<strong>Color: </strong> {_Producto.intIdColor}
    // 								</p>
    // 							</div>
    // 							<div className='flex-start'>
    // 								<p>
    // 									{_Producto.txtAlturaPlataformaZa === null ? (
    // 										' '
    // 									) : (
    // 										<strong>
    // 											Altura:{' '}
    // 											<p className='d-contents'>
    // 												{_Producto.txtAlturaPlataformaZa}
    // 											</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtTallaZa === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Talla:{' '}
    // 											<p className='d-contents'>{_Producto.txtTallaZa}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtAnchoBol === null ? (
    // 										' '
    // 									) : (
    // 										<strong>
    // 											Ancho:{' '}
    // 											<p className='d-contents'>{_Producto.txtAnchoBol}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtLargoBol === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Largo:{' '}
    // 											<p className='d-contents'>{_Producto.txtLargoBol}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtProfundidadBol === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Profundidad:{' '}
    // 											<p className='d-contents'>
    // 												{_Producto.txtProfundidadBol}
    // 											</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtAnchoCin === null ? (
    // 										' '
    // 									) : (
    // 										<strong>
    // 											Ancho:{' '}
    // 											<p className='d-contents'>{_Producto.txtAnchoCin}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtLargoCin === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Largo:{' '}
    // 											<p className='d-contents'>{_Producto.txtLargoCin}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtAnchoCo === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Ancho:{' '}
    // 											<p className='d-contents'>{_Producto.txtAnchoCo}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtLargoCo === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Largo:{' '}
    // 											<p className='d-contents'>{_Producto.txtLargoCo}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtFormaGa === null ? (
    // 										' '
    // 									) : (
    // 										<strong>
    // 											Forma:{' '}
    // 											<p className='d-contents'>{_Producto.txtFormaGa}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtAnchoGa === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Ancho:{' '}
    // 											<p className='d-contents'>{_Producto.txtAnchoGa}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtAltoGa === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Alto:{' '}
    // 											<p className='d-contents'>{_Producto.txtAltoGa}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtVarillaGa === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Varilla:{' '}
    // 											<p className='d-contents'>{_Producto.txtVarillaGa}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtPuenteGa === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Puente:{' '}
    // 											<p className='d-contents'>{_Producto.txtPuenteGa}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtAnchoMa === null ? (
    // 										' '
    // 									) : (
    // 										<strong>
    // 											Ancho:{' '}
    // 											<p className='d-contents'>{_Producto.txtAnchoMa}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtLargoMa === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Largo:{' '}
    // 											<p className='d-contents'>{_Producto.txtLargoMa}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.bolKilatajeJo === false ? (
    // 										' '
    // 									) : (
    // 										<strong>
    // 											Kilataje: <p className='d-contents'>Si</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtMedidaJo === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Medida Joyeria:{' '}
    // 											<p className='d-contents'>{_Producto.txtMedidaJo}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.bolPiedrasJo === false ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Piedras: <p className='d-contents'>Si</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtSerieJo === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Serie:{' '}
    // 											<p className='d-contents'>{_Producto.txtSerieJo}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtLeyJo === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Ley:{' '}
    // 											<p className='d-contents'>{_Producto.txtLeyJo}</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.bolKilatajeRe === false ? (
    // 										' '
    // 									) : (
    // 										<strong>
    // 											Kilataje: <p className='d-contents'>Si</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtMaterialRe === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Material:{' '}
    // 											<p className='d-contents'>
    // 												{_Producto.txtMaterialRe}
    // 											</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtModeloPulseraRe === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Ley:{' '}
    // 											<p className='d-contents'>
    // 												{_Producto.txtModeloPulseraRe}
    // 											</p>{' '}
    // 										</strong>
    // 									)}
    // 									{_Producto.txtTipoMaquinariaRe === null ? (
    // 										' '
    // 									) : (
    // 										<strong className='pl1'>
    // 											Tipo Maquinaria:{' '}
    // 											<p className='d-contents'>
    // 												{_Producto.txtTipoMaquinariaRe}
    // 											</p>{' '}
    // 										</strong>
    // 									)}
    // 								</p>
    // 							</div>
    // 							<div>
    // 								<div className='container-Checkbox-Profile'>
    // 									<BpCheckbox
    // 										checked={_checkEnvio}
    // 										onChange={handlechecked}
    // 									/>

    // 									<p>
    // 										¿Deseas realizar el pago mediante una transeferencia
    // 										bancaria?
    // 									</p>
    // 								</div>
    // 							</div>
    // 						</div>
    // 					</div>
    // 				</div>
    // 			</div>
    // 		</div>

    // 		{_OcultarFlujo === false ? (
    // 			<>
    // 				{precioFin === undefined ? (
    // 					' '
    // 				) : (
    // 					<Box sx={{ width: '100%', mt: 3 }}>
    // 						<Stepper activeStep={activeStep}>
    // 							{steps.map((label, index) => {
    // 								const stepProps = {}
    // 								const labelProps = {}

    // 								if (isStepSkipped(index)) {
    // 									stepProps.completed = false
    // 								}
    // 								return (
    // 									<Step key={label} {...stepProps}>
    // 										<StepLabel {...labelProps}>{label}</StepLabel>
    // 									</Step>
    // 								)
    // 							})}
    // 						</Stepper>
    // 						{activeStep === steps.length ? (
    // 							<div className='box-paycodi-stepper'>
    // 								<Typography sx={{ mt: 2, mb: 1 }}>
    // 									All steps completed - you&apos;re finished
    // 								</Typography>
    // 								<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
    // 									<Box sx={{ flex: '1 1 auto' }} />
    // 									<Button onClick={handleReset}>
    // 										Ver historia de pago
    // 									</Button>
    // 								</Box>
    // 							</div>
    // 						) : (
    // 							<div className='box-paycodi-stepper'>
    // 								{getSteps(activeStep, handleNext, handleBack, navigate)}

    // 								{/* <div className='body-stepper'>Step {activeStep + 1}</div>
    // 						<div className='footer-stepper'>
    // 							<Button
    // 								className='btn-stepper'
    // 								disabled={activeStep === 0}
    // 								onClick={handleBack}
    // 							>
    // 								Regresar
    // 							</Button>
    // 							<Button className='btn-stepper' onClick={handleNext}>
    // 								{activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
    // 							</Button>
    // 						</div> */}
    // 							</div>
    // 						)}
    // 					</Box>
    // 				)}
    // 			</>
    // 		) : (
    // 			<div className='box-paycodi-stepper'>
    // 				<p>
    // 					Adjunta el comprobante de la transeferencia realizada, te
    // 					enviaremos un correo con la confirmación del pago.
    // 				</p>
    // 				<p>
    // 					<strong>Banco:</strong> Kuspit
    // 				</p>
    // 				<p>
    // 					<strong>Clabe:</strong> 653180003810159114{' '}
    // 				</p>
    // 				<p>
    // 					<strong>Beneficiario:</strong> Articulos de lujo SA de CV
    // 				</p>
    // 				<Box>
    // 					<label htmlFor='txtUrlComprobanteGuia'>
    // 						<Input
    // 							id='txtUrlComprobanteGuia'
    // 							type='file'
    // 							onChange={handleComprobante}
    // 						/>
    // 						<Button
    // 							startIcon={<CloudUploadIcon />}
    // 							variant='contained'
    // 							className='btn-fail mr1'
    // 							component='span'
    // 						>
    // 							Adjuntar comprobante pago
    // 						</Button>
    // 					</label>
    // 					<Typography variant='caption' gutterBottom component='div'>
    // 						{_Comprobante !== null ? _Comprobante.name : 'sin archivo'}
    // 					</Typography>
    // 					<Button
    // 						className='btn-calendar ml1'
    // 						// startIcon={<CloudUploadIcon />}
    // 						onClick={function () {
    // 							adjuntarComprobante()
    // 							//window.open(row.txtUrlGuia, '_blank')
    // 						}}
    // 					>
    // 						Envíar
    // 					</Button>
    // 				</Box>
    // 			</div>
    // 		)}
    // 	</div>
    // </div>
  )
}
export default PaymentFlowPrimerPago

import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/ventasMb.css'

import Button from '@mui/material/Button'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CUESTIONARIO, CITAS_MB } from '../../../Constants/routesConstants'
import RegisterMb2 from '../../Login/RegisterMb2'

const VSection1Web = (props) => {
  console.log('VSection1Web')
  console.log(props)
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const navigate = useNavigate()

  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    // gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    setOpenSingUp(false)
  }
  return (
    <section className="VentasSection1MB">
      <div className="body">
      <div className="contentSection2MB">
          <p className="titlesection2MB">Vende tus artículos</p>
          <p className="titlesection2MB">preloved con</p>
          <p className="titlesection2MB">seguridad y</p>
          <p className="titlesection2MB">confianza</p>
          <p className="subtitlesection2MB">
            Nuestro servicio exclusivo te garantiza una transacción impecable,
            donde la elegancia y la seguridad se fusionan en armonía.
          </p>
          <div className="bottomLink">
            <Button
              onClick={
                userInfo
                  ? function () {
                      navigate(CUESTIONARIO);
                    }
                  : function () {
                      handleOpenSingUp();
                    }
              }
              className="btnLink"
            >
              Vender ahora
              <img
                src={
                  "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png "
                }
              />
            </Button>
          </div>
        </div>
        <div className="contentSection1MB"></div>
      </div>
      <RegisterMb2
        open={openSingUp}
        onClose={handleCloseSingUp}
        props={props}
      />
    </section>
  )
}

export default VSection1Web

/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import {
	Button,
	Radio,
	RadioGroup,
	FormControl,
	TextField,
	Box,
	FormControlLabel,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import requests from '../AxiosCalls/AxiosCall'
import '../../Styles/Styles.css'

import logo from '../../Images/logo-barter-sin-fondo.png'
import '../../Styles/EncuestasAdd/EncuestasAdd.css'

import { ENCUESTA_SATISFACCION_ADD } from '../../Constants/ApiConstants'
import ModalAgradecimiento from './ModalAgradecimiento'

const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto #c58989',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark'
				? 'rgba(57,75,89,.5)'
				: 'rgba(206,217,224,.5)',
	},
}))

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#c58989',
	backgroundImage:
		'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#c58989de',
	},
})

// Inspired by blueprintjs
function BpRadio(props) {
	return (
		<Radio
			disableRipple
			color='default'
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	)
}

const EncuestasAdd = (props) => {
	const [openModalAgradecimiento, setOpenModalAgradecimiento] = useState(false)
	const [activeIndex, setActiveIndex] = useState(null)
	const methods = useForm()
	const { handleSubmit } = methods
	const valores = useLocation()
	var url = valores.search
	const formula = url.slice(5)
	const [_Encuesta, setEncuesta] = useState({
		numRecomendacion: 0,
		txtDescripcionRecom: '',
		bolInstagram: false,
		bolFacebook: false,
		bolTiktok: false,
		bolAmigoConocido: false,
		bolOtro: false,
		txtOtro: '',
		fecRegistro: '',
		lngIdCliente: '',
	})
	const handleEncuesta = (event) => {
		setEncuesta({ ..._Encuesta, [event.target.name]: event.target.value })
	}
	const handleOpenModalAgradecimiento = (id) => {
		if (openModalAgradecimiento === false) {
			setOpenModalAgradecimiento(!openModalAgradecimiento)
		} else {
			setOpenModalAgradecimiento(!openModalAgradecimiento)
		}
	}

	const OptionsTest = [
		{
			value: 0,
			valuetxt: '0',
		},
		{
			value: 1,
			valuetxt: '1',
		},
		{
			value: 2,
			valuetxt: '2',
		},
		{
			value: 3,
			valuetxt: '3',
		},
		{
			value: 4,
			valuetxt: '4',
		},
		{
			value: 5,
			valuetxt: '5',
		},
		{
			value: 6,
			valuetxt: '6',
		},
		{
			value: 7,
			valuetxt: '7',
		},
		{
			value: 8,
			valuetxt: '8',
		},
		{
			value: 9,
			valuetxt: '9',
		},
		{
			value: 10,
			valuetxt: '10',
		},
	]

	const [_BolMedios, setBolMedios] = useState('')

	const HandleBolMedios = (event) => {
		if (event.target.value === '0') {
			setEncuesta({
				..._Encuesta,
				bolInstagram: true,
				bolFacebook: false,
				bolTiktok: false,
				bolAmigoConocido: false,
				bolOtro: false,
			})
		}
		if (event.target.value === '1') {
			setEncuesta({
				..._Encuesta,
				bolInstagram: false,
				bolFacebook: true,
				bolTiktok: false,
				bolAmigoConocido: false,
				bolOtro: false,
			})
		}
		if (event.target.value === '2') {
			setEncuesta({
				..._Encuesta,
				bolInstagram: false,
				bolFacebook: false,
				bolTiktok: true,
				bolAmigoConocido: false,
				bolOtro: false,
			})
		}
		if (event.target.value === '3') {
			setEncuesta({
				..._Encuesta,
				bolInstagram: false,
				bolFacebook: false,
				bolTiktok: false,
				bolAmigoConocido: true,
				bolOtro: false,
			})
		}
		if (event.target.value === '4') {
			setEncuesta({
				..._Encuesta,
				bolInstagram: false,
				bolFacebook: false,
				bolTiktok: false,
				bolAmigoConocido: false,
				bolOtro: true,
			})
		}

		setBolMedios(event.target.value)
	}

	function Calificacion({ active, onClick, number }) {
		return (
			<div
				className={`BtnProbabilidad ${active ? 'active' : ''}`}
				onClick={onClick}
			>
				{number}
			</div>
		)
	}

	const onClickHandler = (index) => {
		setEncuesta({ ..._Encuesta, numRecomendacion: index })
		setActiveIndex(index)
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		_Encuesta.lngIdCliente = formula
		requests
			.post(ENCUESTA_SATISFACCION_ADD, _Encuesta)
			.then((response) => {
				props.setCloseLoadingScreen()
				handleOpenModalAgradecimiento()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageAlertWarning(error.data.mensaje)
				props.setOpenAlertWarning(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<>
			<div className='ContainerEncuesta'>
				<div className='Container-Content-Encuesta shadow'>
					<Box component='form' onSubmit={handleSubmit(guardar)}>
						<img
							src={logo}
							className='imgBLencuesta'
							style={{ display: 'block', margin: '0 auto', marginTop: 20 }}
						/>

						<p className='section-Title-Encuesta'>
							<span className='boldEncuesta'>1.</span> En una escala de 0 a 10,
							¿qué tan probable es que recomiendes a{' '}
							<span className='boldEncuesta'>BarterLuxe</span> con amigos,
							colegas o familiares?
						</p>

						<div className='EncuestaGridProbabilidad'>
							{OptionsTest.map((product, index) => (
								<Calificacion
									key={index}
									active={activeIndex === index}
									onClick={() => onClickHandler(index)}
									number={product.valuetxt}
								/>
							))}
						</div>
						<div className='EncuestaBetweenProbabilidad'>
							<p className='section-Title-Encuesta'>Nada Probable</p>
							<p className='section-Title-Encuesta'>Extremadamente Probable</p>
						</div>

						<p className='section-Title-Encuesta'>
							<span className='boldEncuesta'>2.</span> Cuéntanos por qué
							elegiste esa calificación.
						</p>
						<TextField
							fullWidth
							id='filled-multiline-static'
							name='txtDescripcionRecom'
							multiline
							rows={5}
							placeholder='Escribe tu comentario...'
							value={_Encuesta.txtDescripcionRecom}
							onChange={handleEncuesta}
							variant='filled'
						/>

						<p className='section-Title-Encuesta'>
							<span className='boldEncuesta'>3.</span> ¿Por qué medio te
							enteraste de nuestros servicios?
						</p>
						<div>
							<FormControl>
								<RadioGroup
									aria-labelledby='demo-radio-buttons-group-label'
									name='radio-buttons-group'
									value={_BolMedios}
									onChange={HandleBolMedios}
								>
									<FormControlLabel
										value='0'
										control={<BpRadio />}
										label='Instagram'
									/>
									<FormControlLabel
										value='1'
										control={<BpRadio />}
										label='Facebook'
									/>
									<FormControlLabel
										value='2'
										control={<BpRadio />}
										label='TikTok'
									/>
									<FormControlLabel
										value='3'
										control={<BpRadio />}
										label='Un amigo o conocido'
									/>
									<div className='flexRadioEncuesta'>
										<FormControlLabel
											value='4'
											control={<BpRadio />}
											label='Otro medio ¿cuál?'
										/>
										<TextField
											id='standard-multiline-static'
											name='txtOtro'
											value={_Encuesta.txtOtro}
											onChange={handleEncuesta}
											multiline
											placeholder='Escribe tu comentario...'
											variant='standard'
										/>
									</div>
								</RadioGroup>
							</FormControl>
						</div>
						<div className='flex mt-50 mb-10'>
							<Button
								type='submit'
								variant='contained'
								color='primary'
								className='back-gradient-7 bord-radius-10  font-letter font-light mt-30 '
								
							>
								Enviar
							</Button>
						</div>
					</Box>
				</div>
			</div>
			<ModalAgradecimiento
				open={openModalAgradecimiento}
				onClose={handleOpenModalAgradecimiento}
			/>
		</>
	)
}

export default EncuestasAdd

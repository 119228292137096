import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/apartadoMB.css'
import Button from '@mui/material/Button'

const ASection1Web = (props) => {
  return (
    <section className="ApartadoSection1MB">
      <div className="body">
        <div className="contentSection1MB">
          <div>
            <p className="titlesection2MB">Abona tu</p>
            <p className="titlesection2MB">Pieza</p>
            <p className="titlesection2MB">Favorita</p>
          </div>
          <p className="subtitlesection2MB">
            Encuentra el artículo Preloved que tanto amas y asegúralo fácilmente
            con nuestro servicio de apartado.
          </p>
          <div className="bottomLink">
            <Button
              onClick={function () {
                window.open(
                  'https://pa.founditpreloved.com/collections/new-arrivals',
                  '_blank'
                )
              }}
              className="btnLink"
            >
              Abona
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png '
                }
              />
            </Button>
          </div>
        </div>
        <div className="contentSection2MB"></div>
      </div>
    </section>
  )
}

export default ASection1Web

/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import '../Styles/GeneralStyles.css'
import '../Styles/Foundit/headerMb.css'
import logo from '../Images/logo-barter-sin-fondo.png'
import Button from '@mui/material/Button'
import AccountMenu from '../Layout/AccountMenu'
import LoginMb from '../Components/Login/LoginMb'
import RegisterMb from '../Components/Login/RegisterMb'
import LocalMallIcon from '@mui/icons-material/LocalMall'
import {
  //Mundo Barter
  MB_CONSIGNA,
  MB_EMPENO,
  MB_APARTADO,
} from '../Constants/routesConstants'
import ModalMundoBarter from './ModalMundoBarter'

//import {  } from '../Constants/GoogleTagManager/UserNoLoggueado/User/SingUp/gtmSingUpConstants'
//import { gtmGoShopyUNL } from '../Constants/GoogleTagManager/UserNoLoggueado/Shopify/gtmShopifyConstants'
import StoreIcon from '@mui/icons-material/Store'
import {
  gtmPageViewHomeUNL,
  gtmMisEmpenosUNL,
  GtmMisVentasUNL,
  gtmOpenMundoBarterUNL,
  gtmCloseMundoBarterUNL,
  gtmGoShopyUNL,
  gtmOpenLoginUNL,
  gtmCloseLoginUNL,
  gtmOpenRegisterUNL,
  gtmCloseRegisterUNL,
} from '../Constants/GoogleTagManager/gtmConstants'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
const HeaderMB = (props) => {
  console.log('header')
  console.log(props)
  let navigate = useNavigate()
  //   ** Toggle Drawer Mennu Mobile
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  //============== ENLACE SHOPIFY =========================
  const goShopify = () => {
    gtmGoShopyUNL()
    window.open('https://pa.founditpreloved.com/', '_blank')
  }

  //============== MODAL ¿QUÉ ES MUNDO BARTER? =========================
  const [openModalMB, setOpenModalMB] = useState(false)
  const handleOpenModalMB = () => {
    gtmOpenMundoBarterUNL()
    setOpenModalMB(!openModalMB)
  }
  const handleCloseModalMB = () => {
    gtmCloseMundoBarterUNL()
    setOpenModalMB(false)
  }
  //============== OPEN LOGIN =========================
  const [openLogin, setOpenLogin] = useState(false)
  const handleOpenlogin = () => {
    gtmOpenLoginUNL()
    setOpenLogin(!openLogin)
  }
  const handleCloseLogin = () => {
    gtmCloseLoginUNL()
    setOpenLogin(false)
  }

  //============== OPEN REGISTRO=========================
  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    gtmCloseRegisterUNL()
    setOpenSingUp(false)
  }

  // ::::::::::::::::::::::::::::::::::::::::::::::: Menu :::::::::::::::::::::::::::::::::::::::::::::::
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openMenu = Boolean(anchorEl)
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  //  *** MENU MOBILE
  const DrawerList = (
    <Box sx={{ width: 300 }} role="presentation" onClick={toggleDrawer(false)}>
      <div className="ContentImgDrawer">
        <img
          onClick={function () {
            gtmPageViewHomeUNL()
            navigate('/')
          }}
          src={
            'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png'
          }
        />
      </div>
      {/* <Divider /> */}
      <List>
        {[
          'Home',
          'Ventas',
          //  "Empeños",
          'Abonos',
          'Tienda en línea',
        ].map((text, index) => (
          <ListItem
            key={text}
            disablePadding
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              padding: '8px 10px',
            }}
          >
            <ListItemButton
              sx={{ width: '100%', display: 'flex' }}
              onClick={function () {
                if (index === 0) {
                  gtmPageViewHomeUNL()
                  navigate('/')
                }
                if (index === 1) {
                  GtmMisVentasUNL()
                  navigate(MB_CONSIGNA)
                }
                // if (index === 2) {
                //   gtmMisEmpenosUNL();
                //   navigate(MB_EMPENO);
                // }
                if (index === 2) {
                  navigate(MB_APARTADO)
                }
                if (index === 3) {
                  goShopify()
                }
              }}
            >
              {/* border-bottom: 1px solid #000; */}
              <ListItemText primary={text} />
              <ListItemIcon sx={{ display: 'flex', justifyContent: 'end' }}>
                <ArrowForwardIosIcon style={{ color: '#000' }} />
              </ListItemIcon>
            </ListItemButton>
            <Divider className="dividerDraw" />
          </ListItem>
        ))}
      </List>
    </Box>
  )
  //   **********
  return (
    <div>
      <div className="containerHeaderMB">
        <div className="section1Mb">
          <img
            onClick={function () {
              gtmPageViewHomeUNL()
              navigate('/')
            }}
            src={
              'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png'
            }
          />
        </div>

        <div className="section2Mb">
          <Button
            onClick={function () {
              GtmMisVentasUNL()
              navigate(MB_CONSIGNA)
            }}
            className="BtnHeaderMb"
          >
            Ventas
          </Button>
          {/* <Button
            onClick={function () {
              gtmMisEmpenosUNL();
              navigate(MB_EMPENO);
            }}
            className="BtnHeaderMb"
          >
            Empeños
          </Button> */}
          <Button
            onClick={function () {
              navigate(MB_APARTADO)
            }}
            className="BtnHeaderMb"
          >
            Abonos
          </Button>
          <Button
            onClick={function () {
              goShopify()
            }}
            className="BtnHeaderMb"
          >
            Tienda en línea
          </Button>

          <Button
            onClick={function () {
              handleOpenlogin()
            }}
            className="BtnHeaderMb2"
          >
            Iniciar sesión
          </Button>
        </div>
      </div>

      <div className="containerHeaderMBMobile">
        <div>
          <IconButton onClick={toggleDrawer(true)} aria-label="delete">
            <MenuIcon style={{ color: '#000' }} />
          </IconButton>
          <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
          </Drawer>
        </div>
        <img
          onClick={function () {
            gtmPageViewHomeUNL()
            navigate('/')
          }}
          src={
            'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png'
          }
        />
        <Button
          onClick={function () {
            handleOpenlogin()
          }}
          className="BtnHeaderMb2"
        >
          <span> Iniciar sesión</span>
        </Button>
      </div>
      <LoginMb open={openLogin} onClose={handleCloseLogin} props={props} />
      <RegisterMb open={openSingUp} onClose={handleCloseSingUp} props={props} />
      <ModalMundoBarter open={openModalMB} onClose={handleCloseModalMB} />
    </div>
  )
}

export default HeaderMB

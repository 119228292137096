/* eslint-disable jsx-a11y/alt-text */
import React from "react"
import { Chip } from "@mui/material"
import PaidIcon from "@mui/icons-material/Paid"
import Button from "@mui/material/Button"
import PaymentIcon from "@mui/icons-material/Payment"
import IconButton from "@mui/material/IconButton"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { useNavigate } from "react-router-dom"
import { FLUJO_CERTIFICADOS } from "../../../Constants/routesConstants"

const PagosCertificados = () => {
  const navigate = useNavigate()

  const pay = () => {
    navigate(FLUJO_CERTIFICADOS)
  }
  return (
    <div className="containerViewUser p2">
      <div className="w100">
        <Chip
          icon={<PaidIcon />}
          // label='Pagos Extraudinarios / Pagos De Certificados'
          label="Pagos De Certificados"
          color="primary"
          className="back-transparent font-letter font-barter font-20"
        />
      </div>

      <div className="box-pagos-Cert">
        <div className="flex ">
          <div className="w20 ">
            <img
              className="w100 br1  "
              src="https://www.entrupy.com/wp-content/uploads/2019/04/Entrupy-Saint-Laurent-authentication-certificate.png"
            />
          </div>

          <div className="flex-column-start-between ml1 w80">
            <div className="flex-column-start w100">
              <div className="flex-between">
                <span className="p">
                  <strong>Saint Laurent</strong> - Leather
                </span>
                <span className="p">
                  <strong>SKU:</strong> CVRTNS01029133
                  <IconButton
                    onClick={() => navigator.clipboard.writeText("9950522024")}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </span>
              </div>

              <span className="p">
                <strong>Material:</strong> Piel
              </span>
              <span className="p">
                <strong>Color:</strong> Negro
              </span>
            </div>

            <span className="p">
              <strong>Total a pagar:</strong>$ 500.00 DÓLARES
            </span>

            <div className="w100 flex-end">
              <Button
                className="btn-pay-full"
                endIcon={<PaymentIcon />}
                onClick={() => pay()}
              >
                Pagar Certificado
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PagosCertificados

import React, { useEffect, useState } from "react";
import "../../../Styles/Foundit/empeñaMB.css";
import Button from "@mui/material/Button";
import { Fade } from "react-awesome-reveal";

const ESection2Web = (props) => {
  return (
    <section className="EmpeñaSection2MB">
      <div className="cardESection2">
        <p>¿Cómo funciona?</p>
        <div className="ContainerInstructions">
        <Fade cascade damping={0.6} triggerOnce >
          <div className="step">
            <span className="number">1</span>
            <span className="text">
              Nuestros expertos evaluarán tu artículo de lujo
            </span>
          </div>
          <div className="step">
            <span className="number">2</span>
            <span className="text">
            Te ofrecerán la mejor tasa de interés del mercado
            </span>
          </div>
          <div className="step">
            <span className="number">3</span>
            <span className="text">
            Tu artículo estará siempre resguardado
            </span>
          </div>
          <div className="step">
            <span className="number">4</span>
            <span className="text">
            Todo se realiza en un entorno privado, seguro y confidencial
            </span>
          </div>
        </Fade>
        </div>
      </div>
    </section>
  );
};

export default ESection2Web;

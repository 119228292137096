import React, { useEffect, useState } from 'react'
import MuiAccordion from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'
import '../../../Styles/Foundit/faqsMB.css'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemText from '@mui/material/ListItemText'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
const Accordionn = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    border: '2px solid #E5E7E0',
    borderRadius: 4,
  },
  '&::before': {
    display: 'none',
  },
  '& .Mui-expanded': {
    backgroundColor: '#757D65',
  },
}))

const AccordionSummaryy = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    color: '#fff',
  },
  '& .MuiTypography-root': {
    // color: "#000",
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    '& .MuiTypography-root': {
      color: '#fff',
    },
  },
}))

const FqSectionVentas = (props) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <section className="FaqsSectionVentas">
      <div className="contentFaqs">
        <span className="titlefqs">Ventas</span>
        <Accordionn
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          className="accordion"
        >
          <AccordionSummaryy
            aria-controls="panel1d-content"
            id="panel1d-header"
            className="acordionbackground"
          >
            <Typography>
              {' '}
              ¿Cómo puedo agendar una cita en su tienda u oficina para vender a
              consigna mis artículos?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Envíanos un Whats al número 507-6359-0318 o un mensaje privado
                en cualquiera de nuestras redes sociales. Te agendaremos la cita
                en la fecha y hora de tu preferencia.
              </p>
              <p>
                Estamos en Casco Antiguo, Calle 3ra, Edificio Casa Cielo, Planta
                Baja.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Cómo puedo vender mis artículos PreLoved?</Typography>
          </AccordionSummaryy>

          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Para poner en venta tus artículos lo puedes hacer mediante el
                proceso de Consigna, este se realiza en nuestra página{' '}
                <a href="www.pa.founditmembers.com" target="_blank">
                  www.pa.founditmembers.com
                </a>
                <br />
                1. Crea tu usuario en la página.
                <br />
                2. Ve al menú y da click en "Vende aquí".
                <br />
                3. Llena el formulario que nos permitirá saber el estado de tu
                pieza y así realizaremos una valuación justa de precio.
                <br />
                4. Nuestro valuador revisará el formulario y te hará llegar una
                propuesta de precio de venta.
                <br />
                5. Si la aceptas, podrás agendar tu cita y traer la pieza a
                nuestra tienda u oficinas para que la podamos resguardar y
                firmes tu contrato de Consigna.
                <br />
                6. Durante 3 meses, haremos toda la labor de venta, tomaremos
                fotos profesionales de tu pieza, la promocionaremos en nuestra
                web y redes sociales hasta lograr venderla.
                <br />
                7. Una vez que se venda recibrás el dinero corespondiente vía
                transferencia bancaria.
                <br />
                Si eres de otra Provincia, no te preocupes, en lugar de traer tu
                pieza a nuestra tienda u oficina, podrás enviarla con nuestra
                paquetería y cuando la recibamos, haremos una video llamada para
                abrir el paquete juntos y firmar de manera digital tu contrato
                de servicio.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel14'}
          onChange={handleChange('panel14')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Cómo agendo mi cita para llevar mis artículos a su tienda u
              oficina?
            </Typography>
          </AccordionSummaryy>

          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Una vez que hayas llenado el formulario de Prevaluación.
                recibirás una propuesta de precio de venta.
              </p>
              <p>
                Si la aceptas podrás agendar tu cita en tu perfil para acudir a
                dejar el artículo en nuestra tienda u oficina.
              </p>
              <p>
                En la visita presencial se realizará una valuación física del
                artículo y se firmará el contrato correspondiente.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Qué hacen con mi artículo consignado?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>Tu artículo se quedará con nosotros durante 3 meses.</p>
              <p>
                Durante ese tiempo, nos encargaremos de hacer toda la labor de
                venta.
              </p>
              <p>
                Tomaremos fotos profesionales, se publicará en nuestro
                MarketPlace, lo mostraremos en nuestros en vivos y en todas las
                redes sociales hasta lograr venderlo.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Dónde resguardan mi artículo durante el tiempo de consigna?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Una vez que hayas dejado tu artículo se realizará el marketing
                para su comercialización y acabando este proceso se resguarda en
                nuestra tienda bajo los más estrictos estándares de seguridad.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Por qué puede cambiar el pre-avalúo que realizaron a mi artículo?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Recuerda que tu artículo fue pre-evaluado por medio de
                fotografías, en la valuación presencial nuestros expertos
                certifican que efectivamente tu artículo cuenta con las
                características mencionadas, pero si hubiera algún desgaste o
                defecto no apreciado este sufrirá un cambio en el importe de
                avalúo.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Cuánto tiempo se queda mi artículo en Consigna?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                El tiempo de estadía es de 3 meses, si al terminarse este plazo
                no hemos logrado vender el artículo, podrás realizar tu cita
                para retirarlo, o bien, puedes dejarlo por 30 días más y aplicar
                un descuento adicional para incentivar la venta.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Existe un límite de artículos a consignar?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                No hay un límite de artículos establecido, lo que significa que
                mientras más consignes más puedes ganar por la venta de tus
                artículos.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel8'}
          onChange={handleChange('panel8')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Puedo retirar mis artículos si aún no se cumplen los 3 meses?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                En caso de que tus artículos ya se hayan publicado y necesites
                retirarlos antes del término del tiempo de consignación, se
                deberá cubrir un costo operativo por artículo de $100 dólares,
                los cuales incluyen, almacenaje, trabajo operativo y marketing
                digital.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel9'}
          onChange={handleChange('panel9')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Qué artículos puedo consignar en Foundit?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Puedes dejar en consigna bolsos, zapatos o accesorios;
                entendiendo por accesorios: carteras, cinturones, aretes,
                pulseras. lentes de sol, etc.
              </p>
              <p>También puedes consignar ropa nueva con etiqueta.</p>
              <p>Se pueden consignar artículos de mujer y de hombre.</p>
              <p>
                Los requisitos que debe cubrir tu artículo son:
                <br />
                1. Autenticidad
                <br />
                2. Buen estado
                <br />
                3. Limpio
              </p>
              <p>Foundit no comercializa cosméticos o perfumería.</p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel10'}
          onChange={handleChange('panel10')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿En dónde aperecerán mis artículos consignados?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Tus artículos en consigna aparecerán anunciados en nuestra
                página web y redes sociales:
              </p>
              <p>
                https://pa.founditpreloved.com/ Instagram:
                https://www.instagram.com/barterluxe_?igsh=NjN2cWdkZnZpd250
                Facebook:
                https://www.facebook.com/people/Foundit/61561594710798/?locale=es_LA
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel11'}
          onChange={handleChange('panel11')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Puedo agregar un descuento a mis artículos en venta?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Sí, desde tu perfil en{' '}
                <a href="www.pa.founditmembers.com" target="_blank">
                  www.pa.founditmembers.com
                </a>
                <br />
                1. Ve al menú y da click en "Mis Ventas".
                <br />
                2. Entra en donde dice "Mis artículos en venta".
                <br />
                3. Ubica el artículo al que le quieres hacer un descuento.
                <br />
                4. Da click en "Otorgar descuento".
                <br />
                5. Elije el % de descuento que quieres otorgar, podrás
                seleccionar desde el 5% hasta el 20%
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel12'}
          onChange={handleChange('panel12')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Cómo puedo saber si ya se vendieron mis artículos?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Podrás visualizar el estatus de los artículos a través de tu
                perfil en nuestra página de
                <a href="www.pa.founditpreloved.com" target="_blank">
                  www.pa.founditpreloved.com
                </a>
                También, se te contactará para notificarte la venta de tu
                artículo y la fecha de tu pago. <br />
                El pago por tu artículo lo recibirás 6 días hábiles después de
                efectuada la venta.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel13'}
          onChange={handleChange('panel13')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Puedo recuperar alguno de mis artículos si ya se ha vendido?
            </Typography>
          </AccordionSummaryy>

          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                ¡Ups! En caso de que el artículo ya haya sido vendido, no
                podremos hacerte la devolución.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <span></span>
      </div>
    </section>
  )
}

export default FqSectionVentas

import React from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import logo from '../../Images/logo.png'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const ModalCargoServicio = (props) => {
	const handleClose = () => {
		props.onCloseModalImgPreview()
	}
	return (
		<div>
			<Dialog
				open={props.openModalImgPreview}
				onClose={
					props.onCloseModalImgPreview ? props.onCloseModalImgPreview : null
				}
				TransitionComponent={Transition}
			>
				<DialogTitle sx={{ color: '#583101' }} id='alert-dialog-title'>
					{'Cargo por servicio'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						id='alert-dialog-description'
						className='text-justify'
					>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
						pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
						culpa qui officia deserunt mollit anim id est laborum.
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					{/* <Button onClick={handleClose}>Aceptar</Button> */}
					<Button
						variant='outlined'
						sx={{
							color: '#583101',
							borderColor: '#583101',
							':hover': { borderColor: '#583101' },
						}}
						onClick={handleClose}
						autoFocus
					>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default ModalCargoServicio

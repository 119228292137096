import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Login from './Components/Login/Login'
import ClientesSingUp from './Components/Clientes/Registro/ClientesSingUp'
import HeaderLayoutCliente from './Layout/HeaderLayoutCliente'
import HeaderMB from './Layout/HeaderMB'
import MainContainerLayout from './Layout/MainContainerLayout'
import FooterMundoBarter from './Layout/FooterMundoBarter'
import Footer from './Components/Footer'
import LoadingScreen from './Util/LoadingScreen'
import LoadingScreenProgressBar from './Util/LoadingScreenProgressBar'
import MySnackBar from './Util/MySnackBar'
import AlertsSuccess from './Util/Alerts/AlertsSuccess'
import AlertWarning from './Util/Alerts/AlertWarning'
import AlertInfo from './Util/Alerts/AlertInfo'
import FloatinfWhatsApp from './Components/FloatingWhatsApp/FloatinfWhatsApp'
import Theme from './Styles/Theme'

import {
  INICIO,
  CLIENTES_SING_UP,
  CUESTIONARIO,
  CANCELACIONES,
  PREEVALUACIONES_DETAILS,
  CANCELACIONES_VIEW,
  CANCELACIONES_DETAILS,
  CLIENTES_SECTION_VIEW,
  CLIENTES_CONSIGNAS,
  CLIENTES_CONSIGNAS_PIEZAS,
  CLIENTES_PROFILE,
  CLIENTES_SECTION_PRO,
  CLIENTES_SECTION_ENT,
  CLIENTES_SECTION_GUI,
  CLIENTES_SECTION_TRA,
  CLIENTES_SECTION_WISH,
  CLIENTES_CONSIGNAS_GUI,
  CLIENTES_CONSIGNAS_TRA,
  CLIENTES_CONSIGNAS_REC,
  CLIENTES_CONSIGNAS_GUIEX,
  CLIENTES_CONSIGNAS_VENTAS,
  CLIENTES_CONSIGNAS_EN_VENTA,
  CLIENTES_CONSIGNAS_RECHAZO,
  CLIENTES_CONSIGNAS_LLEGADOS,
  CLIENTES_EMP_VIEW,
  CLIENTES_EMP,
  CLIENTES_EMP_PRO_VIEW,
  CLIENTES_EMP_PROPUESTAS_VIEW,
  CLIENTES_EMP_REFRENDO_VIEW,
  CLIENTES_EMP_DESEMPEÑO_VIEW,
  CLIENTES_EMP_REFRENDO,
  CLIENTES_EMP_DESEMPEÑO,
  CLIENTES_CON_PROPUESTAS_VIEW,
  FLUJO_PAGO,
  FLUJO_RENTAR,
  CLIENTES_SECTION_PRO_VIEW,
  CLIENTES_SECTION_FIRST_PRO_VIEW,
  PREVALUACIONES_DIN_DETAILS,
  FLUJO_REFRENDO,
  FLUJO_LIQUIDAR,
  FLUJO_APARTAR,
  CITAS_MB,
  CITAS_MB_EMPEÑO,
  CITAS_MB_DESEMPEÑO,
  CITAS_MB_CONSIGNA,
  CITAS_MB_CONSIGNA_FORANEA,
  CITAS_MB_INTERCAMBIO,
  CITAS_MB_RENTA,
  CLIENTES_CONSIGNAS_VIEW,
  CLIENTES_INTERCAMBIO_VIEW,
  CLIENTES_RENT_VIEW,
  CLIENTES_INTERCAMBIO_PROPUESTAS_VIEW,
  CLIENTES_INTERCAMBIO_PROD_VIEW,
  CLIENTES_RENT_PROD_VIEW,
  CLIENTES_RENT_WISH_LIST_PROD_VIEW,
  PAGOS_EXTRAUDINARIOS,
  ENVIO_VIEW,
  ENVIO_DETAIL,
  ENVIOS_EN_TRANSITO,
  ENVIOS_RECIBIDOS,
  CLIENTES_SECTION,
  CLIENTES_RENT,
  PAGOS_EXTRAUDINARIOS_GUIAS,
  PAGOS_EXTRAUDINARIOS_CERTIFICADOS,
  //mundo barter
  MB_CONSIGNA,
  MB_INTERCAMBIA,
  MB_RENTA,
  MB_EMPENO,
  MB_APARTADO,
  CLIENTES_RENT_FIRST_PROD_VIEW,
  FLUJO_GUIAS,
  FLUJO_CERTIFICADOS,
  MB_TERMINOS_CONDICIONES,
  MB_AVISO_PRIVACIDAD,
  MB_FAQS,
  MB_FACTURACION,
  CLIENTES_CONFIRMA_MAIL,
  CALENDAR_VIEW,
  CALENDAR_VIEW_RESCHEDULE,
  CALENDAR_VIEW_MB,
  CALENDAR_VIEW_RMB,
  FLUJO_REFRENDAR,
  CITAS_MB_CON_VENCIDAS,
  CALENDAR_VIEW_CON_VEN_MB,
  CALENDAR_VIEW_CON_VEN_RMB,
  CALENDAR_VIEW_CON_FOR_MB,
  CALENDAR_VIEW_CON_FOR_RMB,
  MB_COOMING_SOON,
  ENCUESTA_SATISFACCION,
  ENVIO_PIEZAS_DHL,
  ENVIO_PIEZAS_OTRA_PAQUETERIA,
  ENVIO_EVALUACION,
} from './Constants/routesConstants'
import HomeMundoBarter from './Components/HomeMundoBarter'
import CuestionarioEmpConAdd from './Components/Cuestionarios/CuestionarioEmpConAdd'
import CuestionarioCancelacion from './Components/Cuestionarios/CancelacionDevoluciones/Cancelacion'
import CancelacionesAdd from './Components/Cancelaciones/CancelacionesAdd'
import MantenimientoView from './Views/Mantenimiento/MantenimientoView'
import CancelacionInfo from './Components/Cuestionarios/CancelacionDevoluciones/CancelacionInfo'
import CancelacionesView from './Views/Cancelaciones/CancelacionesView'
import ProcesoEvaluacion from './Components/Preevaluaciones/ProcesoEvaluacion'
import PreevaluacionDetalles from './Components/Preevaluaciones/PreevaluacionMas2/PreevaluacionDetalles'

import ClientesApartadoView from './Views/Clientes/Apartados/ClientesApartadoView'
import ClientesProfile from './Components/Clientes/ClientesProfile'
import ApartadoEntregado from './Views/Clientes/Apartados/ApartadoEntregado'
import ApartadoDevuelto from './Views/Clientes/Apartados/ApartadoDevuelto'
import ApartadoProceso from './Views/Clientes/Apartados/ApartadoProceso'
import ApartadoTransito from './Views/Clientes/Apartados/ApartadoTransito'
import ApartadoDeseo from './Views/Clientes/Apartados/ApartadoDeseo'
import ApartadoDetalleShopify from './Views/Clientes/Apartados/ApartadoDetalleShopify'

import ClientesConsignaView from './Views/Clientes/Consignas/ClientesConsignaView'
import ClientesConsignaPiezasView from './Views/Clientes/Consignas/ClientesConsignaPiezasView'
import ConsignaTransito from './Views/Clientes/Consignas/ConsignaTransito'
import ConsignaGuia from './Views/Clientes/Consignas/ConsignaGuia'
import ConsignaGuiaExpira from './Views/Clientes/Consignas/ConsignaGuiaExpira'
import ConsignaRecibido from './Views/Clientes/Consignas/ConsignaRecibido'
import ConsignaEnVenta from './Views/Clientes/Consignas/ConsignaEnVenta'
import ConsignaLlegadas from './Views/Clientes/Consignas/ConsignaLlegadas'
import ConsignaRechazadas from './Views/Clientes/Consignas/ConsignaRechazadas'
import ConsignaVendidas from './Views/Clientes/Consignas/ConsignaVendidas'
import ConsignaPropuestas from './Views/Clientes/Consignas/ConsignaPropuestas'

//Empeño MB
import ClienteEmpenoViewMB from './Views/Clientes/Empeños/ClienteEmpenoViewMB'
import EmpeñoProView from './Views/Clientes/Empeños/EmpeñoProView'
import EmpeñoPropuestas from './Views/Clientes/Empeños/EmpeñoPropuestas'
import ClienteEmpeñoView from './Views/Clientes/Empeños/ClienteEmpeñoView'

//Consigna MB
import ConsignaProView from './Views/Clientes/Consignas/ConsignaProView'

//Apartado MB
import ApartadoProcesoProduct from './Views/Clientes/Apartados/ApartadoProcesoProduct'
import ApartadoPrimerProcesoProduct from './Views/Clientes/Apartados/ApartadoPrimerProcesoProduct'
import PaymentFlowPrimerPago from './Components/Pagos/Apartar/PrimerPago/PaymentFlowPrimerPago'

//Intercambio MB
import ClientesIntercambioView from './Views/Clientes/Intercambio/ClientesIntercambioView'
import IntercambioPropuestas from './Views/Clientes/Intercambio/IntercambioPropuestas'
import IntercambiosView from './Views/Clientes/Intercambio/IntercambiosView'

//Rentas MB
import ClientesRentasView from './Views/Clientes/Rentas/ClientesRentasView'
import ClienteRentas from './Views/Clientes/Rentas/ClienteRentas'
import RentaWishList from './Views/Clientes/Rentas/RentaWishList'
import RentaDetalleShopify from './Views/Clientes/Rentas/RentaDetalleShopify'
import RentaPrimerProcesoProduct from './Views/Clientes/Rentas/RentaPrimerProcesoProduct'
import PayPrimerPagoRenta from './Components/Pagos/Rentar/PrimerPago/PaymentComponentPrimerPagoRenta/PayPrimerPagoRenta'

//CITAS MB
import CitasView from './Views/Clientes/Citas/CitasView'
import CitasConsigna from './Views/Clientes/Citas/CitasConsigna'
import CitasEmpeño from './Views/Clientes/Citas/CitasEmpeño'
import CitasIntercambio from './Views/Clientes/Citas/CitasIntercambio'
import CitasRenta from './Views/Clientes/Citas/CitasRenta'

//pago
import PaymentFlow from './Components/Pagos/PaymentFlow'
import Confirmation3Ds from './Components/Pagos/Confirmation3Ds'

//Refrendo
import PaymentFlowRef from './Components/Pagos/Refrendos/PaymentFlowRef'

//Liquidar
import PaymentFlowLiq from './Components/Pagos/Liquidar/PaymentFlowLiq'

//pagos extraudinarios
import PagosExtraudinarios from './Views/PagosExtraudinarios/PagosExtraudinarios'
import PagosCertificados from './Views/PagosExtraudinarios/PagoCertificados/PagosCertificados'
import PagosGuias from './Views/PagosExtraudinarios/PagosGuias/PagosGuias'

//Envios
import EnviosView from './Views/Envios/EnviosView'
import EnviosEnTransito from './Views/Envios/EnviosEnTransito'
import EnviosRecibidos from './Views/Envios/EnviosRecibidos'
import EnviosDetalles from './Views/Envios/EnviosDetalles'
import EnviosConForView from './Views/Envios/ConsignaForanea/EnviosConForView'

//Mundo Barter Routing
import MBConsignaView from './Views/MundoBarterViews/MBConsignaView'
import MBRentaView from './Views/MundoBarterViews/MBRentaView'
import MBIntercambio from './Views/MundoBarterViews/MBIntercambioView'
import MBEmpeñoView from './Views/MundoBarterViews/MBEmpeñoView'
import MBApartadoView from './Views/MundoBarterViews/MBApartadoView'
import PaymentFlowPrimerPagoRenta from './Components/Pagos/Rentar/PrimerPago/PaymentFlowPrimerPagoRenta'
import PaymentFlowGuias from './Components/Pagos/Guias/PaymentFlowGuias'
import PaymentFlowCerticados from './Components/Pagos/Certificados/PaymentFlowCerticados'
import Confirmation3DsCert from './Components/Pagos/Certificados/Confirmation3DsCert'
import EmpeñoRefrendo from './Views/Clientes/Empeños/EmpeñoRefrendo'
import EmpeñoDesempeño from './Views/Clientes/Empeños/EmpeñoDesempeño'
import ClienteEmpeñoRefrendar from './Views/Clientes/Empeños/ClienteEmpeñoRefrendar'
import ClienteEmpeñoDesempeño from './Views/Clientes/Empeños/ClienteEmpeñoDesempeño'
import Confirmation3DsGuide from './Components/Pagos/Guias/Confirmation3DsGuide'
import TermsConditions from './Layout/TermsConditions'
import PrivacyNotice from './Layout/PrivacyNotice'
import Faqs from './Layout/Faqs'

//Calendar
import CalendarScreen from './Components/Calendario/CalendarScreen'
import CalendarReschedule from './Components/Calendario/CalendarReschedule'
import CalendarMb from './Components/Calendario/CanlendarMb'
import CalendarRmb from './Components/Calendario/CalendarRmb'

import ConfirmationMail from './Components/Login/ConfirmationMail'
import ScrollTop from './ScrollTop'
import AlertSuccessLogin from './Util/Alerts/AlertSuccessLogin'
import Confirmation3DsLiq from './Components/Pagos/Confirmation3DsLiq'
import PaymentFlowRefrendar from './Components/Pagos/Refrendar/PaymentFlowRefrendar'
import Confirmation3DsRefre from './Components/Pagos/Confirmation3DsRefre'
import CitasDesempeño from './Views/Clientes/Citas/CitasDesempeño'
import CitasConVencidas from './Views/Clientes/Citas/CitasConVencidas'

import CalendarConVenMb from './Components/Calendario/CalendarConVenMb'
import CalendarConVenRMb from './Components/Calendario/CalendarConVenRMb'
import ComingSoon from './Layout/ComingSoon'

import EncuestasAdd from './Components/Encuestas/EncuestasAdd'
import FormularioPreview from './Layout/FormularioPreview'
import FooterPreview from './Components/FooterPreview'
import HomeMundoBarter2 from './Components/HomeMundoBarter2'

import SolicitarGuiaDhlAdd from './Components/Envios/Solicitudes/SolicitarGuiaDhlAdd'
import OtraPaqueteriaAdd from './Components/Envios/OtraPaqueteria/OtraPaqueteriaAdd'
import FooterMobile from './Components/FooterMobile'
import RoutesLogueado from './Constants/Routes/RooutesLogueado'

import CalendarConFor from './Components/Calendario/CalendarConFor'
import CalendarConForMB from './Components/Calendario/CalendarConForMB'

import CitasConForaneas from './Views/Clientes/Citas/CitasConForaneas'
import FacturacionFoundit from './Layout/FacturacionFoundit'
import FaqsFoundit from './Layout/FaqsFoundit'
function App() {
  //state de las alertas success LOGIN
  const [openAlertSuccessLogin, setOpenAlertSuccessLogin] = useState(false)
  const [messageAlertSuccessLogin, setMessageAlertSuccessLogin] = useState('')
  const handleCloseAlertSuccessLogin = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlertSuccessLogin(false)
  }
  //state de las alertas success
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [messageAlertSuccess, setMessageAlertSuccess] = useState('')
  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlertSuccess(false)
  }

  //state de las alertas warinig
  const [openAlertWarnign, setOpenAlertWarning] = useState(false)
  const [messageAlertWarning, setMessageAlertWarning] = useState('')
  const handleCloseAlertWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlertWarning(false)
  }

  //state de las alertas info
  const [openAlertInfo, setOpenAlertInfo] = useState(false)
  const [messageAlertInfo, setMessageAlertInfo] = useState('')
  const handleCloseAlertInfo = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlertInfo(false)
  }

  const [LSopen, setLSOpen] = React.useState(false)
  const [Msj, setMsj] = React.useState('')

  const [LSPBopen, setLSPBopen] = React.useState(false)
  const [LSPBMsj, setLSPBMsj] = React.useState('')

  const [SBOpen, setSBOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')

  const [type, setType] = React.useState('')
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSBOpen(false)
  }

  /* Estados Loading Escreem */
  const openLoadingScreen = () => {
    setLSOpen(true)
  }
  const closeLoadingScreen = () => {
    setLSOpen(false)
  }

  /* Estados Loading Escreem With Progress Bar*/
  const openLoadingScreenPB = () => {
    setLSPBopen(true)
  }
  const closeLoadingScreenPB = () => {
    setLSPBopen(false)
  }

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  useEffect(() => {
    if (userInfo) {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  }, [userInfo])

  const isAuth = userInfo
  return (
    //<Provider2>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <MySnackBar
            Open={SBOpen}
            Type={type}
            Message={message}
            HandleClose={handleClose}
          />
          <AlertSuccessLogin
            openAlert={openAlertSuccessLogin}
            messageAlert={messageAlertSuccessLogin}
            handleCloseAlert={handleCloseAlertSuccessLogin}
          />

          <AlertsSuccess
            openAlert={openAlertSuccess}
            messageAlert={messageAlertSuccess}
            handleCloseAlert={handleCloseAlertSuccess}
          />

          <AlertWarning
            openAlert={openAlertWarnign}
            messageAlert={messageAlertWarning}
            handleCloseAlert={handleCloseAlertWarning}
          />

          <AlertInfo
            openAlert={openAlertInfo}
            messageAlert={messageAlertInfo}
            handleCloseAlert={handleCloseAlertInfo}
          />

          <LoadingScreen open={LSopen} Msj={Msj} />

          <LoadingScreenProgressBar open={LSPBopen} Msj={LSPBMsj} />

          {!isAuth ? (
            <>
              {/* <FooterMundoBarter> <----*** NO USAR *** */}
              <FloatinfWhatsApp />
              {/* <ScrollTop /> */}

              <ScrollTop />
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <HomeMundoBarter2
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      {/* <HomeMundoBarter
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
												setOpenAlert={setOpenAlertSuccessLogin}
												setMessageAlert={setMessageAlertSuccessLogin}
												setOpenAlertWarning={setOpenAlertWarning}
												setMessageAlertWarning={setMessageAlertWarning}
											/> */}
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />
                <Route
                  exact
                  path={ENCUESTA_SATISFACCION}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <EncuestasAdd
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />
                {/* -------------FORMULARIO PREVIEW------------ */}
                {/* <Route
									exact
									path='/'
									element={
										<div>
											<FormularioPreview
												setMsjLoadingScreen={setMsj}
												setOpenLoadingScreen={openLoadingScreen}
												setCloseLoadingScreen={closeLoadingScreen}
												setOpenSnackBar={setSBOpen}
												setMessageSnackBar={setMessage}
												setTypeSnackBar={setType}
												setOpenAlert={setOpenAlertSuccessLogin}
												setMessageAlert={setMessageAlertSuccessLogin}
												setOpenAlertWarning={setOpenAlertWarning}
												setMessageAlertWarning={setMessageAlertWarning}
											/>
											<FooterPreview />
										</div>
									}
								/> */}
                {/* -------------COMING SOON------------ */}
                {/* <Route exact path='/' element={<ComingSoon />} /> */}
                {/* -------------TERMINOS-CONDICIONES------------ */}
                <Route
                  exact
                  path={MB_TERMINOS_CONDICIONES}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <TermsConditions />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />
                {/* -------------AVISOS DE PRIVACIDAD------------ */}
                <Route
                  exact
                  path={MB_AVISO_PRIVACIDAD}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <PrivacyNotice />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />
                {/* -------------FACTURACION------------ */}
                <Route
                  exact
                  path={MB_FACTURACION}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      {/* <Faqs /> */}
                      <FacturacionFoundit />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />
                {/* -------------FAQS------------ */}
                <Route
                  exact
                  path={MB_FAQS}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      {/* <Faqs /> */}
                      <FaqsFoundit />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />
                {/* ------------------------- */}
                <Route
                  exact
                  path={CLIENTES_CONFIRMA_MAIL}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <ConfirmationMail
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />

                <Route
                  exact
                  path={MB_CONSIGNA}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <MBConsignaView
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />
                <Route
                  exact
                  path={MB_INTERCAMBIA}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <MBIntercambio
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />
                <Route
                  exact
                  path={MB_RENTA}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <MBRentaView
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />
                <Route
                  exact
                  path={MB_EMPENO}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <MBEmpeñoView
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />
                <Route
                  exact
                  path={MB_APARTADO}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <MBApartadoView
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />

                <Route
                  exact
                  path={CLIENTES_SECTION}
                  element={
                    <>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <ApartadoDetalleShopify
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                        hola={'HOLA'}
                      />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </>
                  }
                />
                <Route
                  exact
                  path={CLIENTES_RENT + ':id'}
                  element={
                    <div>
                      <HeaderMB
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                        setOpenAlert={setOpenAlertSuccessLogin}
                        setMessageAlert={setMessageAlertSuccessLogin}
                        setOpenAlertWarning={setOpenAlertWarning}
                        setMessageAlertWarning={setMessageAlertWarning}
                      />
                      <RentaDetalleShopify
                        setMsjLoadingScreen={setMsj}
                        setOpenLoadingScreen={openLoadingScreen}
                        setCloseLoadingScreen={closeLoadingScreen}
                        setOpenSnackBar={setSBOpen}
                        setMessageSnackBar={setMessage}
                        setTypeSnackBar={setType}
                      />
                      {window.screen.width <= 600 ? (
                        <FooterMobile />
                      ) : (
                        <Footer />
                      )}
                    </div>
                  }
                />
                {/*
							
							<Route
								path='/'
								element={
									<Login
										setMsjLoadingScreen={setMsj}
										setOpenLoadingScreen={openLoadingScreen}
										setCloseLoadingScreen={closeLoadingScreen}
										setOpenSnackBar={setSBOpen}
										setMessageSnackBar={setMessage}
										setTypeSnackBar={setType}
									/>
								}
							/> <Route
								path={CLIENTES_SING_UP}
								element={
									<ClientesSingUp
										setMsjLoadingScreen={setMsj}
										setOpenLoadingScreen={openLoadingScreen}
										setCloseLoadingScreen={closeLoadingScreen}
										setOpenSnackBar={setSBOpen}
										setMessageSnackBar={setMessage}
										setTypeSnackBar={setType}
									/>
								}
							/>
							<Route
								exact
								path={CUESTIONARIO}
								element={
									<CuestionarioEmpConAdd
										setMsjLoadingScreen={setMsj}
										setOpenLoadingScreen={openLoadingScreen}
										setCloseLoadingScreen={closeLoadingScreen}
										setOpenSnackBar={setSBOpen}
										setMessageSnackBar={setMessage}
										setTypeSnackBar={setType}
									/>
								}
							/>
							<Route
								exact
								path={CANCELACIONES}
								element={
									<CuestionarioCancelacion
										setMsjLoadingScreen={setMsj}
										setOpenLoadingScreen={openLoadingScreen}
										setCloseLoadingScreen={closeLoadingScreen}
										setOpenSnackBar={setSBOpen}
										setMessageSnackBar={setMessage}
										setTypeSnackBar={setType}
									/>
								}
							/> */}
              </Routes>
            </>
          ) : isAuth ? (
            <>
              <FooterMundoBarter>
                <RoutesLogueado />
              </FooterMundoBarter>
              {/* {window.screen.width <= 600 ? (
								<FooterMundoBarter>
									<RoutesLogueado />
								</FooterMundoBarter>
							) : (
								<FooterMundoBarter>
									<RoutesLogueado />
								</FooterMundoBarter>
							)} */}
            </>
          ) : (
            <MantenimientoView />
          )}
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
    //</Provider2>
  )
}

export default App

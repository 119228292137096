import React from 'react'
import requests from '../AxiosCalls/AxiosCall'
import { CLIENTES_RESET_PASSWORD } from '../../Constants/ApiConstants'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import '../../Styles/Styles.css'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Slide from '@mui/material/Slide'
import CloseIcon from '@mui/icons-material/Close'
import LBL from '../../Images/logo-barter-sin-fondo.png'
import imgbarter from '../../Images/Home/Login.jpeg'

import {
  gtmSendResetPassUNL,
  gtmCloseResetPassUNL,
} from '../../Constants/GoogleTagManager/gtmConstants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const ResetPass = (props) => {
  const handleClose = () => {
    gtmCloseResetPassUNL()
    props.onClose()
  }
  const formik = useFormik({
    initialValues: {
      email: '',
      txtContrasena: '',
      txtContrasenaConfirma: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('El formato del correo no es válido.')
        .required('El correo es requerido.'),
      txtContrasena: Yup.string().required('La contraseña es requerida.'),
      txtContrasenaConfirma: Yup.string().required(
        'La confirmación de contraseña es requerida'
      ),
    }),
    onSubmit: (values) => {
      props.props.props.setOpenLoadingScreen()
      requests
        .put(CLIENTES_RESET_PASSWORD, values)
        .then((response) => {
          gtmSendResetPassUNL()
          props.props.props.setTypeSnackBar('success')
          props.props.props.setMessageAlert(response.mensaje)
          props.props.props.setOpenAlert(true)
          props.props.props.setCloseLoadingScreen()
          handleClose()
        })
        .catch((error) => {
          props.props.props.setTypeSnackBar('warning')
          props.props.props.setMessageAlertWarning(error.data.mensaje)
          props.props.props.setOpenAlertWarning(true)
          props.props.props.setCloseLoadingScreen()
        })
    },
  })

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          background: 'rgba(255, 255, 255, 0.2)',
          backdropFilter: 'blur(10px)',
        },
      }}
    >
      <div className="modal-LoginMB">
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className="h100"
        >
          {window.screen.width <= 600 ? (
            <>
              <div className="h100 bgImageLoginMobile">
                <div className="bodyLoginMobile">
                  <div className="flex-end">
                    <Button onClick={handleClose}>
                      <CloseIcon
                        fontSize="small"
                        className="btn-close-icon-LoginMobile font-dark"
                      />
                    </Button>
                  </div>

                  <div className="flex-column w100">
                    <img className="mt3 w40" src={LBL} />
                    <div className="flex-column-start my3 w90">
                      <span className="h2">Recuperar Contraseña</span>
                      <span>
                        Para recuperar la contraseña de su cuenta en
                        <span className="bold"> FoundIt Members</span>, es
                        necesario que ingrese su dirección de correo electrónico
                        y la nueva contraseña.
                      </span>
                    </div>
                  </div>

                  <div className="center mt2 w90">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Correo electronico"
                      name="email"
                      color="warning"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="txtContrasena"
                      label="Nueva contraseña"
                      type="password"
                      id="txtContrasena"
                      color="warning"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.txtContrasena}
                      error={
                        formik.touched.txtContrasena &&
                        Boolean(formik.errors.txtContrasena)
                      }
                      helperText={
                        formik.touched.txtContrasena &&
                        formik.errors.txtContrasena
                      }
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="txtContrasenaConfirma"
                      label="Confirmar contraseña"
                      type="password"
                      id="txtContrasenaConfirma"
                      color="warning"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.txtContrasenaConfirma}
                      error={
                        formik.touched.txtContrasenaConfirma &&
                        Boolean(formik.errors.txtContrasenaConfirma)
                      }
                      helperText={
                        formik.touched.txtContrasenaConfirma &&
                        formik.errors.txtContrasenaConfirma
                      }
                    />
                  </div>
                  {/* <div className='flex my3'>
											<span
												className='p font-16 co-grey pointer'
												//onClick={handleOpen}
											>
												Recuperar Contraseña
											</span>
											<span className='mx2'> | </span>
											<span
												className='p font-16 co-grey bold pointer'
												//onClick={handleOpen2}
											>
												Crear Cuenta
											</span>									
										</div> */}
                  <div className="center w90">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      className="btnCancel "
                    >
                      Aceptar
                    </Button>
                    <Button
                      //type='submit'
                      fullWidth
                      variant="contained"
                      sx={{ mb: 2 }}
                      className="btnLogin"
                      onClick={function () {
                        handleClose()
                      }}
                    >
                      Cancelar
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex h100">
                <div className="w45">
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                    }}
                    src={imgbarter}
                  />
                </div>

                <div className="w55">
                  <div className="flex-end mt1">
                    <Button onClick={handleClose}>
                      <CloseIcon
                        fontSize="small"
                        className="btn-close-icon-modal font-dark"
                      />
                    </Button>
                  </div>

                  <div className="flex-column w100">
                    <img className="mt3 w40" src={LBL} />
                    <div className="flex-column-start my3 w90">
                      <span className="h2">Recuperar Contraseña</span>
                      <span>
                        Para recuperar la contraseña de su cuenta en
                        <span className="bold"> FoundIt Members</span>, es
                        necesario que ingrese su dirección de correo electrónico
                        y la nueva contraseña.
                      </span>
                    </div>
                  </div>

                  <div className="center mt2 w90">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Correo electronico"
                      name="email"
                      color="warning"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="txtContrasena"
                      label="Nueva contraseña"
                      type="password"
                      id="txtContrasena"
                      color="warning"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.txtContrasena}
                      error={
                        formik.touched.txtContrasena &&
                        Boolean(formik.errors.txtContrasena)
                      }
                      helperText={
                        formik.touched.txtContrasena &&
                        formik.errors.txtContrasena
                      }
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="txtContrasenaConfirma"
                      label="Confirmar contraseña"
                      type="password"
                      id="txtContrasenaConfirma"
                      color="warning"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.txtContrasenaConfirma}
                      error={
                        formik.touched.txtContrasenaConfirma &&
                        Boolean(formik.errors.txtContrasenaConfirma)
                      }
                      helperText={
                        formik.touched.txtContrasenaConfirma &&
                        formik.errors.txtContrasenaConfirma
                      }
                    />
                  </div>

                  <div className="flex center w90">
                    <Button
                      //type='submit'
                      fullWidth
                      variant="contained"
                      sx={{ mb: 2, mt: 1, mr: 1 }}
                      className="btnLogin"
                      onClick={function () {
                        handleClose()
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mb: 2, mt: 1 }}
                      className="btnCancel"
                    >
                      Aceptar
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Box>
      </div>
    </Dialog>
    // <Dialog
    // 	TransitionComponent={Transition}
    // 	open={props.open}
    // 	onClose={props.onClose ? props.onClose : null}
    // >
    // 	<Box component='form' onSubmit={formik.handleSubmit} autoComplete='off'>
    // 		<DialogTitle>
    // 			<span>Recuperar Contraseña</span>
    // 		</DialogTitle>
    // 		<DialogContent>
    // 			<DialogContentText sx={{ textAlign: 'justify' }}>
    // 				<span>
    // 					Para recuperar la contraseña de su cuenta en
    // 					<span className='bold'> Mundo Barter</span>, es necesario que
    // 					ingrese su dirección de correo electrónico y la nueva contraseña.
    // 				</span>
    // 			</DialogContentText>

    // 			<TextField
    // 				margin='normal'
    // 				required
    // 				fullWidth
    // 				id='email'
    // 				label='Correo electronico'
    // 				name='email'
    // 				color='warning'
    // 				onChange={formik.handleChange}
    // 				onBlur={formik.handleBlur}
    // 				value={formik.values.email}
    // 				error={formik.touched.email && Boolean(formik.errors.email)}
    // 				helperText={formik.touched.email && formik.errors.email}
    // 			/>
    // 			<TextField
    // 				margin='normal'
    // 				required
    // 				fullWidth
    // 				name='txtContrasena'
    // 				label='Nueva contraseña'
    // 				type='password'
    // 				id='txtContrasena'
    // 				color='warning'
    // 				onChange={formik.handleChange}
    // 				onBlur={formik.handleBlur}
    // 				value={formik.values.txtContrasena}
    // 				error={
    // 					formik.touched.txtContrasena &&
    // 					Boolean(formik.errors.txtContrasena)
    // 				}
    // 				helperText={
    // 					formik.touched.txtContrasena && formik.errors.txtContrasena
    // 				}
    // 			/>
    // 			<TextField
    // 				margin='normal'
    // 				required
    // 				fullWidth
    // 				name='txtContrasenaConfirma'
    // 				label='Confirmar contraseña'
    // 				type='password'
    // 				id='txtContrasenaConfirma'
    // 				color='warning'
    // 				onChange={formik.handleChange}
    // 				onBlur={formik.handleBlur}
    // 				value={formik.values.txtContrasenaConfirma}
    // 				error={
    // 					formik.touched.txtContrasenaConfirma &&
    // 					Boolean(formik.errors.txtContrasenaConfirma)
    // 				}
    // 				helperText={
    // 					formik.touched.txtContrasenaConfirma &&
    // 					formik.errors.txtContrasenaConfirma
    // 				}
    // 			/>
    // 		</DialogContent>
    // 		<DialogActions>
    // 			<Button onClick={handleClose} className='font-dark font-letter'>
    // 				Cancelar
    // 			</Button>
    // 			<Button type='submit' className='btnLogin'>
    // 				Aceptar
    // 			</Button>
    // 		</DialogActions>
    // 	</Box>
    // </Dialog>
  )
}

export default ResetPass

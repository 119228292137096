import React, { useState } from 'react'
import '../../Styles/StylesMB.css'
import iconEco from '../../Images/MB-Consigna/ecologia-icon.png'
import RegisterMb from '../../Components/Login/RegisterMb'
import ASection1Web from '../../Components/SectionsApartado/Section1/ASection1Web'
import ASection2Web from '../../Components/SectionsApartado/Section2/ASection2Web'
import ASection3Web from '../../Components/SectionsApartado/Section3/ASection3Web'

const MBApartadoView = (props) => {
  //============== OPEN REGISTRO=========================
  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    setOpenSingUp(false)
  }

  return (
    <div className="bg-white-color">
      {/* //? ******************* SECTION 1 ************************* */}
      <ASection1Web props={props} />
      {/* //? ******************* SECTION 2 ************************* */}
      <ASection2Web props={props} />
      {/* //? ******************* SECTION 3 ************************* */}
      <ASection3Web props={props} />

      {/* <RegisterMb open={openSingUp} onClose={handleCloseSingUp} props={props} /> */}
    </div>
  )
}

export default MBApartadoView

import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import '../../Styles/App/User/Profile.css'
import requests from '../../Components/AxiosCalls/AxiosAuth'
import { CONTRATOS_DOCUMENTS_MB } from '../../Constants/ApiConstants'
import CloseIcon from '@mui/icons-material/Close'
import ModalFirmaContrato from '../Clientes/Registro/FirmaContrato'

const AbrirPdf = (props) => {
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1) //setting 1 to show fisrt page
	const [_OpenModalFirma, setOpenModalFirma] = useState(false)

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages)
		setPageNumber(1)
	}

	const handleCloseModalFirma = () => {
		setOpenModalFirma(false)
	}

	const handleOpenModalFirma = () => {
		setOpenModalFirma(true)
	}

	const [_Contratos, setContratos] = useState({
		lngIdDocContrato: '',
		lngIdContrato: '',
		txtUrlDoc: '',
		intIdTipoDocContrato: 0,
		bolFirmado: '',
	})

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(5)
	const [openPdf, setOpenPdf] = useState(false)
	const [Archivo, setArchivo] = useState(null)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleOpenPdf = (id) => {
		if (openPdf === false) {
			setOpenPdf(!openPdf)
		} else {
			setOpenPdf(!openPdf)
		}
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}
	const handleClose = () => {
		props.onClose()
	}

	const columns = [
		{
			id: 'Tipo',
			label: 'Tipo',
			minWidth: window.screen.width <= 600 ? 45 : 80,
		},
		{
			id: 'descargar',
			label: 'Contrato',
			minWidth: window.screen.width <= 600 ? 65 : 100,
		},
	]

	const getDetailContrato = () => {
		requests
			.get('DocContrato/ObtenerPorId/' + props.IdDocContrato)
			.then((response) => {
				setContratos({
					..._Contratos,
					lngIdDocContrato: response === null ? '' : response.lngIdDocContrato,
					lngIdContrato: response === null ? '' : response.lngIdContrato,
					txtUrlDoc: response === null ? '' : response.txtUrlDoc,
					intIdTipoDocContrato:
						response === null ? '' : response.intIdTipoDocContrato,
					bolFirmado: response === null ? '' : response.bolFirmado,
				})
				renderPdf(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const renderPdf = (response) => {
		switch (response.intIdTipoDocContrato) {
			case 1:
				let url =
					requests.Url +
					'Consigna/AnexoAConsignacionIdContrato/' +
					response.lngIdContrato
				fetch(url)
					.then((response) => {
						response.blob().then((blob) => {
							const archivo = window.URL.createObjectURL(blob)
							// window.open(archivo);
							setArchivo(archivo)
						})
					})
					.catch((error) => {
						console.log(error)
					})
				break
			case 2:
				let urls = requests.Url + 'Consigna/AvisoDePrivacidadBarterLuxe'
				fetch(urls)
					.then((response) => {
						response.blob().then((blob) => {
							const archivo = window.URL.createObjectURL(blob)
							setArchivo(archivo)
						})
					})
					.catch((error) => {
						console.log(error)
					})
				break
			case 3:
				let urlsa =
					// 'https://administracionbarterluxeapi.azurewebsites.net/api/v2/Consigna/ConsignacionPorIdContrato/C86CEBAF-13CC-4E95-AFBD-2F651040EC22'
					requests.Url +
					'Consigna/ConsignacionPorIdContrato/' +
					response.lngIdContrato
				fetch(urlsa)
					.then((response) => {
						response.blob().then((blob) => {
							console.log(window.URL.createObjectURL(blob))
							// const archivo = window.URL.createObjectURL(blob)
							setArchivo(window.URL.createObjectURL(blob))
						})
					})
					.catch((error) => {
						console.log(error)
					})
				break
			case 4:
				let urlRec =
					requests.Url +
					'Consigna/RecepcionConsignaPorIdContrato/' +
					response.lngIdContrato
				fetch(urlRec)
					.then((response) => {
						response.blob().then((blob) => {
							const archivo = window.URL.createObjectURL(blob)
							setArchivo(archivo)
						})
					})
					.catch((error) => {
						console.log(error)
					})
				break
			case 6:
				let urlRet =
					requests.Url +
					'Consigna/RetiroDeConsignaPorIdContrato/' +
					response.lngIdContrato
				fetch(urlRet)
					.then((response) => {
						response.blob().then((blob) => {
							const archivo = window.URL.createObjectURL(blob)
							setArchivo(archivo)
						})
					})
					.catch((error) => {
						console.log(error)
					})
				break
			default:
				console.log('Error')
		}
	}

	useEffect(() => {
		getDetailContrato()
	}, [])
	return (
		<>
			<Dialog
				fullScreen
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				// TransitionComponent={Transition}
				PaperProps={{
					style: {
						background:
							window.screen.width <= 600
								? 'rgba(255, 255, 255, 0.2)'
								: 'rgba(255, 255, 255, 0.2)',
					},
				}}
				className={'backdropfilter'}
			>
				<div className='container-modal-pdf '>
					<div className='header-modal-purchase flex-between'>
						<p className='text-TitleDacturasDetail'>Contrato</p>
						<IconButton aria-label='delete' onClick={handleClose}>
							<CloseIcon sx={{ color: 'black' }} />
						</IconButton>
					</div>
					<div className='container-body-modal' style={{ height: '100%' }}>
						{Archivo !== null ? (
							<>
								<object
									data={Archivo}
									type='application/pdf'
									width='100%'
									height='85%'
								>
									<embed src={Archivo} type='application/pdf' />
								</object>
								{/* <iframe
									src={Archivo}
									type='application/pdf'
									height='630px'
									width='100%'
								
								/> */}
								<Button
									className='btn-table-Contratos'
									onClick={function () {
										handleOpenModalFirma()
									}}
								>
									Firmar Contrato
								</Button>
							</>
						) : (
							<p>Cargando archivo...</p>
						)}
					</div>
				</div>
			</Dialog>
			<ModalFirmaContrato
				open={_OpenModalFirma}
				onClose={handleCloseModalFirma}
				Registros={_Contratos}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
		</>
	)
}

export default AbrirPdf

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Button from "@mui/material/Button"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Alert from "@mui/material/Alert"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import {
  FLUJO_PAGO,
  FLUJO_REFRENDAR,
  FLUJO_LIQUIDAR,
} from "../../../Constants/routesConstants"
import { useDispatch, useSelector } from "react-redux"
import {
  postRefrendpCreate,
  postLiquidarEmpCreate,
} from "../../../Actions/MundoBarterActions"

import {
  EMPEÑO_BY_ID,
  EMPEÑO_PAGO_LIST_BY_ID_EMPEÑO,
} from "../../../Constants/ApiConstants"

import requests from "../../../Components/AxiosCalls/AxiosCall"
import "../../../Styles/App/Empeño/EmpeñoView.css"
import NumberFormat from "react-number-format"

const ClienteEmpeñoRefrendar = (props) => {
  const { id } = useParams()
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const [_Empeño, setEmpeño] = useState([])
  const [_Abonos, setAbonos] = useState([])

  const [expanded, setExpanded] = useState(false)

  let date = new Date()
  let day = `${date.getDate()}`.padStart(2, "0")
  let month = `${date.getMonth() + 1}`.padStart(2, "0")
  let year = date.getFullYear()
  let fecActual = `${day}/${month}/${year}`

  const handleChange = (panel1) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel1 : false)
  }
  const getEmpeño = () => {
    props.setOpenLoadingScreen()
    requests
      .get(EMPEÑO_BY_ID, id)
      .then((response) => {
        console.log(response)
        calculo(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log("Error: " + error)
        props.setCloseLoadingScreen()
      })
  }
  const [_Totales, setTotales] = useState({
    liquidacion: 0,
    regrendo: 0,
  })
  const calculo = (data) => {
    let pago = (data.dblImporteDesemp * 2.12) / 100 + 3
    let total = data.dblImporteDesemp + pago
    let re = (data.dblImporteDesemp * 20) / 100
    let pagos = (re * 2.12) / 100 + 3
    let pago2 = re + pagos
    setEmpeño(data)
    setTotales({ ..._Totales, liquidacion: total, regrendo: pago2 })
  }

  const [_Ocultar, setOcultar] = useState(true)
  const [_Ocultar2, setOcultar2] = useState(true)
  const getAbonos = () => {
    props.setOpenLoadingScreen()
    requests
      .get(EMPEÑO_PAGO_LIST_BY_ID_EMPEÑO, id)
      .then((response) => {
        console.log(response)
        setAbonos(response)
        if (response.length > 0) {
          setOcultar(false)
          setOcultar2(true)
        } else {
          setOcultar(true)
          setOcultar2(false)
        }
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log("Error: " + error)
        props.setCloseLoadingScreen()
      })
  }

  useEffect(() => {
    getEmpeño()
    getAbonos()
  }, [])
  return (
    <div className="containerViewUser py4 paddingTablets">
      {/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
      <div className="flex-start w90 pb2 pl1">
        <div className="chip-breadcrumbs">
          <span className="letter-breadcrumbs">E</span>
        </div>

        <div role="presentation" className="ml2">
          <Breadcrumbs aria-label="breadcrumb" separator="›">
            <Link
              underline="hover"
              className="breadcrumb-url"
              onClick={() => {
                navigate(-2)
              }}
            >
              Empeño
            </Link>
            <Link
              underline="hover"
              className="breadcrumb-url "
              onClick={() => {
                navigate(-1)
              }}
            >
              Mis Refrendos
            </Link>
            <Link underline="hover" className="breadcrumb-url bold">
              Realizar Pago
            </Link>
          </Breadcrumbs>
        </div>
      </div>

      {/* :::::::::::::::::::::::::::::::::::::::::::::: BODY ::::::::::::::::::::::::::::::::::::::::::::::*/}
      <div className="body-empeño ">
        <div className="head-empeño flex-between p3">
          <div className="img-refrendo-Desk w20">
            <img className="img-EmpeñoView" src={_Empeño.txtUrl} />
          </div>

          <div className="refrendo-section3-pay">
            <p className="title-section3-pay">Total a pagar</p>
            <p className="subtitle-section3-pay">
              <NumberFormat
                value={_Totales.regrendo}
                displayType={"text"}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={"$ "}
                suffix={" DÓLARES"}
              />
            </p>

            {fecActual !== _Empeño.fecPierdeEmpe ? (
              <>
                {_Empeño.bolPagoRef === true ? (
                  <>
                    <p className="">
                      {" "}
                      Ya se realizado un refrendo a este artículo.{" "}
                    </p>
                  </>
                ) : (
                  <>
                    <Button
                      className="btn-refrendo"
                      onClick={function () {
                        dispatch(postRefrendpCreate(_Empeño.dblImporteDesemp))
                        navigate(FLUJO_REFRENDAR + _Empeño.lngIdProducto)
                      }}
                      disabled={
                        _Empeño.bolPagoRef === true
                          ? true
                          : false || _Empeño.bolPagoLiq === true
                          ? true
                          : false
                      }
                    >
                      Refrendar
                    </Button>
                  </>
                )}
              </>
            ) : (
              <p className=""> Se ha vencido tu fecha de pago. </p>
            )}
          </div>

          <div className="refrendo-img-desk-section2">
            <img className="img-EmpeñoView" src={_Empeño.txtUrl} />
          </div>

          <div className="refrendo-section1-pay">
            <p className="title-ref-pay">Vencimiento</p>
            <p className="subtitle-ref-pay">{_Empeño.fecPierdeEmpe}</p>
          </div>
        </div>

        {/* ::::::::::::::::::::    lista de Detalle   :::::::::::::::::::::: */}
        {_Abonos.length >= 1 ? (
          <div className="empenoDetailPay" hidden={_Ocultar}>
            <div className="box-head-empenoDetailPay">
              <div className="w40 empenoDetailPay-title ml1">Folio</div>
              <div className="w20 empenoDetailPay-title text-center">Fecha</div>
              <div className="w20 empenoDetailPay-title text-center">
                Concepto
              </div>
              <div className="w20 empenoDetailPay-title text-center">Total</div>
            </div>

            {_Abonos.map((row, index) => (
              <div className="box-empenoDetailPay">
                <div className="w40 empenoDetailPay-Subtitle">
                  {row.txtFolio}
                </div>
                <div className="w20 empenoDetailPay-Subtitle text-center">
                  {row.fecPagoEmpeno}
                </div>
                <div className="w20 empenoDetailPay-Subtitle text-center">
                  {row.tipoPagoEmpeno === 4 ? "Refrendo" : "Liquidación"}
                </div>
                <div className="w20 empenoDetailPay-Subtitle text-center">
                  <NumberFormat
                    value={row.dblTotalPagar}
                    displayType={"text"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="box-proceso-producto-list" hidden={_Ocultar2}>
            <Alert severity="info">
              Aún no cuentas con pagos realizados sobre este artículo.
            </Alert>
          </div>
        )}
      </div>
    </div>
  )
}

export default ClienteEmpeñoRefrendar

/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, DialogTitle, TextField } from '@mui/material'
import '../../Styles/Styles.css'
import { useDispatch } from 'react-redux'
import { login2, loginApa } from '../../Actions/UserActions'
import { useFormik } from 'formik'
import { CLIENTES_SECTION_FIRST_PRO_VIEW } from '../../Constants/routesConstants'
import { useNavigate } from 'react-router-dom'
import Slide from '@mui/material/Slide'
import * as Yup from 'yup'
import imgbarter from '../../Images/Home/Login.jpeg'
import {
  gtmOpenResetPassUNL,
  gtmCloseLoginUNL,
} from '../../Constants/GoogleTagManager/gtmConstants'
import LBL from '../../Images/logo-barter-sin-fondo.webp'
import ResetPass from './ResetPass'
import RegisterMbApartado from './RegisterMbApartado'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const LoginMbApartado = (props) => {
  const navigate = useNavigate()

  const handleClose = () => {
    gtmCloseLoginUNL()
    props.onClose()
    props.getRegistros()
    LimpiarValores()
  }

  const [_Banco, setBanco] = useState({
    txtBanco: '',
  })
  const LimpiarValores = () => {
    setBanco({ ..._Banco, txtBanco: '' })
  }

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      correo: '',
      password: '',
    },
    validationSchema: Yup.object({
      correo: Yup.string()
        .email('El formato del correo no es válido.')
        .required('El correo es requerido.'),
      password: Yup.string().required('La correo es requerido.'),
    }),
    onSubmit: (values) => {
      dispatch(loginApa(values, props))
      navigate(CLIENTES_SECTION_FIRST_PRO_VIEW)
    },
  })

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    gtmOpenResetPassUNL()
    setOpen(!open)
  }

  const [open2, setOpen2] = useState(false)
  const handleOpen2 = () => {
    props.onClose()
    setOpen2(!open2)
  }

  return (
    <>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose ? props.onClose : null}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(10px)',
          },
        }}
      >
        <div className="modal-LoginMB">
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className="h100"
          >
            {window.screen.width <= 600 ? (
              <>
                <div className="h100 bgImageLoginMobile">
                  <div className="bodyLoginMobile">
                    <div className="flex-end">
                      <Button onClick={handleClose}>
                        <CloseIcon
                          fontSize="small"
                          className="btn-close-icon-LoginMobile font-dark"
                        />
                      </Button>
                    </div>

                    <div className="flex-column w100">
                      <img className="mt3 w40" src={LBL} />
                      <div className="flex-column-start my3 w90">
                        <span className="h2">¡Hola!</span>
                        <span className="p co-grey">
                          inicia sesión para usar FoundIt Members
                        </span>
                      </div>
                    </div>

                    <div className="center mt2 w90">
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="correo"
                        label="Correo electronico"
                        name="correo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.correo}
                        error={
                          formik.touched.correo && Boolean(formik.errors.correo)
                        }
                        helperText={
                          formik.touched.correo && formik.errors.correo
                        }
                        color="warning"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        color="warning"
                      />
                    </div>
                    <div className="flex my3">
                      <span
                        className="p font-16 co-grey pointer"
                        onClick={handleOpen}
                      >
                        Recuperar Contraseña
                      </span>
                      <span className="mx2"> | </span>
                      <span
                        className="p font-16 co-grey bold pointer"
                        onClick={handleOpen2}
                      >
                        Crear Cuenta
                      </span>
                    </div>
                    <div className="center w90">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        className="btnLogin"
                      >
                        Iniciar sesión
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex h100">
                  <div className="w45">
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                      }}
                      src={imgbarter}
                    />
                  </div>

                  <div className="w55">
                    <div className="flex-end mt1">
                      <Button onClick={handleClose}>
                        <CloseIcon
                          fontSize="small"
                          className="btn-close-icon-modal font-dark"
                        />
                      </Button>
                    </div>

                    <div className="flex-column w100">
                      <img className="mt3 w40" src={LBL} />
                      <div className="flex-column-start my3 w90">
                        <span className="h2">¡Hola!</span>
                        <span className="p co-grey">
                          inicia sesión para usar FoundIt Members
                        </span>
                      </div>
                    </div>

                    <div className="center mt2 w90">
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="correo"
                        label="Correo electronico"
                        name="correo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.correo}
                        error={
                          formik.touched.correo && Boolean(formik.errors.correo)
                        }
                        helperText={
                          formik.touched.correo && formik.errors.correo
                        }
                        color="warning"
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        color="warning"
                      />
                    </div>
                    <div className="flex my3">
                      <span className="p co-grey pointer" onClick={handleOpen}>
                        Recuperar Contraseña
                      </span>
                      <span className="mx2"> | </span>
                      {/* <span
												className='p co-grey bold pointer'
												onClick={handleOpen2}
											>
												Crear Cuenta
											</span>											 */}
                    </div>
                    <div className="center w90">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        className="btnLogin"
                      >
                        Iniciar sesión
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Box>
        </div>
      </Dialog>
      <ResetPass open={open} onClose={handleOpen} props={props} />
      <RegisterMbApartado open={open2} onClose={handleOpen2} props={props} />
    </>
  )
}

export default LoginMbApartado

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import '../Styles/TerminosConditions/TermsConditions.css'
import '../Styles/Foundit/faqsMB.css'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import ListItem from '@mui/material/ListItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemText from '@mui/material/ListItemText'
import FqSection1Web from '../Components/SectionFaqs/Section1/FqSection1Web'
import FqSectionVentas from '../Components/SectionFaqs/Section2/FqSectionVentas'
import FqSectionEmpeños from '../Components/SectionFaqs/Section3/FqSectionEmpeños'
import FqSectionCompras from '../Components/SectionFaqs/Section4/FqSectionCompras'
import FqSectionApartado from '../Components/SectionFaqs/Section5/FqSectionApartado'
const FaqsFoundit = () => {
  return (
    <div className="bg-white-color">
      {/* //? ******************* SECTION 1 ************************* */}
      <FqSection1Web />
      {/* //? ******************* SECTION 2 ************************* */}
      <FqSectionVentas />
      {/* //? ******************* SECTION 3 ************************* */}
      <FqSectionApartado />
      {/* //? ******************* SECTION 4 ************************* */}
      <FqSectionCompras />

      <div className="containertxtBottomfqs">
        <div className="cardbottom">
          <span className="txtbottomfqs">
            Cualquier duda o aclaración, nos puedes contactar a través de
            nuestro número de atención al cliente (63-59-03-18) o nuestro
            correo: foundit.pa@founditpreloved.com
          </span>
        </div>
      </div>
    </div>
  )
}

export default FaqsFoundit

import React, { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Grid } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import WifiIcon from '@mui/icons-material/Wifi'
import requests from '../../../../AxiosCalls/AxiosAuth'
import { PROD_APARTADO_DETAILS2 } from '../../../../../Constants/routesConstants'
import {
  isNullOrEmpty,
  isErrorForm,
  rxCaracteresEsp,
} from '../../../../../Util/Validaddor'
import { useSelector } from 'react-redux'

const visa = require('../../../../../Images/Logo-Visa.png')
const chip1 = require('../../../../../Images/chip1.png')

const CardPayProximoPagoClinPay = (props) => {
  let navigate = useNavigate()
  var dataMnto = JSON.parse(sessionStorage.getItem('montoAbono'))
  const { id } = useParams()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const [cardFormData, setcardFormData] = useState({
    cardNumber: '',
    cardName: '',
    expiryMonth: '',
    expiryYear: '',
    ccv: '',
    loading: false,
    iframeCode: '',
    timer: 10000,
    subTimer: 20000,
    transactionId: '',
    transactionState: 'Unknown',
    paymentComplete: false,
    status: '',
    initialPaymentSubmitted: false,
  })

  // proceso de pago
  const getCardForm = () => ({
    safeIdentifier: { value: '', error: false }, //Numero de la tarjeta
    firstName: { value: '', error: false },
    expiryMonth: { value: '', error: false },
    expiryYear: { value: '', error: false },
    cvv: { value: '', error: false },

    // cardNumber: { value: '', error: false },
    // cardName: { value: '', error: false },
    // expiryMonth: { value: '', error: false },
    // expiryYear: { value: '', error: false },
    // ccv: { value: '', error: false },
    // //city: { value: '', error: false },
    // //zip: { value: '', error: false },
  })
  const [cardForm, setCardForm] = useState(getCardForm)

  const handleNameCard = (e, value) => {
    let nombre = e.target.name
    let valor = e.target.value
    let id = e.target.id

    setCardForm({
      ...cardForm,
      [nombre]: {
        value: valor,
        error: false,
      },
    })
  }

  const handleClickSubmitPayment = () => {
    // const {
    //   firstName,
    //   safeIdentifier,
    //   expiryMonth,
    //   expiryYear,
    //   cvv,
    //   city,
    //   zip,
    // } = cardForm
    // const form = {
    //   ...cardForm,
    //   firstName: {
    //     ...firstName,
    //     error:
    //       isNullOrEmpty(firstName.value) ||
    //       !rxCaracteresEsp.test(firstName.value),
    //   },
    //   expiryMonth: {
    //     ...expiryMonth,
    //     error:
    //       isNullOrEmpty(expiryMonth.value) ||
    //       !rxCaracteresEsp.test(expiryMonth.value),
    //   },
    //   expiryYear: {
    //     ...expiryYear,
    //     error:
    //       isNullOrEmpty(expiryYear.value) ||
    //       !rxCaracteresEsp.test(expiryYear.value),
    //   },
    //   cvv: {
    //     ...cvv,
    //     error: isNullOrEmpty(cvv.value) || !rxCaracteresEsp.test(cvv.value),
    //   },
    //   safeIdentifier: {
    //     ...safeIdentifier,
    //     error:
    //       isNullOrEmpty(safeIdentifier.value) ||
    //       !rxCaracteresEsp.test(safeIdentifier.value),
    //   },
    // }
    // setCardForm(form)
    // if (isErrorForm(form)) {
    //   return
    // }

    const SendBody = {
      idProduct: '', //ordercomp.lngIdCarrito, // pasar el id del carrito para obtener los datos de la db y no tomar el valos de amout del local si no de la base haciendo una consulta con idCarrito,
      // safeIdentifier: cardForm.safeIdentifier.value,
      // month: cardForm.expiryMonth.value,
      // year: cardForm.expiryYear.value,
      // cvv: cardForm.cvv.value,
      tax: (dataMnto * 3.5) / 100 + 0.5,
      // firstName: cardForm.firstName.value, // definir es del usuario de la tarjeta o user del la session
      amout: dataMnto, //ordercomp.dblTotal, // asignar el precio de contexto o m ordercomp.dblTotal
      amoutTotal: dataMnto + ((dataMnto * 3.5) / 100 + 0.5), //ordercomp.dblTotal, // asignar el precio de contexto o m ordercomp.dblTotal
      idPorApart: '',
      idClient: userInfo.intIdUsuario,
      lngIdApartado: id,
    }

    props.props.props.setOpenLoadingScreen()
    requests
      .post('/PaymentsPagueloFacil/SecondPaymentCardPagueloFacil/', SendBody)
      .then((response) => {
        // localStorage.setItem(
        //   "ipgTransactionId",
        //   JSON.stringify(response.data.ipgTransactionId)
        // )

        if (response.data.success === true) {
          // props.props.props.setCloseLoadingScreen()
          // props.props.props.setOpenSnackBar(true)
          // props.props.props.setTypeSnackBar('success')
          // props.props.props.setMessageSnackBar(response.mensaje)
          // navigate(PROD_APARTADO_DETAILS2 + id)
          window.location.href = response.data.data.url

          // return
        }
      })

      .catch((error) => {
        props.props.props.setTypeSnackBar('warning')
        props.props.props.setMessageSnackBar(error.data.mensaje)
        props.props.props.setOpenSnackBar(true)
        props.props.props.setCloseLoadingScreen()
      })
  }

  // El flujo continua el servidor y para luego regresar al cliente
  return (
    <div className="pay-container">
      {/* <div className="container-Card-Payment">
        <div className="section1">
          <div className="flex-between w100 ">
            <img src={visa} className="img-logo-payment" />
            <div className="flex">
              <img src={chip1} className="img-logo-chip mr1" />
              <WifiIcon className="icon-card" />
            </div>
          </div>
          <div className="flex-column line-height-0  upperCase mt2">
            <p>Número de tarjeta</p> 

            {cardForm.safeIdentifier.value.length === 0 ? (
              <p className="card-number">[XXXX] [XXXX] [XXXX] [XXXX]</p>
            ) : (
              <p className="card-number">{cardForm.safeIdentifier.value}</p>
            )}
          </div>
        </div>
        <div className="section2">
          <div className="flex-column-start line-height-0  upperCase mr1">
            <p className="titular-card">Nombre del titular</p>
            <p className="titular-data-card">{cardForm.firstName.value}</p>
          </div>

          <div className="flex-between ">
            <div className="flex-between line-height-0">
              <p className="date-card">EXPIRA</p> &nbsp;
              <div className="flex upperCase mt1">
                <p className="date-data-card">{cardForm.expiryMonth.value}</p>
                &nbsp; <p className="bold mt0"> / </p>&nbsp;
                <p className="date-data-card">{cardForm.expiryYear.value}</p>
              </div>
            </div>

            <div className=" flex-between mr3">
              <div className="flex line-height-0  upperCase ">
                <p className="mr1">CCV</p>
                <p className="bold"> {cardForm.cvv.value}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Grid
        container
        spacing={1}
        sx={{
          mt: 3,
          width: '80%',
          '@media(max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Grid item xs={12} lg={12}>
          <TextField
            keyboardType="numeric"
            maxLength={16}
            name="safeIdentifier"
            onChange={handleNameCard}
            value={cardForm.safeIdentifier.value}
            variant="outlined"
            label="Número de tarjeta"
            fullWidth
            required
            inputProps={{
              maxLength: 16,
            }}
            error={
              cardForm.safeIdentifier.error ||
              !rxCaracteresEsp.test(cardForm.safeIdentifier.value)
            }
            helperText={
              cardForm.safeIdentifier.error ? (
                <span>El número de tarjeta es requerido</span>
              ) : !rxCaracteresEsp.test(cardForm.safeIdentifier.value) ? (
                <span>No se aceptan caracteres especiales</span>
              ) : (
                ''
              )
            }
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <TextField
            name="firstName"
            onChange={handleNameCard}
            value={cardForm.firstName.value}
            label="Titular de la tarjeta"
            required
            //InputLabelProps={{ shrink: true }}
            fullWidth
            sx={{ mr: 2 }}
            type="text"
            error={
              cardForm.firstName.error ||
              !rxCaracteresEsp.test(cardForm.firstName.value)
            }
            helperText={
              cardForm.firstName.error ? (
                <span>El nombre del titular de la tarjeta es requerido</span>
              ) : !rxCaracteresEsp.test(cardForm.firstName.value) ? (
                <span>No se aceptan caracteres especiales</span>
              ) : (
                ''
              )
            }
          />
        </Grid>

        <Grid item xs={4} lg={4}>
          <TextField
            name="expiryMonth"
            onChange={handleNameCard}
            value={cardForm.expiryMonth.value}
            label="Mes de vencimiento"
            required
            fullWidth
            inputProps={{
              maxLength: 2,
            }}
            error={
              cardForm.expiryMonth.error ||
              !rxCaracteresEsp.test(cardForm.expiryMonth.value)
            }
            helperText={
              cardForm.expiryMonth.error ? (
                <span>El mes de vencimiento es requerido</span>
              ) : !rxCaracteresEsp.test(cardForm.expiryMonth.value) ? (
                <span>No se aceptan caracteres especiales</span>
              ) : (
                ''
              )
            }
          />
        </Grid>

        <Grid item xs={4} lg={4}>
          <TextField
            name="expiryYear"
            onChange={handleNameCard}
            value={cardForm.expiryYear.value}
            label="Año de vencimiento"
            required
            fullWidth
            inputProps={{
              maxLength: 2,
            }}
            error={
              cardForm.expiryYear.error ||
              !rxCaracteresEsp.test(cardForm.expiryYear.value)
            }
            helperText={
              cardForm.expiryYear.error ? (
                <span>El año de vencimiento es requerido</span>
              ) : !rxCaracteresEsp.test(cardForm.expiryYear.value) ? (
                <span>No se aceptan caracteres especiales</span>
              ) : (
                ''
              )
            }
          />
        </Grid>

        <Grid item xs={4} lg={4}>
          <TextField
            name="cvv"
            onChange={handleNameCard}
            value={cardForm.cvv.value}
            type={'password'}
            label="CVV"
            fullWidth
            required
            inputProps={{
              maxLength: 4,
            }}
            error={
              cardForm.cvv.error || !rxCaracteresEsp.test(cardForm.cvv.value)
            }
            helperText={
              cardForm.cvv.error ? (
                <span>El cvv es requerido</span>
              ) : !rxCaracteresEsp.test(cardForm.cvv.value) ? (
                <span>No se aceptan caracteres especiales</span>
              ) : (
                ''
              )
            }
          />
        </Grid> */}
      <Button className="btn-pay mt2" onClick={handleClickSubmitPayment}>
        Realizar pago
      </Button>
      {/* </Grid> */}
    </div>
  )
}

export default CardPayProximoPagoClinPay

import React from 'react'
import Grid from '@mui/material/Grid'
import {
  Chip,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
} from '@mui/material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import '../../../Styles/GeneralStyles.css'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { CLIENTES_SECTION_VIEW } from '../../../Constants/routesConstants'

const ApartadoDeseo = (props) => {
  const navigate = useNavigate()

  const goShopify = () => {
    window.open('https://pa.founditpreloved.com/', '_blank')
  }

  return (
    <div className="containerViewUser p2">
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Button
            className="back-gradient-4 bord-radius-10 shadow font-letter font-light"
            startIcon={<ArrowBackIcon />}
            onClick={function () {
              navigate(CLIENTES_SECTION_VIEW)
            }}
          >
            Regresar
          </Button>
          <Chip
            icon={<ShoppingCartIcon />}
            label="Apartado / Lista de deseo"
            color="primary"
            className="back-transparent font-letter font-barter font-20"
          />
        </Grid>
        <Grid item lg={3}>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              height="400"
              image="https://images.unsplash.com/photo-1608256246200-53e635b5b65f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Botas Balenciaga
              </Typography>
              <Typography variant="body2" color="text.secondary">
                $9,999.00
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                className="back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10"
                onClick={function () {
                  goShopify()
                  //navigate(CLIENTES_SHOP_TRA)
                }}
              >
                Comprar ahora
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item lg={3}>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              height="400"
              image="https://images.unsplash.com/photo-1547996160-81dfa63595aa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Panerai Reloj
              </Typography>
              <Typography variant="body2" color="text.secondary">
                $15,879.49
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                className="back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10"
                onClick={function () {
                  goShopify()
                }}
              >
                Comprar ahora
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item lg={3}>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              height="400"
              image="https://images.unsplash.com/photo-1603808033192-082d6919d3e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Tenis Gucci
              </Typography>
              <Typography variant="body2" color="text.secondary">
                $3,978.00
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                className="back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10"
                onClick={function () {
                  goShopify()
                }}
              >
                Comprar ahora
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item lg={3}>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              height="400"
              image="https://images.unsplash.com/photo-1575032617751-6ddec2089882?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Bolso Prada
              </Typography>
              <Typography variant="body2" color="text.secondary">
                $11,000.00
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                className="back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10"
                onClick={function () {
                  goShopify()
                }}
              >
                Comprar ahora
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default ApartadoDeseo

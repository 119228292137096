import React, { useEffect, useState } from "react"
import {
  Button,
  Radio,
  RadioGroup,
  Slider,
  Grid,
  FormControl,
  InputLabel,
  Paper,
  Select,
  TextField,
  MenuItem,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Divider,
  Tooltip,
  Fade,
} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useStylesGeneral } from "../../Styles/GeneralStyles"
import { styled } from "@mui/material/styles"
import SendIcon from "@mui/icons-material/Send"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import requests from "../AxiosCalls/AxiosCall"
import AddIcon from "@mui/icons-material/Add"
import Compressor from "compressorjs"
import "../../Styles/Styles.css"
import {
  CUESTIONARIO_ADD,
  CUESTIONARIO_DINAMICO_ADD,
  MARCAS_PARAMETRO,
  MARCAS_LIST,
  C_ARTICULO_LIST,
  C_PRECIO_LIST,
  C_MATERIAL_LIST,
  C_TIEMPO_LIST,
  C_ESTADO_LIST,
  C_DESGASTE_LIST,
  C_ACCESORIOS_LIST,
  C_JOYERIA01_LIST,
  C_JOYERIA02_LIST,
  MB_TIPO_SERVICIO_LIST,
  GET_LIST_TIPOS_SERV_DES,
  CREATE_FORMULARIO_TEST,
  GET_LIST_ESTADOS_MX,
  MB_TIPO_SERVICIO_LIST_TEST,
} from "../../Constants/ApiConstants"
import { useSelector } from "react-redux"
import AsyncSelect from "react-select/async"

import {
  gtm_SendPreevaluacion_Log,
  gtm_OtraPreevaluacion_Log,
  gtm_No_OtraPreevaluacion_Log,
} from "../../Constants/GoogleTagManager/gtmConstants"

import { INICIO } from "../../Constants/routesConstants"
import Frontal from "../../Images/Preevaluaciones/frontal.png"
import Posterior from "../../Images/Preevaluaciones/posterior.png"
import Interior from "../../Images/Preevaluaciones/Interior.png"
import Etiqueta from "../../Images/Preevaluaciones/etiqueta.png"
import DetallesAdicionales from "../../Images/Preevaluaciones/detallesAdicionales.png"
import CostadoDer from "../../Images/Preevaluaciones/costadoDerecho.png"
import CostadoIzq from "../../Images/Preevaluaciones/costadoIzquierdo.png"

//import logo from '../Images/logo-barter-sin-fondo.png'

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #CBB878",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#CBB878",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#CBB878",
  },
})

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  )
}

const Input = styled("input")({
  display: "none",
})

const CuestionarioEmpConAdd2 = (props) => {
  const classes = useStylesGeneral()
  const methods = useForm()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const { handleSubmit } = methods
  const navigate = useNavigate()
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  //ESTADOS INICIALES
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  const [_ImageFrente, setImageFrente] = useState(null)
  const [_ImageAtras, setImageAtras] = useState(null)
  const [_ImagenIzquierda, setImagenIzquierda] = useState(null)
  const [_ImagenDerecha, setImagenDerecha] = useState(null)
  const [_ImagenDentro, setImagenDentro] = useState(null)
  const [_ImagenDetalle, setImagenDetalle] = useState(null)
  const [_ImagenDetalle2, setImagenDetalle2] = useState(null)
  const [_ImagenDetalle3, setImagenDetalle3] = useState(null)
  const [_EstadosMX, setEstadosMx] = useState([])

  const [openDialog, setOpenDialog] = useState(false)
  const [openDialogDescription, setOpenDialogDescription] = useState(true)
  //=_=
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  //ESTADO INICIAL DEL ARTICULO
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  const initialState = {
    //lngIdRegistro: 0,
    IntIdUsuario: "",
    bolServicio: "",
    intIdArticulo: "",
    intIdMarca: "",
    txtOtraMarca: "",
    intIdPrecio: "",
    intIdMaterial: "",
    intIdTiempoUso: "",
    intIdEstado: "",
    bolReparado: "",
    bolRoto: "",
    porManchadoInt: 0,
    porManchadoExt: 0,
    porRayadoInt: 0,
    porRayadoExt: 0,
    porLimpieza: 0,
    intIdDesgaste: 0,
    intIdMultiple: 0,
    bolPedreria: "",
    intIdAccesorio: 0,
    bolCertificado: "",
    intIdAccesorioOtro: 0,
    bolCierresCompletos: "",
    bolCierresFuncionales: "",
    bolHerrajesBrillantes: "",
    bolPedreriaCompleta: "",
    bolEstuche: "",
    bolHebillascompletas: "",
    bolArrugado: "",
    bolBrochefuncional: "",
    bolRelojDigita: "",
    bolFuncionando: "",
    intIdJoyeria01: "",
    intIdJoyeria02: "",
    intIdStatus: "",
    bolBandolera: "",
    bolCandado: "",
    bolDustBag: "",
    bolLlaveMon: "",
    bolParche: "",
    bolDustBagZ: "",
    bolRepuesto: "",
    intIdTipoServicio: 2,
    intIdEstadoRep: "",
    txtUrlImgFrente: "",
    txtUrlImgAtras: "",
    txtUrlImgIzqIzquierda: "",
    txtUrlImgDerecha: "",
    txtUrlImgDentro: "",
    txtUrlImgDetalle: "",
    TxtUrlImgDetalle2: "",
    TxtUrlImgDetalle3: "",
    txtDescripcion: "",
  }
  const [_AtbRegistro, setAtbRegistro] = React.useState(initialState)
  const handleAtbRegistro = (event) => {
    setAtbRegistro({ ..._AtbRegistro, [event.target.name]: event.target.value })
  }

  const [_OcultarEstado, setOcultarEstado] = useState(true)
  const [_Requerido, setRequerido] = useState(false)
  const handleTipoServicio = (event) => {
    if (event.target.value === 4 || event.target.value === 6) {
      setOcultarEstado(false)
      setRequerido(true)
      setAtbRegistro({ ..._AtbRegistro, intIdTipoServicio: event.target.value })
    } else {
      setRequerido(false)
      setOcultarEstado(true)
      setAtbRegistro({ ..._AtbRegistro, intIdTipoServicio: event.target.value })
    }
  }

  const [_AtbRegistroDin, setAtbRegistroDin] = useState({
    lngIdCliente: "",
    fecFecha: "",
  })

  const handleCloseDialogDescription = () => {
    setOpenDialogDescription(false)
  }
  const handleCloseDialog = () => {
    gtm_OtraPreevaluacion_Log()
    setAtbRegistro(initialState)
    setImageFrente(null)
    setImageAtras(null)
    setImagenIzquierda(null)
    setImagenDerecha(null)
    setImagenDentro(null)
    setImagenDetalle(null)
    setImagenDetalle2(null)
    setImagenDetalle3(null)
    setMaterial([])
    setLimpieza(0)
    setArtManchadoInt(0)
    setArtManchadoExt(0)
    setArtRayadoExt(0)
    setArtRayadoInt(0)
    setOcultar(true)
    setOcultar2(true)
    setOcultar3(true)
    setOcultar4(true)
    setOpenDialog(false)
  }
  const returnHome = () => {
    gtm_No_OtraPreevaluacion_Log()
    setAtbRegistro(initialState)
    setImageFrente(null)
    setImageAtras(null)
    setImagenIzquierda(null)
    setImagenDerecha(null)
    setImagenDentro(null)
    setImagenDetalle(null)
    setImagenDetalle2(null)
    setImagenDetalle3(null)
    setMaterial([])
    setLimpieza(0)
    setArtManchadoInt(0)
    setArtManchadoExt(0)
    setArtRayadoExt(0)
    setArtRayadoInt(0)
    setOcultar(true)
    setOcultar2(true)
    setOcultar3(true)
    setOcultar4(true)
    setOpenDialog(false)
    navigate(INICIO)
  }

  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  //PROCESO DE GUARDADO
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  const guardar = async () => {
    props.setOpenLoadingScreenPB()
    props.setMsjLoadingScreen(
      "Este proceso puede tardar unos segundos, por favor no cierre la página"
    )
    const formData = new FormData()
    formData.append("IntIdUsuario", userInfo.intIdUsuario)
    formData.append("bolServicio", _AtbRegistro.bolServicio)
    formData.append("intIdArticulo", _AtbRegistro.intIdArticulo)
    formData.append("intIdMarca", _AtbRegistro.intIdMarca)
    formData.append("txtOtraMarca", _AtbRegistro.txtOtraMarca)
    formData.append("intIdPrecio", _AtbRegistro.intIdPrecio)
    formData.append("intIdMaterial", _AtbRegistro.intIdMaterial)
    formData.append("intIdTiempoUso", _AtbRegistro.intIdTiempoUso)
    formData.append("intIdEstado", _AtbRegistro.intIdEstado)
    formData.append("bolReparado", _AtbRegistro.bolReparado)
    formData.append("bolRoto", _AtbRegistro.bolRoto)
    formData.append("porManchadoInt", _AtbRegistro.porManchadoInt)
    formData.append("porManchadoExt", _AtbRegistro.porManchadoExt)
    formData.append("porRayadoInt", _AtbRegistro.porRayadoInt)
    formData.append("porRayadoExt", _AtbRegistro.porRayadoExt)
    formData.append("porLimpieza", _AtbRegistro.porLimpieza)
    formData.append("intIdDesgaste", _AtbRegistro.intIdDesgaste)
    formData.append("intIdMultiple", _AtbRegistro.intIdMultiple)
    formData.append("bolPedreria", _AtbRegistro.bolPedreria)
    formData.append("intIdAccesorio", _AtbRegistro.intIdAccesorio)
    formData.append("bolCertificado", _AtbRegistro.bolCertificado)
    formData.append("intIdAccesorioOtro", _AtbRegistro.intIdAccesorioOtro)
    formData.append("bolCierresCompletos", _AtbRegistro.bolCierresCompletos)
    formData.append("bolCierresFuncionales", _AtbRegistro.bolCierresFuncionales)
    formData.append("bolHerrajesBrillantes", _AtbRegistro.bolHerrajesBrillantes)
    formData.append("bolPedreriaCompleta", _AtbRegistro.bolPedreriaCompleta)
    formData.append("bolEstuche", _AtbRegistro.bolEstuche)
    formData.append("bolHebillascompletas", _AtbRegistro.bolHebillascompletas)
    formData.append("bolArrugado", _AtbRegistro.bolArrugado)
    formData.append("bolBrochefuncional", _AtbRegistro.bolBrochefuncional)
    formData.append("bolRelojDigita", _AtbRegistro.bolRelojDigita)
    formData.append("bolFuncionando", _AtbRegistro.bolFuncionando)
    formData.append("intIdJoyeria01", _AtbRegistro.intIdJoyeria01)
    formData.append("intIdJoyeria02", _AtbRegistro.intIdJoyeria02)
    formData.append("intIdStatus", _AtbRegistro.intIdStatus)
    formData.append("bolBandolera", _AtbRegistro.bolBandolera)
    formData.append("bolCandado", _AtbRegistro.bolCandado)
    formData.append("bolDustBag", _AtbRegistro.bolDustBag)
    formData.append("bolLlaveMon", _AtbRegistro.bolLlaveMon)
    formData.append("bolParche", _AtbRegistro.bolParche)
    formData.append("bolDustBagZ", _AtbRegistro.bolDustBagZ)
    formData.append("bolRepuesto", _AtbRegistro.bolRepuesto)
    formData.append("intIdTipoServicio", _AtbRegistro.intIdTipoServicio)
    formData.append("intIdEstadoRep", _AtbRegistro.intIdEstadoRep)
    formData.append("txtUrlImgFrente", _ImageFrente)
    formData.append("txtUrlImgAtras", _ImageAtras)
    formData.append("txtUrlImgIzqIzquierda", _ImagenIzquierda)
    formData.append("txtUrlImgDerecha", _ImagenDerecha)
    formData.append("txtUrlImgDentro", _ImagenDentro)
    formData.append("txtUrlImgDetalle", _ImagenDetalle)
    formData.append("txtUrlImgDetalle2", _ImagenDetalle2)
    formData.append("txtUrlImgDetalle3", _ImagenDetalle3)
    formData.append("txtDescripcion", _AtbRegistro.txtDescripcion)
    if (
      _ImageFrente !== null &&
      _ImageAtras !== null &&
      _ImagenIzquierda !== null &&
      _ImagenDerecha !== null &&
      _ImagenDentro !== null &&
      _ImagenDetalle !== null
    ) {
      requests
        .post(CREATE_FORMULARIO_TEST, formData) //.post(CUESTIONARIO_ADD, formData)
        .then((response) => {
          gtm_SendPreevaluacion_Log()
          setOpenDialog(true)
          props.setCloseLoadingScreenPB()
          props.setMsjLoadingScreen("")
        })
        .catch((error) => {
          props.setTypeSnackBar("warning")
          props.setMessageAlertWarning(
            "Lo sentimos, este artículo no cumple con las características necesarias que requerimos en BarterLuxe. Intenta con otro."
          )
          props.setOpenAlertWarning(true)
          props.setCloseLoadingScreenPB()
          props.setMsjLoadingScreen("")
        })
    } else {
      props.setTypeSnackBar("warning")
      props.setMessageAlertWarning(
        "Debes adjuntar imagenes de tu artículo. Favor de verificar."
      )
      props.setOpenAlertWarning(true)
      props.setCloseLoadingScreenPB()
    }
  }

  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  //LISTADO DE CATALOGOS
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  const [_TipoServicio, setTipoServicio] = React.useState([])
  const getTipoServicio = () => {
    props.setOpenLoadingScreen()
    requests
      // .get(GET_LIST_TIPOS_SERV_DES) //.get(MB_TIPO_SERVICIO_LIST)
      .get(MB_TIPO_SERVICIO_LIST_TEST) //.get(MB_TIPO_SERVICIO_LIST)
      .then((response) => {
        setTipoServicio(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }

  const [_Precios, setPrecios] = React.useState([])
  const getPrecios = () => {
    props.setOpenLoadingScreen()
    requests
      .get(C_PRECIO_LIST)
      .then((response) => {
        setPrecios(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }

  const [_Material, setMaterial] = React.useState([])

  const [_TiempoUso, setTiempoUso] = React.useState([])
  const getTiempoUso = () => {
    props.setOpenLoadingScreen()
    requests
      .get(C_TIEMPO_LIST)
      .then((response) => {
        setTiempoUso(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }
  const [_Estado, setEstado] = React.useState([])
  const getEstado = () => {
    props.setOpenLoadingScreen()
    requests
      .get(C_ESTADO_LIST)
      .then((response) => {
        setEstado(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }
  const [_Desgaste, setDesgaste] = React.useState([])
  const getDesgaste = () => {
    props.setOpenLoadingScreen()
    requests
      .get(C_DESGASTE_LIST)
      .then((response) => {
        setDesgaste(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }
  const [_Accesorios, setAccesorios] = React.useState([])
  const getAccesorios = () => {
    props.setOpenLoadingScreen()
    requests
      .get(C_ACCESORIOS_LIST)
      .then((response) => {
        setAccesorios(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }
  const [_Joreria01, setJoreria01] = React.useState([])
  const getJoreria01 = () => {
    props.setOpenLoadingScreen()
    requests
      .get(C_JOYERIA01_LIST)
      .then((response) => {
        setJoreria01(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }
  const [_Joreria02, setJoreria02] = React.useState([])
  const getJoreria02 = () => {
    props.setOpenLoadingScreen()
    requests
      .get(C_JOYERIA02_LIST)
      .then((response) => {
        setJoreria02(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }
  const [_Marca, setMarca] = React.useState([])
  const getMarca = () => {
    props.setOpenLoadingScreen()
    requests
      .get(MARCAS_LIST)
      .then((response) => {
        setMarca(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }
  const [_completePerfil, setCompletePerfil] = useState(false)
  const [_Ocultar, setOcultar] = React.useState(true)
  const [_Ocultar2, setOcultar2] = React.useState(true)
  const [_Ocultar3, setOcultar3] = React.useState(true)
  const [_Ocultar4, setOcultar4] = React.useState(true)
  const [_Articulos, setArticulos] = React.useState([])
  const getArticulos = () => {
    props.setOpenLoadingScreen()
    requests
      .get(C_ARTICULO_LIST)
      .then((response) => {
        setArticulos(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }
  const handleArticulo = (event) => {
    setAtbRegistro({ ..._AtbRegistro, intIdArticulo: event.target.value })
  }

  const getBool = () => {
    var bool = localStorage.getItem("profile")

    setCompletePerfil(bool)
  }

  const getEstadosMx = () => {
    props.setOpenLoadingScreen()
    requests
      .get(GET_LIST_ESTADOS_MX)
      .then((response) => {
        setEstadosMx(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }

  useEffect(() => {
    getBool()
    getArticulos()
    //getMarca()
    getPrecios()
    getTiempoUso()
    getEstado()
    getDesgaste()
    getAccesorios()
    getJoreria01()
    getJoreria02()
    getTipoServicio()
    getEstadosMx()
  }, [])
  const [_Limpieza, setLimpieza] = React.useState(0)
  const handleLimpieza = (event, newValue) => {
    setLimpieza(newValue)
    setAtbRegistro({ ..._AtbRegistro, porLimpieza: newValue })
  }
  function LimpiezaText(_Limpieza) {
    return `${_Limpieza} %`
  }
  const [_ArtManchadoInt, setArtManchadoInt] = React.useState(0)
  const handleManchadoInt = (event, newValue) => {
    setArtManchadoInt(newValue)
    setAtbRegistro({ ..._AtbRegistro, porManchadoInt: newValue })
  }
  function ManchadoIntText(_ArtManchadoInt) {
    return `${_ArtManchadoInt} %`
  }
  const [_ArtManchadoExt, setArtManchadoExt] = React.useState(0)
  const handleManchadoExt = (event, newValue) => {
    setArtManchadoExt(newValue)
    setAtbRegistro({ ..._AtbRegistro, porManchadoExt: newValue })
  }
  function ManchadoExtText(_ArtManchadoExt) {
    return `${_ArtManchadoExt} %`
  }
  const [_ArtRayadoExt, setArtRayadoExt] = React.useState(0)
  const handleRayadoExt = (event, newValue) => {
    setArtRayadoExt(newValue)
    setAtbRegistro({ ..._AtbRegistro, porRayadoExt: newValue })
  }
  function RayadoExtText(_ArtRayadoExt) {
    return `${_ArtRayadoExt} %`
  }

  const [_ArtRayadoInt, setArtRayadoInt] = React.useState(0)
  const handleRayadoInt = (event, newValue) => {
    setArtRayadoInt(newValue)
    setAtbRegistro({ ..._AtbRegistro, porRayadoInt: newValue })
  }
  function RayadoIntText(_ArtRayadoInt) {
    return `${_ArtRayadoInt} %`
  }
  const [_ZapatoAcc, setZapatoAcc] = React.useState({
    bolParche: false,
    bolDustBagZ: false,
    bolRepuesto: false,
  })
  const handleZapatoAcc = (event) => {
    setZapatoAcc({ ..._ZapatoAcc, [event.target.name]: event.target.checked })
    setAtbRegistro({
      ..._AtbRegistro,
      [event.target.name]: event.target.checked,
    })
  }
  const [_BolsoAcc, setBolsoAcc] = React.useState({
    bolBandolera: false,
    bolCandado: false,
    bolDustBag: false,
    bolLlaveMon: false,
  })
  const handleBolsoAcc = (event) => {
    setBolsoAcc({ ..._BolsoAcc, [event.target.name]: event.target.checked })
    setAtbRegistro({
      ..._AtbRegistro,
      [event.target.name]: event.target.checked,
    })
  }
  const [_BolPedreria, setBolPedreria] = React.useState([])
  const handleBolPedreria = (event) => {
    setBolPedreria(event.target.value)
    setAtbRegistro({ ..._AtbRegistro, bolPedreria: event.target.value })
  }

  const handleImageFrente = (e) => {
    props.setOpenLoadingScreen()
    const file = e.target.files[0]
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const myFile = new File([result], e.target.files[0].name, {
          type: result.type,
        })
        setImageFrente(myFile)
        props.setCloseLoadingScreen()
      },
    })
  }
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  //CARGAR, ELIMINAR Y VER IMAGEN ATRÁS
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  const handleImageAtras = (e) => {
    props.setOpenLoadingScreen()

    const file = e.target.files[0]
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const myFile = new File([result], e.target.files[0].name, {
          type: result.type,
        })
        setImageAtras(myFile)
        props.setCloseLoadingScreen()
      },
    })
  }
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  //CARGAR, ELIMINAR Y VER IMAGEN IZQUIERDA
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  const handleImageIzquierda = (e) => {
    props.setOpenLoadingScreen()
    const file = e.target.files[0]
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const myFile = new File([result], e.target.files[0].name, {
          type: result.type,
        })
        setImagenIzquierda(myFile)
        props.setCloseLoadingScreen()
      },
    })
  }
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  //CARGAR, ELIMINAR Y VER IMAGEN DERECHA
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  const handleImageDerecha = (e) => {
    props.setOpenLoadingScreen()
    const file = e.target.files[0]
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const myFile = new File([result], e.target.files[0].name, {
          type: result.type,
        })
        setImagenDerecha(myFile)
        props.setCloseLoadingScreen()
      },
    })
  }
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  //CARGAR, ELIMINAR Y VER IMAGEN DENTRO
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  const handleImageDentro = (e) => {
    props.setOpenLoadingScreen()
    const file = e.target.files[0]
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const myFile = new File([result], e.target.files[0].name, {
          type: result.type,
        })
        setImagenDentro(myFile)
        props.setCloseLoadingScreen()
      },
    })
  }
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  //CARGAR, ELIMINAR Y VER IMAGEN DETALLE
  //=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=_=
  const handleImageDetalle = (e) => {
    props.setOpenLoadingScreen()
    const file = e.target.files[0]
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const myFile = new File([result], e.target.files[0].name, {
          type: result.type,
        })
        setImagenDetalle(myFile)
        props.setCloseLoadingScreen()
      },
    })
  }
  const handleImageDetalle2 = (e) => {
    props.setOpenLoadingScreen()
    const file = e.target.files[0]
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const myFile = new File([result], e.target.files[0].name, {
          type: result.type,
        })
        setImagenDetalle2(myFile)
        props.setCloseLoadingScreen()
      },
    })
  }
  const handleImageDetalle3 = (e) => {
    props.setOpenLoadingScreen()
    const file = e.target.files[0]
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const myFile = new File([result], e.target.files[0].name, {
          type: result.type,
        })
        setImagenDetalle3(myFile)
        props.setCloseLoadingScreen()
      },
    })
  }
  const [_NoAritculos, setNoArticulos] = useState({ cantidad: "" })
  const [_OcultarForm, setOcultarForm] = useState(false)
  const [_OcultarFormDinamico, setOcultarFormDinamico] = useState(true)
  const handleNoArticulos = (event) => {
    setNoArticulos({ ..._NoAritculos, cantidad: event.target.value })
    if (event.target.value === "1") {
      setOcultarForm(false)
      setOcultarFormDinamico(true)
    }
    if (event.target.value === "2") {
      setOcultarForm(true)
      setOcultarFormDinamico(false)
    }
    if (event.target.value === "") {
      setOcultarForm(true)
      setOcultarFormDinamico(true)
    }
  }

  //OBTENEMOS LISTADO DE CLIENTES
  const [_SelectedMarca, setSelectedMarca] = useState("")
  const marcaOptions = async (inputValue, callback) => {
    if (inputValue !== "")
      await requests
        .get(MARCAS_PARAMETRO + inputValue)
        .then((response) => {
          callback(
            response.map((i) => ({
              label: i.txtMarca,
              value: i.intIdMarca,
            }))
          )
        })
        .catch((error) => {
          console.log("Error: " + error)
        })
  }

  const onChangeSelectedMarca = (e) => {
    setSelectedMarca(e)
    setAtbRegistro({ ..._AtbRegistro, intIdMarca: e.value })
  }
  return (
    <div
      className="containerViewUser"
      style={{
        marginTop: _completePerfil === "true" ? "50px" : "100px",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.back}
      >
        <Grid item xs={12} xl={8}>
          <Grid item xs={12} className={classes.titleCuestionary}>
            <Paper className={classes.paperTitle} elevation={0}>
              <h2 className="flex titleCuestionario">
                PREEVALUACIÓN DEL ARTÍCULO
              </h2>
              <h3 className={classes.question}>
                Proporciona los siguientes datos sobre tu pieza, esto nos
                permitirá realizarte una propuesta previa y ofrecerte un precio
                justo.
              </h3>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                spacing={2}
                className={classes.selectOptions}
              ></Grid>
            </Paper>
          </Grid>
          <Box component="form" onSubmit={handleSubmit(guardar)}>
            <Grid item xs={12} className={classes.bodyCuestionary}>
              <Paper className={classes.paperBody} elevation={0}>
                <div className={classes.paperCuestionaryTitle}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: "600", textTransform: "uppercase" }}
                  >
                    Detalle del artículo
                  </Typography>
                </div>

                {/* <Divider className={classes.dividerhr2} /> */}
                <div className={classes.paperCuestionaryBody}>
                  {/* <h3 className={classes.question}>
                                        Tendrás que enviar un formulario por cada articulo a
                                        preevaluar.
                                    </h3> */}
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    spacing={2}
                    className={classes.selectOptions}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                      sx={{ mt: 2 }}
                      textAlign="center"
                    >
                      <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
                        <label htmlFor="imagenFrente">
                          <img
                            src={Frontal}
                            width="120px"
                            height="120px"
                            // className=' mr2 red'
                            // className=' mr2'
                            style={{ cursor: "pointer" }}
                          />
                          <Input
                            id="imagenFrente"
                            type="file"
                            onChange={handleImageFrente}
                            name="imagenFrente"
                            //disabled
                          />
                        </label>
                        <Typography
                          variant="caption"
                          gutterBottom
                          component="div"
                        >
                          {_ImageFrente !== null
                            ? _ImageFrente.name
                            : "Sin archivo"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
                        <label htmlFor="imagenAtras">
                          <img
                            src={Posterior}
                            width="120px"
                            height="120px"
                            // className=' mr2 red'
                            // className=' mr2'
                            style={{ cursor: "pointer" }}
                          />
                          <Input
                            id="imagenAtras"
                            type="file"
                            onChange={handleImageAtras}
                            name="imagenAtras"
                            //disabled
                          />
                        </label>
                        <Typography
                          variant="caption"
                          gutterBottom
                          component="div"
                        >
                          {_ImageAtras !== null
                            ? _ImageAtras.name
                            : "Sin archivo"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
                        <label htmlFor="imagenIzquierda">
                          <img
                            src={CostadoIzq}
                            width="120px"
                            height="120px"
                            // className=' mr2 red'
                            // className=' mr2'
                            style={{ cursor: "pointer" }}
                          />
                          <Input
                            id="imagenIzquierda"
                            type="file"
                            onChange={handleImageIzquierda}
                            name="imagenIzquierda"
                            //disabled
                          />
                        </label>
                        <Typography
                          variant="caption"
                          gutterBottom
                          component="div"
                        >
                          {_ImagenIzquierda !== null
                            ? _ImagenIzquierda.name
                            : "Sin archivo"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
                        <label htmlFor="imagenDerecha">
                          <img
                            src={CostadoDer}
                            width="120px"
                            height="120px"
                            // className=' mr2 red'
                            // className=' mr2'
                            style={{ cursor: "pointer" }}
                          />
                          <Input
                            id="imagenDerecha"
                            type="file"
                            onChange={handleImageDerecha}
                            name="imagenDerecha"
                            //disabled
                          />
                        </label>
                        <Typography
                          variant="caption"
                          gutterBottom
                          component="div"
                        >
                          {_ImagenDerecha !== null
                            ? _ImagenDerecha.name
                            : "Sin archivo"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
                        <label htmlFor="imagenDentro">
                          <img
                            src={Interior}
                            width="120px"
                            height="120px"
                            // className=' mr2 red'
                            // className=' mr2'
                            style={{ cursor: "pointer" }}
                          />
                          <Input
                            id="imagenDentro"
                            type="file"
                            onChange={handleImageDentro}
                            name="imagenDentro"
                            //disabled
                          />
                        </label>
                        <Typography
                          variant="caption"
                          gutterBottom
                          component="div"
                        >
                          {_ImagenDentro !== null
                            ? _ImagenDentro.name
                            : "Sin archivo"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
                        <label htmlFor="imagenDetalle">
                          <img
                            src={DetallesAdicionales}
                            width="120px"
                            height="120px"
                            // className=' mr2 red'
                            // className=' mr2'
                            style={{ cursor: "pointer" }}
                          />
                          <Input
                            id="imagenDetalle"
                            type="file"
                            onChange={handleImageDetalle}
                            name="imagenDetalle"
                            //disabled
                          />
                        </label>
                        <Typography
                          variant="caption"
                          gutterBottom
                          component="div"
                        >
                          {_ImagenDetalle !== null
                            ? _ImagenDetalle.name
                            : "Sin archivo"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
                        <label htmlFor="imagenDetalle2">
                          <img
                            src={DetallesAdicionales}
                            width="120px"
                            height="120px"
                            // className=' mr2 red'
                            // className=' mr2'
                            style={{ cursor: "pointer" }}
                          />
                          <Input
                            id="imagenDetalle2"
                            type="file"
                            onChange={handleImageDetalle2}
                            name="imagenDetalle2"
                            //disabled
                          />
                        </label>
                        <Typography
                          variant="caption"
                          gutterBottom
                          component="div"
                        >
                          {_ImagenDetalle2 !== null
                            ? _ImagenDetalle2.name
                            : "Sin archivo"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
                        <label htmlFor="imagenDetalle3">
                          <img
                            src={DetallesAdicionales}
                            width="120px"
                            height="120px"
                            // className=' mr2 red'
                            // className=' mr2'
                            style={{ cursor: "pointer" }}
                          />
                          <Input
                            id="imagenDetalle3"
                            type="file"
                            onChange={handleImageDetalle3}
                            name="imagenDetalle3"
                            //disabled
                          />
                        </label>
                        <Typography
                          variant="caption"
                          gutterBottom
                          component="div"
                        >
                          {_ImagenDetalle3 !== null
                            ? _ImagenDetalle3.name
                            : "Sin archivo"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    spacing={2}
                    className={classes.selectOptions}
                  >
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        color="warning"
                        size="small"
                        required
                        disabled
                      >
                        <InputLabel id="intIdTipoServicio">Servicio</InputLabel>
                        <Select
                          labelId="intIdTipoServicio"
                          id="intIdTipoServicio"
                          name="intIdTipoServicio"
                          value={_AtbRegistro.intIdTipoServicio}
                          onChange={handleTipoServicio}
                          label="Servicio"
                          inputProps={{
                            name: "intIdTipoServicio",
                            id: "intIdTipoServicio",
                          }}
                        >
                          <MenuItem value="" />
                          {_TipoServicio.map((object, index) => (
                            <MenuItem value={object.intIdTipoServicio}>
                              {object.txtTipoServicio}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        color="warning"
                        size="small"
                        required
                      >
                        <InputLabel id="intIdArticulo">Articulo</InputLabel>
                        <Select
                          labelId="intIdArticulo"
                          id="intIdArticulo"
                          name="intIdArticulo"
                          value={_AtbRegistro.intIdArticulo}
                          onChange={handleArticulo}
                          label="Servicio"
                          inputProps={{
                            name: "intIdArticulo",
                            id: "intIdArticulo",
                          }}
                        >
                          <MenuItem value="" />
                          {_Articulos.map((object, index) => (
                            <MenuItem value={object.intIdArticulo}>
                              {object.txtArticulo}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <AsyncSelect
                        placeholder="Marca"
                        required
                        value={_SelectedMarca}
                        onChange={onChangeSelectedMarca}
                        loadOptions={marcaOptions}
                        defaultOptions
                        cacheOptions
                        color="warning"
                        variant="outlined"
                        size="small"
                        fullWidth
                        // styles={{
                        //     control: (baseStyles, state) => ({
                        //       ...baseStyles,
                        //       border: 0,
                        //       borderBottom: '1px solid #a1a1a1'
                        //       //borderColor: state.isFocused ? 'grey' : 'red',
                        //     }),
                        //   }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <FormControl
                        fullWidth
                        size="small"
                        color="warning"
                        variant="outlined"
                      >
                        <InputLabel htmlFor="intIdPrecio" required>
                          Precio de compra
                        </InputLabel>
                        <Select
                          value={_AtbRegistro.intIdPrecio}
                          onChange={handleAtbRegistro}
                          label="intIdPrecio"
                          inputProps={{
                            name: "intIdPrecio",
                            id: "intIdPrecio",
                          }}
                        >
                          <MenuItem aria-label="None" value="" />
                          {_Precios.map((object, index) => (
                            <MenuItem value={object.intIdPrecio}>
                              {object.txtPrecio}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      hidden={_OcultarEstado}
                    >
                      <FormControl
                        fullWidth
                        size="small"
                        color="warning"
                        variant="outlined"
                        required={_Requerido}
                      >
                        <InputLabel
                          htmlFor="intIdEstadoRep"
                          required={_Requerido}
                        >
                          Lugar donde radicas
                        </InputLabel>
                        <Select
                          value={_AtbRegistro.intIdEstadoRep}
                          onChange={handleAtbRegistro}
                          label="intIdEstadoRep"
                          inputProps={{
                            name: "intIdEstadoRep",
                            id: "intIdEstadoRep",
                          }}
                        >
                          <MenuItem aria-label="None" value="" />
                          {_EstadosMX.map((object, index) => (
                            <MenuItem value={object.intIdEstado}>
                              {object.txtEstado}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <h3 className={classes.question}>
                        Cuéntanos todas las características de tu artículo,
                        menciona todos los detalles de uso. Ej: está roto,
                        manchado, despintado, reparado, rayado, etc.
                      </h3>
                      <TextField
                        fullWidth
                        id="standard-multiline-flexible"
                        label="Escribe tu respuesta"
                        multiline
                        maxRows={4}
                        variant="outlined"
                        name="txtDescripcion"
                        value={_AtbRegistro.txtDescripcion}
                        onChange={handleAtbRegistro}
                        color="warning"
                      />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
              <div className="flex center w30">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="back-gradient-7 bord-radius-10  font-letter font-light mt-30 w100"
                  startIcon={<SendIcon />}
                  //disabled
                >
                  Enviar
                </Button>
              </div>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="bold HeaderDialogEvaluation"
        >
          {"¿Desea realizar la evaluación de otro artítulo?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="mt1">
            El formulario se ha enviado con éxito. De 24 a 48 horas, recibirás
            un correo con nuestra respuesta.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={returnHome} className="DialogEvaluationBtnCancel">
            No
          </Button>
          <Button
            onClick={handleCloseDialog}
            className="DialogEvaluationBtnAcep"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL DESCRIPCIÓN DE PROCESO DE EVALUACIÓN */}
      <Dialog
        open={openDialogDescription}
        onClose={handleCloseDialogDescription}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="bold HeaderDialogEvaluation"
        >
          {/* <span></span> */}
          {"¡Selecciona el servicio que más te convenga!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="mt1">
            <span>
              ¿Tienes bolsos, zapatos y accesorios de Marcas de Lujo olvidados
              en tu clóset? ¡Ponlos en venta a través de nuestro servicio de
              Consigna! Nosotros nos encargamos de todo el proceso de venta por
              ti.
            </span>
          </DialogContentText>
          {/* <DialogContentText id="alert-dialog-description" className="mt2">
            <span>
              ¿Necesitas dinero al instante? Empeña bolsos PreLoved de marcas de
              lujo que estén en temporada, o bien, zapatos completamente nuevos
              y recibe un beneficio instantáneo.
            </span>
          </DialogContentText> */}
          <DialogContentText id="alert-dialog-description" className="mt2 flex">
            <span>
              <strong>¡Hazlo Ahora!</strong>
            </span>
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" className="mt2">
            <span>
              Llena el formulario de prevaluación, recibirás una propuesta en
              máximo 24 horas.
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="DialogEvaluationBtnAcep"
            onClick={handleCloseDialogDescription}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CuestionarioEmpConAdd2

import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { EMPEÑO_PAGO_DETALLES } from "../../../../Constants/ApiConstants"
import { postAbonoCreate } from "../../../../Actions/MundoBarterActions"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import IconButton from "@mui/material/IconButton"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import NumberFormat from "react-number-format"
import ModalCargoServicio from "../../ModalCargoServicio"

const AmountPayLiq = (props) => {
  const dispatch = useDispatch()

  const handleNext = (data) => {
    dispatch(postAbonoCreate(data))
    props.handleNext()
  }

  const [amountPay, setAmountCodi] = useState({
    fee: 0,
    totalPagar: 0,
  })
  const getData = () => {
    var data = JSON.parse(sessionStorage.getItem("liquidar"))
    console.log(data)
    if (data !== null) {
      setAmountCodi({
        ...amountPay,
        fee: data.fee,
        totalPagar: data.total,
      })
    }
  }
  useEffect(() => {
    console.log(props)
    getData()
  }, [])

  const [openModalTerminos, setModalTerminos] = useState(false)
  const handleOpenTerminos = () => {
    setModalTerminos(!openModalTerminos)
  }

  console.log(props.detalles)
  return (
    <>
      <p className="text-center fs30 bold">DETALLE DE PAGO</p>

      <div className="w90 center">
        <hr />

        <div className="line-height-10">
          <div className="flex w100 line-height-0">
            <p className="w50 title-pay-liq">Saldo pendiente</p>
            <p className="w50 subtitle-pay-liq">
              <NumberFormat
                value={
                  props.detalles.dblSaldoActual === 0 ? 0 : amountPay.totalPagar
                }
                displayType={"text"}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={"$ "}
                suffix={" DÓLARES"}
              />
            </p>
          </div>

          <div className="flex w100">
            <p className="w50 title-pay-liq">Total a pagar</p>
            <p className="w50 subtitle-pay-liq">
              <NumberFormat
                value={
                  props.detalles.dblSaldoActual === 0 ? 0 : amountPay.totalPagar
                }
                displayType={"text"}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={"$ "}
                suffix={" DÓLARES"}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="flex ">
        <Button
          className="btn-pay"
          disabled={props.detalles.dblSaldoActual === 0 ? true : false}
          onClick={function () {
            handleNext(amountPay)
          }}
        >
          Realizar pago
        </Button>
      </div>

      <ModalCargoServicio
        openModalImgPreview={openModalTerminos}
        onCloseModalImgPreview={handleOpenTerminos}
      />
    </>
  )
}
export default AmountPayLiq

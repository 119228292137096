import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import { useDispatch, useSelector } from "react-redux"
import NumberFormat from "react-number-format"

const AmountPayPrimerPagoRenta = (props) => {
  const dispatch = useDispatch()
  const handleNext = (data) => {
    // dispatch(postAbonoCreate(data))
    props.handleNext()
  }
  return (
    <>
      <p className="text-center fs30 bold">DETALLE DE PAGO</p>
      <div className="w90 center">
        <hr />
        <div>
          <div className="flex w100 line-height-0">
            <p className="w70">Precio de renta</p>
            <p className="w30">
              <NumberFormat
                value={(props.data.dblPrecioFinal * 15) / 100}
                displayType={"text"}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={"$ "}
                suffix={" DÓLARES"}
              />
            </p>
          </div>

          <div className="flex w100">
            <p className="w70">Cargo por servicio</p>
            <p className="w30">
              <NumberFormat
                value={((props.data.dblPrecioFinal * 15) / 100) * 0.03 + 3}
                displayType={"text"}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={"$ "}
                suffix={" DÓLARES"}
              />
            </p>
          </div>

          <div className="flex w100">
            <p className="w70">Total a pagar</p>
            <p className="w30">
              <NumberFormat
                value={
                  (props.data.dblPrecioFinal * 30) / 100 +
                  ((props.data.dblPrecioFinal * 30) / 100) * 0.03 +
                  3
                }
                displayType={"text"}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={"$ "}
                suffix={" DÓLARES"}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="flex ">
        <Button
          className="btn-pay"
          //onClick={handleNext}
          onClick={function () {
            handleNext()
          }}
        >
          Realizar pago
        </Button>
      </div>
    </>
  )
}

export default AmountPayPrimerPagoRenta

import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/ventasMb.css'
import { Fade } from 'react-awesome-reveal'
import LoginMb2 from '../../Login/LoginMb2'
import RegisterMb2 from '../../Login/RegisterMb2'
import {
  gtmCloseMundoBarterUNL,
  gtmOpenLoginUNL,
  gtmCloseLoginUNL,
  gtmOpenRegisterUNL,
  gtmCloseRegisterUNL,
} from '../../../Constants/GoogleTagManager/gtmConstants'

const VSection2Web = (props) => {
  console.log('Consiga')
  console.log(props.props)
  const [openModalMB, setOpenModalMB] = useState(false)
  const handleCloseModalMB = () => {
    gtmCloseMundoBarterUNL()
    setOpenModalMB(false)
  }
  //============== OPEN LOGIN =========================
  const [openLogin, setOpenLogin] = useState(false)
  const handleOpenlogin = () => {
    gtmOpenLoginUNL()
    setOpenLogin(!openLogin)
  }
  const handleCloseLogin = () => {
    gtmCloseLoginUNL()
    setOpenLogin(false)
  }

  //============== OPEN REGISTRO=========================
  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    gtmCloseRegisterUNL()
    setOpenSingUp(false)
  }
  return (
    <section className="VentasSection2MB">
      <div className="cardVSection2">
        <p>¿Cómo funciona?</p>
        <div className="ContainerInstructions">
          <Fade cascade damping={0.5} triggerOnce>
            <div className="contentHeight">
              <div className="body">
                <div className="number">
                  <span>1</span>
                </div>
                <div className="instruction">
                  <span className="title">Regístrate</span>
                  <span className="subtitle">
                    Inicia sesión. En caso de que aún no te hayas registrado,
                    crea tu cuenta y únete a nuestra comunidad.
                  </span>
                </div>
              </div>

              <div className="body">
                <div className="number">
                  <span>2</span>
                </div>
                <div className="instruction">
                  <span className="title">Completa</span>
                  <span className="subtitle">
                    Ingresa a la sección Vende{' '}
                    <a
                      onClick={function () {
                        handleOpenlogin()
                      }}
                    >
                      aquí
                    </a>{' '}
                    y completa el formulario detallado de tu pieza.
                    {/* Ingresa a la sección Vende o Empeña aquí y completa el
                    formulario detallado de tu pieza. */}
                    {/* <Button
                      onClick={function () {
                        handleOpenlogin();
                      }}
                      className="BtnHeaderMb2"
                    >
                      Iniciar sesión
                    </Button> */}
                  </span>
                </div>
              </div>
            </div>
            <div className="contentHeight2">
              <div className="body">
                <div className="number">
                  <span>3</span>
                </div>
                <div className="instruction">
                  <span className="title">Recibe</span>
                  <span className="subtitle">
                    Una evaluación profesional con el mejor precio del mercado
                    realizada por nuestros expertos valuadores
                  </span>
                </div>
              </div>

              <div className="body">
                <div className="number">
                  <span>4</span>
                </div>
                <div className="instruction">
                  <span className="title">Agenda</span>
                  <span className="subtitle">
                    Si aceptas nuestra propuesta agenda una cita en nuestra
                    tienda u oficina para presentar tus piezas
                  </span>
                  {/* <span className="subtitle"> *Válido solo en CDMX</span> */}
                </div>
              </div>
            </div>
            <div className="contentHeight3">
              <div className="body">
                <div className="number">
                  <span>5</span>
                </div>
                <div className="instruction">
                  <span className="title">
                    Exhibición y promoción de alto nivel
                  </span>
                  <span className="subtitle">
                    Nos encargamos de realizar una sesión fotográfica
                    profesional, publicar tu pieza en nuestra exclusiva tienda y
                    redes sociales, además de promoverla entre selectos
                    compradores
                  </span>
                </div>
              </div>

              <div className="body">
                <div className="number">
                  <span>6</span>
                </div>
                <div className="instruction">
                  <span className="title">Obtén</span>
                  <span className="subtitle">
                    Una vez que tu artículo se venda, recibirás el dinero de tus
                    ventas de manera rápida y segura
                  </span>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <LoginMb2 open={openLogin} onClose={handleCloseLogin} props={props} />
      <RegisterMb2
        open={openSingUp}
        onClose={handleCloseSingUp}
        props={props.props}
      />
    </section>
  )
}

export default VSection2Web

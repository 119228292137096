import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import { TextField } from '@mui/material'
import { json } from 'react-router-dom'
import TerminosPagoTar from '../../PrimerPago/PaymentComponentPrimerPago/TerminosPagoTar'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const AmountProximoPago = (props) => {
  const dispatch = useDispatch()
  const [_Monto, setMonto] = useState({
    dblMonto: 0,
    dblTotal: 0,
  })
  const handleNext = (data) => {
    props.handleNext()
  }
  const handleOblur = (event) => {
    let monto = _Monto.dblMonto
    sessionStorage.setItem('montoAbono', JSON.stringify(monto))
    // let impot = monto + monto * 0.03 + 3
    let impot = monto + ((monto * 3.5) / 100 + 0.5)
    setMonto({ ..._Monto, dblTotal: impot })
  }

  const [openModalTerminos, setModalTerminos] = useState(false)
  const handleOpenTerminos = () => {
    setModalTerminos(!openModalTerminos)
  }
  return (
    <div className="CardApartadoDetailPagosCardStepper">
      <div className="BodyCardApartadoDetail">
        <div className="ApartadoDetailSection2">
          <h2>Detalle de pago</h2>
          <hr className="Dvividerhr" />
          {/* ***************** */}
          <div className="containerFlexBetween">
            <span className="bold upperCase">
              <div className="containerSelectDetailPago">
                <span className="subtitlesdetail">Monto a pagar: </span>{' '}
              </div>
            </span>
            <span className="bold upperCase">
              <NumberFormat
                required
                value={_Monto.dblMonto}
                customInput={TextField}
                thousandSeparator={true}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={'$'}
                fullWidth
                onValueChange={(values) => {
                  setMonto({
                    ..._Monto,
                    dblMonto: values.floatValue,
                  })
                }}
                onBlur={handleOblur}
                name="dblMonto"
              />
            </span>
          </div>
          {/* ***************** */}
          <div className="containerFlexBetween">
            <span className="bold upperCase">
              <div className="containerSelectDetailPago">
                <span className="subtitlesdetail">Total a pagar: </span>{' '}
              </div>
            </span>
            <span className="bold upperCase">
              <div className="containerSelectDetailPago">
                <NumberFormat
                  value={_Monto.dblTotal.toFixed(4)}
                  // value={_Monto.dblTotal}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={'$ '}
                  suffix={' DÓLARES'}
                />
                <Button
                  className="BtnTermConditions2 "
                  sx={{ padding: '0 !important', minWidth: '0 !important' }}
                  onClick={handleOpenTerminos}
                >
                  <InfoOutlinedIcon />
                </Button>
              </div>
            </span>
          </div>
          <Button
            className="btn-pay"
            onClick={function () {
              handleNext()
            }}
          >
            Realizar pago
          </Button>
        </div>
      </div>
      <TerminosPagoTar
        openModalTerminos={openModalTerminos}
        onCloseModalTerminos={handleOpenTerminos}
      />
    </div>
    // <>
    // 	<p className='text-center fs30 bold'>DETALLE DE PAGO</p>
    // 	<div className='w90 center'>
    // 		<hr />
    // 		<div>
    // 			<div className='flex w100 line-height-0'>
    // 				<p className='w70'>Monto a pagar </p>
    // 				<p className='w30'>
    // 					<NumberFormat
    // 						required
    // 						value={_Monto.dblMonto}
    // 						customInput={TextField}
    // 						thousandSeparator={true}
    // 						fixedDecimalScale={true}
    // 						decimalScale={2}
    // 						prefix={'$'}
    // 						fullWidth
    // 						onValueChange={(values) => {
    // 							setMonto({
    // 								..._Monto,
    // 								dblMonto: values.floatValue,
    // 							})
    // 						}}
    // 						onBlur={handleOblur}
    // 						name='dblMonto'
    // 					/>
    // 				</p>
    // 			</div>

    // 			<div className='flex w100'>
    // 				<p className='w70'>Total a pagar</p>
    // 				<p className='w30'>
    // 					<NumberFormat
    // 						value={_Monto.dblTotal}
    // 						displayType={'text'}
    // 						fixedDecimalScale={true}
    // 						decimalScale={2}
    // 						thousandSeparator={true}
    // 						prefix={'$ '}
    // 						suffix={' MXN'}
    // 					/>
    // 					<Button
    // 						className='BtnTermConditions2 mr1'
    // 						onClick={handleOpenTerminos}
    // 					>
    // 						<InfoOutlinedIcon />
    // 					</Button>
    // 				</p>
    // 			</div>
    // 		</div>
    // 	</div>
    // 	<div className='flex '>
    // 		<Button
    // 			className='btn-pay'
    // 			onClick={function () {
    // 				handleNext()
    // 			}}
    // 		>
    // 			Realizar pago
    // 		</Button>
    // 	</div>
    // 	<TerminosPagoTar
    // 		openModalTerminos={openModalTerminos}
    // 		onCloseModalTerminos={handleOpenTerminos}
    // 	/>
    // </>
  )
}

export default AmountProximoPago

/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog } from '@mui/material'
import '../Styles/Styles.css'
import Slide from '@mui/material/Slide'
import imgbarter from '../Images/Home/Registrerv2.jpeg'
import LBL from '../Images/logo-barter-sin-fondo.png'

import { gtmCloseMundoBarterUNL } from '../Constants/GoogleTagManager/gtmConstants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ModalMundoBarter = (props) => {
  const handleClose = () => {
    gtmCloseMundoBarterUNL()
    props.onClose()
  }
  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose ? props.onClose : null}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(10px)',
          },
        }}
      >
        <div className="modal-LoginMB">
          <Box component="form" autoComplete="off" className="h100">
            <div className="container-modalMB">
              <div className="section1MB">
                <img className="imgModalMB" src={imgbarter} />
              </div>

              <div className="section2MB">
                <div className="flex-end mt1">
                  <Button onClick={handleClose}>
                    <CloseIcon
                      fontSize="small"
                      className="btn-close-icon-modal font-dark"
                      sx={{ fontSize: 40, color: 'black' }}
                    />
                  </Button>
                </div>

                <div className="flex-column w100">
                  <img className="mt3 w40" src={LBL} />
                  <div className="flex-column-start my3  w90">
                    <span className="h2 mb1">
                      ¡Bienvenido a FoundIt Members!
                    </span>
                    <span className="p co-grey">
                      FoundIt Members es la plataforma que te acompañará en tu
                      proceso de consigna y empeño, buscando siempre el mejor
                      destino para las piezas que atesoras.
                    </span>
                  </div>
                  <div className="flex-column-start my1 w90">
                    <span className="p co-grey">
                      Ahí mismo, podrás gestionar y administrar los procesos que
                      tengas actualmente con nosotros.
                    </span>
                  </div>
                  <div className="flex-column-start my1 w90">
                    <span className="p co-grey">
                      ¡Sé parte del FoundIt Members!
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Dialog>
    </div>
  )
}

export default ModalMundoBarter

/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'

import '../Styles/App/Footer/Footer.css'
import { useDispatch, useSelector } from 'react-redux'
// import imgBarterFooter from '../Images/logo-barter-sin-fondo.webp'
import imgBarterFooter from '../Images/logo-barter-sin-fondo.png'
//import fiserVIcon from '../Images/fiserVIcon.png'
import ClinIcon from '../Images/logoClin.png'
import openPay from '../Images/openPayIcon.png'

import facebook from '../Images/Icons/facebook.svg'
import instagram from '../Images/Icons/instagram.svg'
import tiktok from '../Images/Icons/tiktok.svg'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import {
  MB_TERMINOS_CONDICIONES,
  MB_AVISO_PRIVACIDAD,
  MB_FAQS,
  MB_FACTURACION,
} from '../Constants/routesConstants'

import {
  gtmPreevaluacion,
  gtmVendeUNL,
  gtmEmpenaeUNL,
  gtmGoShopyUNL,
  gtmTerminosUNL,
  gtmAvisosUNL,
  gtmGoDevolucionesUNL,
  gtmGoFaqsUNL,
  gtmGoFacebookUNL,
  gtmGoInstagramUNL,
  gtmGoTikTokUNL,
  gtmGoTo_ProcesoAutenticacion_Log,
  gtmApartaUNL,
  gtmFacturacionUNL,
} from '../Constants/GoogleTagManager/gtmConstants'

const Footer = () => {
  const navigate = useNavigate()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const ventasUNL = () => {
    gtmVendeUNL()
    navigate('/consigna')
  }

  const empenosUNL = () => {
    gtmEmpenaeUNL()
    navigate('/empeno')
  }
  const apartarUNL = () => {
    gtmApartaUNL()
    navigate('/apartar')
  }
  const facturacionUNL = () => {
    gtmFacturacionUNL()
    navigate('/facturacion')
  }
  const preevaluacion = () => {
    gtmPreevaluacion()
    navigate('/con-emp')
  }

  return (
    <div className="ContainerFooter">
      <div className="cardFooter">
        <div className="sectionf1">
          <div className="columns">
            <p className="title">Servicios</p>
            <Button
              onClick={function () {
                userInfo ? preevaluacion() : ventasUNL()
              }}
              className="BtnHeaderMb"
            >
              Ventas
            </Button>
            {/* <Button
              onClick={function () {
                userInfo ? preevaluacion() : empenosUNL();
              }}
              className="BtnHeaderMb"
            >
              Empeños
            </Button> */}
            <Button
              onClick={function () {
                userInfo ? preevaluacion() : apartarUNL()
              }}
              className="BtnHeaderMb"
            >
              Abonos
            </Button>
            <Button
              onClick={function () {
                gtmGoShopyUNL()
                window.open('https://pa.founditpreloved.com/', '_blank')
              }}
              className="BtnHeaderMb"
            >
              Tienda en línea
            </Button>
          </div>
          <div className="columns">
            <p className="title">Legales</p>
            <Button
              onClick={function () {
                facturacionUNL()
                navigate(MB_FACTURACION)
              }}
              className="BtnHeaderMb"
            >
              Facturación
            </Button>
            <Button
              onClick={function () {
                gtmGoFaqsUNL()
                navigate(MB_FAQS)
              }}
              className="BtnHeaderMb"
            >
              FAQs
            </Button>
            <Button
              onClick={function () {
                gtmAvisosUNL()
                navigate(MB_AVISO_PRIVACIDAD)
              }}
              className="BtnHeaderMb"
            >
              Aviso de privacidad
            </Button>
            <Button
              onClick={function () {
                gtmTerminosUNL()
                navigate(MB_TERMINOS_CONDICIONES)
              }}
              className="BtnHeaderMb"
            >
              Términos y condiciones
            </Button>
            <Button
              onClick={function () {
                gtmGoDevolucionesUNL()
                window.open(
                  'https://pa.founditpreloved.com/pages/devoluciones-y-garantias',
                  '_blank'
                )
              }}
              className="BtnHeaderMb"
            >
              Devoluciones y garantías
            </Button>
          </div>

          <div className="columnscontact">
            <p className="title">Contacto</p>
            <div className="columninfo">
              <span className="spanHeaderMb">
                foundit.pa@founditpreloved.com
              </span>
              <span className="spanHeaderMb">63-59-03-18</span>
              <span className="spanHeaderMb">
                Edificio Casa Cielo, Planta Baja. Calle 3ra Este, San Felipe,
                Casco Viejo.
              </span>
              <span className="spanHeaderMb">Lunes a Viernes 9h a 6h</span>
              <span className="spanHeaderMb">Sábado 9h a 5h</span>
            </div>
          </div>
        </div>
        <Divider className="DividerFooter" />
        <div className="sectionf2">
          <img src="https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png" />
          <div className="redesSociales">
            <IconButton
              onClick={function () {
                gtmGoFacebookUNL()
                window.open(
                  'https://www.facebook.com/profile.php?id=61561594710798&locale=es_LA',
                  '_blank'
                )
              }}
            >
              <img src="https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/Facebook_1.png" />
            </IconButton>
            <IconButton
              onClick={function () {
                gtmGoInstagramUNL()
                window.open(
                  'https://www.instagram.com/foundit_preloved/?igsh=NjN2cWdkZnZpd250',
                  '_blank'
                )
              }}
            >
              <img src="https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/Instagram_1.png" />
            </IconButton>

            <IconButton
              onClick={function () {
                gtmGoTikTokUNL()
                window.open('https://www.tiktok.com/@foundit_preloved?_t=8oVpfdt9tsE&_r=1', '_blank')
              }}
            >
              <img src="https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/TikTok_1.png" />
            </IconButton>
          </div>
          <span className="derechosR">Derechos Reservados Foundit 2024</span>
        </div>
      </div>
    </div>
    // <div className="footer-Container">
    //   <div className="displayBlockInTablets">
    //     <img
    //       src="https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png"
    //       width="220px"
    //     />
    //   </div>
    //   <div className="footer-body">
    //     <div className="footer-Sections-body-IMG displayNoneInTablets">
    //       <img
    //         src="https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png"
    //         width="220px"
    //       />
    //     </div>
    //     <div className="footer-Sections-body line-height-4">
    //       <ul>
    //         <dt className="title-list-footer">
    //           <h2 className="title-list-footer">Servicios</h2>
    //         </dt>

    //         {userInfo ? (
    //           <dt className="item-list-footer ProductTitleModelo">
    //             <Link
    //               color="inherit"
    //               underline="none"
    //               sx={{ cursor: "pointer" }}
    //               onClick={function () {
    //                 {
    //                   userInfo ? preevaluacion() : ventasUNL();
    //                 }
    //               }}
    //             >
    //               Vende o Empeña tus artículos de lujo
    //             </Link>
    //           </dt>
    //         ) : (
    //           <>
    //             <dt className="item-list-footer ProductTitleModelo">
    //               <Link
    //                 color="inherit"
    //                 underline="none"
    //                 sx={{ cursor: "pointer" }}
    //                 onClick={function () {
    //                   {
    //                     userInfo ? preevaluacion() : ventasUNL();
    //                   }
    //                 }}
    //               >
    //                 Vende
    //               </Link>
    //             </dt>
    //             <dt className="item-list-footer ProductTitleModelo">
    //               <Link
    //                 color="inherit"
    //                 underline="none"
    //                 sx={{ cursor: "pointer" }}
    //                 onClick={function () {
    //                   {
    //                     userInfo ? preevaluacion() : empenosUNL();
    //                   }
    //                 }}
    //               >
    //                 Empeña
    //               </Link>
    //             </dt>
    //           </>
    //         )}

    //         <dt className="item-list-footer ProductTitleModelo">
    //           <Link
    //             color="inherit"
    //             underline="none"
    //             sx={{ cursor: "pointer" }}
    //             onClick={function () {
    //               gtmGoShopyUNL();
    //               window.open("https://pa.founditpreloved.com/", "_blank");
    //             }}
    //           >
    //             Comprar en nuestra tienda
    //           </Link>
    //         </dt>
    //         <dt className="item-list-footer ProductTitleModelo">
    //           <Link
    //             color="inherit"
    //             underline="none"
    //             sx={{ cursor: "pointer" }}
    //             onClick={function () {
    //               gtmGoTo_ProcesoAutenticacion_Log();
    //               window.open(
    //                 "https://pa.founditpreloved.com/pages/proceso-de-autentificacion",
    //                 "_blank"
    //               );
    //             }}
    //           >
    //             Autenticidad
    //           </Link>
    //         </dt>
    //         <dt className="item-list-footer ProductTitleModelo">
    //           <Link
    //             color="inherit"
    //             underline="none"
    //             sx={{ cursor: "pointer" }}
    //             onClick={function () {
    //               window.open(
    //                 "https://pa.founditpreloved.com/blogs/noticias",
    //                 "_blank"
    //               );
    //             }}
    //           >
    //             Barter Blog
    //           </Link>
    //         </dt>
    //       </ul>
    //     </div>
    //     <div className="footer-Sections-body line-height-4">
    //       <ul>
    //         <dt className="title-list-footer">
    //           <h2 className="title-list-footer">SOBRE NOSOTROS</h2>
    //         </dt>
    //         <dt className="item-list-footer ProductTitleModelo">
    //           <Link
    //             color="inherit"
    //             underline="none"
    //             onClick={function () {
    //               window.open(
    //                 "https://pa.founditpreloved.com/pages/facturar",
    //                 "_blank"
    //               );
    //             }}
    //             sx={{ cursor: "pointer" }}
    //           >
    //             Facturar
    //           </Link>
    //         </dt>
    //         <dt className="item-list-footer ProductTitleModelo">
    //           <Link
    //             color="inherit"
    //             underline="none"
    //             onClick={function () {
    //               gtmGoFaqsUNL();
    //               navigate(MB_FAQS);
    //             }}
    //             sx={{ cursor: "pointer" }}
    //           >
    //             FAQS
    //           </Link>
    //         </dt>
    //         <dt className="item-list-footer ProductTitleModelo">
    //           <Link
    //             color="inherit"
    //             underline="none"
    //             onClick={function () {
    //               gtmAvisosUNL();
    //               navigate(MB_AVISO_PRIVACIDAD);
    //             }}
    //             sx={{ cursor: "pointer" }}
    //           >
    //             Avisos de privacidad
    //           </Link>
    //         </dt>
    //         <dt className="item-list-footer ProductTitleModelo">
    //           <Link
    //             color="inherit"
    //             underline="none"
    //             onClick={function () {
    //               gtmTerminosUNL();
    //               navigate(MB_TERMINOS_CONDICIONES);
    //             }}
    //             sx={{ cursor: "pointer" }}
    //           >
    //             Términos y condiciones
    //           </Link>
    //         </dt>

    //         <dt className="item-list-footer ProductTitleModelo">
    //           <Link
    //             color="inherit"
    //             underline="none"
    //             onClick={function () {
    //               gtmGoDevolucionesUNL();
    //               window.open(
    //                 "https://pa.founditpreloved.com/pages/devoluciones-y-garantias",
    //                 "_blank"
    //               );
    //             }}
    //             sx={{ cursor: "pointer" }}
    //           >
    //             Devoluciones y garantías
    //           </Link>
    //         </dt>

    //         <dt className="item-list-footer ProductTitleModelo"></dt>
    //       </ul>
    //     </div>
    //     <div className="footer-Sections-body line-height-4">
    //       <ul>
    //         <dt className="title-list-footer">
    //           <h2 className="title-list-footer">CONTACTO</h2>
    //         </dt>
    //         <dt className="item-list-footer">
    //           <span>contacto@barterluxe.com.mx</span>
    //         </dt>
    //         <dt className="item-list-footer">
    //           <span>56-4181-9020</span>
    //         </dt>
    //         <dt className="item-list-footer mt1">
    //           <span>Río Nazas #34, piso 2, Col. Cuauhtémoc CDMX</span>
    //         </dt>
    //         <dt className="item-list-footer mt1">
    //           <span>Lunes - Viernes</span>
    //         </dt>
    //         <dt className="item-list-footer">
    //           <span>09:00am a 18:00pm</span>
    //         </dt>
    //       </ul>
    //     </div>
    //     <div className="footer-Sections-body line-height-4">
    //       <ul>
    //         <dt>
    //           <h2 className="title-list-footer">REDES SOCIALES</h2>
    //         </dt>
    //         <div>
    //           <IconButton
    //             aria-label="delete"
    //             onClick={function () {
    //               gtmGoFacebookUNL();
    //               window.open(
    //                 "https://www.facebook.com/people/Barter-Luxe-MX/100064095422771/",
    //                 "_blank"
    //               );
    //             }}
    //           >
    //             <img src={facebook} className="icon-btn-social" />
    //           </IconButton>
    //           <IconButton
    //             aria-label="delete"
    //             onClick={function () {
    //               gtmGoInstagramUNL();
    //               window.open(
    //                 "https://www.instagram.com/barterluxe_",
    //                 "_blank"
    //               );
    //             }}
    //           >
    //             <img src={instagram} className="icon-btn-social" />
    //           </IconButton>
    //           <IconButton
    //             aria-label="delete"
    //             onClick={function () {
    //               gtmGoTikTokUNL();
    //               window.open(
    //                 "https://www.tiktok.com/@barterluxe_",
    //                 "_blank"
    //               );
    //             }}
    //           >
    //             <img src={tiktok} className="icon-btn-social" />
    //           </IconButton>
    //         </div>
    //       </ul>
    //     </div>
    //   </div>
    //   <Divider className="DividerFooter" />
    //   <div className="footerDivider mt-10">
    //     <div className="footerDividerSection mt1 flex gap2">
    //       <img src={fiserVIcon} />
    //     </div>
    //     <div className="footerDividerSection mt1">
    //       <div className="center">
    //         <span>© {new Date().getFullYear()} Foundit</span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default Footer

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { ThemeProvider } from '@mui/styles'
import Theme from '../../../Styles/Theme'
import {
  Box,
  Button,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import NumberFormat from 'react-number-format'
import { Chip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import EventIcon from '@mui/icons-material/Event'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import PreviewImg from '../../../Components/PreviewImg'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import {
  PRODUCTOS_DETAILS_BY_SKU,
  PRODUCTOS_DETAILS_BY_SKUP,
} from '../../../Constants/ApiConstants'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
//import LoginMbApartado from '../../../Components/Login/LoginMbApartado'
import { NoBackpackSharp } from '@mui/icons-material'
import LoginMb from '../../../Components/Login/LoginMb'
import LoginMbApartado from '../../../Components/Login/LoginMbApartado'
import TyCApartado from './TyCApartado'
import TerminosApartado from './TerminosApartado'
import '../../../Styles/App/Apartado/ApartadoDetail.css'
// import QRCode from 'qrcode.react';

const ApartadoDetalleShopify = (props) => {
  //const { id } = useParams()
  const navigate = useNavigate()
  const [_Producto, setProducto] = useState([])
  const valores = useLocation()
  var url = valores.search
  const SKU = url.slice(5)
  useEffect(() => {
    getRegistro()
  }, [])

  const getRegistro = () => {
    props.setOpenLoadingScreen()
    requests
      .get(PRODUCTOS_DETAILS_BY_SKUP, SKU)
      .then((response) => {
        setProducto(response)
        sessionStorage.setItem('sku', JSON.stringify(SKU))
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const rechazar = () => {
    sessionStorage.removeItem('sku', JSON.stringify(SKU))
    window.location.href = 'https://pa.founditpreloved.com/'
  }
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  //abrir modal img
  const [IdImgPreview, setIdImgPreview] = useState()
  const [openModalImgPreview, setModalImgPreview] = useState(false)
  const handleOpenImgPreview = () => {
    setModalImgPreview(!openModalImgPreview)
  }

  const [openModalTerminos, setModalTerminos] = useState(false)
  const handleOpenTerminos = () => {
    setModalTerminos(!openModalTerminos)
  }

  const [openTyC, setOpenTyC] = useState(false)
  const handleOpenTyC = () => {
    setOpenTyC(!openTyC)
  }
  return (
    <>
      <div className="Container-Apartado-Detail">
        <div className="CardApartadoDetail">
          <div className="BodyCardApartadoDetail">
            <div className="ApartadoDetailSection1">
              <div
                className="BoxImgADetail"
                style={{
                  backgroundImage: `url(${_Producto.txtUrl})`,
                }}
              >
                <div className="IconEyeDetail">
                  <IconButton
                    className="btn-blur"
                    onClick={function () {
                      setIdImgPreview(_Producto.txtUrl)
                      handleOpenImgPreview()
                    }}
                  >
                    <RemoveRedEyeIcon sx={{ m: 0, p: 0, color: '#000000' }} />
                  </IconButton>
                </div>
              </div>
              {window.screen.width <= 600 ? null : (
                <>
                  <Button
                    className="btn-success  w100"
                    startIcon={<CheckCircleOutlineOutlinedIcon />}
                    onClick={function () {
                      handleOpen()
                    }}
                  >
                    <span>Abonar</span>
                  </Button>
                  <Button
                    className="btn-fail  w100"
                    startIcon={<CancelOutlinedIcon />}
                    onClick={rechazar}
                  >
                    <span>No me interesa</span>
                  </Button>
                </>
              )}
            </div>

            <div className="ApartadoDetailSection2">
              <h2>Detalle del producto</h2>
              <hr className="Dvividerhr" />
              {/* ***************** */}
              <div className="containerFlexBetween">
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Producto:</span>{' '}
                  {_Producto.txtProductoH === null
                    ? _Producto.txtProductoM + ' ' + _Producto.intIdMarca
                    : _Producto.txtProductoH + ' ' + _Producto.intIdMarca}
                </span>
                <span className="bold upperCase">
                  <span className="subtitlesdetail">SKU:</span>{' '}
                  {_Producto.txtSkuReg}{' '}
                </span>
              </div>
              {/* ***************** */}
              <div className="containerFlexBetween">
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Marca:</span>{' '}
                  {_Producto.intIdMarca}
                </span>
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Color:</span>{' '}
                  {_Producto.intIdColor}
                </span>
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Material:</span>{' '}
                  {_Producto.intIdMaterial}
                </span>
              </div>
              {/* ***************** */}
              <div className="containerFlexBetween">
                {_Producto.txtAlturaPlataformaZa === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">
                      Altura / Plataforma:
                    </span>{' '}
                    {_Producto.txtAlturaPlataformaZa}
                  </span>
                )}
                {_Producto.txtTallaZa === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Tall:</span>{' '}
                    {_Producto.txtTallaZa}
                  </span>
                )}
                {_Producto.txtAnchoBol === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Ancho:</span>{' '}
                    {_Producto.txtAnchoBol}
                  </span>
                )}
                {_Producto.txtLargoBol === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Largo:</span>{' '}
                    {_Producto.txtLargoBol}
                  </span>
                )}
                {_Producto.txtProfundidadBol === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Profundidad:</span>{' '}
                    {_Producto.txtProfundidadBol}
                  </span>
                )}
                {_Producto.txtAnchoCin === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Ancho:</span>{' '}
                    {_Producto.txtAnchoCin}
                  </span>
                )}
                {_Producto.txtLargoCin === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Largo:</span>{' '}
                    {_Producto.txtLargoCin}
                  </span>
                )}
                {_Producto.txtAnchoCo === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Ancho:</span>{' '}
                    {_Producto.txtAnchoCo}
                  </span>
                )}
                {_Producto.txtLargoCo === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Largo:</span>{' '}
                    {_Producto.txtLargoCo}
                  </span>
                )}
                {_Producto.txtFormaGa === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Forma:</span>{' '}
                    {_Producto.txtFormaGa}
                  </span>
                )}
                {_Producto.txtAnchoGa === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Ancho:</span>{' '}
                    {_Producto.txtAnchoGa}
                  </span>
                )}
                {_Producto.txtAltoGa === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Alto:</span>{' '}
                    {_Producto.txtAltoGa}
                  </span>
                )}
                {_Producto.txtVarillaGa === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Varilla:</span>{' '}
                    {_Producto.txtVarillaGa}
                  </span>
                )}
                {_Producto.txtPuenteGa === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Puente:</span>{' '}
                    {_Producto.txtPuenteGa}
                  </span>
                )}
                {_Producto.txtAnchoMa === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Ancho:</span>{' '}
                    {_Producto.txtAnchoMa}
                  </span>
                )}
                {_Producto.txtTallaMedidaRo === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Talla/Tamaño:</span>{' '}
                    {_Producto.txtTallaMedidaRo}
                  </span>
                )}

                {_Producto.txtLargoMa === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Largo:</span>{' '}
                    {_Producto.txtLargoMa}
                  </span>
                )}
                {_Producto.bolKilatajeJo === false ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Kilataje:</span> Si
                  </span>
                )}
                {_Producto.txtMedidaJo === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Medida Joyeria:</span>{' '}
                    {_Producto.txtMedidaJo}
                  </span>
                )}
                {_Producto.bolPiedrasJo === false ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Piedras:</span> Si
                  </span>
                )}
                {_Producto.txtSerieJo === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Serie:</span>{' '}
                    {_Producto.txtSerieJo}
                  </span>
                )}
                {_Producto.txtLeyJo === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Ley:</span>{' '}
                    {_Producto.txtLeyJo}
                  </span>
                )}
                {_Producto.bolKilatajeRe === false ||
                _Producto.bolKilatajeRe === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Kilataje:</span> Si
                  </span>
                )}
                {_Producto.txtMaterialRe === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Material:</span>{' '}
                    {_Producto.txtMaterialRe}
                  </span>
                )}
                {_Producto.txtModeloPulseraRe === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Ley:</span>{' '}
                    {_Producto.txtModeloPulseraRe}
                  </span>
                )}
                {_Producto.txtTipoMaquinariaRe === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Tipo Maquinaria:</span>{' '}
                    {_Producto.txtTipoMaquinariaRe}
                  </span>
                )}
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Estuche:</span>{' '}
                  {_Producto.bolEstuche === false ? 'NO' : 'SÍ'}
                </span>
              </div>

              {/* ***************** */}

              <div className="containerFlexBetween">
                <span className="bold upperCase containerFlexColum">
                  <span className="subtitlesdetail">Precio total:</span>{' '}
                  <span className="bold upperCase textPrecio">
                    <NumberFormat
                      value={_Producto.dblPrecioFinal}
                      displayType={'text'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                </span>
                <span className="bold upperCase containerFlexColum">
                  <span className="subtitlesdetail">
                    Apártalo desde el 30%:
                  </span>{' '}
                  <span className="bold upperCase textPrecio textPrecioApartado">
                    <NumberFormat
                      value={_Producto.dblPrecioFinal * 0.3}
                      displayType={'text'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                </span>
              </div>
              <hr className="Dvividerhr" />
              {window.screen.width <= 600 ? (
                <>
                  <Button
                    className="btn-success mr1 w100"
                    startIcon={<CheckCircleOutlineOutlinedIcon />}
                    onClick={function () {
                      handleOpen()
                    }}
                  >
                    <span>Apartar</span>
                  </Button>
                  <Button
                    className="btn-fail mr1 w100"
                    startIcon={<CancelOutlinedIcon />}
                    onClick={rechazar}
                  >
                    <span>No me interesa</span>
                  </Button>
                </>
              ) : null}
              <div className="containerFlexStart">
                <span className="p co-grey">
                  <Button
                    className="BtnTermConditions2 mr1"
                    startIcon={<InfoOutlinedIcon />}
                    onClick={handleOpenTyC}
                  >
                    <span>Reglas de abonado</span>
                  </Button>
                </span>
                <span className="p co-grey">
                  <Button
                    className="BtnTermConditions2 mr1"
                    startIcon={<InfoOutlinedIcon />}
                    onClick={handleOpenTerminos}
                  >
                    <span>Términos y condiciones</span>
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoginMbApartado
        open={open}
        onClose={handleOpen}
        getRegistros={getRegistro}
        props={props}
      />
      <PreviewImg
        IdImgPreview={IdImgPreview}
        openModalImgPreview={openModalImgPreview}
        onCloseModalImgPreview={handleOpenImgPreview}
      />
      <TyCApartado openModalTyC={openTyC} onCloseModalTyC={handleOpenTyC} />
      <TerminosApartado
        openModalTerminos={openModalTerminos}
        onCloseModalTerminos={handleOpenTerminos}
      />
    </>
  )
}
export default ApartadoDetalleShopify

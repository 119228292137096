import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logoBL from '../../Images/logoWhats.jpg'

const FloatinfWhatsApp = (props) => {
  return (
    <FloatingWhatsApp
      phoneNumber="63590318"
      accountName="Foundit"
      avatar={logoBL}
      statusMessage="Normalmente respondemos en menos de 1 hora"
      chatMessage="👋¡Hola! Bienvenid@ a Foundit, voy a asesorarte el día de hoy. ¡Dime cómo puedo ayudarte!"
      placeholder="Escribe tu mensaje"
      allowClickAway={true}
      allowEsc={true}
      buttonStyle={{ bottom: '1rem', right: '12px' }}
      zIndex={10}
      // buttonStyle={{ bottom: '6rem', right: '12px' }}
    />
  )
}

export default FloatinfWhatsApp

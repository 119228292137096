import React, { useEffect, useState } from "react"
import { ThemeProvider } from "@emotion/react"
import { Routes, Route, BrowserRouter } from "react-router-dom"
import { useSelector } from "react-redux"

import HeaderLayoutCliente from "../../Layout/HeaderLayoutCliente"
import MainContainerLayout from "../../Layout/MainContainerLayout"
import FloatinfWhatsApp from "../../Components/FloatingWhatsApp/FloatinfWhatsApp"
import HomeMundoBarter2 from "./../../Components/HomeMundoBarter2"
import ScrollTop from "./../../ScrollTop"
import PagosCertificados from "./../../Views/PagosExtraudinarios/PagoCertificados/PagosCertificados"
import TermsConditions from "./../../Layout/TermsConditions"
import PagosExtraudinarios from "./../../Views/PagosExtraudinarios/PagosExtraudinarios"
import PagosGuias from "./../../Views/PagosExtraudinarios/PagosGuias/PagosGuias"
import EnviosView from "./../../Views/Envios/EnviosView"
import EnviosDetalles from "./../../Views/Envios/EnviosDetalles"
import EnviosEnTransito from "./../../Views/Envios/EnviosEnTransito"
import EnviosRecibidos from "./../../Views/Envios/EnviosRecibidos"
import CuestionarioEmpConAdd from "./../../Components/Cuestionarios/CuestionarioEmpConAdd"
import ClienteEmpenoViewMB from "./../../Views/Clientes/Empeños/ClienteEmpenoViewMB"
import {
  INICIO,
  CLIENTES_SING_UP,
  CUESTIONARIO,
  CANCELACIONES,
  PREEVALUACIONES_DETAILS,
  CANCELACIONES_VIEW,
  CANCELACIONES_DETAILS,
  CLIENTES_SECTION_VIEW,
  CLIENTES_CONSIGNAS,
  CLIENTES_CONSIGNAS_PIEZAS,
  CLIENTES_PROFILE,
  CLIENTES_SECTION_PRO,
  CLIENTES_SECTION_ENT,
  CLIENTES_SECTION_GUI,
  CLIENTES_SECTION_TRA,
  CLIENTES_SECTION_WISH,
  CLIENTES_CONSIGNAS_GUI,
  CLIENTES_CONSIGNAS_TRA,
  CLIENTES_CONSIGNAS_REC,
  CLIENTES_CONSIGNAS_GUIEX,
  CLIENTES_CONSIGNAS_VENTAS,
  CLIENTES_CONSIGNAS_EN_VENTA,
  CLIENTES_CONSIGNAS_RECHAZO,
  CLIENTES_CONSIGNAS_LLEGADOS,
  CLIENTES_EMP_VIEW,
  CLIENTES_EMP,
  CLIENTES_EMP_PRO_VIEW,
  CLIENTES_EMP_PROPUESTAS_VIEW,
  CLIENTES_EMP_REFRENDO_VIEW,
  CLIENTES_EMP_DESEMPEÑO_VIEW,
  CLIENTES_EMP_REFRENDO,
  CLIENTES_EMP_DESEMPEÑO,
  CLIENTES_CON_PROPUESTAS_VIEW,
  FLUJO_PAGO,
  FLUJO_RENTAR,
  CLIENTES_SECTION_PRO_VIEW,
  CLIENTES_SECTION_FIRST_PRO_VIEW,
  PREVALUACIONES_DIN_DETAILS,
  FLUJO_REFRENDO,
  FLUJO_LIQUIDAR,
  FLUJO_APARTAR,
  CITAS_MB,
  CITAS_MB_EMPEÑO,
  CITAS_MB_DESEMPEÑO,
  CITAS_MB_CONSIGNA,
  CITAS_MB_INTERCAMBIO,
  CITAS_MB_RENTA,
  CLIENTES_CONSIGNAS_VIEW,
  CLIENTES_INTERCAMBIO_VIEW,
  CLIENTES_RENT_VIEW,
  CLIENTES_INTERCAMBIO_PROPUESTAS_VIEW,
  CLIENTES_INTERCAMBIO_PROD_VIEW,
  CLIENTES_RENT_PROD_VIEW,
  CLIENTES_RENT_WISH_LIST_PROD_VIEW,
  PAGOS_EXTRAUDINARIOS,
  ENVIO_VIEW,
  ENVIO_DETAIL,
  ENVIOS_EN_TRANSITO,
  ENVIOS_RECIBIDOS,
  CLIENTES_SECTION,
  CLIENTES_RENT,
  PAGOS_EXTRAUDINARIOS_GUIAS,
  PAGOS_EXTRAUDINARIOS_CERTIFICADOS,
  //mundo barter
  MB_CONSIGNA,
  MB_INTERCAMBIA,
  MB_RENTA,
  MB_EMPENO,
  MB_APARTADO,
  CLIENTES_RENT_FIRST_PROD_VIEW,
  FLUJO_GUIAS,
  FLUJO_CERTIFICADOS,
  MB_TERMINOS_CONDICIONES,
  MB_AVISO_PRIVACIDAD,
  MB_FAQS,
  CLIENTES_CONFIRMA_MAIL,
  CALENDAR_VIEW,
  CALENDAR_VIEW_RESCHEDULE,
  CALENDAR_VIEW_MB,
  CALENDAR_VIEW_RMB,
  FLUJO_REFRENDAR,
  CITAS_MB_CON_VENCIDAS,
  CALENDAR_VIEW_CON_VEN_MB,
  CALENDAR_VIEW_CON_VEN_RMB,
  MB_COOMING_SOON,
  ENCUESTA_SATISFACCION,
  ENVIO_PIEZAS_DHL,
  ENVIO_PIEZAS_OTRA_PAQUETERIA,
  ENVIO_EVALUACION,
  CALENDAR_VIEW_CON_FOR_MB,
  CALENDAR_VIEW_CON_FOR_RMB,
  CITAS_MB_CONSIGNA_FORANEA,
  PROD_APARTADO_DETAILS,
  PROD_APARTADO_DETAILS2,
  CLIENTES_SECTION_PRO_PAY,
  MB_FACTURACION
} from "../routesConstants"
import ClienteEmpeñoView from "../../Views/Clientes/Empeños/ClienteEmpeñoView"
import ClienteEmpeñoRefrendar from "../../Views/Clientes/Empeños/ClienteEmpeñoRefrendar"
import ClienteEmpeñoDesempeño from "../../Views/Clientes/Empeños/ClienteEmpeñoDesempeño"
import EmpeñoProView from "../../Views/Clientes/Empeños/EmpeñoProView"
import CitasView from "../../Views/Clientes/Citas/CitasView"
import CitasEmpeño from "../../Views/Clientes/Citas/CitasEmpeño"
import CitasDesempeño from "../../Views/Clientes/Citas/CitasDesempeño"
import CitasConVencidas from "../../Views/Clientes/Citas/CitasConVencidas"
import EmpeñoPropuestas from "../../Views/Clientes/Empeños/EmpeñoPropuestas"
import SolicitarGuiaDhlAdd from "../../Components/Envios/Solicitudes/SolicitarGuiaDhlAdd"
import OtraPaqueteriaAdd from "../../Components/Envios/OtraPaqueteria/OtraPaqueteriaAdd"
import CalendarScreen from "../../Components/Calendario/CalendarScreen"
import CalendarReschedule from "../../Components/Calendario/CalendarReschedule"
import CalendarMb from "../../Components/Calendario/CanlendarMb"
import CalendarRmb from "../../Components/Calendario/CalendarRmb"
import CalendarConVenMb from "../../Components/Calendario/CalendarConVenMb"
import CalendarConVenRMb from "../../Components/Calendario/CalendarConVenRMb"
import EmpeñoRefrendo from "../../Views/Clientes/Empeños/EmpeñoRefrendo"
import EmpeñoDesempeño from "../../Views/Clientes/Empeños/EmpeñoDesempeño"
import PaymentFlow from "../../Components/Pagos/PaymentFlow"
import PaymentFlowRef from "../../Components/Pagos/Refrendos/PaymentFlowRef"
import PaymentFlowLiq from "../../Components/Pagos/Liquidar/PaymentFlowLiq"
import PaymentFlowGuias from "../../Components/Pagos/Guias/PaymentFlowGuias"
import PaymentFlowCerticados from "../../Components/Pagos/Certificados/PaymentFlowCerticados"
import PaymentFlowRefrendar from "../../Components/Pagos/Refrendar/PaymentFlowRefrendar"
import ClientesConsignaPiezasView from "../../Views/Clientes/Consignas/ClientesConsignaPiezasView"
import ClientesConsignaView from "../../Views/Clientes/Consignas/ClientesConsignaView"
import ConsignaPropuestas from "../../Views/Clientes/Consignas/ConsignaPropuestas"
import ConsignaProView from "../../Views/Clientes/Consignas/ConsignaProView"
import ConsignaGuia from "../../Views/Clientes/Consignas/ConsignaGuia"
import ConsignaGuiaExpira from "../../Views/Clientes/Consignas/ConsignaGuiaExpira"
import ConsignaRecibido from "../../Views/Clientes/Consignas/ConsignaRecibido"
import ConsignaTransito from "../../Views/Clientes/Consignas/ConsignaTransito"
import ConsignaEnVenta from "../../Views/Clientes/Consignas/ConsignaEnVenta"
import ConsignaVendidas from "../../Views/Clientes/Consignas/ConsignaVendidas"
import ConsignaLlegadas from "../../Views/Clientes/Consignas/ConsignaLlegadas"
import ConsignaRechazadas from "../../Views/Clientes/Consignas/ConsignaRechazadas"
import ClientesProfile from "../../Components/Clientes/ClientesProfile"
import CitasConsigna from "../../Views/Clientes/Citas/CitasConsigna"
import ClientesIntercambioView from "../../Views/Clientes/Intercambio/ClientesIntercambioView"
import IntercambioPropuestas from "../../Views/Clientes/Intercambio/IntercambioPropuestas"
import IntercambiosView from "../../Views/Clientes/Intercambio/IntercambiosView"
import CitasIntercambio from "../../Views/Clientes/Citas/CitasIntercambio"
import PaymentFlowPrimerPago from "../../Components/Pagos/Apartar/PrimerPago/PaymentFlowPrimerPago"
import ClientesApartadoView from "../../Views/Clientes/Apartados/ClientesApartadoView"
import ApartadoEntregado from "../../Views/Clientes/Apartados/ApartadoEntregado"
import ApartadoDevuelto from "../../Views/Clientes/Apartados/ApartadoDevuelto"
import ApartadoProceso from "../../Views/Clientes/Apartados/ApartadoProceso"
import ApartadoProcesoProduct from "../../Views/Clientes/Apartados/ApartadoProcesoProduct"
import ApartadoPrimerProcesoProduct from "../../Views/Clientes/Apartados/ApartadoPrimerProcesoProduct"
import ApartadoTransito from "../../Views/Clientes/Apartados/ApartadoTransito"
import ApartadoDeseo from "../../Views/Clientes/Apartados/ApartadoDeseo"
import CitasRenta from "../../Views/Clientes/Citas/CitasRenta"
import PaymentFlowPrimerPagoRenta from "../../Components/Pagos/Rentar/PrimerPago/PaymentFlowPrimerPagoRenta"
import ClientesRentasView from "../../Views/Clientes/Rentas/ClientesRentasView"
import ClienteRentas from "../../Views/Clientes/Rentas/ClienteRentas"
import RentaWishList from "../../Views/Clientes/Rentas/RentaWishList"
import RentaPrimerProcesoProduct from "../../Views/Clientes/Rentas/RentaPrimerProcesoProduct"
import ProcesoEvaluacion from "../../Components/Preevaluaciones/ProcesoEvaluacion"
import PreevaluacionDetalles from "../../Components/Preevaluaciones/PreevaluacionMas2/PreevaluacionDetalles"
import CancelacionesView from "../../Views/Cancelaciones/CancelacionesView"
import CancelacionInfo from "../../Components/Cuestionarios/CancelacionDevoluciones/CancelacionInfo"
import Confirmation3DsGuide from "../../Components/Pagos/Guias/Confirmation3DsGuide"
import Confirmation3DsCert from "../../Components/Pagos/Certificados/Confirmation3DsCert"
import Confirmation3Ds from "../../Components/Pagos/Confirmation3Ds"
import Confirmation3DsLiq from "../../Components/Pagos/Confirmation3DsLiq"
import Confirmation3DsRefre from "../../Components/Pagos/Confirmation3DsRefre"
import Confirmation3DsApa from "../../Components/Pagos/Confirmation3DsApa"
import Faqs from "../../Layout/Faqs"
import PrivacyNotice from "../../Layout/PrivacyNotice"
import MySnackBar from "../../Util/MySnackBar"
import AlertSuccessLogin from "../../Util/Alerts/AlertSuccessLogin"
import AlertsSuccess from "../../Util/Alerts/AlertsSuccess"
import AlertWarning from "../../Util/Alerts/AlertWarning"
import AlertInfo from "../../Util/Alerts/AlertInfo"
import LoadingScreen from "../../Util/LoadingScreen"
import LoadingScreenProgressBar from "../../Util/LoadingScreenProgressBar"
import CalendarConFor from "../../Components/Calendario/CalendarConFor"
import CalendarConForMB from "../../Components/Calendario/CalendarConForMB"
import CitasConForaneas from "../../Views/Clientes/Citas/CitasConForaneas"
import EnviosConForView from "../../Views/Envios/ConsignaForanea/EnviosConForView"
import ClienteApartadoDetails from "../../Views/Clientes/Apartados/ClienteApartadoDetails"
import ClienteApartadoDetails2 from "../../Views/Clientes/Apartados/ClienteApartadoDetails2"
import PaymentFlowProximos from "../../Components/Pagos/Apartar/ProximosPagos/PaymentFlowProximos"
import Confirmation3DsApaProx from "../../Components/Pagos/Confirmation3DsApaProx"
import ApartadoDetalleShopify from "../../Views/Clientes/Apartados/ApartadoDetalleShopify"
import CuestionarioEmpConAdd2 from "../../Components/Cuestionarios/CuestionarioEmpConAdd2"
import FaqsFoundit from "../../Layout/FaqsFoundit"
import FacturacionFoundit from "../../Layout/FacturacionFoundit"

const RoutesLogueado = () => {
  //state de las alertas success LOGIN
  const [openAlertSuccessLogin, setOpenAlertSuccessLogin] = useState(false)
  const [messageAlertSuccessLogin, setMessageAlertSuccessLogin] = useState("")
  const handleCloseAlertSuccessLogin = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertSuccessLogin(false)
  }
  //state de las alertas success
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [messageAlertSuccess, setMessageAlertSuccess] = useState("")
  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertSuccess(false)
  }

  //state de las alertas warinig
  const [openAlertWarnign, setOpenAlertWarning] = useState(false)
  const [messageAlertWarning, setMessageAlertWarning] = useState("")
  const handleCloseAlertWarning = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertWarning(false)
  }

  //state de las alertas info
  const [openAlertInfo, setOpenAlertInfo] = useState(false)
  const [messageAlertInfo, setMessageAlertInfo] = useState("")
  const handleCloseAlertInfo = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertInfo(false)
  }

  const [LSopen, setLSOpen] = React.useState(false)
  const [Msj, setMsj] = React.useState("")

  const [LSPBopen, setLSPBopen] = React.useState(false)
  const [LSPBMsj, setLSPBMsj] = React.useState("")

  const [SBOpen, setSBOpen] = React.useState(false)
  const [message, setMessage] = React.useState("")

  const [type, setType] = React.useState("")
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setSBOpen(false)
  }

  /* Estados Loading Escreem */
  const openLoadingScreen = () => {
    setLSOpen(true)
  }
  const closeLoadingScreen = () => {
    setLSOpen(false)
  }

  /* Estados Loading Escreem With Progress Bar*/
  const openLoadingScreenPB = () => {
    setLSPBopen(true)
  }
  const closeLoadingScreenPB = () => {
    setLSPBopen(false)
  }

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  useEffect(() => {
    if (userInfo) {
      return JSON.parse(localStorage.getItem("userInfo"))
    }
  }, [userInfo])

  const isAuth = userInfo
  return (
    <>
      <MySnackBar
        Open={SBOpen}
        Type={type}
        Message={message}
        HandleClose={handleClose}
      />
      <AlertSuccessLogin
        openAlert={openAlertSuccessLogin}
        messageAlert={messageAlertSuccessLogin}
        handleCloseAlert={handleCloseAlertSuccessLogin}
      />

      <AlertsSuccess
        openAlert={openAlertSuccess}
        messageAlert={messageAlertSuccess}
        handleCloseAlert={handleCloseAlertSuccess}
      />

      <AlertWarning
        openAlert={openAlertWarnign}
        messageAlert={messageAlertWarning}
        handleCloseAlert={handleCloseAlertWarning}
      />

      <AlertInfo
        openAlert={openAlertInfo}
        messageAlert={messageAlertInfo}
        handleCloseAlert={handleCloseAlertInfo}
      />

      <LoadingScreen open={LSopen} Msj={Msj} />

      <LoadingScreenProgressBar open={LSPBopen} Msj={LSPBMsj} />
      <MainContainerLayout>
        <HeaderLayoutCliente
          setMsjLoadingScreen={setMsj}
          setOpenLoadingScreen={openLoadingScreen}
          setCloseLoadingScreen={closeLoadingScreen}
          setOpenSnackBar={setSBOpen}
          setMessageSnackBar={setMessage}
          setTypeSnackBar={setType}
          setOpenAlert={setOpenAlertSuccessLogin}
          setMessageAlert={setMessageAlertSuccessLogin}
          setOpenAlertWarning={setOpenAlertWarning}
          setMessageAlertWarning={setMessageAlertWarning}
        />

        {/* <FooterMundoBarter> */}
        <FloatinfWhatsApp />
        <ScrollTop />
        <Routes>
          <Route
            exact
            path={INICIO}
            element={
              // <HomeMundoBarter
              // 	setMsjLoadingScreen={setMsj}
              // 	setOpenLoadingScreen={openLoadingScreen}
              // 	setCloseLoadingScreen={closeLoadingScreen}
              // 	setOpenSnackBar={setSBOpen}
              // 	setMessageSnackBar={setMessage}
              // 	setTypeSnackBar={setType}
              // />
              <HomeMundoBarter2
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          {/* -------------TERMINOS-CONDICIONES------------ */}
          <Route
            exact
            path={MB_TERMINOS_CONDICIONES}
            element={<TermsConditions />}
          />
          {/* -------------AVISOS DE PRIVACIDAD------------ */}
          <Route exact path={MB_AVISO_PRIVACIDAD} element={<PrivacyNotice />} />
          {/* -------------FAQS------------ */}
          <Route exact path={MB_FAQS} element={ <FaqsFoundit />} />
          {/* ------------------------- */}
          {/* PAGOS EXTRAORDINARIOS */}
          <Route
            exact
            path={PAGOS_EXTRAUDINARIOS}
            element={
              <PagosExtraudinarios
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />

          <Route
            exact
            path={PAGOS_EXTRAUDINARIOS_GUIAS}
            element={
              <PagosGuias
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />

          <Route
            exact
            path={PAGOS_EXTRAUDINARIOS_CERTIFICADOS}
            element={
              <PagosCertificados
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />

          {/* ENVIOS */}
          <Route
            exact
            path={ENVIO_VIEW}
            element={
              <EnviosView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={ENVIO_DETAIL}
            element={
              <EnviosDetalles
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={ENVIOS_EN_TRANSITO}
            element={
              <EnviosEnTransito
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={ENVIOS_RECIBIDOS}
            element={
              <EnviosRecibidos
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          {/* CUESTIONARIO DE PREEVALUACION */}
          <Route
            exact
            path={CUESTIONARIO}
            element={
              // openLoadingScreenPB
              // closeLoadingScreenPB
              //  setLSPBMsj
              <CuestionarioEmpConAdd2
                setMsjLoadingScreen={setLSPBMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenLoadingScreenPB={openLoadingScreenPB}
                setCloseLoadingScreenPB={closeLoadingScreenPB}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccessLogin}
                setMessageAlert={setMessageAlertSuccessLogin}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          {/* EMPEÑO */}
          <Route
            exact
            path={CLIENTES_EMP_VIEW}
            element={
              <ClienteEmpenoViewMB
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_EMP + ":id"}
            element={
              <ClienteEmpeñoView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_EMP_REFRENDO + ":id"}
            element={
              <ClienteEmpeñoRefrendar
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_EMP_DESEMPEÑO + ":id"}
            element={
              <ClienteEmpeñoDesempeño
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_EMP_PRO_VIEW}
            element={
              <EmpeñoProView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CITAS_MB}
            element={
              <CitasView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CITAS_MB_EMPEÑO}
            element={
              <CitasEmpeño
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CITAS_MB_DESEMPEÑO}
            element={
              <CitasDesempeño
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CITAS_MB_CON_VENCIDAS}
            element={
              <CitasConVencidas
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_EMP_PROPUESTAS_VIEW}
            element={
              <EmpeñoPropuestas
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={ENVIO_PIEZAS_DHL + ":id"}
            element={
              <SolicitarGuiaDhlAdd
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={ENVIO_PIEZAS_OTRA_PAQUETERIA + ":id"}
            element={
              <OtraPaqueteriaAdd
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CALENDAR_VIEW + ":id"}
            element={
              <CalendarScreen
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CALENDAR_VIEW_RESCHEDULE + ":lngIdCitas"}
            element={
              <CalendarReschedule
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CALENDAR_VIEW_MB + ":lngIdProducto"}
            element={
              <CalendarMb
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CALENDAR_VIEW_RMB + ":lngIdProducto"}
            element={
              <CalendarRmb
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CALENDAR_VIEW_CON_VEN_MB + ":lngIdGuia"}
            element={
              <CalendarConVenMb
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CALENDAR_VIEW_CON_VEN_RMB + ":lngIdGuia"}
            element={
              <CalendarConVenRMb
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CALENDAR_VIEW_CON_FOR_MB + ":id"}
            element={
              <CalendarConFor
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CALENDAR_VIEW_CON_FOR_RMB + ":id"}
            element={
              <CalendarConForMB
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_EMP_REFRENDO_VIEW}
            element={
              <EmpeñoRefrendo
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_EMP_DESEMPEÑO_VIEW}
            element={
              <EmpeñoDesempeño
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          {/* FLUJOS DE PAGOS */}
          <Route
            exact
            path={FLUJO_PAGO + ":id"}
            element={
              <PaymentFlow
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={FLUJO_REFRENDO + ":id"}
            element={
              <PaymentFlowRef
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={FLUJO_LIQUIDAR + ":id"}
            element={
              <PaymentFlowLiq
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={FLUJO_GUIAS}
            element={
              <PaymentFlowGuias
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={FLUJO_CERTIFICADOS}
            element={
              <PaymentFlowCerticados
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={FLUJO_REFRENDAR + ":id"}
            element={
              <PaymentFlowRefrendar
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          {/* CONSIGNA */}

          <Route
            exact
            path={CLIENTES_CONSIGNAS_VIEW}
            element={
              <ClientesConsignaPiezasView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_CONSIGNAS + ":id"}
            element={
              <ClientesConsignaView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_CON_PROPUESTAS_VIEW}
            element={
              <ConsignaPropuestas
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_CONSIGNAS_PIEZAS}
            element={
              <ConsignaProView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />

          <Route
            exact
            path={CLIENTES_CONSIGNAS_GUI}
            element={
              <ConsignaGuia
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_CONSIGNAS_GUIEX}
            element={
              <ConsignaGuiaExpira
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_CONSIGNAS_REC}
            element={
              <ConsignaRecibido
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_CONSIGNAS_TRA}
            element={
              <ConsignaTransito
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_CONSIGNAS_EN_VENTA}
            element={
              <ConsignaEnVenta
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_CONSIGNAS_VENTAS}
            element={
              <ConsignaVendidas
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_CONSIGNAS_LLEGADOS}
            element={
              <ConsignaLlegadas
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_CONSIGNAS_RECHAZO}
            element={
              <ConsignaRechazadas
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_PROFILE}
            element={
              <ClientesProfile
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccessLogin}
                setMessageAlert={setMessageAlertSuccessLogin}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CITAS_MB_CONSIGNA}
            element={
              <CitasConsigna
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CITAS_MB_CONSIGNA_FORANEA}
            element={
              <CitasConForaneas
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />

          {/* INTERCAMBIO */}
          <Route
            exact
            path={CLIENTES_INTERCAMBIO_VIEW}
            element={
              <ClientesIntercambioView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_INTERCAMBIO_PROPUESTAS_VIEW}
            element={
              <IntercambioPropuestas
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_INTERCAMBIO_PROD_VIEW}
            element={
              <IntercambiosView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CITAS_MB_INTERCAMBIO}
            element={
              <CitasIntercambio
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          {/* APARTADOS */}
          <Route
            exact
            path={FLUJO_APARTAR + ":id"}
            element={
              <PaymentFlowPrimerPago
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_SECTION_VIEW}
            element={
              <ClientesApartadoView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_SECTION_ENT}
            element={
              <ApartadoEntregado
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_SECTION_GUI}
            element={
              <ApartadoDevuelto
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_SECTION_PRO}
            element={
              <ApartadoProceso
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_SECTION_PRO_PAY + ":id"}
            element={
              <PaymentFlowProximos
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />

          <Route
            exact
            path={CLIENTES_SECTION_PRO_VIEW}
            element={
              <ApartadoProcesoProduct
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_SECTION_FIRST_PRO_VIEW}
            element={
              <ApartadoPrimerProcesoProduct
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />

          <Route
            exact
            path={CLIENTES_SECTION_TRA}
            element={
              <ApartadoTransito
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_SECTION_WISH}
            element={
              <ApartadoDeseo
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          {/* RENTA */}
          <Route
            exact
            path={CITAS_MB_RENTA}
            element={
              <CitasRenta
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={FLUJO_RENTAR + ":id"}
            element={
              <PaymentFlowPrimerPagoRenta
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_RENT_VIEW}
            element={
              <ClientesRentasView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_RENT_PROD_VIEW}
            element={
              <ClienteRentas
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_RENT_WISH_LIST_PROD_VIEW}
            element={
              <RentaWishList
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_RENT_FIRST_PROD_VIEW}
            element={
              <RentaPrimerProcesoProduct
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />

          {/* PERFIL MUNDO BARTER */}
          <Route
            exact
            path={CLIENTES_PROFILE}
            element={
              <ClientesProfile
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={PREEVALUACIONES_DETAILS + ":id"}
            element={
              <ProcesoEvaluacion
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={PREVALUACIONES_DIN_DETAILS + ":id"}
            element={
              <PreevaluacionDetalles
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />

          <Route
            exact
            path={CANCELACIONES_VIEW}
            element={
              <CancelacionesView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={CANCELACIONES_DETAILS + ":id"}
            element={
              <CancelacionInfo
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />

          <Route
            exact
            path={"/ComfirmationPaymentGuide/:reference"}
            element={
              <Confirmation3DsGuide
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={"/ComfirmationPaymentCert/:reference"}
            element={
              <Confirmation3DsCert
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />

          <Route
            exact
            path={"/ComfirmationPayment/:reference"}
            element={
              <Confirmation3Ds
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={"/ComfirmationPayment3dsLiq/:reference"}
            element={
              <Confirmation3DsLiq
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={"/ComfirmationPayment3dsRefrendar/:reference"}
            element={
              <Confirmation3DsRefre
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={"/ConfirmationPayment3DsApa/:reference"}
            element={
              <Confirmation3DsApa
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={"/ConfirmationPayment3DsProxApa/:reference"}
            element={
              <Confirmation3DsApaProx
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
              />
            }
          />
          <Route
            exact
            path={ENVIO_EVALUACION}
            element={
              <EnviosConForView
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={PROD_APARTADO_DETAILS + ":id"}
            element={
              <ClienteApartadoDetails
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={PROD_APARTADO_DETAILS2 + ":id"}
            element={
              <ClienteApartadoDetails2
                setMsjLoadingScreen={setMsj}
                setOpenLoadingScreen={openLoadingScreen}
                setCloseLoadingScreen={closeLoadingScreen}
                setOpenSnackBar={setSBOpen}
                setMessageSnackBar={setMessage}
                setTypeSnackBar={setType}
                setOpenAlert={setOpenAlertSuccess}
                setMessageAlert={setMessageAlertSuccess}
                setOpenAlertWarning={setOpenAlertWarning}
                setMessageAlertWarning={setMessageAlertWarning}
              />
            }
          />
          <Route
            exact
            path={CLIENTES_SECTION}
            element={
              <>
                <ApartadoDetalleShopify
                  setMsjLoadingScreen={setMsj}
                  setOpenLoadingScreen={openLoadingScreen}
                  setCloseLoadingScreen={closeLoadingScreen}
                  setOpenSnackBar={setSBOpen}
                  setMessageSnackBar={setMessage}
                  setTypeSnackBar={setType}
                  setOpenAlert={setOpenAlertSuccessLogin}
                  setMessageAlert={setMessageAlertSuccessLogin}
                  setOpenAlertWarning={setOpenAlertWarning}
                  setMessageAlertWarning={setMessageAlertWarning}
                  hola={"HOLA"}
                />
              </>
            }
          />
            {/* -------------FACTURACION------------ */}
            <Route
                  exact
                  path={MB_FACTURACION}
                  element={
                    <div>
                     
                      {/* <Faqs /> */}
                      <FacturacionFoundit />
                     
                    </div>
                  }
                />
           {/* -------------FAQS------------ */}
           <Route
                  exact
                  path={MB_FAQS}
                  element={
                    <div>
                     
                      <FaqsFoundit />
                    
                    </div>
                  }
                />
          {/*  */}
        </Routes>
        {/* </FooterMundoBarter> */}
      </MainContainerLayout>
    </>
  )
}

export default RoutesLogueado

import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
	Box,
	Button,
	TextField,
} from '@mui/material'
import imgBarterFooter from '../../Images/logo-barter-sin-fondo.webp'
import { useFormik } from 'formik'
import { loginConfirma } from '../../Actions/UserActions'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'


const ConfirmationMail = (props) => {

	const navigate = useNavigate()
	const valores = useLocation()
	var url = valores.search
	const SKU = url.slice(5)

	const dispatch = useDispatch()

	const formik = useFormik({
		initialValues: {
			id: SKU,
			code: '',
		},
		validationSchema: Yup.object({
			code: Yup.string().required('Código requerido.'),
		}),
		onSubmit: (values) => {
			dispatch(loginConfirma(values, props))
			navigate('/')
		},
	})

	return (
		<div className='bodyConfEmail'>
			<div>
				<div className='cardConfEmail shadow '>
					<div className='cardConfEmailContent'>
						<h1>Confirmar email</h1>
						<p>Ingrese el código de confirmación</p>
						<img src={imgBarterFooter} alt='LogoMundoBarter' />
						<Box
							className='mt-20'
							component='form'
							onSubmit={formik.handleSubmit}
						>
							<TextField
								required
								variant='standard'
								color='warning'
								fullWidth
								size='small'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.code}
								error={
									formik.touched.code && Boolean(formik.errors.code)
								}
								helperText={
									formik.touched.code && formik.errors.code
								}
								name='code'
							/>
							<Button className='btnCardEmail mt-20 w100' type='submit'>
								Confirmar
							</Button>
						</Box>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ConfirmationMail

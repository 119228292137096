/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import {
  Card,
  CardActions,
  CardContent,
  Button,
  CardMedia,
  Alert,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import {
  PROPUESTA_LIST_INTER_BY_ID_CLIENTE,
  SEND_STATUSCRM_MB,
} from "../../../Constants/ApiConstants"
import { Chip } from "@mui/material"
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle"
import CitasAdd from "../../../Components/Preevaluaciones/Citas/CitasAdd"
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import "../../../Styles/App/Apartado/Apartado.css"
import requests from "../../../Components/AxiosCalls/AxiosCall"
import NumberFormat from "react-number-format"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import PreviewImg from "../../../Components/PreviewImg"
import InfoIcon from "@mui/icons-material/Info"
import IconButton from "@mui/material/IconButton"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"

const IntercambioPropuestas = (props) => {
  const [_Propuestas, setPropuestas] = useState([])

  const [_IdPropuesta, setIdPropuesta] = useState()
  const [_Ocultar, setOcultar] = useState(true)
  const [_Ocultar2, setOcultar2] = useState(true)

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const getPropuestas = () => {
    props.setOpenLoadingScreen()
    requests
      .get(PROPUESTA_LIST_INTER_BY_ID_CLIENTE, userInfo.intIdUsuario)
      .then((response) => {
        console.log(response)
        setPropuestas(response)
        if (response.length > 0) {
          setOcultar(false)
          setOcultar2(true)
        } else {
          setOcultar(true)
          setOcultar2(false)
        }
        props.setCloseLoadingScreen()
        setIdPropuesta(null)
      })
      .catch((error) => {
        console.log("Error: " + error)
        props.setCloseLoadingScreen()
      })
  }

  useEffect(() => {
    getPropuestas()
  }, [])

  const handleChangeSendStatusRechazado = (idRegistro) => {
    props.setOpenLoadingScreen()
    var url = SEND_STATUSCRM_MB.replace("{LngIdRegistro}", idRegistro).replace(
      "{IntIdStatusCrm}",
      "c2ae1194-2335-4987-bb96-5897fe028365"
    )
    requests
      .get(url)
      .then((response) => {
        console.log(response)
        props.setTypeSnackBar("success")
        //rechazo
        props.setMessageAlertWarning(
          "Lamentamos que hayas tomado esta decisión esperamos vuelvas pronto a Barter Luxe, te recordamos que tenemos disponibles para ti los siguientes servicios (consigna, renta y empeño)"
        )
        props.setOpenAlertWarning(true)
        // window.location.reload()
        getPropuestas()
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }
  const handleChangeSendStatusCita = (idRegistro) => {
    props.setOpenLoadingScreen()
    var url = SEND_STATUSCRM_MB.replace("{LngIdRegistro}", idRegistro).replace(
      "{IntIdStatusCrm}",
      "c10d01a9-a2fc-4cbb-9f3a-1a7a5b9e8b61"
    )
    requests
      .get(url)
      .then((response) => {
        props.setTypeSnackBar("success")
        //Exito
        props.setMessageAlert(response.mensaje)
        props.setOpenAlert(true)
        props.setCloseLoadingScreen()
        handleOpen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  //abrir modal img
  const [IdImgPreview, setIdImgPreview] = useState()
  const [openModalImgPreview, setModalImgPreview] = useState(false)
  const handleOpenImgPreview = () => {
    setModalImgPreview(!openModalImgPreview)
  }

  return (
    <div>
      <div className="containerViewUser" hidden={_Ocultar}>
        <div className="flex-start w100">
          <Chip
            icon={<ChangeCircleIcon />}
            label="Mis intercambios / Propuestas"
            color="primary"
            className="back-transparent font-letter font-barter font-20"
          />
        </div>

        {/*
				{
					"lngIdPropuestas": 8,
					"lngIdRegistro": 86,
					"fecPropuestas": "17/01/2023",
					"dblMontoPropuesta": 5500,
					"bolAprobacion": true,
					"txtArticulo": "Zapatos",
					"txtMarca": "CHANEL",
					"txtOtraMarca": null,
					"txtUrlImgFrente": "https://dl.dropbox.com/s/lfqlsaqwhr3m9ty/PARA1418CHI15.png?dl=0",
					"bolUpdateMb": true,
					"intIdTipoServicio": 3,
					"txtTipoServicio": "Intercambio",
					"bolCitaAgendada": true
				}
				 */}

        <div className="box-consigna-pro mt3">
          {_Propuestas.map((row, index) => (
            <div className="box-list-consigna-pro">
              <div className="w20 relative">
                <img className="img-consigna-pro" src={row.txtUrlImgFrente} />
                <div className="box-view-absolute">
                  <IconButton
                    className="btn-blur"
                    onClick={function () {
                      setIdImgPreview(row.txtUrlImgFrente)
                      handleOpenImgPreview()
                    }}
                  >
                    <RemoveRedEyeIcon sx={{ m: 0, p: 0, color: "#000000" }} />
                  </IconButton>
                </div>
              </div>
              <div className="flex-column-start-between w80 pl1">
                <div className="flex-column-start w100">
                  <div className="flex-between">
                    <span className="p20 bold upperCase">
                      {row.txtArticulo} {row.txtMarca} {row.txtOtraMarca}
                      <span className="mx1"> - </span>
                      <span className="p">{row.txtTipoServicio}</span>
                    </span>

                    <span className="p co-grey">{row.fecPropuestas}</span>
                  </div>
                  <span className="p ">
                    <strong>Cuenta con Cita: &nbsp;</strong>
                    {row.bolCitaAgendada === true ? "si" : "no"}
                  </span>
                  <span className="p ">
                    <strong>Propuesta: &nbsp;</strong>

                    <NumberFormat
                      value={row.dblMontoPropuesta}
                      displayType={"text"}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={"$ "}
                      suffix={" DÓLARES"}
                    />
                  </span>
                </div>

                <div className="flex-start">
                  {row.bolUpdateMb === false ? (
                    <CardActions>
                      <Button
                        className="btn-success mr1"
                        startIcon={<CheckCircleOutlineOutlinedIcon />}
                        onClick={function () {
                          setIdPropuesta(row.lngIdPropuestas)
                          handleChangeSendStatusCita(row.lngIdRegistro)
                        }}
                      >
                        Aceptar
                      </Button>
                      <Button
                        className="btn-fail mr1"
                        startIcon={<CancelOutlinedIcon />}
                        onClick={function () {
                          handleChangeSendStatusRechazado(row.lngIdRegistro)
                        }}
                      >
                        Rechazar
                      </Button>
                    </CardActions>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div hidden={_Ocultar2}>
        <Alert
          className="alert-no-data"
          icon={<InfoIcon fontSize="inherit" className="alert-icon-no-data" />}
        >
          Aún no cuentas con propuestas, sobre tus articulos.
        </Alert>
      </div>
      <CitasAdd
        registroId={_IdPropuesta}
        open={open}
        onClose={handleOpen}
        getRegistros={getPropuestas}
        setOpenLoadingScreen={props.setOpenLoadingScreen}
        setCloseLoadingScreen={props.setCloseLoadingScreen}
        setOpenSnackBar={props.setOpenSnackBar}
        setMessageSnackBar={props.setMessageSnackBar}
        setTypeSnackBar={props.setTypeSnackBar}
        setMessageAlert={props.setMessageAlert}
        setOpenAlert={props.setOpenAlert}
        setMessageAlertWarning={props.setMessageAlertWarning}
        setOpenAlertWarning={props.setOpenAlertWarning}
      />
      <PreviewImg
        IdImgPreview={IdImgPreview}
        openModalImgPreview={openModalImgPreview}
        onCloseModalImgPreview={handleOpenImgPreview}
      />
    </div>
  )
}

export default IntercambioPropuestas

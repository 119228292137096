/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import {
  CLIENTES_SECTION_VIEW,
  CLIENTES_SECTION_PRO,
} from '../../../Constants/routesConstants'
import { useDispatch, useSelector } from 'react-redux'
import { postLiquidarEmpCreate } from '../../../Actions/MundoBarterActions'

import {
  // EMPEÑO_BY_ID,
  // EMPEÑO_PAGO_LIST_BY_ID_EMPEÑO,
  GET_DET_APA_BY_IDCLIPRO,
  GET_LIST_PAG_APA_IDCLIPRO,
} from '../../../Constants/ApiConstants'

import requests from '../../../Components/AxiosCalls/AxiosCall'
import '../../../Styles/App/Empeño/EmpeñoView.css'
import NumberFormat from 'react-number-format'

import '../../../Styles/App/Apartado/ApartadoDetail.css'

const ClienteApartadoDetails = (props) => {
  const { id } = useParams()
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const [_Parametrs, setParameters] = useState({
    idProd: id,
    idClient: userInfo.intIdUsuario,
  })

  const [_DataInfo, setDataInfo] = useState({})
  const [_Empeño, setEmpeño] = useState({})
  const [_Abonos, setAbonos] = useState([])
  const [_OcultarFecha, setOcultarFecha] = useState(false)
  const [expanded, setExpanded] = useState(false)

  let date = new Date()
  let day = `${date.getDate()}`.padStart(2, '0')
  let month = `${date.getMonth() + 1}`.padStart(2, '0')
  let year = date.getFullYear()
  let fecActual = `${day}/${month}/${year}`

  const handleChange = (panel1) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel1 : false)
  }
  const getInfo = async () => {
    // props.setOpenLoadingScreen()
    await requests
      .post(GET_DET_APA_BY_IDCLIPRO, _Parametrs)
      .then((response) => {
        setDataInfo(response)
        // props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log('Error: ' + error)
        props.setCloseLoadingScreen()
      })
  }
  const [_Totales, setTotales] = useState({
    liquidacion: 0,
    regrendo: 0,
  })
  const calculo = (data) => {
    let pago = (data.dblCostoEmpe * 2.12) / 100 + 3
    let total = data.dblCostoEmpe + pago
    let re = (data.dblImporteDesemp * 20) / 100
    let pagos = (re * 2.12) / 100 + 3
    let pago2 = re + pagos
    setEmpeño(data)
    setTotales({ ..._Totales, liquidacion: total, regrendo: pago2 })
  }
  const [_Ocultar, setOcultar] = useState(true)
  const [_Ocultar2, setOcultar2] = useState(true)
  const getAbonos = async () => {
    // props.setOpenLoadingScreen()
    await requests
      .post(GET_LIST_PAG_APA_IDCLIPRO, _Parametrs)
      .then((response) => {
        setAbonos(response)
        if (response.length > 0) {
          setOcultar(false)
          setOcultar2(true)
        } else {
          setOcultar(true)
          setOcultar2(false)
        }
        // props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log('Error: ' + error)
        props.setCloseLoadingScreen()
      })
  }
  const [_redirect, setRedirect] = useState(false)

  const [_SendEmail, setSendEmail] = useState({
    orderId: '',
    idProductoParm: '',
    estadoPf: '',
    codePf: '',
    idClient: '',
    idPorApart: '',
    intIdPlazo: '',
    totalAmount: '',
    skuProduct: '',
    amountPf: '',
    taxPf: '',
    apartadoPF: '',
    apartadoAprobado: '',
    apartadoTotalAmount: '',
    apartadoAmount: '',
    apartadoTax: '',
  })
  const sendEmail = async () => {
    const url = window.location.search
    const urlParams = new URLSearchParams(url)
    const parametro1 = urlParams.get('PARM_ORDER')
    const parametro2 = urlParams.get('PARM_PRODUCT')
    const parametroEstado = urlParams.get('Estado')
    const parametroCodePf = urlParams.get('Oper')
    const parametroidClient = urlParams.get('CLIENT_FOUND')
    const parametroIdApartado = urlParams.get('CLIEN_APART_FOUND')
    const parametroIntPlazo = urlParams.get('CLIEN_PLA_FOUND')
    const parametroSkuProduct = urlParams.get('PRODUCT_FOUND')
    const parametroApartado = urlParams.get('PARM_APARTA')
    const parametroAmount = urlParams.get('PARM_1')
    const parametroTax = urlParams.get('PARM_2')
    const parametrotoTal = urlParams.get('TotalPagado')
    const parametroAprobado = urlParams.get('PARM_APROBADO')
    const parametroTotalAmountClient = urlParams.get('TOTAL_AMOUNT_CLIENT')
    const parametroAmountClient = urlParams.get('AMOUNT_CLIENT')
    const parametroTaxClient = urlParams.get('TAX_CLIENT')

    _SendEmail.orderId = parametro1
    _SendEmail.idProductoParm = parametro2
    _SendEmail.estadoPf = parametroEstado
    _SendEmail.codePf = parametroCodePf
    _SendEmail.idClient = parametroidClient
    _SendEmail.idPorApart = parametroIdApartado
    _SendEmail.intIdPlazo = parametroIntPlazo
    _SendEmail.totalAmount = parametrotoTal
    _SendEmail.skuProduct = parametroSkuProduct
    _SendEmail.amountPf = parametroAmount
    _SendEmail.taxPf = parametroTax
    _SendEmail.apartadoPF = parametroApartado
    _SendEmail.apartadoAprobado = parametroAprobado
    _SendEmail.apartadoTotalAmount = parametroTotalAmountClient
    _SendEmail.apartadoAmount = parametroAmountClient
    _SendEmail.apartadoTax = parametroTaxClient

    if (_SendEmail.estadoPf === 'Aprobada') {
      props.setOpenLoadingScreen()
      await requests
        .post('PaymentsPagueloFacil/SendEmail/', _SendEmail)
        .then((response) => {
          setRedirect(true)
          props.setCloseLoadingScreen()
        })
        .catch((error) => {
          console.log('Error: ' + error)
          props.setCloseLoadingScreen()
        })
    } else {
      if (_SendEmail.estadoPf === 'Denegada') {
        props.setTypeSnackBar('warning')
        props.setMessageAlertWarning(
          'NO SE PUDO REALIZAR TU PAGO CORRECTAMENTE'
        )
        props.setOpenAlertWarning(true)
        setTimeout(() => {
          // window.location.href = `http://localhost:3000/apartado?sku=${_SendEmail.skuProduct}`
          // window.location.href = `https://sys-mundobl.azurewebsites.net/apartado?sku=${_SendEmail.skuProduct}`
          window.location.href = `https://pa.founditmembers.com/apartado?sku=${_SendEmail.skuProduct}`
        }, 1500)
      } else {
        if (_SendEmail.apartadoAprobado === 'Aprobado') {
          props.setOpenLoadingScreen()
          await requests
            .post('PaymentsPagueloFacil/SendEmailAprobado/', _SendEmail)
            .then((response) => {
              // debugger
              setRedirect(true)
              props.setCloseLoadingScreen()
            })
            .catch((error) => {
              console.log('Error: ' + error)
              props.setCloseLoadingScreen()
            })
        } else {
          props.setTypeSnackBar('warning')
          props.setMessageAlertWarning(
            'NO SE PUDO REALIZAR TU PAGO CORRECTAMENTE'
          )
          props.setOpenAlertWarning(true)
          setTimeout(() => {
            // window.location.href = `http://localhost:3000/apartado?sku=${_SendEmail.skuProduct}`
            // window.location.href = `https://sys-mundobl.azurewebsites.net/apartado?sku=${_SendEmail.skuProduct}`
            window.location.href = `https://pa.founditmembers.com/apartado?sku=${_SendEmail.skuProduct}`
          }, 1500)
        }
      }

      // debugger
      // // props.setCloseLoadingScreen()
      // props.setTypeSnackBar('warning')
      // props.setMessageAlertWarning('NO SE PUDO REALIZAR TU PAGO CORRECTAMENTE')
      // props.setOpenAlertWarning(true)
      // setTimeout(() => {
      //   window.location.href = `http://localhost:3000/apartado?sku=${_SendEmail.skuProduct}`
      //   // window.location.href = `https://sys-mundobl.azurewebsites.net/apartado?sku=${_SendEmail.skuProduct}`
      //   // window.location.href = `https://pa.founditmembers.com/apartado?sku=${_SendEmail.skuProduct}`
      // }, 1500)
    }

    // if (_SendEmail.apartadoAprobado === 'Aprobado') {
    //   props.setOpenLoadingScreen()
    //   await requests
    //     .post('PaymentsPagueloFacil/SendEmail/', _SendEmail)
    //     .then((response) => {
    //       debugger
    //       setRedirect(true)
    //       props.setCloseLoadingScreen()
    //     })
    //     .catch((error) => {
    //       console.log('Error: ' + error)
    //       props.setCloseLoadingScreen()
    //     })
    //   } else {
    //     debugger
    //     props.setTypeSnackBar('warning')
    //     props.setMessageAlertWarning('NO SE PUDO REALIZAR TU PAGO CORRECTAMENTE')
    //     props.setOpenAlertWarning(true)
    //     setTimeout(() => {
    //       window.location.href = `http://localhost:3000/apartado?sku=${_SendEmail.skuProduct}`
    //       // window.location.href = `https://sys-mundobl.azurewebsites.net/apartado?sku=${_SendEmail.skuProduct}`
    //       // window.location.href = `https://pa.founditmembers.com/apartado?sku=${_SendEmail.skuProduct}`
    //     }, 1500)
    //   }
  }

  useEffect(() => {
    const fetchData = async () => {
      await sendEmail()
      await getInfo()
      await getAbonos()
    }
    fetchData()
  }, [])

  // useEffect(() => {
  //   sendEmail()
  //   getInfo()
  //   getAbonos()
  // }, [])

  return (
    <>
      {_redirect === true ? (
        <div className="containerViewUser py4 paddingTablets">
          {/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
          <div className="flex-start w90 pb2 pl1">
            <div className="chip-breadcrumbs">
              <span className="letter-breadcrumbs">A</span>
            </div>

            <div role="presentation" className="ml2">
              <Breadcrumbs aria-label="breadcrumb" separator="›">
                <Link
                  underline="hover"
                  className="breadcrumb-url"
                  onClick={() => {
                    navigate(CLIENTES_SECTION_VIEW)
                  }}
                >
                  Abonado
                </Link>
                <Link
                  underline="hover"
                  className="breadcrumb-url "
                  onClick={() => {
                    navigate(CLIENTES_SECTION_PRO)
                  }}
                >
                  Mis Abonos
                </Link>
                <Link underline="hover" className="breadcrumb-url bold">
                  Detalle de pago
                </Link>
              </Breadcrumbs>
            </div>
          </div>

          <div className="CardApartadoDetailPagosCardStepper">
            <div className="BodyCardApartadoDetail">
              <div className="ApartadoDetailSection1">
                <div
                  className="BoxImgADetail"
                  style={{
                    backgroundImage: `url(${_DataInfo.txtUrl})`,
                  }}
                ></div>
              </div>
              <div className="ApartadoDetailSection2">
                <h2>Gracias por realizar tu pago</h2>
                <hr className="Dvividerhr" />
                {/* ***************** */}
                <div className="containerFlexBetween">
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Producto:</span>{' '}
                    {_DataInfo.txtCategoria} - {_DataInfo.txtMarca}
                  </span>
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">SKU:</span>{' '}
                    {_DataInfo.txtSkuReg}
                  </span>
                </div>
                {/* ***************** */}
                <div className="containerFlexBetween">
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Total a pagar:</span>{' '}
                    <NumberFormat
                      value={_DataInfo.dblPrecioFinal}
                      displayType={'text'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={'$ '}
                      suffix={' DÓLARES'}
                    />
                  </span>
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Liquida antes del:</span>{' '}
                    {_DataInfo.fecFinApartado}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* 
        <div className='body-empeño'>
          <div className='head-empeño flex-between p3'>
            <div className='img-refrendo-Desk w20'>
              <img className='img-EmpeñoView' src={_DataInfo.txtUrl} />
            </div>

            <div className='refrendo-section3-pay'>
              <p className='title-section3-pay'>Total a pagar</p>
              <p className='subtitle-section3-pay'>
                <NumberFormat
                  value={_DataInfo.dblPrecioFinal}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={'$ '}
                  suffix={'MXN'}
                />
              </p>

              <p>
                {_DataInfo.txtCategoria} - {_DataInfo.txtMarca}
              </p>
              <p>SKU: {_DataInfo.txtSkuReg}</p>
              <p className='title-section3-pay'>Gracias por realizar tu pago</p>
            </div>

            <div className='refrendo-img-desk-section2'>
              <img className='img-EmpeñoView' src={_DataInfo.txtUrl} />
            </div>

            <div className='refrendo-section1-pay'>
              <p className='title-ref-pay'>Liquida antes del: </p>
              <p className='subtitle-ref-pay'>{_DataInfo.fecFinApartado}</p>
            </div>
          </div>
        </div> */}
        </div>
      ) : null}
    </>
  )
}

export default ClienteApartadoDetails
